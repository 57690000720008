export const FILE_GATEWAY_SK = {
  FILEGATEWAY: {
    TITLE: 'Podpísať dokument',
    TITLE_CREATE: 'Nahrať dokumenty',
    WELCOME: 'Vitajte na stránke NFQES',
    WELCOME_MAIN:
      'NFQES je služba spoločnosti Brain:IT, ktorá je certifikačnou autoritou, pre poskytovanie a správu elektronických podpisov a zabezpečenie právnej istoty pri elektronickom podpisovaní.',
    WELCOME_SUB:
      'Táto služba Vás prevedie jednoduchým procesom elektronického podpisovania dokumentov spoločnosti ',
    WELCOME_SUB_CREATE:
      'Na tejto stránke nahráte súbory, ktoré môžu vaši príjemcovia podpísať. Pred uložením odporúčame prekontrolovať nastavenia.',
    CREATE_DATE: 'Dátum a čas vytvorenia',
    DEADLINE: 'Exspirácia správy',
    STATUS: 'Stav správy',
    NUMBER_OF_FILES: 'Počet súborov na podpis',
    SENDER: 'Odosielateľ',
    RECIPIENT: 'Príjemca',
    ALREADY_SIGNED:
      'Túto správu a súbory v nej ste už <b>podpísali</b>, preto nie je potrebné ich podpísať znova. <br/><br/><b>Riaďte sa pokynmi, ktoré ste obdržali od odosielateľa tejto správy.</b>',
    FILES: 'Súbory v správe',
    GRAPHICAL_SIGNATURE: 'Grafický podpis',
    SAVE_GRAPHICAL_SIGNATURE: 'Uložiť graf. podpis',
    REMOVE_GRAPHICAL_SIGNATURE: 'Nakresliť nový',
    MIN_SIGN_LEVEL: 'Minimálna úroveň podpisu',
    DIGITAL: 'Digitálny podpis',
    ES: 'Elektronický podpis',
    AdES: 'Pokročilý el. podpis',
    QES: 'Kvalifikovaný elektronický podpis',
    ALLOWED_TYPES: 'Povolené spôsoby podpísania',
    ALLOW_DIGI: 'Digitálny podpis',
    ALLOW_HAND: 'Ručný podpis',
    ALLOW_ONLINE_CERT: 'Online certifikát',
    ALLOW_CARD: 'Občianský preukaz alebo QSCD karta',
    AUTO_PREVIEW_FILES: 'Po načítaní zobraziť detail dokumentov',
    IS_PUBLIC: 'Vytvoriť verejnú správu (pôvodne file gateway)',
    SWAP_TO_GENERATE: 'Chcem vygenerovaný podpis',
    SWAP_TO_DRAW: 'Chcem ručný podpis',
    SETTINGS: 'Nastavenia',
    RECIPIENTS: 'Príjemcovia',
    RECIPIENTS_EMPTY: 'Zoznam príjemcov je prázdny.',
    RECIPIENT_NAME: 'Meno príjemcu',
    RECIPIENT_MAIL: 'Emailová adresa príjemcu',
    ADD_RECIPIENT: 'Pridať',
    REMOVE: 'Odstrániť',
    URL_TO_SIGN: 'URL adresa pre',
    URL_TO_DOWNLOAD: 'URL adresa pre stiahnutie',
    SAVE_MESSAGE: 'Uložiť a odoslať správu',
    ERROR_SENDING: 'Nastala chyba pri ukladaní!',
    SHORT_UUID: 'Vyplňte toto pole.',
    SENDER_UUID: 'Identifikačné údaje odosielateľa',
    SIGNED: 'Podpísal',
    NO_DOWNLOAD:
      'Súbory ešte <b>neboli podpísané</b> všetkými príjemcami, preto ich nie je možné stiahnúť.<br/><br/><b>Po podpísaní nájdete tlačidlo stiahnúť v tomto okne.</b>',
    DOWNLOAD:
      'Súbory <b>boli podpísané</b> všetkými príjemcami.<br/><br/><b>Kliknutím na túto správu spustíte sťahovanie.</b>',
    LOAD_MESSAGE: 'Načítať správu',
    NO_GRAPHIC_SIGNATURE:
      'Pre podpísanie dokumentu musíte <b>nakresliť</b> alebo <b>vygenerovať</b> grafický podpis.<br/>Pre nakreslenie alebo generovanie grafického podpisu môžete využiť <b>pole nižšie</b>.<br/><br/>Výsledný grafický podpis sa zobrazí v <b>úvodnej časti stránky</b>.',
    CONTINUE_TO_SIGN_BUTTON:
      'Pre dokončenie podpísania pokračujte na <b>spodnú časť stránky</b>, kde nájdete tlačidlo pre podpísanie.',
    DRAW_SIGNATURE: {
      PLACEHOLDER: 'Tu nakreslite grafický podpis',
    },
    SIGN: 'Podpísať dokumenty',
    SIGN_AND_PRESS_SIGN_BUTTON: 'Podpíšte sa prosím do poľa nižšie a stlačte tlačidlo PODPÍSAŤ.',
    EXPIRED: 'Tejto správe už vypršala platnosť a preto ju už nie je možné podpísať.',
    ERROR: {
      GET_FILE: 'Nepodarilo sa stiahnuť dokument',
    },
    ARCHIVED_UNTIL: 'Archivovať do:',
    WILL_BE_ARCHIVED: 'Archivované do',
  },
  DEFAULT: {
    YES: 'Áno',
    NO: 'Nie',
  },
};
