import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

const GREY = {
  0: '#FFFFFF',
  100: '#F7F9FA',
  200: '#F0F0F0',
  300: '#CDD1D3',
  400: '#AAAEB1',
  500: '#7F7F7F',
  600: '#64696D',
  700: '#41474B',
  800: '#1E2429',
  900: '#181C20',
  1000: '#000000',
  500_8: alpha('#7F7F7F', 0.08),
  500_12: alpha('#7F7F7F', 0.12),
  500_16: alpha('#7F7F7F', 0.16),
  500_24: alpha('#7F7F7F', 0.24),
  500_32: alpha('#7F7F7F', 0.32),
  500_48: alpha('#7F7F7F', 0.48),
  500_56: alpha('#7F7F7F', 0.56),
  500_80: alpha('#7F7F7F', 0.8),
  1000_65: alpha('#000', 0.65),
  1000_90: alpha('#000', 0.9),
};

const COMMON = {
  common: { black: GREY[1000], white: GREY[0] },
  black: {
    main: '#000000',
    contrastText: '#FFFFFF',
  },
  white: {
    main: '#FFFFFF',
    contrastText: '#000000',
  },
  progressBar: {
    high: '#A1D081',
    medium: '#FAD286',
    low: '#BC3F3F',
    zero: '#E1E1E3',
  },
  divider: GREY[500_48],
};

const LIGHT = {
  transparent: 'transparent',
  shadows: {
    invitation: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 2px, rgba(0, 0, 0, 0.06) 0px 1px 2px 2px',
    card: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    cardHover: 'rgba(50, 50, 93, 0.35) 0px 6px 12px -2px, rgba(0, 0, 0, 0.4) 0px 3px 7px -3px',
  },
  error: {
    contrastText: '#BC3F3F',
    main: '#BC3F3F',
    default: '#FEF0F0',
  },
  warning: {
    contrastText: '#9F3900',
    main: '#9F3900',
    default: '#FDF6EC',
  },
  info: {
    contrastText: '#1D4D6D',
    main: '#1D4D6D',
    default: '#D9EDF6',
    background: '#ECF6FA',
  },
  success: {
    contrastText: '#3C6321',
    main: '#3C6321',
    default: '#F0F9EB',
  },
  tile: {
    primary: '#1D4D6D',
    secondary: '#1B5F8D',
    tertiary: '#ABD0E9',
    quaternary: '#E1E1E3',
  },
  zero: {
    main: '#162232',
    contrastText: '#EEEEEE',
  },
  primary: {
    main: '#205985',
    contrastText: '#EEEEEE',
  },
  secondary: {
    main: '#379BDD',
    contrastText: '#EEEEEE',
  },
  tertiary: {
    main: '#9BCDEE',
    contrastText: '#142031',
  },
  quaternary: {
    main: '#EEEEEE',
    contrastText: '#142031',
  },
  text: {
    error: '#BC3F3F',
    success: '#A1D081',
    inputBorder: '#c4c4c4',
    primary: GREY[1000_90],
    secondary: GREY[1000_65],
    disabled: GREY[500],
    inverse: GREY[200],
    inverseLight: GREY[300],
  },
  background: {
    header: GREY[0],
    paper: GREY[0],
    default: '#FAFAFA',
    neutral: GREY[200],
    darker: GREY[300],
  },
  link: { primary: GREY[1000] },
  sidebar: {
    primary: '#08162C',
    secondary: GREY[200],
    premiumStip1: '#205985',
    premiumStip2: '#379BDD',
    premiumStip3: '#9bcdee',
  },
  disabled: {
    text: '#808080',
    background: '#E6E6E6',
    border: '#E6E6E6',
  },
  quota: {
    success: '#4CAF50',
    warning: '#FFA000',
    error: '#D32F2F',
  },
};

const DARK = {
  transparent: '#000000',
  shadows: {
    invitation: 'rgba(0, 0, 0) 0px 1px 3px 2px, rgba(0, 0, 0) 0px 1px 2px 2px',
    card: 'rgba(0,0,0, 0.55) 0px 6px 12px -2px, rgba(0, 0, 0, 0.5) 0px 3px 7px -3px',
    cardHover: 'rgba(0,0,0, 0.95) 0px 6px 12px -2px, rgba(0, 0, 0, 0.9) 0px 3px 7px -3px',
  },
  error: {
    contrastText: '#FFDEDE',
    main: '#BC3F3F',
    default: '#BC3F3F',
  },
  warning: {
    contrastText: '#FDF8E4',
    main: '#9F3900',
    default: '#9F3900',
  },
  info: {
    contrastText: '#D9EDF6',
    main: '#1D4D6D',
    default: '#1D4D6D',
    background: '#1E394B',
  },
  success: {
    contrastText: '#DEF0D8',
    main: '#3C6321',
    default: '#3C6321',
  },
  tile: {
    primary: '#1B5F8D',
    secondary: '#1D4D6D',
    tertiary: '#ABD0E9',
    quaternary: '#E1E1E3',
  },
  zero: {
    main: '#162232',
    contrastText: '#EEEEEE',
  },
  primary: {
    main: '#379BDD',
    contrastText: '#EEEEEE',
  },
  secondary: {
    main: '#205985',
    contrastText: '#EEEEEE',
  },
  tertiary: {
    main: '#9BCDEE',
    contrastText: '#142031',
  },
  quaternary: {
    main: '#000000',
    contrastText: '#FFFFFF',
  },
  text: {
    error: '#BC3F3F',
    success: '#A1D081',
    inputBorder: '#AFAFAF',
    primary: GREY[0],
    secondary: GREY[0],
    disabled: GREY[600],
    inverse: GREY[900],
    inverseLight: GREY[800],
  },
  background: {
    header: GREY[900],
    paper: GREY[800],
    default: '#111417', //GREY[1000],
    neutral: GREY[500_16],
    darker: GREY[700],
  },
  link: { primary: GREY[200] },
  sidebar: {
    primary: GREY[1000],
    secondary: GREY[500_32],
    premiumStip1: '#205985',
    premiumStip2: '#379BDD',
    premiumStip3: '#457397',
  },
  disabled: {
    text: '#888888',
    background: '#333333',
    border: '#333333',
  },
  quota: {
    success: '#2E7D32',
    warning: '#FFA000',
    error: '#D32F2F',
  },
};

const palette = {
  light: {
    ...COMMON,
    ...LIGHT,
    grey: { ...GREY },
    lightOverride: LIGHT,
    darkOverride: DARK,
  },
  dark: {
    ...COMMON,
    ...DARK,
    grey: { ...GREY },
    lightOverride: LIGHT,
    darkOverride: DARK,
  },
};

export default palette;
