import i18next, { t as fixedT } from 'i18next';
import { useTranslation } from 'react-i18next';

import { NotificationManager } from 'components/NotifyBox';

import { Box, styled } from '@mui/material';

import { InputProps } from '../hooks/useInput';
import CheckboxInput from './CheckboxInput';

const Link = styled('span')(({ theme }) => ({
  color: theme.palette.primary.light,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Star = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

interface EULAInputProps extends InputProps<boolean> {}

export default function EULAInput(props: EULAInputProps) {
  const { t } = useTranslation('sign');

  const handleClickEULA = (e) => {
    e.preventDefault();
    window.open(
      `${process.env.REACT_APP_NFQES_REDIRECT_URL}/${
        process.env[`REACT_APP_GENERAL_CONDITIONS_LINK${i18next.language === 'en' ? '_EN' : ''}`]
      }`,
      '_blank'
    );
  };

  const textEULA =
    props.error?.length > 0 ? (
      <Box component='span' sx={{ color: 'error.main' }}>
        {t('sign:AGREE_WITH')}{' '}
        <Link onClick={handleClickEULA} sx={{ color: 'error.main' }}>
          {t('sign:GENERAL_CONDITIONS')}
        </Link>{' '}
        <Star>*</Star>
      </Box>
    ) : (
      <Box component='span'>
        {t('sign:AGREE_WITH')} <Link onClick={handleClickEULA}>{t('sign:GENERAL_CONDITIONS')}</Link>{' '}
        <Star>*</Star>
      </Box>
    );

  return <CheckboxInput {...props} label={textEULA} />;
}

export function GDPRInput(props: EULAInputProps) {
  const { t } = useTranslation('certificate');

  const handleClickGDPR = (e) => {
    e.preventDefault();
    window.open(
      `${process.env.REACT_APP_NFQES_REDIRECT_URL}/${
        process.env[`REACT_APP_GDPR_LINK${i18next.language === 'en' ? '_EN' : ''}`]
      }`,
      '_blank'
    );
  };

  const textGDPR =
    props.error?.length > 0 ? (
      <Box component='span' sx={{ color: 'error.main' }}>
        {t('certificate:CREATE_CERT.UNDERSTAND')}{' '}
        <Link onClick={handleClickGDPR} sx={{ color: 'error.main' }}>
          {t('certificate:CREATE_CERT.GDPR')}
        </Link>{' '}
        <Star>*</Star>
      </Box>
    ) : (
      <Box component='span'>
        {t('certificate:CREATE_CERT.UNDERSTAND')}{' '}
        <Link onClick={handleClickGDPR}>{t('certificate:CREATE_CERT.GDPR')}</Link> <Star>*</Star>
      </Box>
    );

  return <CheckboxInput {...props} label={textGDPR} />;
}

export const checkGDPR = (gdprProps: { hasError: () => boolean }) => {
  if (!gdprProps.hasError()) return true;
  else {
    NotificationManager.warning(fixedT('EL_MAILBOX.NEED_AGREE'));
    return false;
  }
};

export const checkEULA = (eulaProps: { hasError: () => boolean }) => {
  if (!eulaProps.hasError()) return true;
  else {
    NotificationManager.warning(fixedT('EL_MAILBOX.NEED_AGREE'));
    return false;
  }
};
