import { useEffect, useState } from 'react';

import { CustomTheme } from 'theme/PaletteDTO';

import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';

const Bar = styled(Box)(({ theme, percent, active }: CustomTheme) => {
  let color = theme.palette?.progressBar.low;
  if (percent >= 35) color = theme.palette?.progressBar.medium;
  if (percent > 70) color = theme.palette?.progressBar.high;
  if (active === 'false') color = theme.palette.disabled.background;
  return {
    backgroundColor: color,
    borderRadius: '2px',
    width: '100%',
    height: '6px',
  };
});

const TypographySx = styled(Typography)(({ theme, percent }: CustomTheme) => {
  let color = theme.palette?.progressBar.low;
  if (percent >= 35) color = theme.palette?.progressBar.medium;
  if (percent > 70) color = theme.palette?.progressBar.high;
  return {
    color: color,
    fontSize: '11px !important',
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'right',
  };
});

interface IProps {
  value: number;
  maxValue: number;
  text: string;
}

export default function SimpleBarPassword(props: Readonly<IProps>) {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const percent = (props.maxValue > 0 ? value / props.maxValue : 0) * 100;

  return (
    <div>
      <Grid container spacing={'4px'}>
        <Grid item xs={3}>
          <Bar percent={percent}></Bar>
        </Grid>
        <Grid item xs={3}>
          <Bar xs={3} percent={percent} active={(percent > 25).toString()}></Bar>
        </Grid>
        <Grid item xs={3}>
          <Bar xs={3} percent={percent} active={(percent > 50).toString()}></Bar>
        </Grid>
        <Grid item xs={3}>
          <Bar xs={3} percent={percent} active={(percent > 75).toString()}></Bar>
        </Grid>
      </Grid>
      <TypographySx percent={percent}>{props.text}</TypographySx>
    </div>
  );
}
