import { LazyMotion } from 'framer-motion';
import PropTypes from 'prop-types';
import { ReactNode } from 'react';

interface MotionLazyContainerProps {
  children: ReactNode;
}

const loadFeatures = () => import('./Features.js').then((res) => res.default);

MotionLazyContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function MotionLazyContainer({ children }: MotionLazyContainerProps) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
