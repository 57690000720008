import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageSubscription = lazy(() =>
  lazyRetry(() => import('pages/(public)/subscription/PageSubscription'), 'PageSubscription')
);

const PageSubscriptionAdmin = lazy(() =>
  lazyRetry(() => import('pages/admin/subscription/PageSubscriptionAdmin'), 'PageSubscriptionAdmin')
);

const PageSubscriptionCreate = lazy(() =>
  lazyRetry(
    () => import('pages/admin/subscription/_components/create/[id]/PageSubscriptionCreate'),
    'PageSubscriptionCreate'
  )
);
const PageSubscriptionBuy = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/subscription/_components/[level]/PageSubscriptionBuy'),
    'PageSubscriptionBuy'
  )
);

const routesSubscribe: Array<DBRoute> = [
  {
    path: 'URL.SUBSCRIBE_INFO_ADMIN',
    name: 'DASHBOARD_ROUTES.SUBSCRIBE_INFO',
    icon: <Icon icon='ic:twotone-diamond' width={30} height={30} />,
    component: <PageSubscriptionAdmin />,
    show: true,
    user: false,
    admin: true,
    manager: false,
    order: 12,
  },
  {
    path: 'URL.SUBSCRIBE_INFO',
    name: 'DASHBOARD_ROUTES.SUBSCRIBE_INFO',
    icon: <Icon icon='ic:twotone-diamond' width={30} height={30} />,
    component: <PageSubscription />,
    show: true,
    user: true,
    admin: false,
    manager: false,
    order: 12,
  },
  {
    path: 'URL.SUBSCRIBE_BUY',
    component: <PageSubscriptionBuy />,
    show: false,
    user: true,
    admin: false,
    manager: false,
  },
  {
    path: 'URL.SUBSCRIBE',
    component: <PageSubscriptionCreate />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.SUBSCRIBE_UPDATE',
    component: <PageSubscriptionCreate />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesSubscribe;
