import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import i18next from 'i18next';

export class AuthService {
  public userManager: UserManager;

  metadata = {
    authorization_endpoint: process.env.REACT_APP_OAUTH + '/connect/authorize',
    token_endpoint: process.env.REACT_APP_OAUTH + '/connect/token',
    end_session_endpoint: process.env.REACT_APP_OAUTH + '/connect/endsession',
    issuer: process.env.REACT_APP_OAUTH,
    state: 'fungujem',
  };

  settings = {
    authority: process.env.REACT_APP_OAUTH + '/',
    client_id: process.env.REACT_APP_CLIENT_ID,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    redirect_uri: window.location.href.includes('.com')
      ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN + `/signin`
      : process.env.REACT_APP_OAUTH_REDIRECT_URL + `/signin`,
    post_logout_redirect_uri: window.location.href.includes('.com')
      ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN
      : process.env.REACT_APP_OAUTH_REDIRECT_URL,
    silent_redirect_uri: window.location.href.includes('.com')
      ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN + `/silent-renew`
      : process.env.REACT_APP_OAUTH_REDIRECT_URL + `/silent-renew`,
    response_type: 'code',
    scope: 'openid profile IdentityServerApi offline_access email',
    metadata: this.metadata,
    monitorSession: true,
    automaticSilentRenew: true,
    ui_locales: 'sk-SK',
    includeIdTokenInSilentRenew: true,
  };

  state = '';

  constructor() {
    this.userManager = new UserManager(this.settings);

    this.userManager.events.addAccessTokenExpiring(this.signinSilent);
    this.test();
  }

  async test() {
    const saved = localStorage.getItem(
      `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
    );
    if (saved === 'null' || !saved) return;
    const user = await this.userManager.getUser();
    if (user) {
      const exp = new Date(user.expires_at * 1000);
      console.log('Expiracia za ' + (exp.getTime() - new Date().getTime()) / 1000 + ' sekund.');
      console.log(exp);
    }
  }

  public async login(): Promise<void> {
    let lang = '';
    switch (i18next.language) {
      case 'en': lang = 'en-US'; break;
      case 'sk': lang = 'sk-SK'; break;
      case 'cz': lang = 'cz-CZ'; break;
      default: lang = 'sk-SK'; break;
    }

    await this.silentSignout();

    return this.userManager.signinRedirect({
      state: this.state,
      ui_locales: lang,
      prompt: 'login',
    });
  }

  public async silentSignout() {
    try {
      const idTokenHint = localStorage.getItem('oidc.ustok');
      if (idTokenHint) {
        await this.userManager.signoutSilent({
          silentRequestTimeoutInSeconds: 1,
          id_token_hint: JSON.parse(idTokenHint),
        });
        localStorage.removeItem('oidc.ustok');
      } else {
        console.log('[ AUTH SERVICE ] No id_token_hint found in localStorage.');
      }
    } catch (error) {
      return;
    }
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect({
      post_logout_redirect_uri: window.location.href.includes('.com')
      ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN
      : process.env.REACT_APP_OAUTH_REDIRECT_URL,
   });
  }

  public autoLogout(): Promise<void> {
    return this.userManager.signoutRedirect({
      post_logout_redirect_uri: window.location.href.includes('.com')
        ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN + '/automaticke-odhlasenie'
        : process.env.REACT_APP_OAUTH_REDIRECT_URL + '/automaticke-odhlasenie',
    });
  }

  public signinSilent = async () => {
    await this.userManager
      .signinSilent()
      .then(() => {
        console.log('Silent sign-in successful.');
      })
      .catch((error) => {
        console.error('Silent sign-in error', error);
        // this.autoLogout();
        this.handleSessionExpired();
      });
  };

  private handleSessionExpired() {
    this.userManager.clearStaleState();
    this.userManager.removeUser().then(() => {
      this.logout();
      console.warn('Session expired. Handling session expiration.');
    });
  }

  public signinRedirectCallback(): Promise<any> {
    return this.userManager.signinRedirectCallback().then((r) => {
      if (r && r?.id_token) localStorage.setItem('oidc.ustok', JSON.stringify(r?.id_token));
      localStorage.setItem('role', undefined);
      this.userManager = new UserManager(this.settings);
    });
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public isLoggedIn() {
    const localStorageValue = window.localStorage.getItem(
      `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
    );
    if (localStorageValue != null) {
      const oidcStorage = JSON.parse(localStorageValue);
      return !!oidcStorage && !!oidcStorage.access_token;
    } else {
      return false;
    }
  }

  public getToken() {
   const localStorageValue = window.localStorage.getItem(
     `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
   );
   if (localStorageValue != null) {
     const oidcStorage = JSON.parse(localStorageValue);
     if (oidcStorage && oidcStorage.access_token) {
       return oidcStorage.access_token;
     } else {
       return '';
     }
   } else {
     return '';
   }
 }

 public setState(state: string) {
   this.state = state;
 }
}

//  OLD USED SESSION STORAGE
// import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
// import i18next from 'i18next';

// export class AuthService {
//   public userManager: UserManager;

//   metadata = {
//     authorization_endpoint: process.env.REACT_APP_OAUTH + '/connect/authorize',
//     token_endpoint: process.env.REACT_APP_OAUTH + '/connect/token',
//     end_session_endpoint: process.env.REACT_APP_OAUTH + '/connect/endsession',
//     issuer: process.env.REACT_APP_OAUTH, // nove
//     state: 'fungujem',
//   };

//   settings = {
//     authority: process.env.REACT_APP_OAUTH + '/',
//     client_id: process.env.REACT_APP_CLIENT_ID,
//     userStore: new WebStorageStateStore({ store: window.sessionStorage }),
//     client_secret: process.env.REACT_APP_CLIENT_SECRET,
//     redirect_uri: window.location.href.includes('.com')
//       ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN + `/signin`
//       : process.env.REACT_APP_OAUTH_REDIRECT_URL + `/signin`,
//     post_logout_redirect_uri: window.location.href.includes('.com')
//       ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN
//       : process.env.REACT_APP_OAUTH_REDIRECT_URL,
//     silent_redirect_uri: window.location.href.includes('.com')
//       ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN
//       : process.env.REACT_APP_OAUTH_REDIRECT_URL,
//     response_type: 'code',
//     scope: 'openid profile IdentityServerApi offline_access email',
//     metadata: this.metadata,
//     monitorSession: false,
//     automaticSilentRenew: false,
//     ui_locales: 'sk-SK',

//     includeIdTokenInSilentRenew: false,
//   };

//   state = '';

//   constructor() {
//     this.userManager = new UserManager(this.settings);

//     // runs cca 1 min before expiration, runs several times
//     this.userManager.events.addAccessTokenExpiring(this.signinSilent);
//     this.test();
//   }

//   //toto je useless len informacne
//   async test() {
//     const saved = sessionStorage.getItem(
//       `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
//     );
//     if (saved === 'null' || !saved) return;
//     const user = await this.userManager.getUser();
//     if (user) {
//       const exp = new Date(user.expires_at * 1000);
//       console.log('Expiracia za ' + (exp.getTime() - new Date().getTime()) / 1000 + ' sekund.');
//       console.log(exp);
//     }
//   }

//   public async login(): Promise<void> {
//     let lang = '';
//     switch (i18next.language) {
//       case 'en': {
//         lang = 'en-US';
//         break;
//       }
//       case 'sk': {
//         lang = 'sk-SK';
//         break;
//       }
//       case 'cz': {
//         lang = 'cz-CZ';
//         break;
//       }
//       default: {
//         lang = 'sk-SK';
//         break;
//       }
//     }
//     await this.silentSignout();

//     return this.userManager.signinRedirect({
//       state: this.state,
//       ui_locales: lang,
//       prompt: 'login',
//     });
//   }

//   public async silentSignout() {
//     try {
//       const idTokenHint = localStorage.getItem('oidc.ustok');

//       if (idTokenHint) {
//         await this.userManager.signoutSilent({
//           silentRequestTimeoutInSeconds: 1,
//           id_token_hint: JSON.parse(idTokenHint),
//         });

//         localStorage.removeItem('oidc.ustok');
//       } else {
//         console.log('[ AUTH SERVICE ] No id_token_hint found in localStorage.');
//       }
//     } catch (error) {
//       return;
//     }
//   }

//   public logout(): Promise<void> {
//     return this.userManager.signoutRedirect();
//   }

//   public autoLogout(): Promise<void> {
//     return this.userManager.signoutRedirect({
//       post_logout_redirect_uri: window.location.href.includes('.com')
//         ? process.env.REACT_APP_OAUTH_REDIRECT_URL_EN + '/automaticke-odhlasenie'
//         : process.env.REACT_APP_OAUTH_REDIRECT_URL + '/automaticke-odhlasenie',
//     });
//   }

//   /*public signinCallback(): Promise<User> {
//     return this.userManager.signinRedirectCallback();
//   }*/

//   public signinSilent = async () => {
//     await this.userManager
//       .signinSilent(/*{ scope: 'openid profile role myApi', response_type: 'id_token token' }*/)
//       .then(() => {})
//       .catch(() => {
//         this.autoLogout();
//       });
//   };

//   public signinRedirectCallback(): Promise<any> {
//     //, userStore:  new WebStorageStateStore({ store: window.localStorage })
//     // toto je potrebné aby sa token a udaje ukladali do local storage... bez toho to ide do session storage
//     //this.userManager = new UserManager({ response_mode: 'query', userStore:  new WebStorageStateStore({ store: window.localStorage })  });
//     return this.userManager.signinRedirectCallback().then((r) => {
//       if (r && r?.id_token) localStorage.setItem('oidc.ustok', JSON.stringify(r?.id_token));
//       localStorage.setItem('role', undefined);

//       this.userManager = new UserManager(this.settings);
//     });
//   }

//   public getUser(): Promise<User | null> {
//     return this.userManager.getUser();
//   }

//   public isLoggedIn() {
//     const sessionValue = window.sessionStorage.getItem(
//       `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
//     );
//     if (sessionValue != null) {
//       const oidcStorage = JSON.parse(
//         window.sessionStorage.getItem(
//           `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
//         ) || ''
//       );
//       return !!oidcStorage && !!oidcStorage.access_token;
//     } else {
//       return false;
//     }
//   }

//   public getToken() {
//     const sessionValue = window.sessionStorage.getItem(
//       `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
//     );
//     if (sessionValue != null) {
//       const oidcStorage = JSON.parse(
//         window.sessionStorage.getItem(
//           `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
//         ) || ''
//       );
//       if (oidcStorage && oidcStorage.access_token) {
//         return oidcStorage.access_token;
//       } else {
//         return '';
//       }
//     } else {
//       return '';
//     }
//   }

//   public setState(state) {
//     this.state = state;
//   }
// }
