import { forwardRef, MouseEvent } from 'react';

import handleMiddleClick from 'utils/handleMiddleClick';

import { Tooltip } from 'components/Tooltip';

import { Icon } from '@iconify/react';
import { Button, styled, SxProps } from '@mui/material';

const ThemedButton: any = styled(Button)<any>(
  ({ theme, variant, color, light, error, size }: any) => ({
    fontWeight: '500 !important',
    marginTop: '8px !important',
    marginBottom: '8px !important',
    cursor: 'pointer !important',
    lineHeight: '2 !important',

    '&.Mui-disabled': {
      color: theme.palette.disabled?.text + ' !important',
      borderColor: theme.palette.disabled?.border + ' !important',
      backgroundColor: theme.palette.disabled?.background + ' !important',
    },

    //  '&:hover': {

    //  },

    ...(size === 'small' &&
      variant !== 'outlined' && {
        padding: '3px 10px !important',
        fontSize: '13px !important',
        borderRadius: '6px !important',
      }),

    ...(size === 'medium' &&
      variant !== 'outlined' && {
        padding: '6px 16px !important',
        fontSize: '14px !important',
        borderRadius: '8px !important',
      }),

    ...(size === 'large' &&
      variant !== 'outlined' && {
        padding: '10px 20px !important',
        fontSize: '14px !important',
        borderRadius: '8px !important',
      }),

    ...(size === 'small' &&
      variant === 'outlined' && {
        padding: '2.1px 10px !important',
        fontSize: '13px !important',
        borderRadius: '6px !important',
      }),

    ...(size === 'medium' &&
      variant === 'outlined' && {
        padding: '5px 16px !important',
        fontSize: '14px !important',
        borderRadius: '8px !important',
      }),

    ...(size === 'large' &&
      variant === 'outlined' && {
        padding: '9px 20px !important',
        fontSize: '14px !important',
        borderRadius: '8px !important',
      }),

    ...(color === 'warning' && {
      color:
        light === 'true'
          ? theme.palette.primary.contrastText + ' !important'
          : error === 'true'
            ? theme.palette.common.white + ' !important'
            : theme.palette.primary?.contrastText + ' !important',

      background: `#C98235`,
      '&:hover': {
        background: '#BE7526',
        color: '#fff',
      },
    }),

    ...(color === 'primary' && {
      color:
        light === 'true'
          ? theme.palette.secondary.contrastText + ' !important'
          : error === 'true'
            ? theme.palette.common.white + ' !important'
            : theme.palette.primary?.contrastText + ' !important',
      background:
        light === 'true'
          ? theme.palette.secondary.main
          : error === 'true'
            ? theme.palette.error.main
            : theme.palette.primary?.main,
    }),

    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      color: theme.palette.primary?.main,
      '&:hover': {},
    }),

    ...(variant === 'outlined' &&
      color === 'secondary' && {
        borderColor: theme.palette.primary?.main,
        backgroundColor: 'transparent',
        color: theme.palette.primary?.main + ' !important',
        '&:hover': {
          borderColor: theme.palette.primary?.main,
        },
      }),

    ...(color === 'error' &&
      variant === 'contained' && {
        color: theme.palette.common.white + ' !important',
      }),

    ...(color === 'error' &&
      variant === 'outlined' && {
        backgroundColor: 'transparent',
        color: theme.palette.error.main + ' !important',
      }),
  })
);

const PrimaryButton = forwardRef((props: any, ref) => (
  <ThemedButton
    ref={ref}
    variant='contained'
    color={props.error === 'true' ? 'error' : 'primary'}
    size={props.large ? 'large' : props.small ? 'small' : 'medium'}
    light={props.light}
    error={props.error}
    {...props}
  />
));

const SecondaryButton = forwardRef((props: any, ref) => (
  <ThemedButton
    ref={ref}
    variant='outlined'
    size={props.large ? 'large' : props.small ? 'small' : 'medium'}
    color={props.error === 'true' ? 'error' : 'secondary'}
    light={props.light}
    {...props}
  />
));

const MainActionButton = forwardRef((props: any, ref) => (
  <ThemedButton
    ref={ref}
    variant='contained'
    size={props.large ? 'large' : props.small ? 'small' : 'medium'}
    color={props.error === 'true' ? 'error' : 'warning'}
    light={props.light}
    error={props.error}
    {...props}
  />
));

export const TextButton = (props) => <ThemedButton variant='text' color='textPrimary' {...props} />;

interface IProps {
  innerRef?: (_el: any) => void;
  id?: string;
  children?: any;
  className?: string;
  tooltipClassName?: string;
  styles?: string;
  icon?: string;
  style?: SxProps;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any; //(_e?: MouseEvent) => void;
  onMiddleClick?: (_e?: MouseEvent) => string;
  disabled?: boolean;
  rightIcon?: boolean;
  fullWidth?: boolean;
  margin?: 'none' | 'left' | 'right' | 'both' | 'auto';

  primary?: boolean;
  secondary?: boolean;
  mainAction?: boolean;
  light?: boolean;
  error?: boolean;

  small?: boolean;
  large?: boolean;

  tooltip?: string;
  tooltipPosition?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'right-start'
    | 'right'
    | 'right-end';
}

export default function SlideButton(props: IProps) {
  let onlyIcon = props.children === undefined || props.children?.length === 0;

  const icon = props.icon && (
    <Icon
      icon={props.icon}
      style={{
        fontSize: '14px',
        marginLeft: '4px',
        marginRight: onlyIcon ? '-8px' : '2px',
        marginTop: onlyIcon ? '4px' : 0,
        marginBottom: onlyIcon ? '4px' : 0,
      }}
    />
  );

  let ButtonType = PrimaryButton;

  if (props.primary && !props.mainAction) {
    ButtonType = PrimaryButton;
  }

  if ((props.primary && props.mainAction) || props.mainAction) {
    ButtonType = MainActionButton;
  }

  if (props.secondary || props.light) {
    ButtonType = SecondaryButton;
  }

  // nemozem pouzit na "onClick" lebo testy...
  // musi byt opodmienkovane, lebo "onClick" sa nezavola ak je nastaveny onMouseUp
  const handleMouseClick = props.onMiddleClick
    ? (e: MouseEvent<HTMLElement>) => {
        if (e.button === 0) {
          props.onClick?.(e);
        } else {
          handleMiddleClick(e, props.onMiddleClick?.(e));
        }
      }
    : undefined;

  const btn = (
    <ButtonType
      fullWidth={props.fullWidth}
      id={props.id}
      role='button'
      type={props.type ? props.type : 'button'}
      aria-label={props.id}
      ref={props.innerRef}
      sx={{
        margin:
          props.margin === 'left'
            ? '0 0 0 8px'
            : props.margin === 'right'
              ? '0 8px 0 0'
              : props.margin === 'none'
                ? '0px 0px 0px 0px !important'
                : props.margin === 'auto'
                  ? 'auto'
                  : '0 8px 0 8px',
        textTransform: 'none',
        ...props.style,
      }}
      className={`${props.className} ${props.styles}`}
      error={props.error ? 'true' : 'false'}
      disabled={props.disabled}
      size={props.large ? 'large' : props.small ? 'small' : 'medium'}
      endIcon={props.rightIcon && icon}
      startIcon={!props.rightIcon && icon}
      // nemozem pouzit na "onClick" lebo testy...
      onMouseUp={handleMouseClick}
      onClick={props.onClick}
    >
      {<span>{props.children}</span>}
    </ButtonType>
  );

  return props.tooltip ? (
    <Tooltip
      arrow
      title={<div style={{ fontSize: '12px' }}>{props.tooltip}</div>}
      placement={props.tooltipPosition || 'top'}
      PopperProps={{
        sx: {
          '& div': { textAlign: 'center' },
        },
      }}
      className={props.tooltipClassName}
    >
      <span style={{ display: 'inline-block', width: props.fullWidth ? '100%' : 'auto' }}>
        {btn}
      </span>
    </Tooltip>
  ) : (
    btn
  );
}
