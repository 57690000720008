import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { services } from 'context/context';

import { IconButtonAnimate } from 'components/IconButton';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { Badge, Box } from '@mui/material';

import MenuPopover from '../HeaderComponents/MenuPopover';
import NotificationBox, { INotification } from './NotificationBox';

import css from '../HeaderNavbar.module.css';

interface HeaderNotificatiosProps {
  className?: string;
  mobile?: boolean;
}

const CustomIcon: any = styled(Icon)(() => ({
  '@media (max-width:900px)': {
    backgroundColor: 'transparent',
    color: '#fff',
  },
}));

export default function HeaderNotifications(props: Readonly<HeaderNotificatiosProps>) {
  const { t } = useTranslation();
  const { notifyService, notifyWebService } = useContext(services);

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(null);

  const sendNotifyToSystem = useCallback(
    (notify: INotification[], count: number) => {
      const outArray = notify.filter((notification) => !notification.isRead);
      const out = outArray
        .map((notification) => t('NOTIFY_TYPE.' + notification.notificationKey))
        .join('\n');
      notifyWebService.send('unread_count', 'Máte ' + count + ' nových notifikácií.' + out);
    },
    [notifyWebService, t]
  );

  const load = useCallback(
    (page: number, onlyUnread: boolean) => {
      const requestNotifications = onlyUnread
        ? notifyService.getUnreadNotify
        : notifyService.getAllNotify;
      setLoading(true);
      requestNotifications(page)
        .then((resp) => {
          setLoading(false);
          setNotifications((prev) =>
            page === 0 ? resp.notificationDTOList : [...prev, ...resp.notificationDTOList]
          );
          setTotalCount(resp.countOfAll);
          setUnreadCount(resp.countOfUnread);
          if (resp.countOfUnread > 0) {
            sendNotifyToSystem(resp.notificationDTOList, resp.countOfUnread);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [notifyService.getAllNotify, notifyService.getUnreadNotify, sendNotifyToSystem]
  );

  useEffect(() => load(0, false), [load]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40, mr: { md: 1, sm: 0, xs: 0 } }}
      >
        <Badge badgeContent={unreadCount} max={99} color='error'>
          <CustomIcon icon='eva:bell-fill' width={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        className={props.className}
        arrow={props.mobile === undefined ? 'top-right' : 'none'}
        sx={{
          p: 0,
          mt: 1.5,
          ml: { md: 0.75, sm: 0, xs: 0 },
          width: props.mobile ? '100%' : '500px',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box className={`${css.top} ${css.menu} ${css.notif}`} bgcolor='background.default'>
          <NotificationBox
            notifications={notifications}
            setNotifications={setNotifications}
            totalCount={totalCount}
            unreadCount={unreadCount}
            setUnreadCount={setUnreadCount}
            load={load}
            loading={loading}
            mobile={props.mobile}
            closeNotificationBox={handleClose}
          />
        </Box>
      </MenuPopover>
    </>
  );
}
