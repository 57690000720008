import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/Tooltip';

import { Box, Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material';

import { InputProps } from '../hooks/useInput';

import css from './Inputs.module.css';

export interface CheckboxInputProps extends Omit<InputProps<boolean>, 'label'> {
  bold?: boolean;
  textAlign?: 'left' | 'right' | 'justify' | 'center';
  nopadding?: boolean;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  label?: string | JSX.Element;
  tooltip?: string;
}

export default function CheckboxInput({
  name,
  label,
  placeholder,
  bold,
  value,
  onChange,
  error,
  disabled,
  textAlign,
  nopadding,
  labelPlacement,
  tooltip,
}: Readonly<CheckboxInputProps>) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    onChange?.({ target: { value: event.target.checked } });
  };

  const checkbox = (
    <Box className={`${css.baseInput} ${css.labelFix} ${css.checkboxFix}`}>
      <FormControlLabel
        id={name}
        data-testid={name}
        label={
          <Typography
            variant='body2'
            color={disabled ? 'text.disabled' : ''}
            fontWeight={bold ? 'bold' : ''}
            textAlign={textAlign}
          >
            {label || placeholder}
          </Typography>
        }
        labelPlacement={labelPlacement || 'end'}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        control={
          <Checkbox
            size='small'
            checked={value}
            sx={{ padding: nopadding ? '0px 9px' : undefined }}
          />
        }
        disabled={disabled}
        sx={{
          '& fieldset': { borderColor: 'primary.main' },
          '&.Mui-disabled span': { color: 'text.disabled' },
        }}
      />
      <FormHelperText id={`${name}-helper`} error={error?.length > 0} sx={{ paddingLeft: '12px' }}>
        {t(error)}
      </FormHelperText>
    </Box>
  );

  if (tooltip)
    return (
      <Tooltip arrow title={tooltip}>
        {checkbox}
      </Tooltip>
    );
  else return checkbox;
}
