//imports
//css
import 'assets/css/layout.css';
import 'assets/css/variables.css';

import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';

import MotionLazyContainer from 'providers/Animate/MotionLazyContainer';
import GoogleAnalytics from 'providers/GoogleAnalytics/GoogleAnalytics';
import CustomLocalizationProvider from 'providers/LocalizationProvider/CustomLocalizationProvider';
import OurThemeProvider from 'providers/themeProvider/OurThemeProvider';

import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

// just for test commented this shit out
//import GetSessionStorageProvider from 'context/GetSessionStorageProvider';
import { LoadingProvider } from 'context/LoadingProvider';
import { ServiceProvider } from 'context/ServiceProvider';
import ShowMenuProvider from 'context/ShowMenuProvider';
import { UserProvider } from 'context/UserProvider';
import AutoLogoutProvider from 'hooks/useAutoLogoutTimer';
import LangChecker from 'service/language/LangChecker';

import '../src/translations/config';

import * as Sentry from '@sentry/react';

import Router from './router/Router';
import { AuthService } from './service/authentication/AuthService';
import * as serviceWorker from './serviceWorker';

// lang checker
LangChecker.test();

//export services
const authorizationService = new AuthService();
export { authorizationService };

const root = ReactDOM.createRoot(document.getElementById('root') || document.createElement('div'));

function RedirectProvider({ children }) {
  useEffect(() => {
    if (window.location.host.includes('zone.nfqes.sk')) {
      window.location.replace(`https://zone.nfqes.com${window.location.pathname}`);
    }
  }, []);

  return children;
}

if (process.env.REACT_APP_SEND_ERROR_LOGS === 'true') {
  Sentry.init({
    dsn: 'http://a6aa1d7033c9004e4cd46f8136152e01@sentry.brainit.tech:9000/6',
  });
}

function useAuthStateSync() {
  useEffect(() => {
    const syncAuthState = (event: StorageEvent) => {
      if (event.key === `oidc.user:${process.env.REACT_APP_IAM_API_URL}/:nfqes_web`) {
        window.location.replace('/');
      }
    };

    window.addEventListener('storage', syncAuthState);

    return () => {
      window.removeEventListener('storage', syncAuthState);
    };
  }, []);
}

function App() {
  useAuthStateSync();

  return (
    <RedirectProvider>
      {/* <GetSessionStorageProvider> */}
      <GoogleAnalytics>
        <CookiesProvider>
          <CustomLocalizationProvider>
            <ServiceProvider>
              <UserProvider>
                <AutoLogoutProvider>
                  <ShowMenuProvider>
                    <OurThemeProvider>
                      <MotionLazyContainer>
                        <LoadingProvider>
                          <Router />
                        </LoadingProvider>
                      </MotionLazyContainer>
                    </OurThemeProvider>
                  </ShowMenuProvider>
                </AutoLogoutProvider>
              </UserProvider>
            </ServiceProvider>
          </CustomLocalizationProvider>
        </CookiesProvider>
      </GoogleAnalytics>
      {/* </GetSessionStorageProvider> */}
    </RedirectProvider>
  );
}

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
