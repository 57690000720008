import { Tooltip } from 'components/Tooltip';

import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import css from './Checkboxes.module.css';

interface GeneralCheckboxProps {
  checked?: boolean;
  onChange?: Function | ((_value: any) => void);
  required?: boolean;
  name?: string;
  label?: any;
  theme?: string;
  labelPlacement?: 'top' | 'start' | 'end' | 'bottom';
  dense?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  style?: any;
  smallText?: boolean;
  center?: boolean;
  tooltip?: string;
}

export default function GeneralCheckbox({
  checked = undefined,
  onChange = undefined,
  required = false,
  name,
  label,
  theme = 'blue',
  labelPlacement = 'end',
  dense = false,
  className = '',
  disabled = false,
  id,
  style,
  smallText,
  center,
  tooltip,
}: Readonly<GeneralCheckboxProps>) {
  const checkbox = (
    <FormGroup
      className={`${css.box} ${dense ? css.dense : ''} ${className} ${
        disabled ? css.disabled : ''
      }`}
      style={style}
    >
      <FormControlLabel
        className={`${css.checkbox} ${!disabled ? css[theme] : ''}`}
        sx={
          center
            ? {
                width: { xs: center ? 'auto !important' : '' },
                margin: {
                  xs: center ? 'auto !important' : '',
                  md: '0px !important',
                },
              }
            : {}
        }
        control={
          checked !== undefined && onChange !== undefined ? (
            <Checkbox
              onChange={(e) => onChange(e.target.checked)}
              checked={checked}
              required={required}
              name={name}
              disabled={disabled}
              id={id}
              sx={{ padding: smallText ? '0px' : '', width: 'auto' }}
              // stopPropagation zabrani propagacii kliku na parenta
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <Checkbox required={required} name={name} disabled={disabled} />
          )
        }
        label={
          (!!label || required) && (
            <Box
              component='div'
              color='text.primary'
              className={`${css.label} ${!disabled ? css.textColor : ''}`}
              style={{ fontSize: smallText ? '12px' : '', paddingTop: '3px' }}
            >
              {label}
            </Box>
          )
        }
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );

  if (tooltip)
    return (
      <Tooltip arrow title={tooltip}>
        {checkbox}
      </Tooltip>
    );
  else return checkbox;
}
