import { JSXElementConstructor, ReactNode } from 'react';

import SecureRandom from 'service/defaultServices/SecureRandom';

import { Box } from '@mui/material';

import { ConfirmModal } from './ModalProvider';

/*
    Táto služba/komponent slúži na asynchronne otvorenie modálneho okna. Po novom služba podporuje aj stackovanie modalov, takže môžete otvoriť viac modalnych okien súčasne.

    Použitie:
    1) Vytvoríš si komponent, ktorý bude predstatovať obsah modálu
        "props" daného komponentu bude typu ModalContentProps alebo ModalContentProps<T> kde T pridáva ďalšie props
        ModalContentProps pridáva "resolve" a "changeTitle" funkciu do "props", táto funkcia slúži na zatvorenie modálu a odoslanie odpovede (typu "any") a druhá na okamžitú zmenu nazvu modalu
        príklad: onClickProcessResponse => props.resolve({ objekt: "s odpovedou "})

        function ContentModal({ resolve, changeTitle, ...props }: ModalContentProps<MojeVlastneDodatocneProps>) {

            return <button onClick={() => resolve(true)}>ODOSLI TRUE</button>;
        }

    2) Vo svojom komponente zavoláš zobrazenie modálu, napr. po kliknutí
        const handleClick = async (nejakeData: any) => {
            const response = await ModalManager.show(
                "nadpis_modalu", // text alebo kluc pre preklad, automaticky sa prelozi ak existuje preklad
                TypKomponentu, // POZOR! jedna sa o typ komponentu a nie cely komponent, tj. treba tam poslat napr. ContentModal a nie <ContentModal />
                nepovinne_props_parameter, // nepovinny parameter - object, ktory sa pouzije ako "props" pre content (ContentModal z prikladu vyssie)
                nepovinne_outer_click_boolean // ak je true, modal sa da zatvorit kliknuti mimo modalneho okna (default: false)
            );
            if (response) {
                // dostal som odpoved z modalneho okna a mozem ju spracovat dalej
            } else {
                // zatvoril som modalne okno (alebo poslal odpoved false/undefined/null)
            }
        };
*/

export type ModalContentProps<T = {}> = {
  resolve: (_value: any) => void;
  changeTitle: (_val: string | JSX.Element) => void;
} & T;

interface InternalModalFunctionsProps {
  setTitle: (_val: string | JSX.Element) => void;
  setShow: (_val: boolean) => void;
  setContent: (_val: ReactNode) => void;
  setCloseable: (_val: boolean) => void;
  setResolveFn: (_val: { resolve: (_inner: unknown) => void }) => void;
  setMaxWidth: (_val: string) => void;
  setPurpose: (_val?: 'info' | 'success' | 'warning' | 'error') => void;
}

const internalModalFunctions: InternalModalFunctionsProps = {
  setTitle: undefined,
  setShow: undefined,
  setContent: undefined,
  setCloseable: undefined,
  setResolveFn: undefined,
  setMaxWidth: undefined,
  setPurpose: undefined,
};

const modalStack: Array<{
  key: string; // random SecureRandom.uuid();
  title: any;
  content: ReactNode;
  config: OpenModalConfigProps;
  resolve: Function;
  promise: Promise<any>;
}> = [];

interface OpenModalConfigProps {
  purpose?: 'info' | 'success' | 'warning' | 'error';
  outerClickClose?: boolean;
  maxWidth?: string;
  onClose?: () => void;
}

/**
 * Open new modal. Modals are stacking, so you can open modal in modal.
 * @param title
 * @param Content
 * @param contentProps
 * @param config
 * @param id
 * @returns
 */
function open<contentPropsType>(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: contentPropsType,
  config?: OpenModalConfigProps,
  id?: string // vyuzije sa na zavretie modalu
) {
  return openNewModal(title, Content, contentProps, config, id);
}

function openInfo<contentPropsType>(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: contentPropsType,
  config?: OpenModalConfigProps
) {
  return openNewModal(title, Content, contentProps, { purpose: 'info', ...config });
}

function openSuccess<contentPropsType>(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: contentPropsType,
  config?: OpenModalConfigProps
) {
  return openNewModal(title, Content, contentProps, { purpose: 'success', ...config });
}

function openWarning<contentPropsType>(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: contentPropsType,
  config?: OpenModalConfigProps
) {
  return openNewModal(title, Content, contentProps, { purpose: 'warning', ...config });
}

function openError<contentPropsType>(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: contentPropsType,
  config?: OpenModalConfigProps
) {
  return openNewModal(title, Content, contentProps, { purpose: 'error', ...config });
}

const ModalManager = {
  open,

  openInfo,

  openSuccess,

  openWarning,

  openError,

  /**
   * Open simple confirm dialog. Response is true or false.
   * @param title
   * @param text
   * @param confirm
   * @returns
   */
  confirm: (title?: string, text?: string, confirm?: string): Promise<boolean> => {
    return ModalManager.open(
      title || 'DEFAULT.CONFIRM',
      ConfirmModal,
      { text, confirm },
      { maxWidth: '600px' }
    ) as Promise<boolean>;
  },

  confirmInfo: (title?: string, text?: string, confirm?: string): Promise<boolean> => {
    return ModalManager.open(
      title || 'DEFAULT.CONFIRM',
      ConfirmModal,
      { text, confirm },
      { maxWidth: '600px', purpose: 'info' }
    ) as Promise<boolean>;
  },

  confirmSuccess: (title?: string, text?: string, confirm?: string): Promise<boolean> => {
    return ModalManager.open(
      title || 'DEFAULT.CONFIRM',
      ConfirmModal,
      { text, confirm },
      { maxWidth: '600px', purpose: 'success' }
    ) as Promise<boolean>;
  },

  confirmWarning: (title?: string, text?: string, confirm?: string): Promise<boolean> => {
    return ModalManager.open(
      title || 'DEFAULT.CONFIRM',
      ConfirmModal,
      { text, confirm },
      { maxWidth: '600px', purpose: 'warning' }
    ) as Promise<boolean>;
  },

  confirmError: (title?: string, text?: string, confirm?: string): Promise<boolean> => {
    return ModalManager.open(
      title || 'DEFAULT.CONFIRM',
      ConfirmModal,
      { text, confirm },
      { maxWidth: '600px', purpose: 'error' }
    ) as Promise<boolean>;
  },

  close: (id: string) => {
    const index = modalStack.findIndex((modal) => modal.key === id);
    if (index > -1) modalStack.splice(index, 1);
    return openLastModalFromStack();
  },
};

export default ModalManager;

export function SetModalManagerInternalFunctions(params: InternalModalFunctionsProps) {
  internalModalFunctions.setTitle = params.setTitle;
  internalModalFunctions.setShow = params.setShow;
  internalModalFunctions.setContent = params.setContent;
  internalModalFunctions.setCloseable = params.setCloseable;
  internalModalFunctions.setResolveFn = params.setResolveFn;
  internalModalFunctions.setMaxWidth = params.setMaxWidth;
  internalModalFunctions.setPurpose = params.setPurpose;
}

/**
 * Funkcia prida novy modal do stacku, nasledne zavola zobrazenie posledneho modalu zo stacku
 * @param title
 * @param Content
 * @param contentProps
 * @param config
 * @returns
 */
function openNewModal(
  title: string,
  Content: JSXElementConstructor<any>,
  contentProps?: any,
  config?: OpenModalConfigProps,
  id?: string
) {
  // vytvorim Promise a nastavim resolve funkciu pre neskorsie pouzitie
  let resolve;
  const promise = new Promise((newResolve) => {
    resolve = newResolve;
  });

  // funkcia odstrani posledny modal zo stacku a zatvori aktualny modal, potom skontroluje, ci sa nachadza nejaky modal v stacku a ten zobrazi
  const resolveAndClose = (value: any) => {
    modalStack.pop();
    internalModalFunctions.setShow(false);
    resolve(value);
    if (modalStack.length > 0) {
      // open vracia Promise, ale tu ho nechcem ukladat do "promise", pretoze tento bol vrateny uz pri vzniku modalu
      openLastModalFromStack();
    }
  };

  // nahodny kluc aby mali modaly unikatny kluc
  const key = id ? id : SecureRandom.uuid();

  // vytvorim content modalu, naplnim ho udajmi - aby sa mi udaje uchovavali aj po zobrazeni noveho modalu
  const content = (
    <Content
      key={key}
      {...contentProps}
      resolve={resolveAndClose}
      changeTitle={internalModalFunctions.setTitle}
    />
  );

  // vsetky udaje vlozim do stacku
  modalStack.push({
    key,
    title,
    content,
    config,
    resolve: resolveAndClose,
    promise,
  });

  // zavolam otvorenie posledneho (aktualne vlozeneho) modalu zo stacku
  return openLastModalFromStack();
}

/**
 * Funkcia zobrazi posledny modal v stacku
 * @returns
 */
function openLastModalFromStack() {
  // ak sa ziaden modal nenachadza v stacku, vratim undefined - toto by sa nikdy nemalo stat
  if (modalStack.length < 1) return undefined;

  // zoberiem niektore udaje z posledneho stacku
  const { title, config, resolve, promise } = modalStack[modalStack.length - 1];

  // nastavim configuraciu
  internalModalFunctions.setCloseable?.(config?.outerClickClose || false);
  internalModalFunctions.setMaxWidth?.(config?.maxWidth || undefined);
  internalModalFunctions.setPurpose?.(config?.purpose || undefined);

  // nastavim nadpis, obsah a zobrazim modal
  internalModalFunctions.setTitle?.(title);
  // musim vykreslit vsetky modaly, ale zobrazim len posledny z nich
  // je to kvoli tomu, aby sa useState a ostatne hooky zachovali aj medzi jednotlivymi modalmi v stacku
  internalModalFunctions.setContent?.(
    modalStack.map((stack, idx) => (
      <Box key={stack.key} display={idx !== modalStack.length - 1 ? 'none' : ''}>
        {stack.content}
      </Box>
    ))
  );
  internalModalFunctions.setShow?.(true);

  // nastavim resolve funkciu + onClose funkciu ak je zadana
  internalModalFunctions.setResolveFn?.({
    resolve: (val) => {
      resolve(val);
      if (!val && config?.onClose) config.onClose();
    },
  });

  // vratim Promise, ktory je priradeny k poslednemu modalu
  return promise;
}
