import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './OTPDialog.css';

import { Icon } from '@iconify/react';
import { Box, CircularProgress, Link } from '@mui/material';

import ChooseVerifyType from './components/ChooseVerifyType';
import LimitError from './components/LimitError';
import ResponseToChallenge from './components/ResponseToChallenge';
import Success from './components/Success';

export interface OTPUserType {
  nextSubscriptionReset: Date;
  subscription: number;
  role: string;
}

interface IProps {
  close: (_submit?: boolean) => void;
  changeTitle: (_val: string | JSX.Element) => void;
  sendSMS: any;
  otpValue: string;
  allowedMethods: (0 | 1 | 2 | 3 | 4 | 5)[];
  userMethods: {
    method: -1 | 0 | 1 | 2 | 3 | 4 | 5;
    level: number;
  }[];
  challenge: string | null;
  type: string;
  setType: any;
  step: number;
  setStep: any;
  setTransactionId: any;
  error: boolean;
  setError: any;
  submitHandler: any;
  addMethodHandler: any;
  loading: boolean;
  success: boolean;
  showLimitError: boolean;
  user?: OTPUserType;
  addUser2Fa: (_string) => void;
  infoText?: string;
  getChalengeOnDemand?: () => void;
  minimalOperationLevel: number;
}

export default function OTPDialog(props: IProps) {
  const { t } = useTranslation(['translation', 'otp']);
  const {
    otpValue,
    allowedMethods,
    userMethods,
    challenge,
    type,
    error,
    setError,
    loading,
    success,
    showLimitError,
    user,
    step,
    setStep,
    setType,
    sendSMS,
    addUser2Fa,
    infoText,
    setTransactionId,
    changeTitle,
    getChalengeOnDemand,
    minimalOperationLevel,
  } = props;

  const [otp, setOtp] = useState('');

  useEffect(() => {
    setError(false);
  }, [otp, setError]);

  useEffect(() => {
    if (otpValue !== '') {
      setOtp(otpValue);
    }
  }, [otpValue]);

  useEffect(() => {
    if (allowedMethods.length === 1) {
      setType(allowedMethods[0]);
      setStep(1);
      sendSMS(allowedMethods[0]);
    }
  }, [allowedMethods, sendSMS, setStep, setType]);

  const goBack = useCallback(() => {
    setStep(0);
    setType('');
    setOtp('');
    setTransactionId(null);
  }, [setType, setStep, setTransactionId]);

  const setTitle = useCallback(() => {
    if (step === 0) {
      changeTitle(t('OTP.TYPES.CHOOSE'));
    } else if (allowedMethods.length > 1) {
      changeTitle(
        <Link component='button' variant='body1' onClick={() => goBack()}>
          <Icon icon='fa:chevron-left' style={{ fontSize: '16px', marginRight: '8px' }} />
          {t('OTP.TYPES.BACK_LINK')}
        </Link>
      );
    } else {
      changeTitle('');
    }
  }, [allowedMethods, changeTitle, goBack, step, t]);

  useEffect(() => {
    setTitle();
  }, [setTitle]);

  useEffect(() => {
    setStep(0);
    setType('');
    setOtp('');
  }, [success, setType, setStep]);

  const submitOtp = useCallback(() => {
    if (step === 1) {
      props.submitHandler(otp);
      setOtp('');
    }
    if (step === 2) {
      let typeName;
      if (type === '1' || (type as unknown as number) === 1) {
        typeName = 'email';
      } else if (type === '2' || (type as unknown as number) === 2) {
        typeName = 'ocra';
      } else if (type === '3' || (type as unknown as number) === 3) {
        typeName = 'virtualToken';
      } else if (type === '5' || (type as unknown as number) === 5) {
        typeName = 'ocraApp';
      }
      props.addMethodHandler(otp, type, typeName);
      setOtp('');
    }
  }, [otp, props, step, type]);

  useEffect(() => {
    if (!error) {
      if (otp.length === 6) {
        setTimeout(() => {
          submitOtp();
        }, 500);
      }
    }
  }, [error, otp.length, submitOtp]);

  return (
    <>
      {step === 0 && !success && !loading && (
        <ChooseVerifyType
          close={props.close}
          setType={setType}
          sendSMS={sendSMS}
          allowedMethods={allowedMethods}
          userMethods={userMethods}
          minimalOperationLevel={minimalOperationLevel}
          addUser2Fa={addUser2Fa}
          user={user}
        />
      )}

      {success && <Success />}

      {step >= 1 && !loading && !success && !showLimitError && (
        <ResponseToChallenge
          infoText={infoText}
          type={type}
          challenge={challenge}
          step={step}
          otp={otp}
          setOtp={setOtp}
          error={error}
          sendSMS={sendSMS}
          getChalengeOnDemand={getChalengeOnDemand}
          submitOtp={submitOtp}
          user={user}
        />
      )}

      {step === 1 && showLimitError && !loading && <LimitError close={props.close} user={user} />}

      {loading === true && (
        <Box pt={20} pb={20} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
