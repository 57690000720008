import { ColorModeContext } from 'providers/themeProvider/OurThemeProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardActionArea,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import css from './HeaderNotifications/HeaderNotifications.module.css';

const BoxStyle: any = styled(CardActionArea)(({ theme, active }: any) => ({
  height: 78,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: active === 'true' ? theme.palette.background.neutral + ' !important' : '',
  color:
    active === 'true' ? 'theme.palette.text.primary ' : theme.palette.text.disabled + ' !important',
  border:
    active === 'true'
      ? `solid 1px ${theme.palette.text.inputBorder} !important`
      : `solid 1px ${theme.palette.text.inverse} !important`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

export default function ChangeTheme() {
  const { t } = useTranslation();
  const colorMode = useContext(ColorModeContext);
  const [themeMode, setMode] = useState<string>(localStorage.getItem('mode') || 'light');

  return (
    <>
      <Typography variant='subtitle2' sx={{ ml: 3 }} noWrap>
        {t('DEFAULT.MODE')}
      </Typography>
      <RadioGroup
        name='themeMode'
        value={themeMode}
        onChange={() => {
          setMode(themeMode === 'light' ? 'dark' : 'light');
          colorMode.toggleColorMode();
        }}
        sx={{ mt: 1, ml: 3, mr: 3, mb: 2 }}
      >
        <Grid dir='ltr' container spacing={1}>
          {['light', 'dark'].map((mode, index) => {
            const isSelected = themeMode === mode;
            return (
              <Grid key={mode} item xs={6}>
                <BoxStyle active={isSelected.toString()}>
                  {index === 0 ? (
                    <div className={`${css.sun} ${isSelected ? css.activeMode : ''}`} />
                  ) : (
                    <div className={`${css.moon} ${isSelected ? css.activeMode : ''}`} />
                  )}
                  <BoxMask value={mode} />
                  <small style={{ marginTop: 8 }}>
                    {index === 0 ? t('DEFAULT.LIGHT_MODE') : t('DEFAULT.DARK_MODE')}
                  </small>
                </BoxStyle>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </>
  );
}

interface BoxMaskProps {
  value: any;
}

function BoxMask({ value }: BoxMaskProps) {
  return (
    <FormControlLabel
      label=''
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
