import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageEzzkNewRecord = lazy(() =>
  lazyRetry(() => import('pages/(public)/ezzk/new-record/PageEzzkNewRecord'), 'PageEzzkNewRecord')
);

const PageEzzkNewRecordType = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/ezzk/new-record/[type]/PageEzzkNewRecordTypeVersion'),
    'PageEzzkNewRecordTypeVersion'
  )
);
export const routesEzzk: DBRoute[] = [
  {
    path: 'URL.EZZK_NEW_RECORD_TYPE',
    component: <PageEzzkNewRecordType />,
    isDevelop: true,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
  },
  {
    path: 'URL.EZZK_NEW_RECORD',
    name: 'DASHBOARD_ROUTES.EZZK',
    icon: <Icon icon='fa6-brands:dev' width={30} height={30} />,
    component: <PageEzzkNewRecord />,
    isDevelop: true,
    show: true,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
  },
];
