import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Joyride, { BaseProps, CallBackProps, Step } from 'react-joyride';

import { CustomTheme } from 'theme/PaletteDTO';

import { Box, useTheme } from '@mui/material';

interface WalktourProps {
  steps: Array<Step>;
  run: boolean;
  setRun: (_value: boolean) => void;
  callback?: (_data: CallBackProps) => void;
  onChangeStep?: (_data?: CallBackProps, _nextStep?: Step) => void;
  onStart?: () => void;
  onEnd?: () => void;
  onClose?: (_data?: CallBackProps) => void;
  props?: BaseProps;
}

export default function Walktour({
  steps,
  setRun,
  run,
  callback,
  onChangeStep,
  onStart,
  onEnd,
  onClose,
  props = {},
}: WalktourProps) {
  const { t } = useTranslation();
  const theme: CustomTheme = useTheme();
  const [stepIdx, setStepIdx] = useState(0);
  const [filteredSteps, setFilteredSteps] = useState([]);

  const [cookies, setCookie] = useCookies();
  const [showedStepsFromCookie, setShowedStepsFromCookie] = useState(cookies.showedWalktour);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    if (data.action === 'update') {
      if (cookies.showedWalktour) {
        if (cookies.showedWalktour.findIndex((item) => item === data.step.target) === -1)
          setCookie('showedWalktour', [...cookies.showedWalktour, data.step.target]);
      } else {
        setCookie('showedWalktour', [data.step.target]);
      }
    }
    if (data.action === 'reset' || data.action === 'close') {
      setRun(false);
      setShowedStepsFromCookie(cookies.showedWalktour);
      if (onClose) await onClose(data);
    }
    if (data.index >= filteredSteps.length - 1 && data.type === 'tour:end') {
      setRun(false);
      setStepIdx(0);
      setShowedStepsFromCookie(cookies.showedWalktour);
      if (onEnd) await onEnd();
    }
    if (data.action === 'next' && data.lifecycle === 'complete') {
      if (onChangeStep) await onChangeStep(data, filteredSteps[data.index + 1]);
      setStepIdx(data.index + 1);
    }
    if (data.action === 'prev' && data.lifecycle === 'complete') {
      if (onChangeStep) await onChangeStep(data, filteredSteps[data.index - 1]);
      setStepIdx(data.index - 1);
    }
    if (callback) await callback(data);
  };

  const start = useCallback(async () => {
    const tempFilteredSteps = steps
      .filter(
        (item) => document.getElementsByClassName((item.target + '').replaceAll('.', '')).length > 0
      )
      .map((item) => ({ disableBeacon: true, ...item }));
    // ak nejaký nevidel tak ide rovno naň
    let found = false;
    tempFilteredSteps?.forEach((step, idx) => {
      if (!found)
        if (showedStepsFromCookie?.findIndex((item) => item === step.target) === -1) {
          setStepIdx(idx);
          found = true;
        }
    });
    setFilteredSteps(tempFilteredSteps);
    if (onStart) await onStart();
    if (onChangeStep) await onChangeStep(undefined, tempFilteredSteps[0]);
  }, [onChangeStep, onStart, steps, showedStepsFromCookie]);

  useEffect(() => {
    if (run) {
      start();
    }
  }, [run, start]);

  useEffect(() => {
    setStepIdx(0);
  }, [steps, setStepIdx, setRun]);

  useEffect(() => {
    if (stepIdx >= filteredSteps.length) setStepIdx(0);
  }, [stepIdx, filteredSteps, setStepIdx]);

  return (
    <Box id='joyride_waltour_component'>
      <Joyride
        stepIndex={stepIdx}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        run={process.env.REACT_APP_HIDE_WALKTOUR === 'false' && run}
        steps={filteredSteps}
        styles={{
          options: {
            arrowColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
            overlayColor: theme.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.8)',
            primaryColor: theme.palette.primary.main,
            textColor: theme.palette.text.primary,
          },
        }}
        locale={{
          next: <span>{t('DEFAULT.CONTINUE')}</span>,
          back: <span>{t('DEFAULT.BACK')}</span>,
          last: <span>{t('DEFAULT.LAST')}</span>,
        }}
        {...props}
      />
    </Box>
  );
}
