import { useContext } from 'react';

import { showMenu } from 'context/context';

import { Icon } from '@iconify/react';

import css from './HeaderNavbar.module.css';

export default function OpenMenuButton() {
  const { show: showSidebar } = useContext(showMenu);

  const mobileSidebarToggle = (event) => {
    event.preventDefault();
    showSidebar();
  };

  return (
    <div className={css.openMenuIconBox}>
      <Icon icon='fa:bars' className={css.mobileMenuIcon} onClick={(e) => mobileSidebarToggle(e)} />
    </div>
  );
}
