import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageManualList = lazy(() =>
  lazyRetry(() => import('pages/(public)/manuals/PageManualList'), 'PageManualList')
);

const PageManualCategory = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/manuals/[category]/PageManualCategory'),
    'PageManualCategory'
  )
);

const PageManualDetail = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/manuals/[category]/[manual]/PageManualDetail'),
    'PageManualDetail'
  )
);

const routesManuals: Array<DBRoute> = [
  {
    path: 'URL.MANUALS_DETAIL',
    component: <PageManualDetail />,
    show: false,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.MANUALS_SECTION',
    component: <PageManualCategory />,
    show: false,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.MANUALS',
    name: 'DASHBOARD_ROUTES.MANUALS',
    icon: <Icon icon='streamline:manual-book-solid' width={30} height={30} />,
    component: <PageManualList />,
    show: true,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 14,
  },
];

export default routesManuals;
