import { ReactComponent as NfqesSVG } from 'assets/img/NFQES_ikona.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sanitize } from 'utils/Format';
import { hexToRgb } from 'utils/HexToRGB';

import { SlideButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';

import { Icon } from '@iconify/react';
import { Badge, Box, IconButton, styled } from '@mui/material';
import { keyframes } from '@mui/styled-engine';

const fadeOutAnimation = keyframes`
  from {
    transform-origin: top;
    transform: scaleY(1);
  }
  to {
    transform-origin: top;
    transform: scaleY(0);
  }
`;

const OuterBox: any = styled('div')(({ theme }: any) => ({
  color: theme.palette.info.contrastText,
  backgroundColor: theme.palette.info.background,
  borderRadius: '5px',
}));

const StyledIcon: any = styled(NfqesSVG)(({ theme }: any) => ({
  width: '32px',
  height: '32px !important',

  '& .fil0': {
    fill: theme.palette.info.contrastText,
  },
}));

const StyledIconButton: any = styled(IconButton)(({ theme }: any) => ({
  color: theme.palette.info.contrastText + '!important',
  marginLeft: 'auto',
  '@media (min-width: 900px)': {
    marginTop: '-28px',
    marginRight: '-14px',
  },
  marginTop: '-22px',
  marginRight: '-14px',
}));
const HeaderPremium: any = styled(Box)(({ theme, isclosing }: any) => ({
  background: `linear-gradient(45deg, ${theme.palette.sidebar.primary}  180px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip1}  210px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip2} 270px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,${theme.palette.sidebar.premiumStip3} 360px, rgba(${hexToRgb(
    theme.palette.sidebar.premiumStip3
  )}, 0.1))`,
  borderRadius: '10px',
  animation: isclosing === 'true' ? `${fadeOutAnimation} 0.2s ease` : 'none',
}));

interface IProps {
  text?: string;
  title?: string;
  link?: string;
}

export default function AnnouncementBox(props: IProps) {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const toggleVisibility = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 200);
  };
  const openLink = () => {
    window.open(props.link, '_blank');
  };

  return isVisible ? (
    <HeaderPremium
      sx={{ p: 1 }}
      className='marginLikePage'
      isclosing={isClosing ? 'true' : 'false'}
    >
      <OuterBox sx={{ p: '16px 12px' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} mb={1}>
          <Badge color='error' variant='dot'>
            <StyledIcon />
          </Badge>
          {props.title && (
            <div
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: '18px',
                marginLeft: '12px',
              }}
              dangerouslySetInnerHTML={{ __html: sanitize(props.title) }}
            ></div>
          )}
          <Tooltip
            title={<span style={{ fontSize: '12px' }}>{t('PROMO.DONT_SHOW')}</span>}
            placement='left'
          >
            <StyledIconButton
              onClick={(e) => {
                e.stopPropagation();
                toggleVisibility();
              }}
            >
              <Icon icon='iconamoon:close-bold' width='22' />
            </StyledIconButton>
          </Tooltip>
        </Box>

        <div
          style={{ width: '100%', wordBreak: 'break-all' }}
          dangerouslySetInnerHTML={{ __html: sanitize(props.text) }}
        ></div>
        {props.link.length > 0 && (
          <SlideButton
            onClick={openLink}
            mainAction
            icon='fa:external-link'
            small
            margin='right'
            style={{ marginTop: '19px !important', marginBottom: '1px !important' }}
          >
            {t('ANNOUNCEMENT.LEARN_MORE')}
          </SlideButton>
        )}
      </OuterBox>
    </HeaderPremium>
  ) : null;
}
