import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CustomTheme } from 'theme/PaletteDTO';

import { Box, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { InputProps } from '../hooks/useInput';

export interface DateInputProps extends InputProps<Date | string> {
  required: boolean;
}

/**
 * !POZOR: ked ma DateInput required: true, jeho hodnota moze byt undefined a teda je nekontrolovany. ked ma required: false, je kontrolovany. nesmie sa to striedat dynamicky, lebo MUI moze robit blbosti. takze DateInput musi mat pocas celej svojej existencie rovnaku hodnotu required
 */
export default function DateInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  disabled,
  size,
  // specifics
  required,
}: DateInputProps) {
  const { t } = useTranslation();

  const handleChange = (newValue) => {
    if (newValue) {
      const newDate = new Date(newValue.$d);
      newDate.setHours(0, 0, 0);
      onChange?.({ target: { value: newDate } });
    } else {
      onChange?.({ target: { value: undefined } });
    }
  };

  return (
    <Box>
      <DatePickerSx
        label={label}
        onChange={handleChange}
        value={value ? dayjs(value) : null}
        disabled={disabled}
        slotProps={{
          textField: {
            size: size || 'medium',
            id: name,
            placeholder: placeholder || label,
            variant: 'outlined',
            helperText: t(error),
            fullWidth: true,
            // ...inpProps,
            error: error?.length > 0,
            onBlur: onBlur,
            required: required,
            disabled: disabled,
          },
          field: !required
            ? { clearable: true, onClear: () => onChange({ target: { value: undefined } }) }
            : undefined,
        }}
      />
    </Box>
  );
}

const DatePickerSx = styled(DatePicker)(({ theme }: CustomTheme) => ({
  '& .MuiFormLabel-root': {
    top: '8px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '& div button': { color: 'text.primary' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder },
    '& svg': { color: 'text.primary' },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder, opacity: 0.6 },
  },
}));
