import { useTranslation } from 'react-i18next';

import { useAutoLogoutTimer } from 'hooks/useAutoLogoutTimer';

import { authorizationService } from '../../index';

import css from './HeaderNavbar.module.css';

interface HeaderTimeoutProps {
  className?: string;
}

function HeaderTimeout({ className = undefined }: HeaderTimeoutProps) {
  const { t } = useTranslation();

  const time = useAutoLogoutTimer();

  const isLoggedIn = authorizationService.isLoggedIn();

  const mins = Math.floor(time / 60);
  const secs = time - mins * 60;

  return (
    <span className={className}>
      {isLoggedIn && <b className={css.timerText}>{t('SIGNED_OUT.SIGN_OUT_IN')}</b>}
      {isLoggedIn && (
        <span className={css.timerBox}>
          {mins}:{secs > 9 ? secs : `0${secs}`}
        </span>
      )}
    </span>
  );
}

export default HeaderTimeout;
