import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { services, userCtx } from 'context/context';

import { IconButtonAnimate } from 'components/IconButton';

import { languages } from 'translations/config';

import { MenuItem, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { ReactComponent as SkFlag } from '../../assets/images/flags/SKflag.svg';
import { ReactComponent as USFlag } from '../../assets/images/flags/USFlag.svg';
import { authorizationService } from '../../index';
import MenuPopover from './HeaderComponents/MenuPopover';

interface ChooseLanguageProps {
  isMobile?: boolean;
}

export default function ChooseLanguage({ isMobile = false }: ChooseLanguageProps) {
  const { t, i18n } = useTranslation();
  const { changeLanguage: i18nChangeLanguage } = i18n;
  const [cookies, setCookie] = useCookies();
  const { iamService } = useContext(services);
  const user = useContext(userCtx);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const changeLanguage = (lang) => {
    sessionStorage.setItem('lang', lang);
    const cookieValue = cookies.cookies || localStorage.getItem('cookies');
    if (cookieValue === 'true') setCookie('lang', lang);

    if (authorizationService.isLoggedIn()) {
      iamService
        .changeLanguage(lang)
        .then(() => user.loadUser())
        .catch(() => {});
    } else {
      i18nChangeLanguage(lang);
      (window as any).webkit?.messageHandlers?.changeLanguage.postMessage(lang);
    }
  };

  useEffect(() => {
    if (user.data && user.data.language) {
      i18nChangeLanguage(user.data.language);
      (window as any).webkit?.messageHandlers?.changeLanguage.postMessage(user.data.language);
    }
  }, [user.data, i18nChangeLanguage]);

  const currentLanguage = i18n.language;
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          mt: { md: '4px', sm: '12px', xs: '12px' },
          ml: { md: '5px', sm: '12px', xs: '12px' },
          mr: { md: '5px', sm: '5px', xs: '5px' },
          width: 40,
          height: 40,
          ...(open && {
            '&:before': {
              zIndex: 1,
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {currentLanguage === 'sk' ? (
          <SkFlag width='30px' height='20px' />
        ) : (
          <USFlag width='30px' height='20px' />
        )}
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow={isMobile ? 'none' : 'top-right'}
        sx={{
          left: isMobile ? '0 !important' : '',
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {languages.map((option) => (
            <MenuItem
              key={option}
              selected={option === currentLanguage}
              onClick={() => {
                changeLanguage(option);
                handleClose();
              }}
            >
              {option === 'sk' ? (
                <SkFlag width='30px' height='20px' style={{ marginRight: '9px' }} />
              ) : (
                <USFlag width='30px' height='20px' style={{ marginRight: '9px' }} />
              )}

              {t(`LANGUAGE.${option?.toUpperCase()}`)}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
