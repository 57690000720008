import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import palette from 'theme/palette';
import typography from 'theme/typography';

import { createTheme, ThemeProvider } from '@mui/material';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: 'light',
});

export default function OurThemeProvider(props: any) {
  const [mode, setMode] = useState<any>(localStorage.getItem('mode') || 'light');

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  const toggleColorMode = useCallback(
    () => setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light')),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: mode === 'light' ? palette.light : palette.dark,
        typography: typography,
        components: {
          MuiButtonBase: {
            styleOverrides: {
              root: {
                color: `${palette[mode].text.primary} !important`,
                '&.Mui-disabled': {
                  color: `${palette[mode].text.disabled} !important`,
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                color: `${palette[mode].text.primary} !important`,
                '&.Mui-disabled': {
                  color: `${palette[mode].text.disabled} !important`,
                },
                '&.Mui-selected': {
                  color: `${palette[mode].primary.main} !important`,
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  const value = useMemo(() => ({ toggleColorMode, mode }), [toggleColorMode, mode]);

  return (
    <ColorModeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
