import { CustomTheme } from 'theme/PaletteDTO';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import css from './Bar.module.css';

const Bar = styled(Box)(({ theme, percent }: CustomTheme) => {
  let color = theme.palette?.progressBar.zero;
  if (percent >= 25) color = theme.palette?.progressBar.low;
  if (percent >= 50) color = theme.palette?.progressBar.medium;
  if (percent > 70) color = theme.palette?.progressBar.high;
  return {
    backgroundColor: color,
  };
});

const BoxSx = styled(Box)(({ theme }: CustomTheme) => ({
  backgroundColor: theme.palette.background.default + ' !important',
}));

interface SimpleBarProps {
  value: number;
  maxValue: number;
  text?: string;
  fixedNumberOfDecimal?: number;
}

export default function SimpleBar({
  value,
  maxValue,
  text,
  fixedNumberOfDecimal,
}: Readonly<SimpleBarProps>) {
  const percent = (maxValue > 0 ? value / maxValue : 0) * 100;
  const percentWidth = percent > 100 ? 100 : percent;

  return (
    <BoxSx className={css.barOut}>
      <Bar className={css.barIn} style={{ width: `${percentWidth}%` }} percent={percent}></Bar>
      <Typography
        className={css.percent}
        fontWeight={'bold'}
        color={'common.black'}
        sx={{ fontSize: '11px !important' }}
      >
        {text || `${percent.toFixed(fixedNumberOfDecimal ?? 1)}%`}
      </Typography>
    </BoxSx>
  );
}
