export const GRAVITEE_SK = {
  GRAVITEE: {
    USER_NAME: 'Prihlasovacie meno',
    NFQES_KEY: 'API kľúč',
    REMAINING_TIMESTAMP: 'Zostávajúci počet časových pečiatok',
    NOTIFICATION_REMAINING_TIMESTAMP: 'Notifikácia pri počte časových pečiatok',
    REMAINING_SIGNATURES: 'Zostávajúci počet transakcií',
    NOTIFICATION_REMAINING_SIGNATURES: 'Notifikácia pri počte podpisov',
    CERT_LIMIT: 'Počet zostávajúcich certifikátov',
    NOTIFICATION_CERT_LIMIT: 'Notifikácia pri počte certifikátov',
    CUSTOMER: 'Názov klienta (Klientský autentifikačný certifikát)',
    BUTTON_SET_NOTIFICATION: 'Nastaviť notifikáciu',
    NOTIFICATION_LIMIT_MUST_BE_POSITIVE_NUMBER: 'Notifikácia musí obsahovať kladnú hodnotu.',
    NOTIFICATION_SET_ERROR: 'Nepodarilo sa nastaviť notifikáciu.',
    NOTIFICATION_CONFIG: 'Nastavenie notifikácií',
    ADMIN_CONFIG: 'Nastavenie limitov',
    BUTTON_SET_SIGNATURES: 'Nastaviť zost. podpisy',
    BUTTON_SET_CUSTOMER: 'Nastaviť názov klienta',
    BUTTON_SET_CERT_LIMIT: 'Nastaviť zost. cert.',
    BUTTON_RESET: 'Zabudnuté heslo',
    BUTTON_BUY_TIMESTAMP: 'Kúpiť časové pečiatky',
    BUTTON_DELETE: 'Zrušiť účet',
    BUTTON_ADD_TIMESTAMPS: 'Pridať čas. peč.',
    BUTTON_SET_TIMESTAMPS: 'Nastaviť čas. peč.',
    ADDING_TIMESTAMPS: 'Počet pridaných čas. peč.',
    LIMIT_SUCCESSFULLY_CHANGED: 'Limit úspešne nastavený.',
    NOTIFICATION_SUCCESSFULLY_CHANGED: 'Notifikácia úspešne nastavená.',
    LIMIT_NOT_CHANGED: 'Limit sa nepodarilo nastaviť.',
    CUSTOMER_SUCCESSFULLY_CHANGED: 'Názov klienta úspešne nastavený.',
    CUSTOMER_NOT_CHANGED: 'Názov klienta sa nepodarilo nastaviť.',
  },
};
