import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import styled from '@emotion/styled';
import { AvatarProps, Avatar as MUIAvatar } from '@mui/material';

const CustomMUIAvatar: any = styled(MUIAvatar)(({ theme }: any) => ({
  fontWeight: 800,
  backgroundColor: theme.palette.sidebar.user,
  color: theme.palette.text.secondary,
  //   border: '2px solid ' + theme.palette.primary.main,
  fontSize: '0.8rem',

  '@media (max-width:900px)': {
    backgroundColor: 'transparent',
    color: '#fff',

    svg: {
      maxWidth: '14px',
    },
  },
}));

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({ children, sx, ...other }, ref) => {
  return (
    <CustomMUIAvatar
      ref={ref}
      sx={{
        ...sx,
      }}
      {...other}
    >
      {children}
    </CustomMUIAvatar>
  );
});

Avatar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default Avatar;
