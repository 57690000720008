import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';

import { useInput } from 'components/Input';
import { ModalContentProps, OTPDialog } from 'components/Modal';
import { NotificationManager } from 'components/NotifyBox';

import { OTPUserType } from './OTPDialog/OTPDialog';

interface NeededSmsFunctions {
  getUserMethods: () => Promise<{ items: { method: 0 | 1 | 2 | 3 | 4 | 5; level: number }[] }>;
  getMinimalOperationLevel?: (_operation: number) => Promise<number>;
  send2faToken?: (_operation: number, _method?: number, _operationName?: string) => Promise<string>;
  send2fa: (
    _operation: number,
    _operationName?: string,
    _method?: number,
    _level?: number
  ) => Promise<string>;
  sendRegistration2Fa?: (_uuid: string) => Promise<string>;
  addUser2fa?: (
    _operation: number,
    _operationName?: string,
    _method?: number,
    _token?: string
  ) => Promise<any>;
  getPrefilledRegistration?: (
    _prefilledUid: string,
    _token: string,
    _sessionId: string
  ) => Promise<any>;
}

export interface TwoFaDialogProps {
  //   message: string | JSX.Element;
  smsService: NeededSmsFunctions;
  afterSuccessAction: (
    _token: string,
    _method?: number,
    _level?: number,
    _sessionId?: string
  ) => any;
  user?: OTPUserType;
  level?: number;
  // customLimitReachedContent?: JSX.Element;
  isPreffiledRegistration?: boolean;
  operation: number;
  operationName: string;
  /**
   * 0 - SMS
   * 1 - EMAIL
   * 2 - OCRA
   * 5 - OCRA APP
   */
  allowedMethods: (0 | 1 | 2 | 3 | 4 | 5)[];
  infoText?: string;
  prefilledUid?: string;
}

export default function TwoFaDialog({
  resolve,
  changeTitle,
  ...props
}: ModalContentProps<TwoFaDialogProps>) {
  const { t } = useTranslation(['translation', 'sign', 'otp']);
  const [showLimitError, setShowLimitError] = useState(false); // Use state to manage showLimitError
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState<string>();

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [challenge, setChallenge] = useState('');

  const [step, setStep] = useState(0);

  const [type, setType] = useState('');

  const [minimalOperationLevel, setMinimalOperationLevel] = useState(2);
  const [userMethods, setUserMethods] = useState(
    props.allowedMethods.map((method) => ({
      method,
      level: -1,
    }))
  );
  const { smsService, operation, operationName, allowedMethods, infoText } = props;
  const inputProps = useInput<string>(
    '',
    'modal-input-sms',
    useCallback((val) => (val.length < 1 ? 'translation:DEFAULT.REQUIRED_FIELD' : ''), [])
  );
  // ---------- WEBSOCKET FOR 2FA
  const [transactionId, setTransactionId] = useState(null);

  const reloadUserMethods = useCallback(() => {
    if (props.user) {
      smsService.getUserMethods().then((res) => {
        setUserMethods(res.items);
      });
    }
  }, [props.user, smsService]);

  const getLevelByMethod = useCallback(
    (method: number) => {
      console.log(method);
      console.log(userMethods);
      const methodRecord = userMethods.find((item) => item.method === method);
      return methodRecord ? methodRecord.level : null;
    },
    [userMethods]
  );

  useEffect(() => {
    if (props.isPreffiledRegistration) {
      return;
    }
    if (smsService.getMinimalOperationLevel)
      smsService.getMinimalOperationLevel(operation).then((res) => {
        setMinimalOperationLevel(res);
      });
  }, [operation, props.isPreffiledRegistration, smsService]);

  useEffect(() => {
    reloadUserMethods();
  }, [reloadUserMethods]);

  const WS_URL = process.env.REACT_APP_WSS_ORCHERSTRATOR_URL + '/2fa/connect';

  const { lastMessage, sendMessage, readyState } = useWebSocket(transactionId ? WS_URL : null, {
    onOpen: () => console.log('wss:opened'),
    shouldReconnect: () => false,
    onClose: (event) => {
      if (event.code !== 4000 && event.code !== 4001) {
        setTransactionId(null);
        setChallenge(null);
        setSuccess(false);
        setLoading(false);
        setStep(0);
        setShowLimitError(false);
      }
    },
  });

  const oidcStorage = props.isPreffiledRegistration
    ? ''
    : (() => {
        const item = window.localStorage.getItem(
          `oidc.user:${process.env.REACT_APP_OAUTH}/:${process.env.REACT_APP_CLIENT_ID}`
        );
        try {
          return item ? JSON.parse(item) : '';
        } catch (error) {
          console.error('Error parsing JSON from sessionStorage:', error);
          return '';
        }
      })();

  useEffect(() => {
    if (transactionId !== null) {
      if (readyState === 1) {
        sendMessage(oidcStorage.access_token);
        sendMessage(transactionId);
      }
    }
  }, [oidcStorage.access_token, readyState, sendMessage, transactionId]);

  const getChalengeOnDemand = useCallback(() => sendMessage('1'), [sendMessage]);

  useEffect(() => {
    if (lastMessage?.data) {
      if (lastMessage?.data?.length === 7) {
        setChallenge(lastMessage?.data);
        return;
      }
      const data = JSON.parse(lastMessage.data);
      inputProps.setValue(data.Token);
      setOtp(data.Token);
    }
  }, [inputProps, lastMessage]);

  const allCloseActions = () => {
    resolve(true);
    setTransactionId(null);
    setChallenge(null);
    setSuccess(false);
    setLoading(false);
    setStep(0);
    setShowLimitError(false);
  };
  // ---------- END WEBSOCKET FOR 2FA
  const addUser2Fa = (method) => {
    smsService
      .send2faToken(6, 0, method)
      .then(() => {
        setStep(2);
      })
      .catch((err) => {
        setSuccess(false);
        setChallenge(null);
        setTransactionId(null);
        setChallenge(null);
        try {
          const resp = JSON.parse(err.message);
          if (resp.message === 'SMS limit reached') {
            NotificationManager.error('HISTORY.SMS_NOT_SEND_TOO_MUCH');
            setShowLimitError(true);
          } else {
            const utcDate = new Date(resp.params[0] * 1000);
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

            const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));
            const offset = localDate.getTimezoneOffset();
            localDate.setMinutes(localDate.getMinutes() - offset);
            localDate.setMinutes(localDate.getMinutes() + 2);

            NotificationManager.error(
              t('HISTORY.SMS_NOT_SEND_TIME').replace(':time', localDate.toLocaleString())
            );
          }
        } catch {
          setSuccess(false);
          setChallenge(null);
          setTransactionId(null);
          setChallenge(null);
          NotificationManager.error(t('HISTORY.SMS_NOT_SEND'));
        }
      });
  };

  const getSmsCode = useCallback(
    (type) => {
      setLoading(true);

      if (getLevelByMethod(Number(type)) === -1) {
        return;
      }

      smsService
        .send2fa(operation, operationName, type, getLevelByMethod(Number(type)))
        .then((res: any) => {
          setStep(1);
          setType(type);
          setShowLimitError(false);
          setSuccess(false);
          if (type === 5) {
            setTransactionId(res.transactionId ? res.transactionId : null);
          }
          setChallenge(res.challenge ? res.challenge : null);
          setSessionId(res);
        })
        .catch((err) => {
          setSuccess(false);
          setChallenge(null);
          setTransactionId(null);
          setChallenge(null);
          try {
            const resp = JSON.parse(err.message);
            if (resp.message === 'No pairing active for level') {
              NotificationManager.error(t('OTP.ACTIONS.OCRA_APP_ALERT'));
              setStep(0);
              return;
            }
            if (resp.message === 'User has no ocra token assigned') {
              NotificationManager.error(t('OTP.NO_OCRA'));
              setStep(0);
              return;
            }
            if (resp.message === 'SMS limit reached') {
              NotificationManager.error('HISTORY.SMS_NOT_SEND_TOO_MUCH');
              setStep(0);
              setShowLimitError(true);
            } else {
              // for dev purposes or admin to continue if 2fa timeout
              if (
                process.env.REACT_APP_HIDE_DEV_THINGS === 'false' ||
                props.user.role === 'admin'
              ) {
                setStep(1);
              } else {
                setStep(0);
              }
              const utcDate = new Date(resp.params[0] * 1000);
              const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

              const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));
              const offset = localDate.getTimezoneOffset();
              localDate.setMinutes(localDate.getMinutes() - offset);
              localDate.setMinutes(localDate.getMinutes() + 2);

              NotificationManager.error(
                t('HISTORY.SMS_NOT_SEND_TIME').replace(':time', localDate.toLocaleString())
              );
            }
          } catch {
            setStep(0);
            setSuccess(false);
            setChallenge(null);
            setTransactionId(null);
            setChallenge(null);
            NotificationManager.error(t('HISTORY.SMS_NOT_SEND'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [smsService, operation, operationName, getLevelByMethod, t, props.user?.role]
  );

  const isAdmin = props?.user?.role === 'admin' ? true : false;

  const getCodePreffiled = useCallback(
    (type) => {
      setLoading(true);
      smsService
        .sendRegistration2Fa(props.prefilledUid)
        .then((res: any) => {
          setStep(1);
          setType(type);
          setShowLimitError(false);
          setSuccess(false);
          setSessionId(res);
        })
        .catch((err) => {
          setSuccess(false);
          setChallenge(null);
          setTransactionId(null);
          setChallenge(null);
          try {
            const resp = JSON.parse(err.message);
            if (resp.message === 'SMS limit reached') {
              setShowLimitError(true);
              setStep(1);
            } else {
              // for dev purposes or admin to continue if 2fa timeout
              if (process.env.REACT_APP_HIDE_DEV_THINGS === 'false' || isAdmin) {
                setStep(1);
              }
              const utcDate = new Date(resp.params[0] * 1000);
              const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

              const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));
              const offset = localDate.getTimezoneOffset();
              localDate.setMinutes(localDate.getMinutes() - offset);
              localDate.setMinutes(localDate.getMinutes() + 2);

              NotificationManager.error(
                t('HISTORY.SMS_NOT_SEND_TIME').replace(':time', localDate.toLocaleString())
              );
            }
          } catch {
            setSuccess(false);
            setChallenge(null);
            setTransactionId(null);
            setChallenge(null);
            NotificationManager.error(t('HISTORY.SMS_NOT_SEND'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [smsService, props.prefilledUid, isAdmin, t]
  );

  const addMethodHandler = (token, method, methodName) => {
    setLoading(true);
    smsService
      .addUser2fa(6, methodName, 0, token)
      .then((response) => {
        if (response.status === 200) {
          reloadUserMethods();
          NotificationManager.success(t('OTP.SUCCESS_NEW_METHOD'));
          getSmsCode(method);
          setStep(1);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const confirmHandler = (token) => {
    setLoading(true);
    //  smsService
    //    .verify2fa(operation, token, operationName)
    //    .then((response) => {
    //      if (response.status === 200) {
    //        setSuccess(true);
    //        setLoading(false);
    //        setTimeout(() => {
    props
      .afterSuccessAction(token, Number(type), getLevelByMethod(Number(type)), sessionId)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setLoading(false);
          setTimeout(() => {
            allCloseActions();
          }, 500);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        let message = '';
        try {
          const errorMessage = JSON.parse(error.message)?.description;
          const startIndex = errorMessage.indexOf('{');
          const endIndex = errorMessage.lastIndexOf('}') + 1;
          const jsonErrorString = errorMessage?.substring(startIndex, endIndex);
          const errorObject = JSON.parse(jsonErrorString);
          // eslint je zablokovany, lebo nevytvaram novu premennu ale nastavujem hodnotu existujucej, takze nemozem pouzit destrukturovanie
          message = errorObject.message; //eslint-disable-line prefer-destructuring
        } catch {
          message = error.message; //eslint-disable-line prefer-destructuring
        }

        if (Number(message) === 0) {
          allCloseActions();
          NotificationManager.error(t('OTP.THREE_ATTEMPTS'));
        }
        setError(true);
        setLoading(false);
      });
    //   }
    // props.close();
    //       setTransactionId(null);
    //       setChallenge(null);
    //       setSuccess(false);
    //       setLoading(false);
    //     }, 200);
    //   } else {
    //     setError(true);
    //     setLoading(false);
    //   }
    // })
    // .catch(() => {
    //   setError(true);
    //   setLoading(false);
    // });
  };

  const confirmHandlerPrefilled = (token) => {
    setLoading(true);
    smsService
      .getPrefilledRegistration(props.prefilledUid, token, sessionId)
      .then((response) => {
        setSuccess(true);
        setLoading(false);
        setTimeout(() => {
          props.afterSuccessAction(response);
          allCloseActions();
          setTransactionId(null);
          setChallenge(null);
          setSuccess(false);
          setLoading(false);
        });
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <OTPDialog
      // message={props.message}
      changeTitle={changeTitle}
      close={allCloseActions}
      submitHandler={
        props.isPreffiledRegistration
          ? (token) => confirmHandlerPrefilled(token)
          : (token) => confirmHandler(token)
      }
      sendSMS={props.isPreffiledRegistration ? getCodePreffiled : getSmsCode}
      otpValue={otp}
      allowedMethods={allowedMethods}
      userMethods={userMethods}
      challenge={challenge}
      type={type}
      setType={setType}
      error={error}
      setError={setError}
      loading={loading}
      success={success}
      showLimitError={showLimitError}
      user={props.user}
      step={step}
      setStep={setStep}
      addUser2Fa={addUser2Fa}
      minimalOperationLevel={minimalOperationLevel}
      addMethodHandler={addMethodHandler}
      infoText={infoText}
      getChalengeOnDemand={getChalengeOnDemand}
      setTransactionId={setTransactionId}
    />
  );
}
