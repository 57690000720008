import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Box, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

import { InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

interface PasswordInputProps extends InputProps<string> {
  onFocus?: (_e) => void;
}

const EyeButton = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  fontSize: '18px',
  marginRight: '-6px',
  color: theme.palette.text.primary,
}));

export default function PasswordInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  onFocus,
}: // specifics
// ...
Readonly<PasswordInputProps>) {
  const { t } = useTranslation();
  const [visiblePassword, setVisiblePassword] = useState(false);

  const switchVisiblePassword = () => {
    setVisiblePassword((old) => !old);
  };

  return (
    <Box>
      <TextFieldSx
        onFocus={onFocus}
        size={size || 'medium'}
        id={name}
        data-testid={name}
        label={label}
        placeholder={placeholder}
        variant='outlined'
        type={visiblePassword ? 'text' : 'password'}
        value={value}
        error={error?.length > 0}
        helperText={<Box component='span' dangerouslySetInnerHTML={{ __html: t(error) }} />}
        onChange={onChange}
        fullWidth
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <EyeButton
                type='button'
                className={'css.inputButton'}
                onClick={switchVisiblePassword}
              >
                <Icon
                  icon={visiblePassword ? 'bi:eye-slash-fill' : 'bi:eye-fill'}
                  className={visiblePassword ? 'eye-slash-fill' : 'eye-fill'}
                />
              </EyeButton>
            </InputAdornment>
          ),
        }}
        // sx={{ "& fieldset": { borderColor: "primary.main" } }}
      />
    </Box>
  );
}
