import logos from 'assets/images/paymentsLogos/all-logos.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import URLTransform from 'service/defaultServices/URLTransform';
import handleMiddleClick from 'utils/handleMiddleClick';

import { Box } from '@mui/material';

import css from './Footer.module.css';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const insideLinks = ['CONTACT', 'TRUSTED_LIST', 'COOKIES'];

  const outsideLinks = [
    { name: 'POLITICS', link: URLTransform('documents') },
    {
      name: 'GENERAL_CONDITIONS',
      link: `${process.env.REACT_APP_NFQES_REDIRECT_URL}/${
        process.env[`REACT_APP_GENERAL_CONDITIONS_LINK${lang === 'en' ? '_EN' : ''}`]
      }`,
    },
    {
      name: 'GDPR',
      link: `${process.env.REACT_APP_NFQES_REDIRECT_URL}/${
        process.env[`REACT_APP_GDPR_LINK${lang === 'en' ? '_EN' : ''}`]
      }`,
    },
    { name: 'PRICING', link: URLTransform('price_list') },
  ];

  return (
    <Box className={`${css.footer}`} sx={{ backgroundColor: 'sidebar.primary' }}>
      <div className={css.links}>
        <div
          className={`${css.centeredDiv}`}
          style={{
            marginTop: '4px',
            marginBottom: '4px',
          }}
        >
          <img src={logos} alt={'CARDS'} className={css.logos} />

          <span className={css.pipe}>&#124;</span>

          {outsideLinks.map((link) => (
            <span
              key={link.name}
              onMouseDown={(e) => handleMiddleClick(e, link.link)}
              onClick={() => window.open(link.link, '_blank')}
            >
              {t(`DASHBOARD_ROUTES.${link.name}`)}
            </span>
          ))}

          {insideLinks.map((link) => (
            <Link to={t('URL.' + link) + ''} key={link}>
              {t('DASHBOARD_ROUTES.' + link)}
            </Link>
          ))}
          <span
            className={css.rightAligned}
            style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            onMouseDown={(e) => handleMiddleClick(e, process.env.REACT_APP_NFQES_REDIRECT_URL)}
            onClick={() => window.open(process.env.REACT_APP_NFQES_REDIRECT_URL, '_blank')}
          >
            &copy; {new Date().getFullYear()} {t('DASHBOARD_ROUTES.BRAINIT')}
          </span>
        </div>
      </div>
    </Box>
  );
}
