import sendRequestFunctions from 'service/common/sendRequestFunctions';

import { GraviteeAccount, GraviteeNotificationData } from './GraviteeDTO';

const {
  sendRequestNoBody,
  sendRequestNoBodyNoJSON,
  sendRequestWithBodyNoJSON,
  /* sendRequestNoBodyNoJSON,
    sendRequestNoBodyNoUser,
    sendRequestWithBody,
    sendRequestWithBodyNoJSON,
    sendRequestWithFormDataBody,
    sendRequestWithBodyNoUser, */
} = sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

export const getGraviteeApis = async (signal?: AbortSignal): Promise<Array<string>> => {
  try {
    return await sendRequestNoBody('/gravitee/apis', 'GET', signal);
  } catch {
    return [];
  }
};

export const getUserGraviteeAccountsAll = async (
  userId?: string,
  apiName?: string,
  signal?: AbortSignal
): Promise<Array<GraviteeAccount>> => {
  try {
    const queryString =
      'page=0&size=10' +
      (userId ? `&userId=${userId}` : '') +
      (apiName ? `&apiName=${apiName}` : '');
    const url = `/gravitee/getAll?${queryString}`;
    const response = await sendRequestNoBody(url, 'GET', signal);
    return response.content;
  } catch {
    return [];
  }
};

export const getUserGraviteeAccounts = async (
  apiName: string,
  userId?: string,
  signal?: AbortSignal
): Promise<Array<GraviteeAccount>> => {
  try {
    const url = userId ? `/gravitee/${apiName}/user?userId=${userId}` : `/gravitee/${apiName}/user`;
    return await sendRequestNoBody(url, 'GET', signal);
  } catch {
    return [];
  }
};

export const generateGraviteeAccount = async (
  apiName: string,
  userId: string,
  signal?: AbortSignal
): Promise<GraviteeAccount> => {
  try {
    return await sendRequestNoBody(
      `/admin/gravitee/${apiName}/user?userId=${userId}`,
      'POST',
      signal
    );
  } catch {
    return undefined;
  }
};

export const resetGraviteeAccountPassword = async (
  apiName: string,
  userId: string,
  signal?: AbortSignal
): Promise<GraviteeAccount> => {
  try {
    return await sendRequestNoBody(`/gravitee/${apiName}/user?userId=${userId}`, 'PUT', signal);
  } catch {
    return undefined;
  }
};

export const deleteGraviteeAccount = async (
  apiName: string,
  userId: string,
  signal?: AbortSignal
): Promise<boolean> => {
  try {
    const response = await sendRequestNoBodyNoJSON(
      `/gravitee/${apiName}/user?userId=${userId}`,
      'DELETE',
      signal
    );
    const OKstatus = await response.text();
    return OKstatus === 'OK';
  } catch {
    return false;
  }
};

export const setGraviteeNotificationLimit = async (
  notifyData: GraviteeNotificationData,
  signal?: AbortSignal
): Promise<boolean> => {
  try {
    await sendRequestWithBodyNoJSON(
      '/api/limit/create',
      'POST',
      JSON.stringify(notifyData),
      signal
    );
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
