export default function signFileServiceFunction<type = any>() {
  let files: type;
  let timestamp: any;

  function setData(data: type, minutes = 0) {
    files = data;
    if (minutes > 0) {
      let tmstmp = new Date();
      tmstmp.setMinutes(tmstmp.getMinutes() + minutes);
      timestamp = tmstmp;
    }
  }

  function getData() {
    if (!timestamp || timestamp >= new Date()) {
      return files;
    }
    return undefined;
  }

  return { setData, getData };
}
