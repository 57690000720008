import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleBar } from 'components/Bar';

import { Box, Grid, Typography } from '@mui/material';

import css from './LoadingOverlay.module.css';

interface IProps {
  children?: any;
  loading: boolean;
  setCount: Dispatch<SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
  total: number;
  count: number;
}

/**
 * const [mode] = useState<string>(localStorage.getItem('mode') || 'light');
 * Použitie
 * pridaj const { setLoading } = useContext(loadingCtx);
 * setLoading(true); keď pre zobrazenie loadera
 * setLoading(false); pre skrytie
 * setTotal(4); nastavi x / 4 ako progres
 * setCount(3); nastav=i 3 / x ako progres
 * ba v prípade že je nastavený total a count sa zobrazi progress bar, reset týchto hodnôt sa vykonáva keď sa loading zmeni na false
 */

export default function LoadingOverlay(props: Readonly<IProps>) {
  const { t } = useTranslation();
  const [realLoading, setRealLoading] = useState(false);
  const { loading, setCount, setTotal, total, count } = props;

  const currentUrl = window.location.href;

  useEffect(() => {
    if (loading === false) {
      setCount(0);
      setTotal(0);
    }
  }, [loading, setCount, setTotal]);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setRealLoading(loading);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setRealLoading(loading);
    }
  }, [loading]);

  return (
    <>
      {realLoading && (
        <Box
          className={css.testOverlay}
          sx={{
            width: currentUrl.includes('file-gateway')
              ? '100% !important'
              : {
                  xs: '100% !important',
                  sm: '100% !important',
                  md:
                    document.getElementById('nfqes-sidebar') !== null
                      ? 'calc(100% - 270px) !important'
                      : '100% !important',
                },
            left: currentUrl.includes('file-gateway')
              ? '0px !important'
              : {
                  xs: '0px !important',
                  sm: '0px !important',
                  md:
                    document.getElementById('nfqes-sidebar') !== null
                      ? '270px !important'
                      : '0px !important',
                },
          }}
        >
          <Box className={css.overlayIn} sx={{ width: { xs: '80%', md: '40%' } }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '20px', fontWeight: 'bold' }}>
              {t('HOME.LOADING')}...
            </Typography>
            <div className={css.loaderOffset}></div>
            <span className={css.loader}></span>
            {total !== undefined && total > 1 && count !== undefined && (
              <Grid container mt={3}>
                <SimpleBar value={count} maxValue={total} />
              </Grid>
            )}
          </Box>
        </Box>
      )}
      {props.children}
    </>
  );
}
