import { FC } from 'react';

import { Icon } from '@iconify/react';

import Avatar from './Avatar';
import createAvatar from './CreateAvatar';

interface MyAvatarProps {
  user: any;
}

const MyAvatar: FC<MyAvatarProps> = ({ user, ...other }) => {
  const name = user?.data ? user.data.person.firstName + ' ' + user.data.person.lastName : '';
  return (
    <Avatar
      src={null}
      alt={name}
      color={createAvatar(name).color}
      {...other}
      style={{ width: '38px', height: '38px' }}
    >
      <Icon icon='fa:user' width={18} />
    </Avatar>
  );
};

export default MyAvatar;
