import { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { CustomTheme } from 'theme/PaletteDTO';

import { SlideButton } from 'components/Buttons';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { Box, Paper, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';

interface ErrorBoundaryProps extends WithTranslation {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidMount(): void {
    //document.title = 'NFQES - ' + (this.props as any).t('ERROR_BOUNDARY.HEADER');
    document.title = 'NFQES';
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    const errorObj = { error: error.toString(), errorInfo };
    console.log(errorObj);
    if (process.env.REACT_APP_SEND_ERROR_LOGS === 'true') {
      Sentry.captureException(JSON.stringify(errorObj));
    }
  }

  redirectToHome() {
    window.location.href = '/';
  }

  render() {
    // Check if the error is thrown
    if ((this.state as any).hasError) {
      // You can render any custom fallback UI
      return (
        <Paper>
          <RootStyle>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '32px', flexDirection: 'column' }}
            >
              <IconSx icon='fluent:globe-error-20-regular' />
              <Box textAlign='center'>
                <Typography variant='h3'>{(this.props as any).t('ERROR_BOUNDARY.TEXT')}</Typography>
                <Typography>{(this.props as any).t('ERROR_BOUNDARY.ERROR')}</Typography>
              </Box>
              <SlideButton large onClick={() => window.location.reload()}>
                {(this.props as any).t('ERROR_BOUNDARY.TRY_AGAIN')}
              </SlideButton>
              <Typography variant='body2'>{(this.props as any).t('ERROR_BOUNDARY.OR')}</Typography>
              <SlideButton large onClick={() => this.redirectToHome()}>
                {(this.props as any).t('ERROR_BOUNDARY.BACK_TO_HOME')}
              </SlideButton>
            </Box>
          </RootStyle>
        </Paper>
      );
    }

    // Return children components in case of no error
    return (this.props as any).children;
  }
}

export default withTranslation()(ErrorBoundary);

const RootStyle = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '20vh',
  boxSizing: 'border-box',
}));

const IconSx = styled(Icon)(({ theme }: CustomTheme) => ({
  color: theme.palette.primary.main,
  width: '120px',
  height: '120px',
}));
