import { useContext } from 'react';

import { walktourCtx } from 'context/context';

const useWalktour = () => {
  const context = useContext(walktourCtx);

  if (!context) throw new Error('Context do not exist! useWalktoru.ts line 7');

  return context;
};

export default useWalktour;
