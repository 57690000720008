import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageOCRATokenManagement = lazy(() =>
  lazyRetry(
    () => import('pages/admin/ocra-token-management/PageOCRATokenManagement'),
    'PageOCRATokenManagement'
  )
);
const PageUserManagement = lazy(() =>
  lazyRetry(() => import('pages/admin/user-management/PageUserManagement'), 'PageUserManagement')
);
const PageUserManagementAll = lazy(() =>
  lazyRetry(
    () => import('pages/admin/user-management/all/PageUserManagementAll'),
    'PageUserManagementAll'
  )
);
const PageCertificateHubUsers = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/user-management/PageCertificateHubUsers'),
    'PageCertificateHubUsers'
  )
);
const PageCertificateHubCompanies = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/companies/PageCertificateHubCompanies'),
    'PageCertificateHubCompanies'
  )
);
const PageCertificateHubCertificates = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/certificates/PageCertificateHubCertificates'),
    'PageCertificateHubCertificates'
  )
);
const PageCertificateHubQscdCard = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/qscd-card/PageCertificateHubQscdCard'),
    'PageCertificateHubQscdCard'
  )
);
const PageCertificateHubRevokeCert = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/revoke-cert/PageCertificateHubRevokeCert'),
    'PageCertificateHubRevokeCert'
  )
);
const PageCertificateHubUsersDetail = lazy(() =>
  lazyRetry(
    () =>
      import('pages/admin/certificate-hub/user-management/detail/PageCertificateHubUsersDetail'),
    'PageCertificateHubUsersDetail'
  )
);
const PageCertificateHubCreateUser = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/create-user/[id]/PageCertificateHubCreateUser'),
    'PageCertificateHubUsersDetail'
  )
);
const PageCertificateHubCreateCompany = lazy(() =>
  lazyRetry(
    () => import('pages/admin/certificate-hub/create-company/[id]/PageCertificateHubCreateCompany'),
    'PageCertificateHubUsersDetail'
  )
);
const PageCertificateHubCreateCertificate = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/admin/certificate-hub/create-certificate/[id]/PageCertificateHubCreateCertificate'
      ),
    'PageCertificateHubUsersDetail'
  )
);
const PageAdminDeviceManagement = lazy(() =>
  lazyRetry(
    () => import('pages/admin/device-management/PageDeviceManagement'),
    'PageDeviceManagement'
  )
);
const PageAdminRegisterDevice = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-account/device-management/register/PageRegisterDevice'),
    'PageRegisterDevice'
  )
);
const PageAnnouncements = lazy(() =>
  lazyRetry(() => import('pages/admin/announcements/PageAnnouncements'), 'PageAnnouncements')
);
const PageUserManagementNewUser = lazy(() =>
  lazyRetry(
    () => import('pages/admin/user-management/new-user/PageUserManagementNewUser'),
    'PageUserManagementNewUser'
  )
);
const PageAnnouncementsNew = lazy(() =>
  lazyRetry(
    () => import('pages/admin/announcements/new/PageAnnouncementsNew'),
    'PageAnnouncementsNew'
  )
);
const PageAnnouncementsDetail = lazy(() =>
  lazyRetry(
    () => import('pages/admin/announcements/[id]/PageAnnouncementsDetail'),
    'PageAnnouncementsDetail'
  )
);
const PagePayments = lazy(() =>
  lazyRetry(() => import('pages/my-account/payments/PagePayments'), 'PagePayments')
);
const PagePaymentDetail = lazy(() =>
  lazyRetry(() => import('pages/my-account/payments/[id]/PagePaymentDetail'), 'PagePaymentDetail')
);
const PageTimestampAPIAccounts = lazy(() =>
  lazyRetry(
    () => import('pages/admin/timestamp-api-accounts/PageTimestampAPIAccounts'),
    'PageTimestampAPIAccounts'
  )
);
const PageAPIAccounts = lazy(() =>
  lazyRetry(() => import('pages/(public)/api-accounts/PageAPIAccounts'), 'PageAPIAccounts')
);
const PageSendEmail = lazy(() =>
  lazyRetry(() => import('pages/admin/send-email/PageSendEmail'), 'PageSendEmail')
);

const routesAdmin: Array<DBRoute> = [
  {
    path: 'URL.USER_MANAGEMEN',
    name: 'DASHBOARD_ROUTES.USER_MANAGEMEN',
    icon: <Icon icon='f7:person-2-fill' width={30} height={30} />,
    component: <PageUserManagement />,
    show: true,
    admin: true,
    manager: true,
    worker: true,
    order: 3.1,
    submenu: [
      {
        path: 'URL.USER_MANAGEMENT',
        name: 'DASHBOARD_ROUTES.USER_MANAGEMENT',
        icon: <Icon icon='f7:person-2-fill' width={30} height={30} />,
        component: <PageUserManagementAll />,
        show: true,
        admin: true,
        manager: true,
        worker: true,
      },
    ],
  },

  {
    path: 'URL.CERTIFICATE_HUB',
    name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB',
    icon: <Icon icon='material-symbols:assistant-on-hub-outline' width={30} height={30} />,
    component: <PageCertificateHubUsers />,
    show: true,
    admin: true,
    manager: true,
    worker: false,
    order: 3.2,
    submenu: [
      {
        path: 'URL.CERTIFICATE_HUB_LIST',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB_LIST',
        component: <PageCertificateHubUsers />,
        show: true,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_CERTIFICATE',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB_CERTIFICATE',
        component: <PageCertificateHubCertificates />,
        show: true,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_REVOKE_CERT',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB_REVOKE_CERT',
        component: <PageCertificateHubRevokeCert />,
        show: true,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_QSCD_CARD',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB_QSCD_CARD',
        component: <PageCertificateHubQscdCard />,
        show: true,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_COMPANIES',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_HUB_COMPANIES',
        component: <PageCertificateHubCompanies />,
        show: true,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_DETAIL',
        component: <PageCertificateHubUsersDetail />,
        show: false,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_CREATE_USER',
        component: <PageCertificateHubCreateUser />,
        show: false,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_CREATE_COMPANY',
        component: <PageCertificateHubCreateCompany />,
        show: false,
        admin: true,
        manager: true,
        worker: false,
      },
      {
        path: 'URL.CERTIFICATE_HUB_CREATE_CERTIFICATE',
        component: <PageCertificateHubCreateCertificate />,
        show: false,
        admin: true,
        manager: true,
        worker: false,
      },
    ],
  },

  {
    path: 'URL.USER_MANAGEMENT_NEW',
    component: <PageUserManagementNewUser />,
    show: false,
    admin: true,
    manager: false,
    worker: false,
  },
  {
    path: 'URL.OCRA_MANAGEMENT',
    name: 'DASHBOARD_ROUTES.OCRA_MANAGEMENT',
    icon: <Icon icon='bi:device-ssd' width={30} height={30} />,
    component: <PageOCRATokenManagement />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 3.3,
  },
  {
    path: 'URL.DEVICE_MANAGEMENT_ADMIN',
    name: 'DASHBOARD_ROUTES.DEVICE_MANAGEMENT',
    icon: <Icon icon='tabler:device-laptop' width={30} height={30} />,
    component: <PageAdminDeviceManagement />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 3.4,
  },
  {
    path: 'URL.REGISTER_DEVICE',
    component: <PageAdminRegisterDevice />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.ANNOUNCEMENT_MANAGEMENT',
    name: 'DASHBOARD_ROUTES.ANNOUNCEMENT_MANAGEMENT',
    icon: <Icon icon='mingcute:horn-fill' width={30} height={30} />,
    component: <PageAnnouncements />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: false,
    worker: false,
    order: 3.5,
  },
  {
    path: 'URL.ANNOUNCEMENT_DETAIL',
    component: <PageAnnouncementsDetail />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: false,
    worker: false,
  },
  {
    path: 'URL.ANNOUNCEMENT_CREATE_WITHOUT_ID',
    component: <PageAnnouncementsNew />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: false,
    worker: false,
  },
  {
    path: 'URL.PAYMENTS_ADMIN',
    name: 'DASHBOARD_ROUTES.PAYMENTS',
    icon: <Icon icon='ic:twotone-payments' width={30} height={30} />,
    component: <PagePayments />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 3.6,
  },
  {
    path: 'URL.PAYMENT_DETAIL_ADMIN',
    component: <PagePaymentDetail width={30} height={30} />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.TIMESTAMP_API_ADMIN',
    name: 'DASHBOARD_ROUTES.TIMESTAMP_API_ADMIN',
    icon: <Icon icon='ph:stamp-fill' width={30} height={30} />,
    component: <PageTimestampAPIAccounts />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 3.7,
  },
  {
    path: 'URL.API',
    name: 'DASHBOARD_ROUTES.API',
    icon: <Icon icon='material-symbols:api' width={30} height={30} />,
    component: <PageAPIAccounts />,
    show: true,
    user: true,
    unlogged: false,
    admin: false,
    manager: false,
    worker: false,
    order: 10,
  },
  {
    path: 'URL.ANNOUNCEMENT_SEND_EMAIL',
    component: <PageSendEmail />,
    show: false,
    user: false,
    unlogged: false,
    admin: true,
    manager: false,
    worker: false,
  },
];

export default routesAdmin;
