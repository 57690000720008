import sendRequestFunctions from 'service/common/sendRequestFunctions';
import { SearchParamFormat } from 'utils/Format';

import {
  EditMessageContainer,
  Message,
  MessageContainer,
  MessageNew,
  MessageRecipient,
  NewMessageContainer,
  RequestMessages,
} from './MessageInterface';

export default function messageServiceFunction() {
  const {
    sendRequestNoBody,
    sendRequestNoBodyNoJSON,
    sendRequestWithBody,
    sendRequestWithFormDataBody,

    sendRequestNoBodyNoUser,
    sendRequestNoBodyNoUserNoJSON,
  } = sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

  // GET MESSAGES --------------------------------------------------------------------------------------------------------------------

  function getMessages(
    request: string,
    page: number,
    rows: number,
    searchString?: string
  ): Promise<RequestMessages> {
    const url = `/message/${request}?page=${page}&size=${rows}${
      searchString ? `&${searchString}` : ''
    }`;

    return sendRequestNoBody(url, 'GET');
  }

  function getInboxMessages(
    page: number,
    rows: number,
    searchString?: string
  ): Promise<RequestMessages> {
    return getMessages('inbox', page, rows, searchString);
  }

  function getDraftsMessages(
    page: number,
    rows: number,
    searchString?: string
  ): Promise<RequestMessages> {
    return getMessages('drafts', page, rows, searchString);
  }

  function getSentMessages(
    page: number,
    rows: number,
    searchString?: string
  ): Promise<RequestMessages> {
    return getMessages('sent', page, rows, searchString);
  }

  function getDeletedMessages(
    page: number,
    rows: number,
    searchString?: string
  ): Promise<RequestMessages> {
    return getMessages('deleted', page, rows, searchString);
  }

  // SINGLE MESSAGE ---------------------------------------------------------------------------------------------------------------

  function saveNewKonceptMessage(message: MessageNew): Promise<Message> {
    if (!message.publicUserMessage)
      return sendRequestWithBody('/message/', 'POST', JSON.stringify(message));
    else return sendRequestWithBody('/message/create/public', 'POST', JSON.stringify(message));
  }

  function saveExistingKonceptMessage(id: number | string, message: MessageNew): Promise<Message> {
    return sendRequestWithBody(`/message/${id}`, 'PUT', JSON.stringify(message));
  }

  function copyMessage(msgId: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${msgId}/copy`, 'PUT');
  }

  function editMessage(msgId: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${msgId}/refuse-and-create-new`, 'PUT');
  }

  function getMessage(id: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}`, 'GET');
  }

  function getMessagesSummary(): Promise<{
    toBeSigned: number;
    toBeApproved: number;
    drafts: number;
  }> {
    return sendRequestNoBody(`/message/summary`, 'GET');
  }

  function deleteMessage(id: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}`, 'DELETE');
  }

  function sendMessage(id: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}/send`, 'PUT');
  }

  function restoreMessage(id: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}/restore`, 'PUT');
  }

  function refuseMessage(id: number | string, note: string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}/refuse?refuseReason=${note}`, 'PUT');
  }

  function acceptMessage(id: number | string): Promise<Message> {
    return sendRequestNoBody(`/message/${id}/accept`, 'PUT');
  }

  function addMessageRecipients(
    id: number | string,
    recipients: MessageRecipient[]
  ): Promise<boolean> {
    return sendRequestWithBody(`/message/${id}/recipients`, 'POST', JSON.stringify(recipients));
  }

  // MESSAGE FILES ------------------------------------------------------------------------------------------------------------

  function getMessageContainers(id: number | string): Promise<MessageContainer[]> {
    return sendRequestNoBody(`/message/${id}/filecontainer`, 'GET');
  }

  function addMessageContainer(
    id: number | string,
    folder: NewMessageContainer
  ): Promise<MessageContainer> {
    const data = new FormData();
    data.append('comment', folder.comment);
    data.append('addTimestamp', folder.addTimestamp + '');
    data.append('type', folder.type);
    if (folder.predefinedSignatures) {
      data.append('predefinedSignatures', JSON.stringify(folder.predefinedSignatures));
    }
    folder.files.forEach((file) => data.append('files', file));

    return sendRequestWithFormDataBody(`/message/${id}/filecontainer`, 'POST', data);
  }

  function addContainerWithFileIds(
    id: number | string,
    folder: {
      comment: string;
      addTimestamp: boolean;
      type: string;
      fileIds: number[];
      predefinedSignatures?: Array<any>;
    }
  ): Promise<MessageContainer> {
    const data = new FormData();
    data.append('comment', folder.comment);
    data.append('addTimestamp', folder.addTimestamp + '');
    data.append('type', folder.type);
    if (folder.predefinedSignatures) {
      data.append('predefinedSignatures', JSON.stringify(folder.predefinedSignatures));
    }
    folder.fileIds.forEach((fileId) => data.append('fileIds', fileId.toString()));

    return sendRequestWithFormDataBody(`/message/${id}/filecontainerWithIds`, 'POST', data);
  }

  function editMessageContainer(
    id: number | string,
    contID: number,
    container: EditMessageContainer
  ): Promise<MessageContainer> {
    return sendRequestWithBody(
      `/message/${id}/filecontainer/${contID}`,
      'PUT',
      JSON.stringify(container)
    );
  }

  function moveMessageFile(
    id: number | string,
    contId: number,
    fileId: number
  ): Promise<MessageContainer> {
    return sendRequestNoBody(
      `/message/${id}/movefile?fileId=${fileId}&containerTo=${contId}`,
      'PUT'
    );
  }

  function addMessageFiles(
    id: number | string,
    contID: number,
    files: File[]
  ): Promise<MessageContainer> {
    const data = new FormData();
    files.forEach((file) => data.append('files', file));

    return sendRequestWithFormDataBody(
      `/message/${id}/filecontainer/${contID}/files`,
      'POST',
      data
    );
  }

  // to prve id na na BE nekontroluje, nevieme, ci to ma byt id spravy alebo kontajnera
  function getMessageFile(id: number | string, fileId: number): Promise<Blob> {
    return sendRequestNoBodyNoJSON(`/message/${id}/files/${fileId}`, 'GET')
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function getMessageAllOriginalFiles(id: number | string): Promise<Blob> {
    return sendRequestNoBodyNoJSON(`/message/${id}/allOriginalFiles`, 'GET')
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function getMessageAllSignedFiles(id: number | string): Promise<Blob> {
    return sendRequestNoBodyNoJSON(`/message/${id}/allSignedFiles`, 'GET')
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function getSignedFilePreview(msgId: number | string, containerId: number): Promise<Blob> {
    return sendRequestNoBodyNoJSON(
      `/message/${msgId}/filecontainer/${containerId}/signedPreview`,
      'GET'
    )
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function getMessageSignedContainer(id: number | string, contID: number): Promise<Blob> {
    return sendRequestNoBodyNoJSON(`/message/${id}/filecontainer/${contID}/signed`, 'GET')
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function deleteMessageContainer(id: number | string, contID: number): Promise<boolean> {
    return sendRequestNoBody(`/message/${id}/filecontainer/${contID}`, 'DELETE');
  }

  function deleteMessageFile(id: number | string, fileId: number): Promise<boolean> {
    return sendRequestNoBody(`/message/${id}/files/${fileId}`, 'DELETE');
  }

  function addMessageAttachment(id: number | string, file: File): Promise<any> {
    const data = new FormData();
    data.append('file', file);

    return sendRequestWithFormDataBody(`/message/${id}/attachment`, 'POST', data);
  }

  function getMessageAttachments(id: number | string): Promise<any> {
    return sendRequestNoBody(`/message/${id}/attachment`, 'GET');
  }

  function deleteMessageAttachments(id: number | string, attID: number): Promise<any> {
    return sendRequestNoBody(`/message/${id}/attachment/${attID}`, 'DELETE');
  }

  // PUBLIC MESSAGE -------------------------------------------------------------------------------------------------------------------------------------------------

  function getMessagePublic(messageUuid: string, recipientUuid: string): Promise<Message> {
    return sendRequestNoBodyNoUser(
      `/message/public/get/${messageUuid}?recipientUuid=${recipientUuid}`,
      'GET'
    );
  }

  function getMessageContainersPublic(
    messageUuid: string,
    recipientUuid: string
  ): Promise<MessageContainer[]> {
    return sendRequestNoBodyNoUser(
      `/message/public/${messageUuid}/filecontainer?recipientUuid=${recipientUuid}`,
      'GET'
    );
  }

  function getMessageAttachmentsPublic(messageUuid: string, recipientUuid: string): Promise<any> {
    return sendRequestNoBodyNoUser(
      `/message/public/${messageUuid}/attachment?recipientUuid=${recipientUuid}`,
      'GET'
    );
  }

  // to prve messageUuid sa na BE nekontroluje, nevieme, ci tam ma byt id spravy alebo id kontajnera
  function getMessageFilePublic(
    messageUuid: string,
    recipientUuid: string,
    fileId: number
  ): Promise<Blob> {
    return sendRequestNoBodyNoUserNoJSON(
      `/message/public/${messageUuid}/files/${fileId}?messageUuid=${messageUuid}&recipientUuid=${recipientUuid}`,
      'GET'
    )
      .then((response) => response.blob())
      .then((blob) => blob);
  }

  function getMessageIdPublic(messageUuid: string, recipientUuid: string): Promise<string> {
    return sendRequestNoBodyNoUser(
      `/message/public/${messageUuid}?recipientUuid=${recipientUuid}`,
      'GET'
    );
  }

  function checkDownloadLinkValidity(messageUuid: string, recipientUuid: string): Promise<any> {
    return sendRequestNoBodyNoUser(
      `/message/public/${messageUuid}/isExpired?recipientUuid=${recipientUuid}`,
      'GET'
    );
  }

  function send2faToDownloadSignedDocumentsPublicMessage(
    messageUuid: string,
    recipientUuid: string
  ) {
    return sendRequestNoBodyNoUser(
      `/message/public/send2fa/download?messageUuid=${messageUuid}&recipientUuid=${recipientUuid}`,
      'POST'
    );
  }

  function getSignedDocumentsPublicMessage(
    messageUuid: string,
    recipientUui: string,
    token: string,
    sessionId: string
  ) {
    return sendRequestNoBodyNoUserNoJSON(
      `/message/public/${messageUuid}/allSignedFiles?recipientUuid=${recipientUui}&code=${token}&sessionId=${sessionId}`,
      'GET'
    );
  }

  // VALIDATE MESSAGE -----------------------------------------------------------------------------------------------------------------------------------------------

  function validateMessageFolder(
    containerId: number | string,
    policy?: string,
    validateOriginalDocuments?: boolean
  ): Promise<any> {
    const url = SearchParamFormat(`/messageValidation/validateSimpleContainer`, {
      containerId,
      policy,
      validateOriginalDocuments,
    });
    return sendRequestNoBody(url, 'POST');
  }

  function validateMessageAllFolders(
    messageId: number | string,
    policy?: string,
    validateOriginalDocuments?: boolean
  ): Promise<any> {
    const url = SearchParamFormat(`/messageValidation/validateSimple`, {
      messageId,
      policy,
      validateOriginalDocuments,
    });
    return sendRequestNoBody(url, 'POST');
  }

  function validateMessageFolderGES(
    containerId: number | string,
    policy?: string,
    validateOriginalDocuments?: boolean
  ): Promise<any> {
    const url = SearchParamFormat(`/messageValidation/validateSimpleContainerUpvs`, {
      containerId,
      policy,
      validateOriginalDocuments,
    });
    return sendRequestNoBody(url, 'POST');
  }

  function validateMessageAllFoldersGES(
    messageId: number | string,
    policy?: string,
    validateOriginalDocuments?: boolean
  ): Promise<any> {
    const url = SearchParamFormat(`/messageValidation/validateSimpleUpvs`, {
      messageId,
      policy,
      validateOriginalDocuments,
    });
    return sendRequestNoBody(url, 'POST');
  }

  return {
    getInboxMessages,
    getDraftsMessages,
    getSentMessages,
    getDeletedMessages,
    saveNewKonceptMessage,
    saveExistingKonceptMessage,
    copyMessage,
    editMessage,
    getMessage,
    getMessagesSummary,
    deleteMessage,
    sendMessage,
    restoreMessage,
    refuseMessage,
    acceptMessage,
    addMessageRecipients,
    getMessageContainers,
    moveMessageFile,
    addMessageContainer,
    addContainerWithFileIds,
    editMessageContainer,
    addMessageFiles,
    getMessageFile,
    getMessageAllOriginalFiles,
    getMessageAllSignedFiles,
    getSignedFilePreview,
    getMessageSignedContainer,
    deleteMessageContainer,
    deleteMessageFile,
    addMessageAttachment,
    getMessageAttachments,
    deleteMessageAttachments,
    getMessagePublic,
    getMessageContainersPublic,
    getMessageAttachmentsPublic,
    getMessageFilePublic,
    getMessageIdPublic,
    checkDownloadLinkValidity,
    send2faToDownloadSignedDocumentsPublicMessage,
    getSignedDocumentsPublicMessage,
    validateMessageFolder,
    validateMessageAllFolders,
    validateMessageFolderGES,
    validateMessageAllFoldersGES,
  };
}
