import { useCallback, useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { InputChangeMethod, UseInputProps } from './useInput';

export default function useAsyncInput<T>(
  initValue: T,
  nameOrId?: string,
  check?: (_newValue: T) => Promise<string>
): UseInputProps<T> {
  const [value, setValue] = useState<T>(initValue);
  const [error, setError] = useState<string>('');
  const [afterBlur, setAfterBlur] = useState<boolean>(false);

  const [hasErrorValue, setHasError] = useState(false);

  const asyncCheckHasError = useCallback(async () => {
    if (!check) {
      setHasError(false);
      return;
    }
    const temp = await check?.(value);
    setHasError(temp !== undefined && temp?.length > 0);
  }, [value, check]);

  useEffect(() => {
    asyncCheckHasError();
  }, [asyncCheckHasError]);

  const asyncCheckError = useCallback(async () => {
    setError((await check?.(value)) || '');
  }, [value, check]);

  useEffect(() => {
    if (afterBlur) asyncCheckError();
  }, [asyncCheckError, afterBlur]);

  const onChange = useCallback((e: InputChangeMethod<T> | SelectChangeEvent<T>) => {
    setValue(e.target.value as T);
  }, []);

  // funkcia, ktora vrati ci je input validny + ako bonus rozsvieti dany input (ak to input podporuje)
  const hasError = useCallback(() => {
    setAfterBlur(true);
    if (hasErrorValue && nameOrId) {
      const input = document.getElementById(nameOrId);
      input?.scrollIntoView({ behavior: 'smooth' });
    }
    return hasErrorValue;
  }, [hasErrorValue, nameOrId]);

  const onBlur = useCallback(() => {
    setAfterBlur(true);
  }, []);

  const reset = useCallback(() => {
    setValue(initValue);
    setError('');
    setAfterBlur(false);
  }, [initValue]);

  return {
    id: nameOrId,
    name: nameOrId,
    value,
    setValue,
    onChange,
    hasError,
    hasErrorValue,
    error,
    onBlur,
    reset,
  };
}

/*
    Vytvorenie custom inputu:
    
    0) naozaj sa zamysli, ci dany input naozaj treba - mozno staci len upravit check funckiu
    1) vytvor komponent "CustomInput", kde slovo "Custom" rozumne popíše typ daného inputu
    2) komponent musi prijimat minimalne parametre z interface InputProps
    3) ak tvoje input vracia iny format odpovede ako InputChangeMethod interface, vytvor handleChange metodu a prisposob odpoved
    4) ak je "error" dlhsi ako 0 znakov, zobraz ho

    Inspiruj sa vytvorenymi inputmi
*/
