/*
    tento komponent sluzi na to, aby odchytil "lang" v query parametroch a nastavil ho ako jazyk stranky
*/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export default function QueryLanguageSwitcher({ children }: any) {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const [query] = useSearchParams();
  const queryLang = query.get('lang');

  useEffect(() => {
    if (queryLang) {
      changeLanguage(queryLang);
    }
  }, [queryLang, changeLanguage]);

  return children;
}
