import { CustomTheme } from 'theme/PaletteDTO';

import { styled, TextField } from '@mui/material';

export const TextFieldSx = styled(TextField)(({ theme }: CustomTheme) => ({
  '& .MuiFormLabel-root': {
    top: '8px',
    color: (theme.palette as any).grey[500],
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '& div button': { color: 'text.primary' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder },
    '& svg': { color: 'text.primary' },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder, opacity: 0.6 },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));
