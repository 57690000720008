import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavItemRoot, NavItemSub } from './NavItem';

NavListRoot.propTypes = {
  userRole: PropTypes.string,
  list: PropTypes.any,
  isCorrect: PropTypes.func,
  sidebarShields: PropTypes.object,
  closeMenu: PropTypes.func,
};

export function NavListRoot({ userRole, list, isCorrect, sidebarShields, closeMenu }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const active = getActive(t(list.path), pathname);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    setOpen(active);
  }, [active]);

  if (!isCorrect(list)) {
    return;
  }
  const validSubmenuItems = list?.submenu?.filter((item) => isCorrect(item));

  if (list.submenu) {
    return (
      <>
        <NavItemRoot
          userRole={userRole}
          item={list}
          active={active}
          open={open}
          sidebarShields={sidebarShields}
          onOpen={() => setOpen(!open)}
        />

        <Collapse
          in={open}
          timeout='auto'
          unmountOnExit
          sx={{ p: 0, m: 0 /*, transition: 'opacity 0.5s ease'*/ }}
        >
          <List component='div' disablePadding sx={{ pl: 2 }}>
            {validSubmenuItems.map((item, index) => (
              <NavItemSub
                key={`${item.path}-${item.name}`}
                item={item}
                active={getActive(t(item.path), pathname)}
                isCorrect={isCorrect(item)}
                sidebarShields={sidebarShields}
                isLast={index === validSubmenuItems.length - 1}
                closeMenu={closeMenu}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <NavItemRoot
      userRole={userRole}
      key={list.name}
      item={list}
      active={active}
      closeMenu={closeMenu}
    />
  );
}

function getActive(path, pathname) {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
}
