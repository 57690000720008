import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IconButtonAnimate } from 'components/IconButton';
import { useWalktour, walktourPages } from 'components/Walktour';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { Box, Grid } from '@mui/material';

import { authorizationService } from '../../index';
import ChooseLanguage from './ChooseLanguage';
import HeaderNotificatios from './HeaderNotifications/HeaderNotifications';
import HeaderTimeout from './HeaderTimeout';
import OpenMenuButton from './OpenMenuButton';
import SubscriptionMenu from './SubscriptionMenu';
import UnloggedUserMenu from './UnloggedUserMenu';
import UserMenu from './UserMenu';

import css from './HeaderNavbar.module.css';

const HeaderPremium: any = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'subscribe' && prop !== 'menuButtonOnTheLeft',
})(({ theme, subscribe, menuButtonOnTheLeft }: any) => ({
  background:
    subscribe > 1
      ? menuButtonOnTheLeft
        ? `linear-gradient(45deg, ${theme.palette.sidebar.primary}  90px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip1}  110px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip2} 230px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,${theme.palette.sidebar.premiumStip3} 250px, ${theme.palette.sidebar.secondary} 0)`
        : `linear-gradient(45deg, ${theme.palette.sidebar.primary}  50px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip1}  70px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip2} 190px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,${theme.palette.sidebar.premiumStip3} 210px, ${theme.palette.sidebar.secondary} 0)`
      : menuButtonOnTheLeft
        ? `linear-gradient(45deg, ${theme.palette.sidebar.primary} 90px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,  ${theme.palette.sidebar.premiumStip1} 120px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,  ${theme.palette.sidebar.premiumStip3} 140px,  ${theme.palette.sidebar.secondary} 0)`
        : `linear-gradient(45deg, ${theme.palette.sidebar.primary} 50px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,  ${theme.palette.sidebar.premiumStip1} 80px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,  ${theme.palette.sidebar.premiumStip3} 100px,  ${theme.palette.sidebar.secondary} 0)`,
  color: theme.palette.text.primary,
}));

interface DesktopNavbarProps {
  subscription?: number;
  hiddenMenu?: boolean;
}

export default function DesktopNavbar({
  subscription = 0,
  hiddenMenu = false,
}: Readonly<DesktopNavbarProps>) {
  const { t } = useTranslation();
  const { setRun: setRunWalktour } = useWalktour();
  const { pathname } = useLocation();

  const isLoggedIn = authorizationService.isLoggedIn();

  return (
    <HeaderPremium className={css.navbar} subscribe={subscription} menuButtonOnTheLeft={hiddenMenu}>
      {hiddenMenu && <OpenMenuButton />}

      {subscription > 1 && <div className={css.premium}>{t(`SUBSCRIBE.PLAN_${subscription}`)}</div>}
      {authorizationService.isLoggedIn() && <HeaderTimeout className={css.timer} />}

      <Grid sx={{ textAlign: 'right' }}>
        <ChooseLanguage />

        <Box component={'span'}>
          {walktourPages.find((item) => t(item) === pathname) ? (
            <IconButtonAnimate
              color={'default'}
              onClick={() => setRunWalktour(true)}
              sx={{ width: 40, height: 40 }}
              className='wizard_home_walktour_button'
            >
              <Icon icon='mdi:lightbulb-help-outline' width={20} />
            </IconButtonAnimate>
          ) : (
            <Box display={'none'} className='wizard_home_walktour_button' />
          )}
        </Box>

        {!isLoggedIn && <UnloggedUserMenu />}

        {isLoggedIn && (
          <Fragment>
            <SubscriptionMenu isMobile={false} />
            <HeaderNotificatios />

            <UserMenu />
          </Fragment>
        )}
      </Grid>
    </HeaderPremium>
  );
}
