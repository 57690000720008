import { ReactComponent as Lock } from 'assets/img/lock.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SimplePage from 'containers/Page/SimplePage';

import { SlideButton } from 'components/Buttons';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const NNotfound = styled(Lock)(({ theme }: any) => ({
  height: 300,
  width: 300,
  '& .cls-1': {
    fill: theme.palette.sidebar.premiumStip2 + ' !important',
  },
  '& .cls-2': {
    fill: theme.palette.sidebar.premiumStip1 + ' !important',
  },
}));

export default function PageNoAccess() {
  const { t } = useTranslation();

  const navigation = useNavigate();

  const redirectToHomepage = () => {
    navigation(t('URL.SUBSCRIBE_INFO'));
  };

  return (
    <SimplePage
      title={t('NO_SUBSCRIPTION.MAIN')}
      component={
        <Box>
          <NNotfound />
        </Box>
      }
    >
      <Typography
        paddingBottom={2}
        variant='h4'
        sx={{ maxWidth: '600px', margin: 'auto', color: '#76838d !important' }}
      >
        {t('NO_SUBSCRIPTION.SECOND')}
      </Typography>
      <SlideButton
        primary
        mainAction
        onClick={redirectToHomepage}
        onMiddleClick={() => t('URL.SUBSCRIBE_INFO')}
      >
        {t('NO_SUBSCRIPTION.GO_SUBS')}
      </SlideButton>
    </SimplePage>
  );
}
