import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { lazyRetry } from '../Router';

const PageContact = lazy(() =>
  lazyRetry(() => import('pages/(common)/contact/PageContact'), 'PageContact')
);
const PageTrustedList = lazy(() =>
  lazyRetry(() => import('pages/(common)/trusted-list/PageTrustedList'), 'PageTrustedList')
);
const PageTrustedListDetail = lazy(() =>
  lazyRetry(
    () => import('pages/(common)/trusted-list/[id]/PageTrustedListDetail'),
    'PageTrustedListDetail'
  )
);
const PageSupport = lazy(() =>
  lazyRetry(() => import('pages/(public)/support/PageSupport'), 'PageSupport')
);

const routesNoCategory: Array<DBRoute> = [
  {
    path: 'URL.CONTACT',
    component: <PageContact />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.TRUSTED_LIST_DETAIL',
    component: <PageTrustedListDetail />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.TRUSTED_LIST',
    component: <PageTrustedList />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.SUPPORT',
    component: <PageSupport />,
    show: false,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesNoCategory;
