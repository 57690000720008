import { FreeCerts } from 'context/UserProvider';
import sendRequestFunctions from 'service/common/sendRequestFunctions';
import { FileValue, ValidationReportValue } from 'service/dto/ValidationDTO';
import {
  AnnouncementValues,
  BillingDTO,
  SubscriptionRestrictions,
  SubscriptionValue,
  UserContact,
  UserInfoValue,
} from 'service/iam/UserValue';

import { lang } from 'translations/config';

import { handleErrors } from '../defaultServices/ErrorHandler';
import { VisualSignature } from '../dto/VisualSignatureDTO';
import { ITicketData } from '../support/ITicketInterfaces';
import {
  CertificatePersonContact,
  CertificationValue,
  PagedCertificationValue,
} from './CertificationValue';
import {
  CompanyInvitationValue,
  CompanyValue,
  CreateCompanyValue,
  HistoryApprovalValue,
  PagedCompanyHistoryValue,
  PagedCompanyInviteeValue,
  PagedCompanyMemberValue,
  PagedCompanyValue,
} from './CompanyValue';
import { CertRequestToSendValue } from './CreateCertificate';
import defaultService from './DefaultService';
import { Response } from './DTO/ResponseDTO';
import { LimitsKeyType, LimitsValue } from './LimitsValue';
import { CardPay, PagedPaymentValue, Payment, Price, TatraPay } from './PaymentValue';
import { PagedQSCDcardValue, QSCDcardValue } from './QSCDcardValue';
import { PagedTimestampApiValue, TimestampApiValue } from './TimestampApiValue';
import { TlInfoDTOSEntity, TrustedListValue } from './TrustedListValue';

type RequestRole = 'user' | 'worker' | 'admin';

export default function nfqesServiceFunction() {
  const {
    sendRequestNoBody,
    sendRequestNoBodyNoJSON,
    sendRequestNoBodyNoUser,
    sendRequestWithBody,
    sendRequestWithBodyNoJSON,
    sendRequestWithFormDataBody,
    sendRequestWithBodyNoUser,
  } = sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

  // VALIDATE -------------------------------------------------------------------------------------------------------------------------------------------

  function validateDocument(doc: any): Promise<ValidationReportValue> {
    return sendRequestWithBodyNoUser('/signature/validateSimple', 'POST', doc, 'FORM');
  }

  function validateGESDocument(doc: any): Promise<ValidationReportValue> {
    return sendRequestWithBodyNoUser('/signature/validateUpvs', 'POST', doc, 'FORM');
  }

  function validationGetOriginalFiles(doc: any): Promise<FileValue[]> {
    const data = new FormData();
    data.append('signedFile', doc);
    return sendRequestWithBodyNoUser('/signature/validate/originalDocuments', 'POST', data, 'FORM');
  }

  // TIMESTAMPS -------------------------------------------------------------------------------------------------------------------------------

  function addTimeStamp(userId, count): Promise<any> {
    return sendRequestNoBodyNoJSON(`/admin/timestamp/${userId}/add/${count}`, 'POST');
  }

  function setTimeStamp(userId, count): Promise<any> {
    return sendRequestNoBodyNoJSON(`/admin/timestamp/${userId}/set/${count}`, 'POST');
  }

  function allowNegativeTimestampLimit(userId): Promise<any> {
    return sendRequestNoBody(`/admin/timestamp/${userId}/allow-negative-timestamp`, 'PUT');
  }

  function getTimeStampHistory(userId, page, size, sort): Promise<any> {
    return sendRequestNoBody(
      `/admin/timestamp/history?userId=${userId}&page=${page}&size=${size}&sort=${sort}`,
      'GET'
    );
  }

  function getTimeStampHistoryCurrentMonth(userId): Promise<any> {
    return sendRequestNoBody(`/admin/timestamp/history/current-month?userId=${userId}`, 'GET');
  }

  function getTimeStampHistoryMonth(userId, month, year): Promise<any> {
    return sendRequestNoBody(
      `/admin/timestamp/history/month?userId=${userId}&month=${month}&year=${year}`,
      'GET'
    );
  }

  function getTimeStamps(): Promise<any> {
    return sendRequestNoBody('/users/timestamp/count', 'GET');
  }

  function getTimeStampOrders(
    page: number,
    size: number,
    role: 'user' | 'worker',
    userId?: string
  ): Promise<any> {
    return sendRequestNoBody(
      `${role === 'worker' ? '/worker' : ''}/orders?typeId=3&statusId=1${
        userId ? `&userId=${userId}` : ''
      }&page=${page}&size=${size}&sort=paymentDue,desc&sort=id,desc`,
      'GET'
    );
  }

  function getUserTimeStamps(userId: string | number): Promise<any> {
    return sendRequestNoBody(`/users/timestamp/count?userId=${userId}`, 'GET');
  }

  function buyTimestamps(userId: string, count: number, billingInfoDTO: BillingDTO): Promise<any> {
    return sendRequestWithBody(
      `/orders/${userId}/timestamp/request/${count}`,
      'POST',
      JSON.stringify(billingInfoDTO)
    );
  }

  function timestampApiCreateAccount(userId?: string): Promise<TimestampApiValue> {
    let url = `/gravitee/timestampApi/user`;
    if (userId) url += `?userId=${userId}`;
    return sendRequestNoBody(url, 'POST');
  }

  function timestampApiGetAccount(): Promise<TimestampApiValue[]> {
    return sendRequestNoBody('/gravitee/timestampApi/user', 'GET');
  }

  function timestampApiWorkerGetAccounts(
    page: number,
    pageSize: number,
    userId?: string
  ): Promise<PagedTimestampApiValue> {
    let url = `/worker/gravitee/users?page=${page}&size=${pageSize}`;
    if (userId) url += `?userId=${userId}`;
    return sendRequestNoBody(url, 'GET');
  }

  function timestampApiDeleteAccount(userId?: string): Promise<string> {
    let url = `/gravitee/timestampApi/user`;
    if (userId) url += `?userId=${userId}`;
    return sendRequestNoBodyNoJSON(url, 'DELETE');
  }

  function timestampApiResetPassword(userId?: string): Promise<TimestampApiValue> {
    let url = `/gravitee/timestampApi/user`;
    if (userId) url += `?userId=${userId}`;
    return sendRequestNoBody(url, 'PUT');
  }

  function changeGraviteeSignLimit(userId: string, signatureLimit: number) {
    return sendRequestNoBodyNoJSON(
      `/admin/gravitee/signer/user/${userId}/signature-limit?signatureLimit=${signatureLimit}`,
      'PUT'
    );
  }

  function changeGraviteeHSMLimit(userId: string, signatureLimit: number) {
    return sendRequestNoBodyNoJSON(
      `/admin/gravitee/virtual-hsm/user/${userId}/signature-limit?signatureLimit=${signatureLimit}`,
      'PUT'
    );
  }

  function changeGraviteeCustomer(userId: string, customer: string) {
    return sendRequestNoBody(`/admin/gravitee/user/${userId}/customer?customer=${customer}`, 'PUT');
  }

  function changeGraviteeCertProviderLimit(userId: string, limit: string | number) {
    return sendRequestNoBody(
      `/admin/gravitee/cert-provider/user/${userId}/cert-limit?certLimit=${limit}`,
      'PUT'
    );
  }

  // OCRA TOKEN OREDR ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function buyOcra(userId: string, billingInfoDTO: BillingDTO): Promise<any> {
    return sendRequestWithBody(`/orders/${userId}/token`, 'POST', JSON.stringify(billingInfoDTO));
  }

  function getUsersOcraOrders(
    page: number,
    size: number,
    status?: number[],
    userId?: string
  ): Promise<any> {
    let url = `/hwtoken/order?page=${page}&size=${size}`;
    if (userId) url += `&userId=${userId}`;
    if (status?.length > 0) url += `&statusId=${status.join('&statusId=')}`;
    return sendRequestNoBody(url, 'GET');
  }

  function changeOcraOrderState(tokenId: number, newStatus: number): Promise<string> {
    return sendRequestNoBodyNoJSON(
      `/worker/hwtoken/order/${tokenId}?newStatus=${newStatus}`,
      'PUT'
    );
  }

  function deleteOcraOrder(tokenId) {
    return sendRequestNoBodyNoJSON(`/hwtoken/order/${tokenId}`, 'DELETE');
  }

  // VISUAL SIGNATURE ----------------------------------------------------------------------------------------------------------------------------------------------------------

  function createVisualSignature(png: any): Promise<any> {
    return sendRequestWithFormDataBody('/visualSignature?setDefault=true', 'POST', png);
  }

  function getAllVisualSignature(): Promise<VisualSignature[]> {
    return sendRequestNoBody(`/visualSignature/`, 'GET');
  }

  function getDefaultVisualSignature(): Promise<VisualSignature> {
    return sendRequestNoBody(`/visualSignature/default`, 'GET');
  }

  function setDefaultSignature(id: number): Promise<any> {
    return sendRequestNoBody(`/visualSignature/setDefault?visualSignatureId=${id}`, 'PUT');
  }

  function deleteVisualSignature(id: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/visualSignature/${id}`, 'DELETE');
  }

  // CERTIFICATES ----------------------------------------------------------------------------------------------------------------

  function getCertificate(id: number | string, role: RequestRole): Promise<CertificationValue> {
    return sendRequestNoBody(`${role === 'user' ? '' : '/worker'}/certs/find/${id}`, 'GET');
  }

  function downloadCertificate(id: number, role: RequestRole): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `${role === 'user' ? '' : '/worker'}/certs/download/${id}`,
      'GET'
    );
  }

  function downloadSend(id: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/certs/download/${id}/key`, 'GET');
  }

  function getCertificatesAndRequests(
    paging: string,
    filter: string,
    role: RequestRole
  ): Promise<PagedCertificationValue> {
    return sendRequestNoBody(
      `${role === 'user' ? '' : '/worker'}/certs/filter?${paging}${filter}`,
      'GET'
    );
  }

  function getCertificatesFiltered(
    paging: string,
    filter: string,
    role: RequestRole,
    certsToSign: boolean
  ): Promise<PagedCertificationValue> {
    return sendRequestNoBody(
      `${
        role === 'user' ? '' : '/worker'
      }/certs/filter?evidenceType=2&isOnQscdCard=false&${paging}${filter}&canSign=${certsToSign}`,
      'GET'
    );
  }

  function getCertificatesForSigning(minLevel: 2 | 3 | 4): Promise<PagedCertificationValue> {
    const fromTo = new Date().toISOString();
    return sendRequestNoBody(
      `/certs/filter?evidenceType=2&isOnQscdCard=false&page=0&size=999&sort=id,DESC&validFrom=${fromTo}&validTo=${fromTo}&hideServerCert=true&minimalSignatureLevel=${minLevel}&valid=1&active=true&canSign=true`,
      'GET'
    );
  }

  function getCertRequestsFiltered(
    paging: string,
    filter: string,
    role: RequestRole,
    canSign?: boolean
  ): Promise<PagedCertificationValue> {
    let url = '/certs/filter';
    if (role !== 'user') url = '/worker/certs/filter';

    if (role !== 'admin') {
      url += `?evidenceType=1&${paging}${filter}&canSign=`;
      if (canSign) {
        url += 'true';
      } else {
        url += 'false';
      }
    } else {
      url += `?${paging}${filter}`;
    }

    return sendRequestNoBody(url, 'GET');
  }

  function getCompanyCertificatesFiltered(
    paging: string,
    filter: string,
    role: RequestRole,
    companyId: number,
    certsToSign: boolean
  ): Promise<PagedCertificationValue> {
    return sendRequestNoBody(
      `${
        role === 'user' ? '' : '/worker'
      }/certs/filter/company/${companyId}?${paging}${filter}&canSign=${certsToSign}`,
      'GET'
    );
  }

  function disableCertificateAdmin(id: string): Promise<CertificationValue> {
    return sendRequestWithBody(`/admin/certs/${id}/revoke`, 'POST', '');
  }

  function disableCertificateWorker(
    id: string,
    userId: string,
    code: string,
    method: number,
    level: number | null
  ): Promise<CertificationValue> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestWithBodyNoJSON(
      `/worker/certs/${id}/revoke?userId=${userId}&${query}`,
      'POST',
      code + ''
    );
  }

  function disableCertificateUser(
    id: string,
    code: string,
    method: number,
    level: number | null
  ): Promise<CertificationValue> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestWithBodyNoJSON(`/certs/${id}/revoke?${query}`, 'POST', code + '');
  }

  function unblockCertificate(certificateId: number): Promise<CertificationValue> {
    return sendRequestNoBody(`/admin/certs/${certificateId}/activate`, 'PUT');
  }

  function validateCSR(csrValue: string, certType = 2): Promise<string | boolean> {
    return sendRequestWithBody(
      `/cert-requests/validate-csr?certificateType=${certType}`,
      'POST',
      csrValue
    );
  }

  function generateCertificate(
    certificate: CertRequestToSendValue,
    role: 'user' | 'worker'
  ): Promise<any> {
    return sendRequestWithBody(
      `${role === 'worker' ? '/worker' : ''}/cert-requests`,
      'POST',
      JSON.stringify(certificate)
    );
  }

  function editIdInRequest(certId: number, newValue: string): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/worker/cert-requests/${certId}/update-idCardOrPassport`,
      'PUT',
      newValue
    );
  }

  function editIdInRequestUser(certId: number, newValue: string): Promise<CertificationValue> {
    return sendRequestWithBody(`/cert-requests/${certId}/update-idCardOrPassport`, 'PUT', newValue);
  }

  function editRequestDN(
    certId: number,
    dn: {
      commonName: string;
      serialNumber: string;
      organization: string;
      organizationUnit: string;
      locality: string;
      state: string;
      country: string;
    }
  ): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/worker/cert-requests/${certId}/edit-subject`,
      'PUT',
      JSON.stringify(dn)
    );
  }

  function editRequestPerson(
    certId: number,
    authPerson: {
      firstName: string;
      lastName: string;
      email: string;
      mobile: string;
    },
    techPerson: {
      firstName: string;
      lastName: string;
      email: string;
      mobile: string;
    }
  ): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/worker/certs/${certId}/updatePerson`,
      'PUT',
      JSON.stringify({
        authPerson,
        techPerson,
      })
    );
  }

  function generateCertificateProlong(
    role: 'user' | 'worker',
    evidenceId,
    withoutPaymentOrder?
  ): Promise<any> {
    return sendRequestNoBody(
      `${
        role === 'worker' ? '/worker' : ''
      }/cert-requests/${evidenceId}/prolong?withoutPaymentOrder=${withoutPaymentOrder || false}`,
      'POST'
    );
  }

  function downloadCertificateRequest(id: number, role: RequestRole): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `${role === 'user' ? '' : '/worker'}/cert-requests/download/${id}`,
      'GET'
    );
  }

  function getTrustServiceAgreement(body: {
    name: string;
    address: string;
    contact: string;
    identificationNumber?: string;
  }): Promise<any> {
    return sendRequestWithBodyNoJSON(
      '/cert-requests/trust-service-agreement',
      'POST',
      JSON.stringify(body)
    );
  }

  function uploadDocument(doc: any, id: number, role: RequestRole): Promise<void> {
    return sendRequestWithFormDataBody(
      `${role === 'user' ? '' : '/worker'}/cert-requests/upload/${id}`,
      'POST',
      doc
    );
  }

  function downloadDocument(
    fileId: number,
    role: 'user' | 'worker'
  ): Promise<{
    id: number;
    active: boolean;
    fileName: string;
    creationTime: string;
    file: File;
  }> {
    return sendRequestNoBody(
      `${role === 'user' ? '' : '/worker'}/cert-requests/findFile/${fileId}`,
      'GET'
    );
  }

  function cancelCertRequest(id: number): Promise<CertificationValue> {
    return sendRequestNoBody(`/cert-requests/${id}`, 'DELETE');
  }

  function returnCertRequest(id: string, description: string): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/worker/cert-requests/${id}/change-state/1`,
      'PUT',
      JSON.stringify(description)
    );
  }

  function updateCertRequestSubject(id: string | number): Promise<CertificationValue> {
    return sendRequestWithBody(`/worker/cert-requests/${id}/update-subject`, 'PUT', '');
  }

  function approveCertRequest(id: string): Promise<void> {
    return sendRequestWithBody(`/worker/cert-requests/approve/${id}`, 'PUT', '');
  }

  function rejectCertRequest(id: string, description: string): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/worker/cert-requests/reject/${id}`,
      'PUT',
      JSON.stringify(description)
    );
  }

  function approveAdminCertRequest(id: string): Promise<void> {
    return sendRequestWithBody(`/admin/cert-requests/approve/${id}`, 'PUT', '');
  }

  function rejectAdminCertRequest(id: string, description: string): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/admin/cert-requests/return/${id}`,
      'PUT',
      JSON.stringify(description)
    );
  }

  function generateCertOnCard(certId: number, type: 'PEM' | 'DER', csr: string) {
    return sendRequestWithBodyNoJSON(
      `/admin/cert-requests/${certId}/generate/${type.toLowerCase()}`,
      'POST',
      csr
    );
  }

  function getFreeCerts(
    role: 'user' | 'worker',
    userId?: string
  ): Promise<Record<FreeCerts, boolean>> {
    return sendRequestNoBody(
      `${role === 'user' ? '' : '/worker'}/cert-requests/getUserFreeCert${
        role === 'user' ? '' : `?userId=${userId}`
      }`,
      'GET'
    );
  }

  function changeVerification(userId: string, certId: number, data: any): Promise<any> {
    return sendRequestWithBody(
      `/orders/${userId}/cert-identity-verification?certificateId=${certId}`,
      'POST',
      JSON.stringify(data)
    );
  }

  function updateCertPerson(
    id: number,
    authPerson: CertificatePersonContact,
    techPerson: CertificatePersonContact
  ): Promise<CertificationValue> {
    return sendRequestWithBody(
      `/certs/${id}/updatePerson`,
      'PUT',
      JSON.stringify({ authPerson, techPerson })
    );
  }

  // SUBSCRIPTION -------------------------------------------------------------------------------------------------------------------------

  function requestSubscription(data: SubscriptionValue, role: 'user' | 'worker'): Promise<any> {
    return sendRequestWithBody(
      `${role === 'worker' ? '/worker' : ''}/subscription/`,
      'POST',
      JSON.stringify(data)
    );
  }

  function updateSubscription(id: number | string, data: SubscriptionValue): Promise<any> {
    return sendRequestWithBody(`/worker/subscription/${id}`, 'PUT', JSON.stringify(data));
  }

  function subscriptionSetAutorenewal(id: number, autRenew: boolean, signal?): Promise<any> {
    return sendRequestNoBody(
      `/subscription/${id}/renewal?automaticRenewal=${autRenew}`,
      'PUT',
      signal
    );
  }

  function deleteSubscription(id: number): Promise<any> {
    return sendRequestNoBody(`/worker/subscription/${id}`, 'DELETE');
  }

  function deleteSubscriptionUser(id: number): Promise<any> {
    return sendRequestNoBody(`/subscription/${id}`, 'DELETE');
  }

  // signal je parameter z AbortController na zrusenie requestu
  function getSubscriptionRequests(
    paging: string,
    role: 'user' | 'worker',
    signal?
  ): Promise<{ content: SubscriptionValue[] }> {
    return sendRequestNoBody(
      `${role === 'worker' ? '/worker' : ''}/subscription/requestAll${paging}`,
      'GET',
      signal
    );
  }

  // signal je parameter z AbortController na zrusenie requestu
  function getUserSubscription(userId: string, signal?): Promise<SubscriptionValue> {
    return sendRequestNoBody(`/worker/subscription/getByUser?userId=${userId}`, 'GET', signal);
  }

  // signal je parameter z AbortController na zrusenie requestu
  function getMySubscription(signal?): Promise<SubscriptionValue & { restrictions: LimitsValue }> {
    return sendRequestNoBody('/subscription/', 'GET', signal);
  }

  // signal je parameter z AbortController na zrusenie requestu
  function getSubscriptionLimits(
    levelList?:
      | (0 | 1 | 2 | 3 | 4 | 5 | '0' | '1' | '2' | '3' | '4' | '5')[]
      | (0 | 1 | 2 | 3 | 4 | 5 | '0' | '1' | '2' | '3' | '4' | '5'),
    actionKeyList?: LimitsKeyType[],
    signal?
  ): Promise<{ level: 0 | 1 | 2 | 3 | 4; restrictions: LimitsValue }[]> {
    const queryObject: any = {
      levelList,
      actionKeyList,
    };
    if (!levelList) delete queryObject.levelList;
    if (!actionKeyList) delete queryObject.actionKeyList;
    const query = new URLSearchParams(queryObject).toString();
    let url = `/subscriptionRestriction/getRestrictions?${query}`;
    return sendRequestNoBodyNoUser(url, 'GET', signal);
  }

  function getSubscriptionRestrictions(signal?): Promise<SubscriptionRestrictions[]> {
    return sendRequestNoBody(`/admin/subscriptionRestriction/getAll`, 'GET', signal);
  }

  function changeSubscriptionRestrictions(data, signal?): Promise<any> {
    return sendRequestWithBody(
      `/admin/subscriptionRestriction/${data.id}`,
      'PUT',
      JSON.stringify(data),
      signal
    );
  }

  function changeEnterpriseRestrictions(
    data: { id: string; actionKey: string; userId: string; value: string },
    signal?
  ): Promise<any> {
    return sendRequestWithBody(
      `/admin/subscriptionRestriction/user/${data.id}`,
      'PUT',
      JSON.stringify(data),
      signal
    );
  }

  function postEnterpriseRestrictions(
    data: { actionKey: string; userId: string; value: string },
    signal?
  ): Promise<any> {
    return sendRequestWithBody(
      `/admin/subscriptionRestriction/user/`,
      'POST',
      JSON.stringify(data),
      signal
    );
  }

  function getUserEnterpriseLimits({
    userId,
  }: {
    userId: string;
    actionKey?: string;
  }): Promise<any> {
    return sendRequestNoBody(`/admin/subscriptionRestriction/user/?userId=${userId}`, 'GET');
  }

  function getUserInfo(userId?: string): Promise<UserInfoValue> {
    let req = '/userInfo/getUserInfo';
    if (userId) {
      req += `?userId=${userId}`;
    }
    return sendRequestNoBody(req, 'GET');
  }

  // signal je parameter z AbortController na zrusenie requestu
  function getSubscription(id: string, signal?): Promise<any> {
    return sendRequestNoBody(`/subscription/${id}`, 'GET', signal);
  }

  // TRUSTED LIST ------------------------------------------------------------------------------------------------------------------------------------------------------------

  function getTrustedList(): Promise<TrustedListValue> {
    return sendRequestNoBodyNoUser('/signature/tl-info', 'GET');
  }

  function getTrustedListDetail(id: string): Promise<TlInfoDTOSEntity> {
    return sendRequestNoBodyNoUser(`/signature/tl-info/${id}`, 'GET');
  }

  // CONTACTS ------------------------------------------------------------------------------------------------------------------------------------------------------

  function getContacts(): Promise<{ id: number; name: string; email: string }[]> {
    return sendRequestNoBody('/contacts/', 'GET');
  }

  function createContact(data: UserContact): Promise<any> {
    return sendRequestWithBody('/contacts/', 'POST', JSON.stringify(data));
  }

  function editContact(id: number, data: UserContact): Promise<any> {
    return sendRequestWithBody('/contacts/', 'PUT', JSON.stringify({ id, ...data }));
  }

  function deleteContact(id: number): Promise<any> {
    return sendRequestNoBody(`/contacts/${id}`, 'DELETE');
  }

  // PAYMENTS ------------------------------------------------------------------------------------------------------------------------------------------------------

  function getPaymentType(): Promise<any> {
    return sendRequestNoBody(`/enum/payment-type`, 'GET');
  }

  function getPaymentItem(): Promise<any> {
    return sendRequestNoBody(`/enum/payment-item`, 'GET');
  }

  function getPayments(role, page, size, orderBy, order, filter = ''): Promise<PagedPaymentValue> {
    return sendRequestNoBody(
      `${
        role === 'user' ? '' : '/worker'
      }/orders?page=${page}&size=${size}&sort=${orderBy},${order}${filter}`,
      'GET'
    );
  }

  function getPayment(role, paymentId): Promise<Payment> {
    return sendRequestNoBody(`${role === 'user' ? '' : '/worker'}/orders/${paymentId}`, 'GET');
  }

  function getPaymentPreinvoice(role, paymentId): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `${role === 'user' ? '' : '/worker'}/orders/${paymentId}/proformaPdf`,
      'GET'
    );
  }

  function getPaymentInvoice(role, paymentId): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `${role === 'user' ? '' : '/worker'}/orders/${paymentId}/invoicePdf`,
      'GET'
    );
  }

  function getCardPaymentData(orderId: string, saveCard: boolean): Promise<CardPay> {
    return sendRequestNoBody(
      `/payment/${orderId}/cardPay${saveCard ? '?saveCard=true' : ''}`,
      'GET'
    );
  }

  function getTatraPaymentData(orderId): Promise<TatraPay> {
    return sendRequestNoBody(`/payment/${orderId}/tatraPay`, 'GET');
  }

  function getPayBySqureQRCode(payment: Payment): Promise<any> {
    const reqBody = {
      amount: payment.value?.toString(),
      currency: payment.currency,
      dueDate: payment.paymentDue,
      variable: payment.variableSymbol,
      constant: payment.constantSymbol,
      specific: payment.specificSymbol !== undefined ? payment.specificSymbol : '',
      iban: payment.iban,
      bic: 'TATRSKBXXXX',
      includeBackground: 'true',
      includeLogo: 'true',
    };
    return defaultService
      .customFetch(
        process.env.REACT_APP_PAYBYSQUARE_API_URL +
          `/paybysquare/qrcode?` +
          new URLSearchParams(reqBody),
        {
          method: 'GET',
        }
      )
      .then((response) => handleErrors(response))
      .then((response) => response);
    //.then((json) => json);
  }

  function payAdmin(orderId): Promise<Payment> {
    return sendRequestNoBody(`/admin/orders/${orderId}/pay`, 'PUT');
  }

  function getPrices(
    type?: 'cert' | 'subscr' | 'ts' | 'cert_prol' | 'token' | 'card' | 'card_reader'
  ): Promise<Price[]> {
    const types = {
      cert: '1',
      subscr: '2',
      ts: '3',
      cert_prol: '4',
      token: '5',
      card: '6',
      card_reader: '7',
    };
    let url = `/enum/payment-item?showPrices=true`;
    if (type) url += `&type=${types[type]}`;
    return sendRequestNoBodyNoUser(url, 'GET');
  }

  // ANNOUNCEMENTS ------------------------------------------------------------------------------------------------------------------------------------------------------

  function getAnnouncements(): Promise<any> {
    return sendRequestNoBody('/announcement', 'GET');
  }

  function createAnnouncement(data: AnnouncementValues): Promise<any> {
    return sendRequestWithBody('/worker/announcement', 'POST', JSON.stringify(data));
  }

  function getAnnouncement(id: string | number): Promise<any> {
    return sendRequestNoBody(`/announcement/${id}`, 'GET');
  }

  function updateAnnouncement(id: string | number, data: AnnouncementValues): Promise<any> {
    return sendRequestWithBody(`/worker/announcement/${id}`, 'PUT', JSON.stringify(data));
  }

  function deleteAnnouncement(id: number): Promise<any> {
    return sendRequestNoBody(`/worker/announcement/${id}`, 'DELETE');
  }

  //TWO FACTOR ----------------------------------------------------------------------------------------------------------------------------------------------------------------

  function hwtokenAuthGenerate(
    key: string,
    referenceId: number,
    level: number
  ): Promise<{ requestCode: string; guid: string }> {
    return sendRequestWithBody(
      '/hwtoken/auth/generateCode',
      'POST',
      JSON.stringify({ key, referenceId, level })
    );
  }

  function hwtokenAuthVerify(
    guid: string,
    code: string
  ): Promise<{
    verify: boolean;
    numberOfFailedAttempts: number;
    errorText: string;
  }> {
    let data = new FormData();
    data.append('responseCode', code);
    data.append('guid', guid);

    return sendRequestWithFormDataBody('/hwtoken/auth/verifyCode', 'POST', data);
  }

  function workerHwtokenAuthGenerate(
    userId: string,
    key: string,
    referenceId: number,
    level: number
  ): Promise<{ requestCode: string; guid: string }> {
    return sendRequestWithBody(
      `/worker/hwtoken/auth/generateCode?userId=${userId}`,
      'POST',
      JSON.stringify({ key, referenceId, level })
    );
  }

  function workerHwtokenAuthVerify(
    userId: string,
    guid: string,
    code: string
  ): Promise<{
    verify: boolean;
    numberOfFailedAttempts: number;
    errorText: string;
  }> {
    let data = new FormData();
    data.append('responseCode', code);
    data.append('guid', guid);

    return sendRequestWithFormDataBody(
      `/worker/hwtoken/auth/verifyCode?userId=${userId}`,
      'POST',
      data
    );
  }

  // COMPANIES ----------------------------------------------------------------------------------------------------------------------------------------------------------------

  function createCompany(company: CreateCompanyValue): Promise<any> {
    return sendRequestWithBody('/company', 'POST', JSON.stringify(company));
  }

  function createCompanyAdmin(company: CreateCompanyValue, userId: string): Promise<any> {
    return sendRequestWithBody(`/worker/company?userId=${userId}`, 'POST', JSON.stringify(company));
  }

  function updateCompany(companyId: number, company: CreateCompanyValue): Promise<any> {
    return sendRequestWithBody(`/company/${companyId}`, 'PUT', JSON.stringify(company));
  }

  function changeCompanyLanguage(companyId: number, language: lang): Promise<CompanyValue> {
    return sendRequestNoBody(`/company/${companyId}/language?language=${language}`, 'PUT');
  }

  function getCountOfExistingCompanies(userId?: string): Promise<number> {
    return sendRequestNoBody(
      userId ? `/worker/company/count?userId=${userId}` : '/company/count',
      'GET'
    );
  }

  function getCompanies(
    page: number,
    pageSize: number,
    userId?: string,
    searchString = '',
    signal?: AbortSignal
  ): Promise<PagedCompanyValue> {
    let url = `/company?page=${page}&size=${pageSize}`;
    if (userId) url += `&userId=${userId}`;
    url += searchString;
    return sendRequestNoBody(url, 'GET', signal);
  }

  function getCompaniesAccountable(
    page: number,
    pageSize: number,
    userId?: string,
    searchString = '',
    signal?: AbortSignal
  ): Promise<PagedCompanyValue> {
    return sendRequestNoBody(
      `/worker/company?page=${page}&size=${pageSize}${
        userId ? `&userId=${userId}` : ''
      }${searchString}`,
      'GET',
      signal
    );
  }

  function getCompany(companyId): Promise<CompanyValue> {
    return sendRequestNoBody(`/company/${companyId}`, 'GET');
  }

  function checkHasContractWork(userId: string): Promise<any> {
    return sendRequestNoBody(`/company/contractor?userId=${userId}`, 'GET');
  }

  function approveCompanyRequest(companyId: number, note = ''): Promise<any> {
    return sendRequestWithBody(`/worker/company/${companyId}/approve`, 'PUT', JSON.stringify(note));
  }

  function rejectCompanyRequest(companyId: number, note: string): Promise<any> {
    return sendRequestWithBody(`/worker/company/${companyId}/reject`, 'PUT', JSON.stringify(note));
  }

  function returnCompanyRequest(companyId: number, note: string): Promise<any> {
    return sendRequestWithBody(`/worker/company/${companyId}/return`, 'PUT', JSON.stringify(note));
  }

  function deleteCompany(
    companyId: number,
    code: string,
    method: number,
    level: number | null
  ): Promise<any> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestNoBodyNoJSON(`/company/${companyId}?code=${code}&${query}`, 'DELETE');
  }

  function deleteCompanyWorker(
    companyId: number,
    code: string,
    method: number,
    level: number | null
  ): Promise<any> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestNoBodyNoJSON(`/worker/company/${companyId}?code=${code}&${query}`, 'DELETE');
  }

  function blockCompany(companyId: number, note: string): Promise<any> {
    return sendRequestWithBody(`/admin/company/${companyId}/block`, 'PUT', JSON.stringify(note));
  }

  function unblockCompany(companyId: number, note: string): Promise<any> {
    return sendRequestWithBody(`/admin/company/${companyId}/unblock`, 'PUT', JSON.stringify(note));
  }

  function companySwitchCustomBilling(compId: string): Promise<any> {
    return sendRequestNoBody(`/admin/company/${compId}/custom-billing`, 'PUT');
  }

  function assignCompanyMember(companyId: number, email: string, role: number): Promise<any> {
    const invitation = {
      email: email,
      roleId: role,
    };
    return sendRequestWithBody(
      `/company/invitation/${companyId}`,
      'POST',
      JSON.stringify(invitation)
    );
  }

  function assignWorkerCompanyMember(companyId: number, email: string, role: number): Promise<any> {
    const invitation = {
      email: email,
      roleId: role,
    };
    return sendRequestWithBody(
      `/worker/company/invitation/${companyId}`,
      'POST',
      JSON.stringify(invitation)
    );
  }

  function adminAddCompanyMember(
    companyId: number,
    userId: string,
    email: string,
    role: number
  ): Promise<any> {
    const invitation = {
      userId: userId,
      email: email,
      roleId: role,
    };
    return sendRequestWithBody(
      `/admin/company/${companyId}/member`,
      'POST',
      JSON.stringify(invitation)
    );
  }

  function getCompanyInvitees(
    page: number,
    size: number,
    companyId: number,
    hideRefused?: boolean,
    searchString = ''
  ): Promise<PagedCompanyInviteeValue> {
    return sendRequestNoBody(
      `/company/${companyId}/invitations?page=${page}&size=${size}${
        hideRefused ? '&refused=false' : ''
      }&accepted=false${searchString}`,
      'GET'
    );
  }

  async function getUserInvitations(
    userId?: string,
    signal?: AbortSignal
  ): Promise<Response<Array<CompanyInvitationValue>>> {
    try {
      let url = `/company/invitations`;
      if (userId) url += `?userId=${userId}`;
      const response = await sendRequestNoBody(url, 'GET', signal);
      return { data: response, error: null };
    } catch (error) {
      return { data: [], error: signal?.aborted ? null : (error as Error).message };
    }
  }

  async function acceptCompanyInvitation(
    invitationId: number,
    signal?: AbortSignal
  ): Promise<Response<any>> {
    try {
      const response = await sendRequestNoBodyNoJSON(
        `/company/invitation/${invitationId}/accept`,
        'PUT',
        signal
      );
      return { data: response, error: null };
    } catch (error) {
      return { data: [], error: signal?.aborted ? null : (error as Error).message };
    }
  }

  async function refuseCompanyInvitation(
    invitationId: number,
    signal?: AbortSignal
  ): Promise<Response<any>> {
    try {
      const response = await sendRequestNoBodyNoJSON(
        `/company/invitation/${invitationId}/refuse`,
        'PUT',
        signal
      );
      return { data: response, error: null };
    } catch (error) {
      return { data: [], error: signal?.aborted ? null : (error as Error).message };
    }
  }

  function revokeCompanyInvitation(invitationId: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/company/invitation/${invitationId}/delete`, 'DELETE');
  }

  function resignCompanyMember(companyId: number, memberId: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/company/${companyId}/members/${memberId}`, 'DELETE');
  }

  function leaveComapny(companyId: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/company/${companyId}/members/leave`, 'DELETE');
  }

  function changeCompanyMemberRole(
    companyId: number,
    memberId: number,
    role: number
  ): Promise<any> {
    return sendRequestNoBodyNoJSON(`/company/${companyId}/members/${memberId}?role=${role}`, 'PUT');
  }

  function getCompanyMembers(
    page: number,
    size: number,
    companyId: number,
    searchString?: string
  ): Promise<PagedCompanyMemberValue> {
    return sendRequestNoBody(
      `/company/${companyId}/members?page=${page}&size=${size}${searchString}`,
      'GET'
    );
  }

  function getCompanyMemberById(
    companyId: string,
    memberId: string
  ): Promise<{ id: number; name: string; email: string; role: { id: number } }> {
    return sendRequestNoBody(`/company/${companyId}/members/${memberId}`, 'GET');
  }

  function getCompanyFiles(companyId): Promise<any> {
    return sendRequestNoBody(`/company/request/${companyId}/file`, 'GET');
  }

  function saveCompanyFiles(companyId: number, files: File[]): Promise<any> {
    const data = new FormData();
    files.forEach((file) => data.append('files', file));
    return sendRequestWithFormDataBody(`/company/request/${companyId}/file`, 'POST', data);
  }

  function deleteCompanyFile(companyId: number, fileId: number): Promise<any> {
    return sendRequestNoBodyNoJSON(`/company/request/${companyId}/file/${fileId}`, 'DELETE');
  }

  function getCompanyFile(companyId: number, fileId: number): Promise<any> {
    return sendRequestNoBody(`/company/request/${companyId}/file/${fileId}`, 'GET');
  }

  function sendCompanyFiles(companyId: number): Promise<any> {
    return sendRequestNoBody(`/company/request/${companyId}/sendForApproval`, 'POST');
  }

  function getCompanyHistory(
    companyId: number,
    page: number,
    pageSize: number,
    searchString = ''
  ): Promise<PagedCompanyHistoryValue> {
    return sendRequestNoBody(
      `/worker/company/${companyId}/history?page=${page}&size=${pageSize}${searchString}`,
      'GET'
    );
  }

  function getCompanyApproval(
    companyId: number | string,
    approvalId: number | string
  ): Promise<HistoryApprovalValue> {
    return sendRequestNoBody(`/worker/company/${companyId}/approval/history/${approvalId}`, 'GET');
  }

  // QSCD CARDS -----------------------------------------------------------------------------------------------------------------------------------------------------------------

  function getAllCards(
    paging: string,
    filter: string,
    role: 'user' | 'worker'
  ): Promise<PagedQSCDcardValue> {
    return sendRequestNoBody(
      `${role === 'worker' ? '/worker' : ''}/qscd?${paging}${filter}`,
      'GET'
    );
  }

  function getUserCards(
    paging: string,
    userId: string | number,
    role: 'user' | 'worker',
    sort?: string
  ): Promise<PagedQSCDcardValue> {
    return sendRequestNoBody(
      `${role === 'worker' ? '/worker' : ''}/qscd?${paging}&userId=${userId}${
        sort ? sort : '&sort=createdAt,desc'
      }`,
      'GET'
    );
  }

  function getCard(cardId: number | string, role?: string): Promise<QSCDcardValue> {
    const url = role && role !== 'user' ? `/worker/qscd/${cardId}` : `/qscd/${cardId}`;
    return sendRequestNoBody(url, 'GET');
  }

  function issueCard(cardId: number | string): Promise<QSCDcardValue> {
    return sendRequestNoBody(`/worker/qscd/${cardId}/issue`, 'PUT');
  }

  function cancelCard(cardId: number | string): Promise<QSCDcardValue> {
    return sendRequestNoBody(`/worker/qscd/${cardId}/cancel`, 'PUT');
  }

  function revokeCard(role: string, cardId: number | string): Promise<QSCDcardValue> {
    return sendRequestNoBody(`${role === 'user' ? '' : '/admin'}/qscd/${cardId}/revoke`, 'DELETE');
  }

  function revokeCardByType(typeId: number): Promise<QSCDcardValue> {
    return sendRequestNoBodyNoJSON(`/admin/qscd/revoke-type/${typeId}`, 'DELETE');
  }

  // HELPDESK ------------------------------------------------------------------------------------------------------------------------------------
  function getIssues(): Promise<ITicketData> {
    return sendRequestNoBody('/help-desk/issues', 'GET');
  }

  function createIssue(issue: any, file: File): Promise<any> {
    const projectId = process.env.REACT_APP_HELPDESK_PROJECT;
    const trackerId = '1';
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('description', issue.description);
    formData.append('subject', issue.subject);
    formData.append('projectId', projectId);
    formData.append('tracker', trackerId);
    formData.append('priority', '13');
    formData.append('sendMail', issue.sendMail);
    return sendRequestWithFormDataBody('/help-desk/issue', 'POST', formData);
  }

  // HASH ARCHIVE ----------------------------------------------------------------------------------------------------------------------------------------------------------------
  function validateWithArchivation(file: File, useArchive: boolean): Promise<any> {
    let data: FormData = new FormData();
    data.append('fileToValidate', file);
    let path = `/hash-archive/validateWithArchivation/${useArchive ? 'archive' : 'hashArchive'}`;
    return sendRequestWithFormDataBody(path, 'POST', data);
  }

  function verifyHash(hash: string): Promise<any> {
    return sendRequestNoBody(`/hash-archive/verifyHash?hash=${hash}`, 'POST');
  }

  function verifyFile(file: File): Promise<any> {
    let data: FormData = new FormData();
    data.append('fileToVerify', file);
    return sendRequestWithFormDataBody('/hash-archive/verifyFile', 'POST', data);
  }

  function getHashArchiveEntries(
    page: number,
    size: number,
    sort: string,
    sortBy: string
  ): Promise<any> {
    let data = {
      page: page,
      size: size,
      sort: sort + ',' + sortBy,
    };
    return sendRequestNoBody(
      `/hash-archive?page=${data.page}&size=${data.size}&sort=${data.sort}`,
      'GET'
    );
  }

  function deleteHashFromArchive(hash: string): Promise<any> {
    return sendRequestNoBodyNoJSON(`/hash-archive?hash=${hash}`, 'DELETE');
  }

  function getReportFile(ltpFileId: number): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/hash-archive/reportFile?ltpFileId=${ltpFileId.toString()}`,
      'GET'
    );
  }

  // ----------- FOR UNLOGGED USER

  function getPrefilledRegistration(id: string, token: string, sessionId: string): Promise<any> {
    return fetch(
      `${process.env.REACT_APP_NFQES_API_URL}/public/certificate-hub/user/${id}?code=${token}&sessionId=${sessionId}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error ${res.status}: ${res.statusText}`);
        }
        return res.json();
      })
      .catch((err) => {
        throw new Error(`Error while fetching data: ${err.message}`);
      });
  }

  function sendRegistration2Fa(uid: string): Promise<any> {
    const url = `${process.env.REACT_APP_NFQES_API_URL}/public/certificate-hub/user/${uid}/send2fa`;
    const options = {
      method: 'POST',
    };

    return fetch(url, options).then((res) => {
      return res.json();
    });
  }

  // USER ---------------

  function adminDeleteUser(
    userId: string,
    code: string,
    method: number,
    level: number | null
  ): Promise<string> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }

    return sendRequestNoBodyNoJSON(
      `/userInfo/deleteUser/${userId}?code=${code}&${query}`,
      'DELETE'
    );
  }

  function deleteUser(code: string, method: number, level: number | null): Promise<string> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }

    return sendRequestNoBodyNoJSON(`/userInfo/deleteUser?code=${code}&${query}`, 'DELETE');
  }

  // ENDUSER ---------------
  return {
    validateDocument,
    validateGESDocument,
    validationGetOriginalFiles,
    addTimeStamp,
    setTimeStamp,
    allowNegativeTimestampLimit,
    getTimeStampHistory,
    getTimeStampHistoryCurrentMonth,
    getTimeStampHistoryMonth,
    getTimeStamps,
    getTimeStampOrders,
    getUserTimeStamps,
    buyTimestamps,
    timestampApiCreateAccount,
    timestampApiGetAccount,
    timestampApiWorkerGetAccounts,
    timestampApiDeleteAccount,
    timestampApiResetPassword,
    buyOcra,
    getUsersOcraOrders,
    changeOcraOrderState,
    deleteOcraOrder,
    createVisualSignature,
    getAllVisualSignature,
    getDefaultVisualSignature,
    setDefaultSignature,
    deleteVisualSignature,
    getCertificate,
    downloadCertificate,
    downloadSend,
    getCertificatesAndRequests,
    getCertificatesFiltered,
    getCertificatesForSigning,
    getCertRequestsFiltered,
    getCompanyCertificatesFiltered,
    disableCertificateAdmin,
    disableCertificateWorker,
    disableCertificateUser,
    unblockCertificate,
    generateCertificate,
    validateCSR,
    downloadCertificateRequest,
    getTrustServiceAgreement,
    uploadDocument,
    downloadDocument,
    cancelCertRequest,
    returnCertRequest,
    approveCertRequest,
    rejectCertRequest,
    approveAdminCertRequest,
    rejectAdminCertRequest,
    generateCertOnCard,
    getFreeCerts,
    changeVerification,
    requestSubscription,
    updateSubscription,
    deleteSubscription,
    deleteSubscriptionUser,
    getSubscriptionRequests,
    getUserSubscription,
    getMySubscription,
    getSubscriptionLimits,
    getSubscriptionRestrictions,
    changeSubscriptionRestrictions,
    changeEnterpriseRestrictions,
    postEnterpriseRestrictions,
    getUserEnterpriseLimits,
    getUserInfo,
    getSubscription,
    subscriptionSetAutorenewal,
    getTrustedList,
    getTrustedListDetail,
    getContacts,
    createContact,
    editContact,
    deleteContact,
    getPayments,
    getPayment,
    getPaymentPreinvoice,
    getPaymentInvoice,
    getCardPaymentData,
    getTatraPaymentData,
    editIdInRequest,
    editIdInRequestUser,
    generateCertificateProlong,
    getPayBySqureQRCode,
    payAdmin,
    getPrices,
    getAnnouncements,
    createAnnouncement,
    getAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    updateCertRequestSubject,
    createCompany,
    createCompanyAdmin,
    updateCompany,
    changeCompanyLanguage,
    getCountOfExistingCompanies,
    getCompanies,
    getCompaniesAccountable,
    getCompany,
    checkHasContractWork,
    approveCompanyRequest,
    rejectCompanyRequest,
    returnCompanyRequest,
    deleteCompany,
    deleteCompanyWorker,
    blockCompany,
    unblockCompany,
    companySwitchCustomBilling,
    assignCompanyMember,
    assignWorkerCompanyMember,
    adminAddCompanyMember,
    getCompanyInvitees,
    getUserInvitations,
    acceptCompanyInvitation,
    refuseCompanyInvitation,
    revokeCompanyInvitation,
    resignCompanyMember,
    leaveComapny,
    changeCompanyMemberRole,
    getCompanyMembers,
    getCompanyMemberById,
    getCompanyFiles,
    saveCompanyFiles,
    deleteCompanyFile,
    getCompanyFile,
    sendCompanyFiles,
    getCompanyHistory,
    getCompanyApproval,
    hwtokenAuthGenerate,
    hwtokenAuthVerify,
    workerHwtokenAuthGenerate,
    workerHwtokenAuthVerify,
    getAllCards,
    getUserCards,
    getCard,
    issueCard,
    cancelCard,
    revokeCard,
    revokeCardByType,
    getPaymentType,
    getPaymentItem,
    getIssues,
    createIssue,
    validateWithArchivation,
    verifyHash,
    verifyFile,
    getHashArchiveEntries,
    deleteHashFromArchive,
    getReportFile,
    changeGraviteeSignLimit,
    changeGraviteeHSMLimit,
    getPrefilledRegistration,
    sendRegistration2Fa,
    updateCertPerson,
    adminDeleteUser,
    deleteUser,
    changeGraviteeCertProviderLimit,
    changeGraviteeCustomer,
    editRequestDN,
    editRequestPerson,
  };
}
