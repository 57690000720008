import sendRequestFunctions from 'service/common/sendRequestFunctions';

export default function commonServices() {
  const { sendRequestNoBodyNoUser, sendRequestNoBodyNoJSON } = sendRequestFunctions(
    process.env.REACT_APP_NFQES_API_URL
  );

  // -------------------------------------------------------------------------------------------------------------------------

  function getCity(postalCode: string): Promise<any> {
    return sendRequestNoBodyNoUser(`/postalCode?postalCode=${postalCode}`, 'GET');
  }
  function getUsersData(): Promise<any> {
    return sendRequestNoBodyNoJSON(`/export/subUsers`, 'GET');
  }

  return {
    getCity,
    getUsersData,
  };
}
