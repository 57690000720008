import { ReactNode, useContext } from 'react';

import twoFaServiceFunction from 'service/2fa/2FA';
import twoFaOauthServiceFunction from 'service/2fa/2FAoauth';
import twoFaOrchestratorServiceFunction from 'service/2fa/2FAorchestrator';
import commonServices from 'service/basic/commonServices';
import { IFolder } from 'service/dto/SignatureDTO';
import enumServiceFunction from 'service/enum/EnumService';
import signFileServiceFunction from 'service/file/SignFile';
import fileGatewayServiceFunction from 'service/filegateway/FileGateway';
import historyServiceFunction from 'service/history/HistoryService';
import iamServiceFunction from 'service/iam/IamService';
import manualsServiceFunction from 'service/manuals/ManualsService';
import messageServiceFunction from 'service/messages/MessageService';
import nexUServiceFunction from 'service/nexu/NexUService';
import nfqesServiceFunction from 'service/nfqes/NfqesService';
import { notifyServiceFunction, notifyWebServiceFunction } from 'service/notify/NotifyService';
import orchestratorServiceFunction from 'service/orchestrator/OrchestratorService';
import smsServiceFunction from 'service/sms/SMSservice';

import { services } from './context';

const createContextValue = () => {
  const nfqesService = nfqesServiceFunction();
  const fgService = fileGatewayServiceFunction();
  const enumService = enumServiceFunction();
  const iamService = iamServiceFunction();
  const smsService = smsServiceFunction();
  const signFileService = signFileServiceFunction<{
    folders: IFolder[];
    actCert: number;
  }>();
  const digitalSignFileService = signFileServiceFunction<IFolder[]>();
  const validFileService = signFileServiceFunction();
  const nexUService = nexUServiceFunction();
  const notifyService = notifyServiceFunction();
  const notifyWebService = notifyWebServiceFunction();
  const manualsService = manualsServiceFunction();
  const messageService = messageServiceFunction();
  const twoFaService = twoFaServiceFunction();
  const twoFaOauthService = twoFaOauthServiceFunction();
  const twoFaOrchestratorService = twoFaOrchestratorServiceFunction();
  const commonService = commonServices();
  const historyService = historyServiceFunction();
  const orchestratorService = orchestratorServiceFunction();

  return {
    nfqesService,
    fgService,
    enumService,
    iamService,
    smsService,
    signFileService,
    digitalSignFileService,
    validFileService,
    nexUService,
    notifyService,
    notifyWebService,
    manualsService,
    messageService,
    twoFaService,
    twoFaOauthService,
    twoFaOrchestratorService,
    commonService,
    historyService,
    orchestratorService,
  };
};

export { createContextValue };

export type servicesType = ReturnType<typeof createContextValue>;

// -----------------------------------------------------------------------------------------------------------------------------

interface ServiceProviderProps {
  children: ReactNode;
}

export function ServiceProvider({ children }: Readonly<ServiceProviderProps>) {
  const value = createContextValue();

  return <services.Provider value={value}>{children}</services.Provider>;
}

export function useServices() {
  return useContext(services);
}
