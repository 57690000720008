import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SlideButton } from 'components/Buttons';
import { IconButtonAnimate } from 'components/IconButton';

import { Icon } from '@iconify/react';
import { MenuItem } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { authorizationService } from '../../index';
import MenuPopover from './HeaderComponents/MenuPopover';
import MyAvatar from './HeaderComponents/MyAvatar';

interface UnloggedUserMenuProps {
  isMobile?: boolean;
}

export default function UnloggedUserMenu({ isMobile = false }: UnloggedUserMenuProps) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const goLogin = () => authorizationService.login();
  const goRegister = () => navigation(t('URL.REGISTER'));

  return (
    <>
      {isMobile ? (
        <>
          <IconButtonAnimate
            onClick={handleOpen}
            sx={{
              p: 0,
              mt: { md: '4px', sm: '12px', xs: '12px' },
              ml: { md: '5px', sm: '5px', xs: '5px' },
              mr: { md: '5px', sm: '5px', xs: '5px' },
              ...(open && {
                // outline: '2px solid #1d4d6d',
                '&:before': {
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <MyAvatar user={null} />
          </IconButtonAnimate>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            arrow={isMobile ? 'none' : 'top-right'}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: '300px',
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            <MenuItem onClick={goLogin} sx={{ m: 1 }}>
              <Icon
                icon='fa:sign-in'
                width={16}
                style={{ marginLeft: '2px', marginRight: '10px' }}
              />

              {t('DASHBOARD_ROUTES.LOGIN')}
            </MenuItem>

            <MenuItem onClick={goRegister} sx={{ m: 1 }}>
              <Icon
                icon='fa:user-plus'
                width={16}
                style={{ marginLeft: '2px', marginRight: '10.3px' }}
              />

              {t('DASHBOARD_ROUTES.REGISTER')}
            </MenuItem>
          </MenuPopover>
        </>
      ) : (
        <div style={{ display: 'inline-block' }}>
          <SlideButton
            style={{
              fontSize: '14px !important',
              padding: '3px 12px !important',
              marginLeft: '4px !important',
              marginTop: '-13.5px !important',
            }}
            icon='fa:sign-in'
            onClick={goLogin}
            margin='none'
          >
            {t('DASHBOARD_ROUTES.LOGIN')}
          </SlideButton>
          <SlideButton
            margin='none'
            style={{
              fontSize: '14px !important',
              padding: '3px 12px !important',
              marginLeft: '8px !important',
              marginTop: '-13.5px !important',
              marginRight: '10px !important',
            }}
            icon='fa:user-plus'
            onClick={goRegister}
          >
            {t('DASHBOARD_ROUTES.REGISTER')}
          </SlideButton>
        </div>
      )}
    </>
  );
}
