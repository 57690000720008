import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { NavListRoot } from './NavList';

NavSectionVertical.propTypes = {
  userRole: PropTypes.string,
  navConfig: PropTypes.any,
  isCorrect: PropTypes.func,
  sidebarShields: PropTypes.object,
  closeMenu: PropTypes.func,
};

export default function NavSectionVertical({
  userRole,
  navConfig,
  isCorrect,
  sidebarShields,
  closeMenu,
  ...other
}) {
  return (
    <Box {...other}>
      {navConfig?.map((item, idx) => (
        <NavListRoot
          userRole={userRole}
          list={item}
          sidebarShields={sidebarShields}
          isCorrect={isCorrect}
          key={'nav-' + idx}
          closeMenu={closeMenu}
        />
      ))}
    </Box>
  );
}
