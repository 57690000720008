import sendRequestFunctions from 'service/common/sendRequestFunctions';

export default function twoFaOauthServiceFunction() {
  const { sendRequestNoBody } = sendRequestFunctions(process.env.REACT_APP_IAM_API_URL);

  // -------------------------------------------------------------------------------------------------------------------------

  function checkDevice(
    role: 'user' | 'worker',
    userId?: string,
    level?: 'ES' | 'AES' | 'QES'
  ): Promise<any> {
    const transformLevel = { ES: 2, AES: 3, QES: 4 };
    const levelString = level ? `/${transformLevel[level]}` : '';
    return sendRequestNoBody(
      `/api/proxy/v3${
        role === 'worker' ? `/admin/bo/hasdevice/${userId}` : '/bo/hasdevice'
      }${levelString}`,
      'GET'
    );
  }

  return {
    checkDevice,
  };
}
