import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SlideButton } from 'components/Buttons';

import { Box, Grid } from '@mui/material';

import { OTPUserType } from '../OTPDialog';
import { GridItem } from './GridItem';

interface IProps {
  close: (_submit?: boolean) => void;

  setType: any;
  sendSMS: any;
  allowedMethods: (0 | 1 | 2 | 3 | 4 | 5)[];
  userMethods: {
    method: -1 | 0 | 1 | 2 | 3 | 4 | 5;
    level: number;
  }[];
  minimalOperationLevel: number;
  addUser2Fa: (_string) => void;
  user: OTPUserType;
}

export default function ChooseVerifyType(props: IProps) {
  const { t } = useTranslation(['translation', 'otp']);
  const navigate = useNavigate();

  const {
    setType,
    sendSMS,
    userMethods,
    allowedMethods,
    close,
    minimalOperationLevel,
    addUser2Fa,
    user,
  } = props;

  const handleClick = (method) => {
    setType(method);
    sendSMS(method);
  };

  const isUserMethodIncluded = (method) => {
    return userMethods?.some((item) => item.method === method && item.level !== -1);
  };

  const getLevelByMethod = (method: 0 | 1 | 2 | 3 | 4 | 5): number | null => {
    const methodRecord = userMethods.find((item) => item.method === method);
    return methodRecord ? methodRecord.level : null;
  };

  const checkLevel = (method) => {
    return getLevelByMethod(method) >= minimalOperationLevel;
  };

  const handleAddMethodClick = (method, name) => {
    setType(method);
    addUser2Fa(name);
  };

  const goProfile = () => {
    close();
    navigate(t('URL.MY_PROFILE'));
  };

  return (
    <Grid container spacing={1} pt={2}>
      {allowedMethods.includes(0) && (
        <Grid item xs={12}>
          {isUserMethodIncluded(0) ? (
            <GridItem
              icon={0}
              name={t('OTP.TYPES.SMS')}
              subtext={t('OTP.TYPES.SMS_SUBTEXT')}
              onClick={() => handleClick(0)}
            />
          ) : (
            <GridItem
              icon={0}
              name={t('OTP.TYPES.ALLOW_SMS')}
              subtext=''
              onClick={() => null}
              disabled
            />
          )}
        </Grid>
      )}
      {allowedMethods.includes(1) && (
        <Grid item xs={12}>
          {isUserMethodIncluded(1) ? (
            <GridItem
              icon={1}
              name={t('OTP.TYPES.EMAIL')}
              subtext={t('OTP.TYPES.EMAIL_SUBTEXT')}
              onClick={() => handleClick(1)}
            />
          ) : (
            <GridItem
              icon={1}
              name={t('OTP.TYPES.ALLOW_EMAIL')}
              subtext={''}
              onClick={() => handleAddMethodClick(1, 'email')}
              disabled
            />
          )}
        </Grid>
      )}
      {allowedMethods.includes(2) && (
        <Grid item xs={12}>
          {isUserMethodIncluded(2) ? (
            <GridItem
              icon={2}
              name={t('OTP.TYPES.OCRA')}
              subtext={t('OTP.TYPES.OCRA_SUBTEXT')}
              onClick={() => handleClick(2)}
            />
          ) : (
            <GridItem
              icon={2}
              name={getLevelByMethod(2) === -1 ? t('OTP.TYPES.OCRA') : t('OTP.TYPES.ALLOW_OCRA')}
              subtext={getLevelByMethod(2) === -1 ? t('OTP.NO_OCRA') : ''}
              onClick={() => (getLevelByMethod(2) === -1 ? null : handleAddMethodClick(2, 'ocra'))}
              minLevel={getLevelByMethod(2) === -1}
              disabled
            />
          )}
        </Grid>
      )}
      {allowedMethods.includes(5) && (
        <Grid item xs={12}>
          {isUserMethodIncluded(5) ? (
            <GridItem
              icon={5}
              name={t('OTP.TYPES.OCRA_APP')}
              subtext={
                checkLevel(5) === false
                  ? t('OTP.LOW_DEVICE_LEVEL')
                  : t('OTP.TYPES.OCRA_APP_SUBTEXT')
              }
              onClick={() => (checkLevel(5) === false ? null : handleClick(5))}
              disabled={checkLevel(5) === false}
              minLevel={checkLevel(5) === false}
            />
          ) : (
            <GridItem
              icon={5}
              name={
                getLevelByMethod(5) === -1 ? t('OTP.TYPES.OCRA_APP') : t('OTP.TYPES.ALLOW_OCRA_APP')
              }
              subtext={getLevelByMethod(5) === -1 ? t('OTP.NO_DEVICE') : ''}
              onClick={() =>
                getLevelByMethod(5) === -1 ? null : handleAddMethodClick(5, 'ocraApp')
              }
              minLevel={getLevelByMethod(5) === -1}
              disabled
            />
          )}
        </Grid>
      )}
      {userMethods?.length === 0 && (
        <Grid item xs={12}>
          {t('OTP.NO_2FA')}
          {user?.role === 'user' && (
            <Box pt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              <SlideButton
                id={'confirm'}
                primary
                large
                key={'confirm'}
                onClick={() => {
                  goProfile();
                }}
                onMiddleClick={() => t('URL.MY_PROFILE')}
                style={{
                  padding: '12px 26px',
                }}
              >
                {t('OTP.GO_PROFILE')}
              </SlideButton>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
}
