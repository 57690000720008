import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { services } from 'context/context';
import { StateValue } from 'service/iam/UserValue';

import { SelectChangeEvent } from '@mui/material';

export interface InputChangeMethod<T> {
  target: {
    value: T;
  };
}

/*
    PARAMETRE, KTORE BY MAL BYT SCHOPNY SPRACOVAT KAZDY INPUT
    AK HO NEDOKAZE SPRACOVAT SAM, TREBA NA NOM DOROBIT TU FUNKCIU
*/
export interface InputProps<T> {
  name?: string;
  label?: string;
  afterLabel?: ReactNode | string;
  placeholder?: string;
  value: T;
  onChange: (_e: InputChangeMethod<T> | SelectChangeEvent<T>) => void;
  onBlur?: () => void;
  error?: string;
  required?: boolean; // pokusime sa fikano vymysliet
  disabled?: boolean;
  size?: 'small' | 'medium';
}

/*
    TOTO SU PARAMETRE, KTORE VRACIA "useInput"
*/
export interface UseInputProps<T> {
  id?: string;
  name?: string; // id a name zadane uzivatelom - pouziva sa primarne pre errory na automaticke zameranie
  value: T; // zadana hodnota
  setValue: Dispatch<SetStateAction<T>>; // set funkcia pre nastavenie mimo inputu
  onChange: (_e: InputChangeMethod<T> | SelectChangeEvent<T>) => void; // onChange funkcia pre input
  hasError: () => boolean; // funkcia ci je hodnota spravna - vyuzitelne pri kontrole formulara - rozsvieti "nenavstiveny" input
  // idporucam usporiadat "hasError" v opacnom poradi ako sa vykresluju na FE - pretože zameranie je v každom a tým pádom by zameralo len na posledný
  hasErrorValue: boolean; // boolean ci je hodnota spravna
  error: string; // chybova hlaska pre input, pripadne notifikaciu
  onBlur: () => void; // akcia po prvom focuse
  reset?: () => void; // funkcia na reset inputu - nastavi sa ako keby s nim nikdy nebolo manipulovane
}

/*
    POPROSIM KAZDEHO KTO UPRAVI TENTO HOOK, ABY UPRAVIL AJ DOKUMENTACIU!
    V SPODNEJ CASTI SA NACHADZA DOKUMENTACIA NA VYTVORENIE CUSTOM INPUTU

    Použitie:
    T - typ hodnoty (string, Date, number, ...)
    initValue - počiatočná hodnota (rovnaké ako pri useState)
    nameOrId - používa sa na doplnenie ID do input kvôli zameraniu pri kontrole
    check - kontrolovacia funkcia na nastavenie chybovej hlášky - návratová hodnota je textový reťazec, ak je prázdny tak nezobrazuje chybu (podporuje preklad)

    const pwdProps = useInput<string>("", CommonCheck.password);
    const titleProps = useInput<string>("", (val) => val.length < 5 : "TRANSLATE.MIN_5_CHAR" : "");
    const titleProps = useInput<string>("", useCallback((val) => val.length < 5 : "TRANSLATE.MIN_5_CHAR" : "", [])); // pre pripad ze sa zacne kontrola tocit donekonecna


    // ak treba nastavit hodnotu manualne: pwdProps.setValue("noveHeslo");
    // ak treba nastavit hodnotu manualne: titleProps.setValue("novy nadpis");

    <PasswordInput {...pwdProps} />
    <TextInput {...titleProps} />
*/

export default function useInput<T>(
  initValue: T,
  nameOrId?: string,
  check?: (_newValue: T) => string
): UseInputProps<T> {
  const [value, setValue] = useState<T>(initValue);
  const [error, setError] = useState<string>('');
  const [afterBlur, setAfterBlur] = useState<boolean>(false);

  // premenna uchovavajuca ci je input validny
  const hasErrorValue = useMemo(() => {
    if (!check) return false;
    const temp = check?.(value);
    return temp !== undefined && temp?.length > 0;
  }, [value, check]);

  const checkError = useCallback(() => {
    setError(check?.(value) || '');
  }, [value, check]);

  useEffect(() => {
    if (afterBlur) checkError();
  }, [checkError, afterBlur]);

  const onChange = useCallback((e: InputChangeMethod<T> | SelectChangeEvent<T>) => {
    setValue(e.target.value as T);
  }, []);

  // funkcia, ktora vrati ci je input validny + ako bonus rozsvieti dany input (ak to input podporuje)
  const hasError = useCallback(() => {
    setAfterBlur(true);
    if (hasErrorValue && nameOrId) {
      const input = document.getElementById(nameOrId);
      input?.scrollIntoView({ behavior: 'smooth' });
    }
    return hasErrorValue;
  }, [hasErrorValue, nameOrId]);

  const onBlur = useCallback(() => {
    setAfterBlur(true);
  }, []);

  const reset = useCallback(() => {
    setValue(initValue);
    setError('');
    setAfterBlur(false);
  }, [initValue]);

  return useMemo(
    () => ({
      id: nameOrId,
      name: nameOrId,
      value,
      setValue,
      onChange,
      hasError,
      hasErrorValue,
      error,
      onBlur,
      reset,
    }),
    [nameOrId, value, setValue, onChange, hasError, hasErrorValue, error, onBlur, reset]
  );
}

/*
    Vytvorenie custom inputu:

    0) naozaj sa zamysli, ci dany input naozaj treba - mozno staci len upravit check funckiu
    1) vytvor komponent "CustomInput", kde slovo "Custom" rozumne popíše typ daného inputu
    2) komponent musi prijimat minimalne parametre z interface InputProps
    3) ak tvoje input vracia iny format odpovede ako InputChangeMethod interface, vytvor handleChange metodu a prisposob odpoved
    4) ak je "error" dlhsi ako 0 znakov, zobraz ho

    Inspiruj sa vytvorenymi inputmi
*/

export function useDateInput<Date>(
  initValue: Date,
  nameOrId?: string,
  check?: (_newValue: Date) => string,
  required?: boolean
): UseInputProps<Date> & { required: boolean } {
  const checkDateInput = useCallback(
    (val: Date) => {
      if (required && !val) return 'DEFAULT.REQUIRED_FIELD';
      if (val && val.toString() === 'Invalid Date') return 'DEFAULT.DATE_ERROR';
      else return check ? check(val) : undefined;
    },
    [check, required]
  );

  const inputProps = useInput(initValue, nameOrId, checkDateInput);

  const { onChange: inputPropsOnChange } = inputProps;
  const onChange = useCallback(
    (e) => {
      if (!required && (!e.target.value || e.target.value.toString() === 'Invalid Date'))
        inputPropsOnChange({ target: { value: undefined } });
      else inputPropsOnChange(e);
    },
    [inputPropsOnChange, required]
  );
  return { ...inputProps, onChange, required };
}

export function useStateInput(outputForm: 'stateCode' | 'stateNote') {
  const { iamService } = useContext(services);
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [loadedStates, setLoadedStates] = useState<StateValue[]>();
  const [states, setStates] = useState<{ label: string; value: string; idx: number }[]>([]);
  const [initState, setInitState] = useState(outputForm === 'stateCode' ? '703' : 'SK');

  useEffect(() => {
    iamService
      .getStates()
      .then((resp) => setLoadedStates(resp))
      .catch(() => {});
  }, [iamService]);

  const [state, setState] = useState<{
    label: string;
    value: string;
    idx: number;
  }>({
    label: 'Slovensko',
    value: 'SK',
    idx: 198,
  });
  const { value } = state || { value: '' };

  const filterAndSortStates = useCallback(
    (states) => {
      const translated = states.map((st, idx) => ({
        label: language === 'sk' ? st.officialTitle : st.officialTitleEn,
        value: (outputForm === 'stateCode' ? st.code : st.note).toString(),
        idx,
      }));
      const actualStates = translated.filter((item) => item.value !== '');
      actualStates.sort((st1, st2) =>
        st1.label?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
        st2.label?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          ? -1
          : 1
      );
      return actualStates;
    },
    [language, outputForm]
  );

  useEffect(() => {
    if (loadedStates) {
      const sorted = filterAndSortStates(loadedStates);
      setStates(sorted);
      const titleLanguage = language === 'sk' ? 'officialTitle' : 'officialTitleEn';

      if (!initState) {
        const tempInitState = loadedStates.find((item) => item.note === 'SK');
        setState({
          label: tempInitState?.[titleLanguage],
          value: outputForm === 'stateCode' ? '703' : 'SK',
          idx: 198,
        });
      } else {
        const initStateIdx = loadedStates.findIndex((st) => {
          if (outputForm === 'stateCode') {
            return st.code.toString() === initState;
          } else return st.note === initState;
        });
        if (initStateIdx >= 0) {
          let tempStateValue = loadedStates[initStateIdx].note;
          if (outputForm === 'stateCode') {
            tempStateValue = loadedStates[initStateIdx].code.toString();
          }
          setState({
            label: loadedStates[initStateIdx][titleLanguage],
            value: tempStateValue,
            idx: initStateIdx,
          });
        }
      }
    }
  }, [language, loadedStates, initState, outputForm, filterAndSortStates]);

  const handleStateChange = useCallback((e) => {
    setState(e.target.value);
  }, []);

  return useMemo(
    () => ({ onChange: handleStateChange, value, states, setValue: setInitState, state }),
    [handleStateChange, value, states, setInitState, state]
  );
}
