class RedrawService {
  private text = '0';
  private setMethod: Function = undefined;

  public redraw(text: string) {
    if (this.setMethod) {
      this.text = text;
      this.setMethod(this.text);
    }
  }

  public setChangeMethod(method: Function) {
    this.setMethod = method;
    this.text = '0';
  }
}

const redrawService = new RedrawService();

export default redrawService;
