import { MuiOtpInput } from 'mui-one-time-password-input';

import { CustomTheme } from 'theme/PaletteDTO';

import '../../Modal/components/OTPDialog/OTPDialog.css';

import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import { InputProps } from '../hooks/useInput';
import InputLabel from './InputLabel';

export interface OTPInputProps extends InputProps<string> {
  length?: number;
  center?: boolean;
  maxWidth?: number;
}

export default function OTPInput({
  // all inputs
  name,
  label,
  value,
  error,
  onChange,
  required,
  length,
  center,
  maxWidth,
}: Readonly<OTPInputProps>) {
  const theme = useTheme();
  const mode = localStorage.getItem('mode');

  return (
    <Box textAlign={'center'}>
      {label && label.length > 0 && (
        <InputLabel required={required} id={name}>
          {label}
        </InputLabel>
      )}
      <Box textAlign={'center'}>
        <MuiOtpInput
          value={value}
          onChange={(e) => onChange({ target: { value: e } })}
          length={length || 6}
          TextFieldsProps={{
            type: 'number',
            inputProps: {
              inputMode: 'numeric',
              pattern: 'd*',
            },
            size: 'medium',
          }}
          sx={{
            maxWidth: maxWidth ? `${maxWidth}px` : '480px',
            margin: center ? 'auto' : '',
            gap: { xs: 1, sm: 1.7 },
            '& input': {
              height: '34px',
              padding: '14.5px 12px',
              background:
                mode === 'light'
                  ? error
                    ? '#FEF0F0'
                    : theme.palette.grey[50]
                  : error
                    ? '#bc3f3f3d'
                    : theme.palette.grey[800],
              borderRadius: '4px',
              fontSize: { xs: '1rem', sm: '1.3rem' },
              fontWeight: '500',
              color: error ? theme.palette.error.main : '',
              border: error
                ? `1px solid ${theme.palette.error.main} !important`
                : `1px solid ${theme.palette.grey[400]} !important`,
            },
            '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
              border: `1px solid ${theme.palette.primary.main} !important`,
            },
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
        {error && <ErrorBox>{error}</ErrorBox>}
      </Box>
    </Box>
  );
}

const ErrorBox: any = styled(Box)(({ theme }: CustomTheme) => ({
  color: theme.palette.error.main,
}));
