import { authorizationService } from 'index';
import { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { userCtx } from 'context/context';

import { SlideButton } from 'components/Buttons';
import { InfoBox } from 'components/InfoBox';
import { Spinner } from 'components/Spinner';

import { Paper } from '@mui/material';

import css from './Page.module.css';
import cssAnnouncement from 'modules/announcements/_components/Announcement.module.css';

type KeyType =
  | 'elMailbox'
  | 'company'
  | 'visualSign'
  | 'validation'
  | 'signature_ES'
  | 'signature_DS'
  | 'signature_QSCD'
  | 'history'
  | 'timestamps';
// KeyType je kvoli automatickemu fungovaniu zatvorenia v InfoBox-e
// ak pridate KeyType, je potrebne pridat aj CookieName v InfoBox-e

interface IProps {
  children: any;
  wait?: boolean;
  header?: string | JSX.Element;
  promoText?: { text: Partial<Record<0 | 1 | 2 | 3 | 4, string>>; key: KeyType };
}

export default function Page(props: IProps) {
  const { t } = useTranslation();
  const { loading: loadingUser, subscription } = useContext(userCtx);
  const navigate = useNavigate();

  const goToSubscription = () => navigate(t('URL.SUBSCRIBE_INFO'));
  const goToRegistration = () => navigate(t('URL.REGISTER'));

  useEffect(() => {
    if (props.header) {
      let tempTitle = 'NFQES - ' + props.header;
      if (tempTitle.includes('[object')) document.title = 'NFQES';
      else document.title = tempTitle;
    } else {
      document.title = 'NFQES';
    }
  }, [props.header]);

  return !props.wait && (!loadingUser || !authorizationService.isLoggedIn()) ? (
    <Fragment>
      {props.promoText && props.promoText.text[subscription] && (
        <div className='marginLikePage'>
          <InfoBox cookieName={props.promoText?.key} type='info' leftBorder>
            <div className={cssAnnouncement.subscriptionText}>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.promoText.text[subscription],
                }}
              ></div>
              {subscription < 4 && (
                <div>
                  {subscription === 0 ? (
                    <SlideButton
                      primary
                      mainAction
                      small
                      onClick={goToRegistration}
                      margin='right'
                      style={{ marginBottom: '0px !important' }}
                    >
                      {t('PROMO.GO_TO_REGISTER')}
                    </SlideButton>
                  ) : (
                    <SlideButton
                      primary
                      mainAction
                      small
                      onClick={goToSubscription}
                      margin='right'
                      style={{ marginBottom: '0px !important' }}
                    >
                      {t('PROMO.GO_TO_SUBSCRIPTION')}
                    </SlideButton>
                  )}
                </div>
              )}
            </div>
          </InfoBox>
        </div>
      )}

      <Paper className={`${css.whitePage}`} sx={{ boxShadow: 'none' }}>
        {props.header && <div className={css.pageHead}>{props.header}</div>}

        <div className={`${css.content}`}>{props.children}</div>
      </Paper>
    </Fragment>
  ) : (
    <Paper className={css.loading} sx={{ boxShadow: 'none' }}>
      <Spinner />
    </Paper>
  );
}
