import PropTypes from 'prop-types';
// @mui
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

//
import { Icon } from '@iconify/react';
import { Badge, Box } from '@mui/material';

import { ListItemIconStyle, ListItemStyle } from './style';

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  userRole: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    order: PropTypes.number,
    submenu: PropTypes.array,
    icon: PropTypes.any,
    name: PropTypes.any,
    path: PropTypes.string,
  }),
  sidebarShields: PropTypes.object,
  closeMenu: PropTypes.func,
};

export function NavItemRoot({
  userRole,
  item,
  open = false,
  active,
  onOpen,
  sidebarShields,
  closeMenu,
}) {
  const { path, icon, name, submenu } = item;
  const { t } = useTranslation();
  const navigation = useNavigate();
  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      {name && t(name)}
      <Badge
        badgeContent={sidebarShields && sidebarShields[name] ? sidebarShields[name] : undefined}
        max={99}
        color='error'
        sx={{ right: '-10px', top: '-8px' }}
      />
      {submenu && (
        <div style={{ marginLeft: 'auto', marginTop: '4px' }}>
          <ArrowIcon open={open} />
        </div>
      )}
    </>
  );

  if (submenu) {
    return (
      <>
        {item.order === 7 && userRole === 'user' && <hr />}

        <ListItemStyle
          onClick={(e) => {
            e.preventDefault();
            if (name === 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX') {
              navigation(t('URL.ELECTRONIC_MAILBOX_RECEIVED'));
            }

            if (name === 'DASHBOARD_ROUTES.USER_MANAGEMEN') {
              navigation(t('URL.USER_MANAGEMENT'));
            }
            if (name === 'DASHBOARD_ROUTES.QUALIFIED_ARCHIVE') {
              navigation(t('URL.QUALIFIED_ARCHIVE_LIST'));
            }
            onOpen();
          }}
          activeRoot={active}
          sx={{ color: '#fff' }}
        >
          {renderContent}
        </ListItemStyle>
        {item.order === 10 && userRole === 'user' && <hr />}
      </>
    );
  }

  return (
    <>
      {item.order === 7 && userRole === 'user' && <hr />}
      <ListItemStyle
        component={RouterLink}
        to={t(path)}
        activeRoot={active}
        sx={{ color: '#fff' }}
        onClick={() => closeMenu?.()}
      >
        {renderContent}
      </ListItemStyle>
      {item.order === 12 && userRole === 'user' && <hr />}
    </>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  isCorrect: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    name: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  sidebarShields: PropTypes.object,
  closeMenu: PropTypes.func,
};

export function NavItemSub({ item, active = false, isCorrect, isLast, sidebarShields, closeMenu }) {
  const { path, name } = item;
  const { t } = useTranslation();
  if (!isCorrect) {
    return null;
  }
  const renderContent = (
    <>
      <DotIcon active={active} />
      {name && t(name)}
      {sidebarShields && sidebarShields[name] && (
        <span
          style={{
            color: '#FFF',
            backgroundColor: '#BC3F3F',
            fontWeight: '500',
            fontSize: '12px',
            height: 18,
            minWidth: 26,
            lineHeight: 0,
            borderRadius: 5,
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            marginLeft: 'auto',
          }}
        >
          {sidebarShields[name]}
        </span>
      )}
    </>
  );
  return (
    <ListItemStyle
      key={item}
      component={RouterLink}
      to={t(path)}
      activeSub={active}
      subItem
      sx={{ color: '#fff', margin: isLast ? '0 8px 0px 8px' : '0 8px 8px 8px' }}
      onClick={() => closeMenu?.()}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle sx={{ marginRight: '0px !important' }}>
      <Box
        component='span'
        sx={{
          width: 4,
          height: 4,
          marginTop: '3px',
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(1.8)',
            bgcolor: 'primary.contrastText',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Icon
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      style={{ fontSize: '18px' }}
    />
  );
}
