import 'dayjs/locale/en';
import 'dayjs/locale/sk';

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface CustomLocalizationProviderProps {
  children: ReactNode;
}

export default function CustomLocalizationProvider({ children }: CustomLocalizationProviderProps) {
  const { i18n } = useTranslation();

  const language = i18n?.language as 'en' | 'sk';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      {children}
    </LocalizationProvider>
  );
}
