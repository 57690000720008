import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SlideButton } from 'components/Buttons';
import { GeneralCheckbox } from 'components/Checkboxes';
import { MButtons, ModalDialog } from 'components/Modal';

import { Grid } from '@mui/material';

import css from './Modal.module.css';

interface ConfirmDialogProps {
  showModal: boolean;
  title: string;
  close: (_confirm?: boolean) => void;
  message: any;
  button?: MButtons;
  cookieName?: string;
  redirect?: string;
}

export default function ConfirmDialog(props: Readonly<ConfirmDialogProps>) {
  const { t } = useTranslation();
  const fromLocal = localStorage.getItem(props.cookieName) === 'true';

  const showCheckbox = props.cookieName ? !fromLocal : false;
  const [showNoMore, setShowNoMore] = useState(false);

  const { showModal } = props;
  const skipModal = props.cookieName && fromLocal;

  const {
    close,
    button: { onClick },
  } = props;

  useEffect(() => {
    if (showModal) {
      if (!skipModal) setShowNoMore(false);
      else if (onClick) {
        onClick();
        close();
      }
    }
  }, [showModal, skipModal, close, onClick]);

  const buttonProps = props.button
    ? {
        style: { fontSize: '14px' },
        className: !props.button.onClick ? 'pull-left' : '',
        icon: props.button.icon,
        key: props.button.label,
        onClick: () => {
          if (props.button.onClick) {
            props.button.onClick();
          }
          if (showNoMore) {
            localStorage.setItem(props.cookieName, 'true');
            setShowNoMore(false);
          }
          props.close(true);
        },
      }
    : undefined;

  return !skipModal ? (
    <ModalDialog close={() => props.close(false)} showModal={props.showModal} title={props.title}>
      <div>
        <div className={css.texts}>
          <Grid container item style={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <p dangerouslySetInnerHTML={{ __html: t(props.message) }}></p>
            </Grid>
          </Grid>
        </div>
        {showCheckbox && (
          <GeneralCheckbox
            checked={showNoMore}
            onChange={setShowNoMore}
            label={t('MODAL.SHOW_NO_MORE')}
          />
        )}
        {props.button && (
          <div className={css.buttons}>
            <SlideButton {...buttonProps} id={`confirm_dialog_button_${props.button.label}`}>
              {props.button.label}
            </SlideButton>
          </div>
        )}
      </div>
    </ModalDialog>
  ) : null;
}
