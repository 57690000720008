import MuiPhoneNumber from 'mui-phone-number';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTheme } from 'theme/PaletteDTO';

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { InputProps } from '../hooks/useInput';

interface PhoneInputProps extends InputProps<string> {
  defaultCountry: string;
}

export default function PhoneInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  required,
  disabled,
  size,
  // specifics
  defaultCountry,
}: PhoneInputProps) {
  const { t } = useTranslation();
  const phoneRef = useRef<any>(null);

  const handleChange = (value) => {
    onChange?.({ target: { value } });
  };

  useEffect(() => {
    if (value && typeof value === 'string' && value !== '' && value.startsWith('+09')) {
      const newValue = value.replace('+09', '+4219');
      onChange?.({ target: { value: newValue } });
    }
  }, [value, onChange]);

  const handleKeyDown = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (
        phoneRef.current &&
        phoneRef.current.inputRef.selectionStart === 0 &&
        phoneRef.current.inputRef.selectionEnd === value.length
      ) {
        onChange?.({ target: { value: '' } });
      }
    }
  };
  return (
    <Box>
      <Box>
        <MuiPhoneNumberSx
          label={label}
          placeholder={placeholder}
          ref={phoneRef}
          name={name}
          id={name}
          data-testid={name}
          variant='outlined'
          size={size || 'medium'}
          defaultCountry={defaultCountry || ''}
          value={value}
          error={error?.length > 0}
          helperText={error?.length > 0 ? t(error) : ''}
          onChange={handleChange}
          // onClick={setFocusAtIndex}
          fullWidth
          //   onBlur={onBlur}
          required={required}
          disabled={disabled}
          countryCodeEditable={false}
          autoFormat={true}
          onKeyDown={handleKeyDown}
          sx={{ '& svg': { width: '30px' } }}
        />
      </Box>
    </Box>
  );
}

const MuiPhoneNumberSx = styled(MuiPhoneNumber)(({ theme }: CustomTheme) => ({
  '& .MuiInputLabel-root': {
    top: '8px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-input': {
    padding: '18px',
  },
  '& div button': { color: 'text.primary' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder },
    '& svg': { color: 'text.primary' },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder, opacity: 0.6 },
  },
}));
