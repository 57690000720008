export const GRAVITEE_EN = {
  GRAVITEE: {
    USER_NAME: 'Login name',
    NFQES_KEY: 'API key',
    REMAINING_TIMESTAMP: 'Remaining timestamps count',
    NOTIFICATION_REMAINING_TIMESTAMP: 'Notification when (timestamps)',
    REMAINING_SIGNATURES: 'Remaining number of transactions',
    NOTIFICATION_REMAINING_SIGNATURES: 'Notification when (signatures)',
    CERT_LIMIT: 'Remaining certificates count',
    NOTIFICATION_CERT_LIMIT: 'Notification when (certificates)',
    CUSTOMER: 'Client name (Client authentication certificate)',
    BUTTON_SET_NOTIFICATION: 'Set Notification',
    NOTIFICATION_LIMIT_MUST_BE_POSITIVE_NUMBER: 'Notification must be positive number.',
    NOTIFICATION_SET_ERROR: 'Notification was not set.',
    NOTIFICATION_CONFIG: 'Notifications settings',
    ADMIN_CONFIG: 'Limits settings',
    BUTTON_SET_SIGNATURES: 'Set Signatures',
    BUTTON_SET_CUSTOMER: 'Set Client Name',
    BUTTON_SET_CERT_LIMIT: 'Set Certificates',
    BUTTON_RESET: 'Lost password',
    BUTTON_BUY_TIMESTAMP: 'Buy Timestamps',
    BUTTON_DELETE: 'Remove account',
    BUTTON_ADD_TIMESTAMPS: 'Add Timestamps',
    BUTTON_SET_TIMESTAMPS: 'Set Timestamps',
    ADDING_TIMESTAMPS: 'Add Timestamps count',
    LIMIT_SUCCESSFULLY_CHANGED: 'Limit was set successfully.',
    NOTIFICATION_SUCCESSFULLY_CHANGED: 'Notification was set successfully.',
    LIMIT_NOT_CHANGED: 'Limit was not set.',
    CUSTOMER_SUCCESSFULLY_CHANGED: 'Customer changed successfully.',
    CUSTOMER_NOT_CHANGED: 'Customer was not changed.',
  },
};
