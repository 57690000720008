import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Box, styled } from '@mui/material';

import css from './Notify.module.css';

const NotifyBox: any = styled(Box)(({ theme }: any) => ({
  color: `${theme.palette.text.primary} !important`,
  background: `${theme.palette.background.paper} !important`,
}));

const TimerBox: any = styled(Box)(({ theme, type }: any) => ({
  background: `${theme.palette[type].contrastText} !important`,
}));

const IconBox: any = styled(Box)(({ theme, type }: any) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '6px',
    padding: '12px 6px ',
    borderRadius: '8px',
    color: theme.palette[type].contrastText,
    background: `${theme.palette[type].default} !important`,
    '& svg': {
      background: theme.palette[type].default,
      borderRadius: '50%',
      width: 'auto',
      height: 'auto',
      margin: 'auto',
    },
  };
});

interface NotifyType {
  unique?: any;
  type?: 'info' | 'error' | 'warning' | 'success';
  time: number;
  text: ReactNode | any;
  link?: string;
  linkTarget?: '_blank';
  onClick?: () => void;
}

interface NotifyParams {
  data: NotifyType;
  onRemove: (_notify: NotifyType) => void;
}

export type { NotifyType };

export default function Notify({ data, onRemove }: NotifyParams) {
  const { t } = useTranslation();

  const [startTime] = useState<number>(new Date().getTime());
  const notifyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const notifyEle = notifyRef.current;
    const elapsedTime = new Date().getTime() - startTime;
    const remainingTime = data.time - elapsedTime;

    const timer = setTimeout(() => {
      onRemove(data);
    }, remainingTime);

    const handleEnter = () => {
      clearTimeout(timer);
    };

    const handleLeave = () => {
      const newElapsedTime = new Date().getTime() - startTime;
      const newRemainingTime = data.time - newElapsedTime;
      setTimeout(() => {
        onRemove(data);
      }, newRemainingTime);
    };

    notifyEle?.addEventListener('mouseenter', handleEnter);
    notifyEle?.addEventListener('mouseleave', handleLeave);

    return () => {
      clearTimeout(timer);
      notifyEle?.removeEventListener('mouseenter', handleEnter);
      notifyEle?.removeEventListener('mouseleave', handleLeave);
    };
  }, [data, onRemove, startTime]);

  const handleClick = () => {
    data?.onClick?.();
    onRemove(data);
  };

  const notifyContent = (
    <NotifyBox ref={notifyRef} className={`${css.notify}`} onClick={handleClick}>
      <IconBox type={data.type || 'info'} className={css.icon}>
        {data.type === 'info' && <Icon icon='ic:round-info' />}
        {data.type === 'success' && <Icon icon='ep:success-filled' />}
        {data.type === 'warning' && <Icon icon='mingcute:alert-fill' />}
        {data.type === 'error' && <Icon icon='bxs:error-alt' />}
      </IconBox>
      <Box className={css.textBox}>
        <Box className={css.text}>
          {typeof data.text === 'string' ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: t(data.text),
              }}
            />
          ) : (
            data.text
          )}
        </Box>
      </Box>
      <TimerBox
        type={data.type || 'info'}
        className={css.timer}
        style={{ animationDuration: `${data.time}ms` }}
      />
    </NotifyBox>
  );

  // nejde pouzit Link z routera, pretoze Notify je mimo routera
  if (data.link)
    return (
      <a href={data.link} target={data.linkTarget}>
        {notifyContent}
      </a>
    );

  return notifyContent;
}
