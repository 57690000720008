import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { styled, Typography } from '@mui/material';

interface InputLabelProps {
  text?: string;
  children?: ReactElement | any;
  id?: string;
  required?: boolean;
  afterLabel?: ReactNode | string;
}

const RequiredStar = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function InputLabel({ children, text, id, required, afterLabel }: InputLabelProps) {
  const { t } = useTranslation();

  return (
    <Typography variant='h6' fontWeight='500'>
      <label htmlFor={id}>
        {text ? t(text) : children} {required && <RequiredStar>*</RequiredStar>}
      </label>{' '}
      {afterLabel}
    </Typography>
  );
}
