import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  signatureEN as SIGNATURE_EN,
  signatureSK as SIGNATURE_SK,
} from '@nfqes/enterprise-web-signature';

import { CERTIFICATE_EN } from './data/certificate_en';
import { CERTIFICATE_SK } from './data/certificate_sk';
import { EZZK_EN } from './data/ezzk_en';
import { EZZK_SK } from './data/ezzk_sk';
import { FILE_GATEWAY_EN } from './data/file_gateway_en';
import { FILE_GATEWAY_SK } from './data/file_gateway_sk';
import { GRAVITEE_EN } from './data/gravitee_en';
import { GRAVITEE_SK } from './data/gravitee_sk';
import { OTP_EN } from './data/otp_en.js';
import { OTP_SK } from './data/otp_sk.js';
import { URL_EN } from './data/url_en';
import { URL_SK } from './data/url_sk';
import defaultEN from './en.json';
import defaultSK from './sk.json';

const ALL_TRANSLATIONS_SK = { ...defaultSK, ...URL_SK, ...EZZK_SK, ...OTP_SK, ...GRAVITEE_SK };
const ALL_TRANSLATIONS_EN = { ...defaultEN, ...URL_EN, ...EZZK_EN, ...OTP_EN, ...GRAVITEE_EN };

export const resources = {
  sk: {
    translation: ALL_TRANSLATIONS_SK,
    certificate: CERTIFICATE_SK,
    fileGateway: FILE_GATEWAY_SK,
    sign: SIGNATURE_SK,
  },
  en: {
    translation: ALL_TRANSLATIONS_EN,
    certificate: CERTIFICATE_EN,
    fileGateway: FILE_GATEWAY_EN,
    sign: SIGNATURE_EN,
  },
};

const userLang = navigator.language;
const defaultLanguage = userLang.includes('sk') ? 'sk' : 'en';

export type lang = 'sk' | 'en';
export const languages: lang[] = ['sk', 'en'];

i18n.use(initReactI18next).init({
  resources: resources,
  defaultNS: 'translation',
  lng: defaultLanguage,
  fallbackLng: 'sk',
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: false },
});

export { i18n as default };
