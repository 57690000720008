import { authorizationService } from 'index';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { services, userCtx } from 'context/context';

import { AnnouncementBox } from 'components/AnnouncementBox';
import { InfoBox } from 'components/InfoBox';

import { Box } from '@mui/material';

export default function AnnouncementShow() {
  const { t } = useTranslation(['translation', 'certificate']);
  const { nfqesService } = useContext(services);

  const { data: user } = useContext(userCtx);
  const isLoggedIn = authorizationService.isLoggedIn();

  const [data, setData] = useState(null);

  const load = useCallback(() => {
    nfqesService
      .getAnnouncements()
      .then((resp) => {
        const dataToShow = resp.content.map(
          (item: { id: any; title: any; description: any; published: any; link: any }) => ({
            id: item.id,
            title: item.title,
            description: item.description,
            link: item.link,
            published: item.published ? t('VALIDATE.YES') : t('VALIDATE.NO'),
          })
        );
        setData(dataToShow);
      })
      .catch(() => {});
  }, [nfqesService, t]);

  useEffect(() => {
    if (isLoggedIn) {
      load();
    }
  }, [load, isLoggedIn]);

  const verifyPhoneText = (phoneNumber?: any) => {
    return (
      <>
        {t('PROFILE.VERIFY_PHONE_TEXT')}{' '}
        <NavLink
          to={t('URL.MY_PROFILE')}
          dangerouslySetInnerHTML={{
            __html: t('PROFILE.VERIFY_PHONE_TEXT_LINK'),
          }}
        />
        {phoneNumber && (
          <Box>
            {t('PROFILE.VERIFY_PHONE_TITLE')}: <b>{phoneNumber}</b>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((item) => (
          <AnnouncementBox
            key={item.title}
            title={item.title}
            text={item.description}
            link={item.link ? item.link : ''}
          />
        ))}

      {user && !user.phoneNumberConfirmed && (
        <div className='marginLikePage'>
          <InfoBox
            type='error'
            align='left'
            margin={0}
            title={t('PROFILE.VERIFY_PHONE_TITLE')}
            textElement={verifyPhoneText(user.unconfirmedNumber)}
            leftBorder
          />
        </div>
      )}
    </>
  );
}
