import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageUserProfile = lazy(() =>
  lazyRetry(() => import('pages/my-account/profile/PageUserProfile'), 'PageUserProfile')
);
const PageHWToken = lazy(() =>
  lazyRetry(() => import('pages/my-account/hw-token/PageHWToken'), 'PageHWToken')
);
const PagePayments = lazy(() =>
  lazyRetry(() => import('pages/my-account/payments/PagePayments'), 'payments')
);
const PagePaymentDetail = lazy(() =>
  lazyRetry(() => import('pages/my-account/payments/[id]/PagePaymentDetail'), 'PagePaymentDetail')
);
const PageDeviceManagement = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-account/device-management/PageDeviceManagement'),
    'PageDeviceManagement'
  )
);
const PageRegisterDevice = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-account/device-management/register/PageRegisterDevice'),
    'PageRegisterDevice'
  )
);
const PagePaymentSuccess = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/payment-success/[id]/PagePaymentSuccess'),
    'PagePaymentSuccess'
  )
);
const PagePaymentFailed = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/payment-failed/[id]/PagePaymentFailed'),
    'PagePaymentFailed'
  )
);

const routesMyAccount: Array<DBRoute> = [
  {
    path: 'URL.MY_ACCOUNT',
    name: 'DASHBOARD_ROUTES.MY_ACCOUNT',
    icon: <Icon icon='fluent:person-24-filled' width={30} height={30} />,
    component: <PagePayments />,
    show: true,
    user: true,
    order: 7,
    submenu: [
      {
        path: 'URL.MY_PROFILE',
        name: 'DASHBOARD_ROUTES.MY_PROFILE',
        icon: 'fa:user',
        component: <PageUserProfile />,
        show: true,
        user: true,
      },
      {
        path: 'URL.PAYMENTS',
        name: 'DASHBOARD_ROUTES.PAYMENTS',
        icon: 'fa:shopping-cart',
        component: <PagePayments />,
        show: true,
        user: true,
        unlogged: false,
      },
      {
        path: 'URL.PAYMENT_DETAIL',
        component: <PagePaymentDetail />,
        show: false,
        user: true,
        unlogged: false,
      },
      {
        path: 'URL.DEVICE_MANAGEMENT',
        name: 'DASHBOARD_ROUTES.DEVICE_MANAGEMENT',
        icon: 'fa:laptop',
        component: <PageDeviceManagement />,
        show: true,
        user: true,
        unlogged: false,
      },
      {
        path: 'URL.REGISTER_DEVICE_WITHOUT_ID',
        component: <PageRegisterDevice />,
        show: false,
        user: true,
        unlogged: false,
      },
      {
        path: 'URL.HW_TOKEN_REQUEST',
        component: <PageHWToken />,
        show: false,
        user: true,
        unlogged: true,
      },
    ],
  },
  {
    path: 'URL.PAYMENT_SUCCESS',
    component: <PagePaymentSuccess />,
    show: false,
    user: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.PAYMENT_FAIL',
    component: <PagePaymentFailed />,
    show: false,
    user: true,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesMyAccount;
