import { lazy } from 'react';

import PageElectronicMailboxPublicMessageDownload from 'pages/(public)/electronic-mailbox/message/[id]/user/[id]/download/PageElectronicMailboxPublicMessageDownload';
import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageElectronicMailbox = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/PageElectronicMailbox'),
    'PageElectronicMailbox'
  )
);
const PageElectronicMailboxNewMessage = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/new-message/PageElectronicMailboxNewMessage'),
    'PageElectronicMailboxNewMessage'
  )
);
const PageElectronicMailboxNewMessageType = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/(public)/electronic-mailbox/new-message/[type]/PageElectronicMailboxNewMessageType'
      ),
    'PageElectronicMailboxNewMessageType'
  )
);
const PageElectronicMailboxMessageDetail = lazy(() =>
  lazyRetry(
    () =>
      import('pages/(public)/electronic-mailbox/message/[id]/PageElectronicMailboxMessageDetail'),
    'PageElectronicMailboxMessageDetail'
  )
);
const PageElectronicMailboxMessageDetailPublic = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/(public)/electronic-mailbox/message/[id]/user/[id]/PageElectronicMailboxMessageDetailPublic'
      ),
    'PageElectronicMailboxMessageDetailPublic'
  )
);
const PageElectronicMailboxInbox = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/inbox/PageElectronicMailboxInbox'),
    'PageElectronicMailboxInbox'
  )
);
const PageElectronicMailboxSentMessages = lazy(() =>
  lazyRetry(
    () =>
      import('pages/(public)/electronic-mailbox/sent-messages/PageElectronicMailboxSentMessages'),
    'PageElectronicMailboxSentMessages'
  )
);
const PageElectronicMailboxConcepts = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/concepts/PageElectronicMailboxConcepts'),
    'PageElectronicMailboxConcepts'
  )
);
const PageElectronicMailboxTrash = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/trash/PageElectronicMailboxTrash'),
    'PageElectronicMailboxTrash'
  )
);
const PageElectronicMailboxBoards = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/boards/PageElectronicMailboxBoards'),
    'PageElectronicMailboxBoards'
  )
);
const PageElectronicMailboxContacts = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/electronic-mailbox/contacts/PageElectronicMailboxContacts'),
    'PageElectronicMailboxContacts'
  )
);

const routesElMailbox: Array<DBRoute> = [
  {
    path: 'URL.ELECTRONIC_MAILBOX',
    name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX',
    icon: <Icon icon='ic:baseline-local-post-office' width={30} height={30} />,
    component: <PageElectronicMailbox />,
    show: true,
    user: true,
    admin: true,
    manager: true,
    worker: true,
    onlyFinishedRegister: false,
    order: 2,
    submenu: [
      {
        path: 'URL.ELECTRONIC_MAILBOX_NEW',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_NEW',
        icon: 'fa:plus',
        component: <PageElectronicMailboxNewMessage />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_PUBLIC_NEW',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_PUBLIC_NEW',
        icon: 'fa:plus',
        component: <PageElectronicMailboxNewMessage isPublic />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 5,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_BOARDS',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_BOARDS',
        icon: 'fa:clipboard',
        component: <PageElectronicMailboxBoards />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_RECEIVED',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_RECEIVED',
        icon: 'fa:envelope',
        component: <PageElectronicMailboxInbox />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_SENT',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_SENT',
        icon: 'icon-park:send-email',
        component: <PageElectronicMailboxSentMessages />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_CONCEPT',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_CONCEPT',
        icon: 'fa:envelope-open',
        component: <PageElectronicMailboxConcepts />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_REMOVED',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_REMOVED',
        icon: 'fa:trash',
        component: <PageElectronicMailboxTrash />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_CONTACTS',
        name: 'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_CONTACTS',
        icon: 'ri:contacts-book-3-line',
        component: <PageElectronicMailboxContacts />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_NEW_TYPE',
        component: <PageElectronicMailboxNewMessageType />,
        show: false,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: false,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_PUBLIC_NEW_TYPE',
        component: <PageElectronicMailboxNewMessageType isPublic />,
        show: false,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 5,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_DETAIL',
        component: <PageElectronicMailboxMessageDetail />,
        show: false,
        unlogged: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_PUBLIC_DETAIL',
        component: <PageElectronicMailboxMessageDetailPublic />,
        show: false,
        unlogged: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
      },
      {
        path: 'URL.ELECTRONIC_MAILBOX_PUBLIC_DOWNLOAD',
        component: <PageElectronicMailboxPublicMessageDownload />,
        show: false,
        unlogged: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
      },
    ],
  },
];

export default routesElMailbox;
