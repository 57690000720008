import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import Page from './Page';

import css from './Page.module.css';

const Ic = styled('i')(({ theme, type }: any) => ({
  color: theme.palette?.[type || 'info'].main,
}));

interface IProps {
  title: string;
  icon?: string;
  image?: any;
  component?: any;
  color?: 'info' | 'error' | 'success' | 'warning';
  children: any;
}

export default function SimplePage(props: IProps) {
  return (
    <Page>
      <Box className={css.simplePage}>
        {
          props.icon && (
            <Ic className={`fa ${props.icon} ${css.icon}`} type={props.color}></Ic>
          ) /*  ${css[props.color ? props.color : 'blue']} */
        }
        {props.image && <img src={props.image} alt={props.title} style={{ height: '80px' }} />}
        {Boolean(props.component) && props.component}
        <Typography mt={3} mb={2} variant='h2'>
          <b>{props.title}</b>
        </Typography>
        {props.children}
      </Box>
    </Page>
  );
}
