import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatDate } from 'service/defaultServices/TimeFormatterService';

import { SlideButton } from 'components/Buttons';

import { Box } from '@mui/material';

import { OTPUserType } from '../OTPDialog';

interface IProps {
  close: (_submit?: boolean) => void;
  user: OTPUserType;
}

export default function LimitError(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { close, user } = props;

  const goToSubscription = () => {
    close();
    navigate(t('URL.SUBSCRIBE_INFO'));
  };

  return (
    <>
      <Box pt={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {`${t('sign:SEND_SMS_MAX_MSG_MODAL.TEXT').replace(
            '{date}',
            formatDate(user?.nextSubscriptionReset, true)
          )}${
            user.subscription < 4
              ? t(`sign:SEND_SMS_MAX_MSG_MODAL.TEXT_LEVEL_${user?.subscription}`)
              : ''
          }`}
        </Box>
      </Box>
      {user.subscription < 4 && (
        <Box pt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <SlideButton
            id={'confirm'}
            primary
            large
            key={'confirm'}
            onClick={() => {
              goToSubscription();
            }}
            onMiddleClick={() => t('URL.SUBSCRIBE_INFO')}
            style={{
              padding: '12px 26px',
            }}
          >
            {t('SUBSCRIBE.GET_SUBSCRIPTION')}
          </SlideButton>
        </Box>
      )}
    </>
  );
}
