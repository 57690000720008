export const URL_EN = {
  URL: {
    HOME: '/home',
    ADMIN_DASHBOARD: '/admin-dashboard',
    VERIFIED: '/account-verified',
    PASS_RESET_SUCCESS: '/password-reset-success',
    SIGN_DOCUMENT_REDIRECT: '/sign-document/redirect',
    SIGN_DOCUMENT: '/sign-document',
    SIGN_DOCUMENT_WITH_TYPE: '/sign-document/:type',
    VALIDATE_DOCUMENT: '/online-validation-of-qualified-electronic-signature-KEP',
    ELECTRONIC_MAILBOX_DETAIL: '/electronic-mailbox/message/:id',
    ELECTRONIC_MAILBOX_PUBLIC_DETAIL:
      '/electronic-mailbox/message/:messageUuid/user/:recipientUuid',
    ELECTRONIC_MAILBOX_PUBLIC_DOWNLOAD:
      '/electronic-mailbox/message/download/:messageUuid/user/:recipientUuid',
    ELECTRONIC_MAILBOX_NEW: '/electronic-mailbox/new-message',
    ELECTRONIC_MAILBOX_NEW_TYPE: '/electronic-mailbox/new-message/:type',
    ELECTRONIC_MAILBOX_PUBLIC_NEW: '/electronic-mailbox/new-public-message',
    ELECTRONIC_MAILBOX_PUBLIC_NEW_TYPE: '/electronic-mailbox/new-public-message/:type',
    ELECTRONIC_MAILBOX_RECEIVED: '/electronic-mailbox/inbox',
    ELECTRONIC_MAILBOX_SENT: '/electronic-mailbox/sent-messages',
    ELECTRONIC_MAILBOX_CONCEPT: '/electronic-mailbox/concepts',
    ELECTRONIC_MAILBOX_REMOVED: '/electronic-mailbox/trash',
    ELECTRONIC_MAILBOX: '/electronic-mailbox',
    ELECTRONIC_MAILBOX_BOARDS: '/electronic-mailbox/boards',
    ELECTRONIC_MAILBOX_CONTACTS: '/electromic-storage/contacts',
    CERTIFICATES_MENU: '/eidas',
    MY_CERTIFICATES_ONLINE: '/eidas/my-certificates/online-certificates',
    MY_CERTIFICATES_DOWNLOADABLE: '/eidas/my-certificates/downloadable-certificates',
    MY_CERTIFICATES_CARD: '/eidas/my-certificates/QSCD-cards',
    MY_CERTIFICATES_CARD_DETAIL: '/eidas/my-certificates/QSCD-cards/:id',
    MY_CERTIFICATES_TLS: '/eidas/my-certificates/TLS-certificates',
    MY_CERTIFICATES_REQUESTS: '/eidas/my-certificates/certificate-requests',
    GENERATE_REQUEST_WITHOUT_ID: '/eidas/create-certificate',
    GENERATE_REQUEST_COMPANY_USER: '/eidas/create-certificate/company/:idComp',
    GENERATE_REQUEST_WITHOUT_ID_CARD: '/eidas/create-certificate/card/:cardId',
    GENERATE_REQUEST_WITHOUT_ID_CARD_NEW: '/eidas/create-certificate/card',
    MY_CERTIFICATE_DETAIL: '/eidas/my-certificates/:id',
    MY_CERTIFICATE_REQUEST_DETAIL: '/eidas/my-certificates/certificate-requests/:id',
    CERTIFICATES_ONLINE: '/eidas/certificates-list/online-certificates',
    CERTIFICATES_DOWNLOADABLE: '/eidas/certificates-list/downloadable-certificates',
    CERTIFICATES_CARD: '/eidas/certificates-list/QSCD-cards',
    CERTIFICATES_TLS: '/eidas/certificates-list/TLS-certificates',
    CERTIFICATES_CARD_DETAIL: '/eidas/certificates-list/QSCD-cards/:id',
    CERTIFICATE_REQUESTS: '/eidas/certificate-list/certificate-requests',
    GENERATE_REQUEST: '/eidas/create-certificate/:id',
    GENERATE_REQUEST_COMPANY_ADMIN: '/eidas/create-certificate/:id/company/:idComp',
    GENERATE_REQUEST_COMPANY_FOR_USER:
      '/eidas/create-certificate/company/:idComp/member/:member/department/:ou',
    GENERATE_REQUEST_COMPANY_FOR_ADMIN:
      '/eidas/create-certificate/user/:id/company/:idComp/member/:member/department/:ou',
    GENERATE_REQUEST_CARD: '/eidas/create-certificate/:id/card/:cardId',
    GENERATE_REQUEST_CARD_NEW: '/eidas/create-certificate/:id/card',
    CERTIFICATE_DETAIL: '/eidas/certificate-list/:id',
    CERTIFICATE_REQUEST_DETAIL: '/eidas/certificate-list/certificate-request/:id',
    USER_MANAGEMEN: '/user-management',
    USER_MANAGEMENT: '/user-management/all',
    CERTIFICATE_HUB: '/certificate-hub',
    CERTIFICATE_HUB_LIST: '/certificate-hub/list',
    CERTIFICATE_HUB_DETAIL: '/certificate-hub/detail/:id',
    CERTIFICATE_HUB_CERTIFICATE: '/certificate-hub/certificate',
    CERTIFICATE_HUB_COMPANIES: '/certificate-hub/companies',
    CERTIFICATE_HUB_QSCD_CARD: '/certificate-hub/qscd-cards',
    CERTIFICATE_HUB_CREATE_USER: '/certificate-hub/create-user/:id',
    CERTIFICATE_HUB_CREATE_COMPANY: '/certificate-hub/create-company/:id',
    CERTIFICATE_HUB_CREATE_CERTIFICATE: '/certificate-hub/create-certificate/:id',
    CERTIFICATE_HUB_REVOKE_CERT: '/certificate-hub/revoke-certificates',
    USER_MANAGEMENT_NEW: '/user-management/new-user',
    MANUALS_DETAIL: '/manuals/:section/:manual',
    MANUALS_SECTION: '/manuals/:section',
    MANUALS: '/manuals',
    REGISTER: '/registration',
    REGISTER_UUID: '/registration/:id',
    REGISTER_WORKER: '/worker-register',
    REGISTER_SUCCESS: '/registration/success',
    REGISTER_SUCCESS_ID: '/registration/success/:id',
    CONTACT: '/contact',
    TRUSTED_LIST: '/trusted-list',
    TRUSTED_LIST_DETAIL: '/trusted-list/:id',
    COOKIES: '/cookie-settings',
    MY_PROFILE: '/my-account/profile',
    PROFILE: '/user-profile/:id',
    MY_ACCOUNT: '/my-account',
    COMPANIES_LIST: '/my-companies',
    COMPANIES_LIST_ADMIN: '/admin/companies',
    COMPANIES_CREATE_WITHOUT_ID: '/my-companies/company-create',
    COMPANIES_CREATE: '/my-companies/company-create/:id',
    COMPANIES_DETAIL: '/my-companies/company-detail/:id',
    COMPANIES_DETAIL_ADMIN: '/my-companies/admin/company-detail/:id',
    COMPANIES_APPROVAL_DETAIL_ADMIN: '/my-companies/admin/company-detail/:companyId/approval/:id',
    COMPANIES_UPDATE: '/my-companies/company-update/:id',
    COMPANIES_UPDATE_ADMIN: '/my-companies/admin/company-update/:id',
    HW_TOKEN_REQUEST: '/my-account/hw-token',
    PAYMENTS: '/my-account/payments',
    PAYMENTS_ADMIN: '/payments',
    PAYMENT_DETAIL: '/my-account/payments/:id',
    PAYMENT_DETAIL_ADMIN: '/payments/:id',
    PAYMENT_SUCCESS: '/payment-success/:id',
    PAYMENT_FAIL: '/payment-failed/:id',
    DEVICE_MANAGEMENT: '/my-account/device-management',
    DEVICE_MANAGEMENT_ADMIN: '/device-management',
    REGISTER_DEVICE: '/device-management/register/:id',
    REGISTER_DEVICE_WITHOUT_ID: '/my-account/device-management/register',
    SUBSCRIBE_INFO: '/subscription',
    SUBSCRIBE_INFO_ADMIN: '/admin/subscription',
    SUBSCRIBE: '/subscription/create/:id',
    SUBSCRIBE_UPDATE: '/subscription/update/:id/:subscriptionId',
    SUBSCRIBE_BUY: '/subscription/buy/:level',
    SIGNED_OUT: '/automatic-logout',
    NOT_FOUND: '/page-not-found',
    OCRA_MANAGEMENT: '/ocra-token-management',
    DOWNLOAD_WEB_SIGNER: '/download-nfqes-desktop-toolkit',
    NOTIFICATIONS_SETTINGS: '/settings/notifications',
    TIMESTAMP_INFO: '/timestamps',
    TIMESTAMP_BUY_ID: '/timestamps/buy/:id',
    TIMESTAMP_BUY: '/timestamps/buy',
    TIMESTAMP_API_ADMIN: '/timestamp-api-accounts',
    HISTORY: '/signing-history',
    TB_TEST: '/my-account/test-payment',
    PAYMENT_DETAIL_TEST: '/my-account/test-payment/:id',
    ANNOUNCEMENT_MANAGEMENT: '/announcements/management',
    API: '/api-accounts',
    ANNOUNCEMENT_DETAIL: '/announcements/management/:id',
    ANNOUNCEMENT_CREATE_WITHOUT_ID: '/announcements/management/new',
    ANNOUNCEMENT_SEND_EMAIL: '/announcements/management/bulk-email',
    GRAPHICAL_SIGN: '/graphic-signature',
    GRAPHICAL_SIGN_CREATE_NEW: '/graphic-signature/new/:id',
    SUPPORT: '/support',
    NO_SUBSCRIPTION: '/no-access',
    QUALIFIED_ARCHIVE: '/document-archive',
    QUALIFIED_ARCHIVE_LIST: '/document-archive/archive',
    QUALIFIED_ARCHIVE_NEW: '/document-archive/document-archiving',
    QUALIFIED_ARCHIVE_VALIDATE_NEW: '/document-archive/document-validation',
    QUALIFIED_ARCHIVE_VALIDATE_HASH: '/document-archive/document-validation/:id',
    EL_STORAGE_IDSK: '/electronic-mailbox-sk',
    EZZK_NEW_RECORD: '/ezzk/new-record',
    EZZK_NEW_RECORD_TYPE: '/ezzk/new-record/:type',
    PHONE_VERIFICATION: '/phone-verification',
  },
  DASHBOARD_ROUTES: {
    HOME_PAGE: 'Home Page',
    ADMIN_DASHBOARD: 'Admin dashboard',
    VERIFIED: 'Account verified',
    PASS_RESET_SUCCESS: 'Password reset successfully',
    SIGN_DOCUMENT: 'Sign Document',
    VALIDATE_DOCUMENT: 'Validate Document',
    GRAPHICAL_SIGN: 'Graphic Signature',
    ELECTRONIC_MAILBOX: 'Electronic mailbox',
    ELECTRONIC_MAILBOX_RECEIVED: 'Inbox',
    ELECTRONIC_MAILBOX_SENT: 'Sent messages',
    ELECTRONIC_MAILBOX_CONCEPT: 'Saved concepts',
    ELECTRONIC_MAILBOX_REMOVED: 'Trash',
    ELECTRONIC_MAILBOX_BOARDS: 'Boards',
    ELECTRONIC_MAILBOX_CONTACTS: 'Contacts',
    CLOUD_STORAGE: 'Cloud Storage',
    CERTIFICATES_ONLINE: 'Online Certificates',
    CERTIFICATES_DOWNLOADABLE: 'Downloadable Certificates',
    CERTIFICATES_CARD: 'QSCD Cards',
    CERTIFICATES_TLS: 'TLS Certificates',
    CERTIFICATES: 'Certificates',
    MY_CERTIFICATES: 'My Certificates',
    CERTIFICATE_REQUESTS: 'Certificate Requests',
    GENERATE_REQUEST: 'Create Certificate',
    USER_MANAGEMEN: 'User Management',
    USER_MANAGEMENT: 'User Management',
    CERTIFICATE_HUB: 'Certificate hub',
    CERTIFICATE_HUB_LIST: 'Users',
    CERTIFICATE_HUB_CERTIFICATE: 'Certificates',
    CERTIFICATE_HUB_COMPANIES: 'Companies',
    CERTIFICATE_HUB_REVOKE_CERT: 'Revoke certificates',
    CERTIFICATE_HUB_QSCD_CARD: 'Qscd cards',
    CERTIFICATE_HUB_CREATE_USER: 'Create User',
    CERTIFICATE_HUB_CREATE_COMPANY: 'Create Company',
    CERTIFICATE_HUB_CREATE_CERTIFICATE: 'Create Certificate',
    USER_MANAGEMENT_NEW: 'Register user',
    MANUALS: 'Manuals',
    MY_ACCOUNT: 'My account',
    CERTIFICATE_DETAIL: 'Certificate Detail',
    CERTIFICATE_REQUEST_DETAIL: 'Certificate Request Detail',
    CERTIFICATE_CHANGE_STATE: 'Certificate State Change',
    REGISTER: 'Register',
    LOGIN: 'Log In',
    LOGOUT: 'Log Out',
    MY_PROFILE: 'My Profile',
    GDPR: 'GDPR',
    GENERAL_CONDITIONS: 'Terms of Use',
    ELECTRONIC_SIGN_CARD: 'Electronic ID',
    POLITICS: 'Policies and Terms',
    PRICING: 'Pricing',
    CONTACT: 'Contact',
    TRUSTED_LIST: 'Trusted List',
    TRUSTED_LIST_DETAIL: 'Trusted List - Detail',
    COOKIES: 'Cookies',
    CHANGE_LANGUAGE: 'Change Language',
    NFQES: 'NFQES',
    COMPANIES_LIST: 'Companies',
    MY_COMPANIES_LIST: 'My companies',
    COMPANIES_CREATE: 'Create company',
    COMPANIES_DETAIL: 'Company detail',
    COMPANIES_UPDATE: 'Company update',
    ELECTRONIC_MAILBOX_NEW: 'New message',
    ELECTRONIC_MAILBOX_PUBLIC_NEW: 'New public message',
    COMPANIES_APPROVAL_DETAIL: 'Company approval detail',
    ELECTRONIC_MAILBOX_DETAIL: 'Message detail',
    REGISTER_SUCCESS: 'Successfull registration',
    SUBSCRIBE: 'Subscribe',
    SUBSCRIBE_INFO: 'Subscription',
    SUBSCRIBE_MANAGEMENT: 'Subscription requests',
    SIGNED_OUT: 'Sign out',
    OCRA_MANAGEMENT: 'NFQES HW token management',
    USER: 'User',
    WORKER: 'Worker',
    MANAGER: 'Manager',
    ADMIN: 'Administrator',
    BRAINIT: 'Brain:IT',
    LOGREG: 'Log in/Sign up',
    DOWNLOAD_WEB_SIGNER: 'Download NFQES Desktop toolkit',
    PAYMENTS: 'Payments',
    DEVICE_MANAGEMENT: 'Device Management',
    DEVICE_MANAGEMENT_2: 'My Devices',
    REGISTER_DEVICE: 'Register new device',
    TIMESTAMP_INFO: 'Timestamps',
    TIMESTAMP_API_ADMIN: 'Timestamp API accounts',
    API: 'My API accounts',
    HISTORY: 'Signing history',
    ANNOUNCEMENT_MANAGEMENT: 'Announcement Management',
    ANNOUNCEMENT_DETAIL: 'Announcement Detail',
    ANNOUNCEMENT_CREATE: 'New Announcement',
    ANNOUNCEMENT_SEND_EMAIL: 'Bulk email',
    HW_TOKEN_REQUEST: 'Request HW token',
    GRAPHICAL_SIGN_TYPE: 'Type of graphic signature',
    GRAPHICAL_SIGN_CREATE_NEW: 'Creating a graphic signature',
    QUALIFIED_ARCHIVE: 'Qualified archive',
    QUALIFIED_ARCHIVE_LIST: 'Document Archive',
    QUALIFIED_ARCHIVE_NEW: 'Document Archiving',
    QUALIFIED_ARCHIVE_HASH: 'Hash Archiving',
    QUALIFIED_ARCHIVE_DOCUMENT: 'Document Archiving',
    QUALIFIED_ARCHIVE_VALIDATE_NEW: 'Document Verification',
    QUALIFIED_ARCHIVE_VALIDATE_HASH: 'Hash Verification',
    QUALIFIED_ARCHIVE_VALIDATE_DOCUMENT: 'Document Verification',
    EZZK: 'Guaranteed conversion',
  },
};
