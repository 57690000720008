import { Box, Typography, useTheme } from '@mui/material';

interface LimitItemProps {
  title: string;
  value: string;
  border: boolean;
  sx: any;
}

export default function LimitItem({ title, value, border, sx }: Readonly<LimitItemProps>) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: border ? `1px solid ${theme.palette.divider}` : '',
        ...sx,
      }}
      pl={1}
      pr={1}
      pb={border ? 0.8 : 0}
    >
      <Typography fontSize={12} sx={{ maxWidth: '80%' }}>
        {title}
      </Typography>

      <Typography fontSize={14} fontWeight={'bold'} mr={1}>
        {value}
      </Typography>
    </Box>
  );
}
