import i18next from 'i18next';

export default function URLTransform(key: string) {
  return URL[i18next.language]?.[key] || URL['sk']?.[key] || '';
}

const SK_URL = 'https://nfqes.com/sk'; // process.env.REACT_APP_NFQES_REDIRECT_URL
const EN_URL = 'https://nfqes.com';

const URL = {
  sk: {
    home_es: `${SK_URL}/kvalifikovany-elektronicky-podpis`,
    home_qes: `${SK_URL}/kvalifikovana-elektronicka-pecat`,
    home_qets: `${SK_URL}/kvalifikovana-elektronicka-casova-peciatka`,
    home_qc: `${SK_URL}/kvalifikovane-webove-sidlo-tls`,
    home_storage: `${SK_URL}/platforma/historia-podpisovania`,

    nexu_download: `${SK_URL}/nfqes-web-signer`,
    nexu_downloadWindows: `https://nfqes.b-cdn.net/wp-content/uploads/2022/09/NFQES_Web_Signer_2.00.exe`, // vynimka
    nexu_downloadApple: `https://nfqes.b-cdn.net/wp-content/uploads/2024/02/NFQES-Web-Signer-Install_mac_2.00.zip`, // vynimka
    nexu_downloadLinux: 'https://nfqes.b-cdn.net/wp-content/uploads/2024/03/NFQES_Web_signer.tar',
    eid_download: `${SK_URL}/eid-klient`,
    notification_policy: `${SK_URL}/dokumenty`,

    no_token: `${SK_URL}/kontakt`,

    documents: `${SK_URL}/dokumenty`,

    price_list: `${SK_URL}/cennik`,
  },
  en: {
    home_es: `${EN_URL}/qualified-electronic-signature`,
    home_qes: `${EN_URL}/qualified-electronic-seal`,
    home_qets: `${EN_URL}/qualified-electronic-timestamp`,
    home_qc: `${EN_URL}/tls-qualified-web-site`,
    home_storage: `${EN_URL}/electronic-signatures-and-seals-storage`,

    nexu_download: `${EN_URL}/nfqes-web-signer`,
    nexu_downloadWindows: `https://nfqes.b-cdn.net/wp-content/uploads/2022/09/NFQES_Web_Signer_2.00.exe`, // vynimka
    nexu_downloadApple: `https://nfqes.b-cdn.net/wp-content/uploads/2024/02/NFQES-Web-Signer-Install_mac_2.00.zip`, // vynimka
    nexu_downloadLinux: 'https://nfqes.b-cdn.net/wp-content/uploads/2024/03/NFQES_Web_signer.tar',
    eid_download: `${SK_URL}/eid-client`,
    notification_policy: `${EN_URL}/documents`,

    no_token: `${EN_URL}/contact`,

    documents: `${EN_URL}/documents`,

    price_list: `${SK_URL}/price-list`,
  },
};
