export const OTP_EN = {
  OTP: {
    TYPES: {
      CHOOSE: 'Choose verification method',
      BACK_LINK: 'Back to verification method selection',
      SMS: 'SMS verification',
      ALLOW_SMS: 'Activate SMS verification method',
      SMS_SUBTEXT:
        'The verification code will be sent to you in an SMS message to your phone number',
      EMAIL: 'Email verification',
      EMAIL_SUBTEXT: 'Verification code will be sent to you via email',
      ALLOW_EMAIL: 'Activate email verification method',
      OCRA: 'OCRA token verification',
      OCRA_SUBTEXT: 'The verification code will be generated using the OCRA token Authenticator',
      ALLOW_OCRA: 'Activate OCRA token verification method',
      VIRTUAL_TOKEN: 'NFQES authenticator mobile app verification',
      VIRTUAL_TOKEN_SUBTEXT:
        'The verification code will be generated in the NFQES Authenticator mobile application',
      ALLOW_VIRTUAL_TOKEN: 'Activate virtual token verification method',
      OCRA_APP: 'NFQES authenticator mobile app verification',
      OCRA_APP_SUBTEXT:
        'The verification code will be generated in the NFQES Authenticator mobile application',
      ALLOW_OCRA_APP: 'Activate verification method using our authenticator mobile app',
    },
    ACTIONS: {
      SMS: 'We have sent you a verification code via SMS. Please confirm the verification by entering the verification code.',
      EMAIL:
        'We have sent you a verification code via email. Please confirm the action by entering the verification code.',
      OCRA: 'We have generated a challenge for you. Enter the challenge into the OCRA token. Then confirm the verification by entering the verification code generated by the OCRA token.',
      VIRTUAL_TOKEN:
        'We have generated a challenge for you. Enter the challenge into the mobile application (or scan the QR code). Then confirm the verification by entering the verification code generated by the mobile application.',
      OCRA_APP: 'Confirm the verification by entering the PUK code in the mobile application.',
      OCRA_APP_ALERT: 'For this action, you need to activate the device to the KEP (QES) level.',
    },
    CONFIRM: 'Confirm',
    RESEND_CODE_PROMPT: "Didn't receive the code?",
    RESEND_CODE: 'Resend',
    CONFIRM_RESEND: {
      TITLE: 'Resend Verification Code?',
      TEXT: 'Do you want to resend the verification code? The previous code will be invalidated!',
      BUTTON: 'Resend Code',
    },
    CHALLENGE: 'Challenge: ',
    SUCCESS: 'Verification was successful.',
    NO_2FA: 'None of your two-factor authentication options are currently activated.',
    GO_PROFILE: 'Go to profile',
    NEED_CALIBRATION: 'HW Token may be poorly calibrated. ',
    CALIBRATE: 'Calibrate HW token',
    NEW_METHOD_TEXT:
      "We have sent you a verification code via SMS. To activate <b>'{type}'</b>, enter the verification code from SMS.",
    SUCCESS_NEW_METHOD: 'Verification method activated',
    THREE_ATTEMPTS:
      'Verification has been unsuccessful! You have entered the wrong code too many times.',
    GET_CHALLENGE_ON_DEMAND: 'generate challenge',
    CHALLENGE_ON_DEMAND: "Didn't receive a notification?",
    LOW_DEVICE_LEVEL: 'You must raise your device level to perform this action.',
    NO_OCRA: 'For this action, you need to assign ocra token to your account!',
    NO_DEVICE: 'For this action, you need to assign ocra device to your account!',
  },
};
