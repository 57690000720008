// SubscriptionGuard.tsx
import { FC, ReactNode } from 'react';

import PageNoAccess from 'pages/(common)/no-access/PageNoAccess';

import { useUser } from 'context/UserProvider';

interface SubscriptionGuardProps {
  minLvlSubscription: number;
  children: ReactNode;
}

const SubscriptionGuard: FC<SubscriptionGuardProps> = ({ minLvlSubscription, children }) => {
  const user = useUser();
  const role = user?.role;
  const subscription = user?.subscription;

  if (role === 'user' && subscription < minLvlSubscription) {
    return <PageNoAccess />;
  }

  return <>{children}</>;
};

export default SubscriptionGuard;
