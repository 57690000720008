import { authorizationService } from 'index';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IconButtonAnimate } from 'components/IconButton';
import { useWalktour, walktourPages } from 'components/Walktour';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

import ChooseLanguage from './ChooseLanguage';
import HeaderNotificatios from './HeaderNotifications/HeaderNotifications';
import HeaderTimeout from './HeaderTimeout';
import OpenMenuButton from './OpenMenuButton';
import UnloggedUserMenu from './UnloggedUserMenu';
import UserMenu from './UserMenu';

import css from './HeaderNavbar.module.css';

const SubscibeBox: any = styled(Box)(({ theme }: any) => ({
  background: `linear-gradient(45deg, ${theme.palette.sidebar.premiumStip2} 80px, rgba(0,0,0,0) 80px),
                linear-gradient(45deg, ${theme.palette.sidebar.premiumStip1} 90px, ${theme.palette.sidebar.primary} 90px)`,
}));

interface MobileNavbarProps {
  subscription?: number;
}

export default function MobileNavbar({ subscription = 0 }: MobileNavbarProps) {
  const { t } = useTranslation();
  const { setRun: setRunWalktour } = useWalktour();
  const { pathname } = useLocation();
  const isLoggedIn = authorizationService.isLoggedIn();

  return (
    <Box className={css.navbarMobile} sx={{ backgroundColor: 'sidebar.primary' }}>
      <div className={css.wd60} style={{ verticalAlign: 'top' }}>
        <div style={{ height: '64px' }}>
          <ChooseLanguage isMobile />

          <Box component={'span'}>
            {walktourPages.find((item) => t(item) === pathname) ? (
              <IconButtonAnimate
                color={'primary'}
                onClick={() => setRunWalktour(true)}
                sx={{ width: 40, height: 40 }}
                className='wizard_home_walktour_button'
              >
                <Icon icon='mdi:lightbulb-help-outline' width={20} color={'white'} />
              </IconButtonAnimate>
            ) : (
              <Box display={'none'} className='wizard_home_walktour_button' />
            )}
          </Box>

          {!isLoggedIn && <UnloggedUserMenu isMobile />}
          {isLoggedIn && (
            <Fragment>
              <HeaderNotificatios className={`${css.colorWhite} ${css.mgt12}`} mobile />
              <UserMenu isMobile />
            </Fragment>
          )}
        </div>

        {subscription > 1 && (
          <SubscibeBox className={`${css.mobilePremium} ${css.mobileNavbarBottomText}`}>
            <span className={css.mobilePremiumText}>{t(`SUBSCRIBE.PLAN_${subscription}`)}</span>
          </SubscibeBox>
        )}
      </div>
      <div className={css.wd40}>
        <OpenMenuButton />
        {isLoggedIn && (
          <div className={css.mobileNavbarBottomText} style={{ textAlign: 'right' }}>
            {authorizationService.isLoggedIn() && <HeaderTimeout className={css.timerMobile} />}
          </div>
        )}
      </div>
    </Box>
  );
}
