import { ReactNode } from 'react';

import { Box, MenuItem } from '@mui/material';

import { InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

interface TextInputProps extends InputProps<Array<any>> {
  options: Array<{ label: ReactNode; value: any }>;
}

export default function MultipleSelectInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  // specifics
  options,
}: TextInputProps) {
  return (
    <Box>
      <TextFieldSx
        size={size || 'medium'}
        id={name}
        data-testid={name}
        label={label}
        placeholder={placeholder || label}
        variant='outlined'
        select
        error={error?.length > 0}
        fullWidth
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        SelectProps={{
          multiple: true,
          value: value,
          onChange: onChange as any,
        }}
      >
        {(placeholder || label) && (
          <MenuItem value='' disabled>
            {placeholder || label}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextFieldSx>
    </Box>
  );
}
