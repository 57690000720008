import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { userCtx } from 'context/context';

import { IconButtonAnimate } from 'components/IconButton';
import { SelectInput } from 'components/Input';

import { Icon } from '@iconify/react';
import { Box, Chip, Divider, FormControl, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { authorizationService } from '../../index';
import ChangeTheme from './ChangeTheme';
import MenuPopover from './HeaderComponents/MenuPopover';
import MyAvatar from './HeaderComponents/MyAvatar';

interface UserMenuProps {
  isMobile?: boolean;
}

export default function UserMenu({ isMobile = false }: Readonly<UserMenuProps>) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const user = useContext(userCtx);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const name = user.data ? user.data.person.firstName + ' ' + user.data.person.lastName : '';

  const logout = () => {
    localStorage.setItem('logOut', 'true');
    authorizationService.logout().then(() => {});
  };

  const goProfile = () => {
    navigation(t('URL.MY_PROFILE'));
    handleClose();
  };
  const goPayment = () => {
    navigation(t('URL.PAYMENTS'));
    handleClose();
  };
  const goDevices = () => {
    navigation(t('URL.DEVICE_MANAGEMENT'));
    handleClose();
  };

  const goCert = () => {
    navigation(t('URL.CERTIFICATES_MENU'));
    handleClose();
  };
  const goComp = () => {
    navigation(t('URL.COMPANIES_LIST'));
    handleClose();
  };
  const goApi = () => {
    navigation(t('URL.API'));
    handleClose();
  };

  const goProfileAdmin = () => {
    navigation(t('URL.USER_MANAGEMENT'));
    handleClose();
  };
  const goPaymentAdmin = () => {
    navigation(t('URL.PAYMENTS_ADMIN'));
    handleClose();
  };
  const goDevicesAdmin = () => {
    navigation(t('URL.DEVICE_MANAGEMENT_ADMIN'));
    handleClose();
  };
  const roles = ['admin', 'manager', 'worker', 'user'];
  const { realRole } = user;
  const availableRoles =
    realRole !== 'unlogged' ? roles.slice(roles.indexOf(realRole), roles.length) : [];

  const handleChangeRole = (role) => {
    user.changeRole(role);
    if (role === 'user') {
      navigation(t('URL.HOME'));
    } else {
      navigation(t('URL.ADMIN_DASHBOARD'));
    }
  };

  const services = ['file-gateway', 'signer', 'hash-archive', 'archive', 'timestampApi'];
  const isActiveServices = user.userApis.some((api) => services.includes(api.apiName));

  return (
    <>
      {['admin', 'manager', 'worker'].includes(user?.role) && !isMobile && (
        <Chip
          sx={{
            marginTop: '-12.31px',
            mr: '6px',
            bgcolor: (theme) => alpha(theme.palette.grey[600], 0.3),
            boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 3px 2px, rgba(0, 0, 0, 0.02) 0px 1px 2px 2px',
          }}
          label={t(`DASHBOARD_ROUTES.${user?.role.toUpperCase()}`)}
        />
      )}
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          mt: { md: '4px', sm: '12px', xs: '12px' },
          ml: { md: '5px', sm: '0px', xs: '0px' },
          mr: { md: '10px', sm: '10px' },
          ...(open && {
            // outline: '2px solid #1d4d6d',
            '&:before': {
              zIndex: 1,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar user={user} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow={isMobile ? 'none' : 'top-right'}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: '300px',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ ml: 3, mt: 2 }}>
          <Typography variant='subtitle2' noWrap>
            {name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.data?.person.email}
          </Typography>
        </Box>

        {['admin', 'manager', 'worker'].includes(realRole) ? (
          <>
            <Stack sx={{ pl: 3, pr: 3, pt: 1, pb: 1 }}>
              <FormControl fullWidth>
                <SelectInput
                  value={user.role}
                  onChange={(e) => handleChangeRole(e.target.value)}
                  options={[
                    ...availableRoles.map((role) => ({
                      label: t(`DASHBOARD_ROUTES.${role.toUpperCase()}`),
                      value: role,
                    })),
                  ]}
                />
              </FormControl>
            </Stack>
            <Divider />
          </>
        ) : (
          <Divider />
        )}

        {user.role === 'user' ? (
          <>
            <MenuItem onClick={goProfile} sx={{ m: 1 }}>
              <Icon icon='fluent:person-24-filled' width={20} style={{ marginRight: '10px' }} />

              {t('DASHBOARD_ROUTES.MY_PROFILE')}
            </MenuItem>
            <MenuItem onClick={goPayment} sx={{ m: 1 }}>
              <Icon
                icon='ic:twotone-payments'
                width={20}
                style={{ marginLeft: '1px', marginRight: '9px' }}
              />

              {t('DASHBOARD_ROUTES.PAYMENTS')}
            </MenuItem>
            <MenuItem onClick={goDevices} sx={{ m: 1 }}>
              <Icon icon='tabler:device-laptop' width={20} style={{ marginRight: '9px' }} />

              {t('DASHBOARD_ROUTES.DEVICE_MANAGEMENT_2')}
            </MenuItem>

            <MenuItem onClick={goCert} sx={{ m: 1 }}>
              <Icon icon='ph:certificate-duotone' width={20} style={{ marginRight: '10px' }} />

              {t('DASHBOARD_ROUTES.MY_CERTIFICATES')}
            </MenuItem>
            <MenuItem onClick={goComp} sx={{ m: 1 }}>
              <Icon icon='mdi:company' width={20} style={{ marginRight: '10px' }} />

              {t('DASHBOARD_ROUTES.MY_COMPANIES_LIST')}
            </MenuItem>

            {user.role === 'user' && user.subscription === 5 && isActiveServices && (
              <MenuItem onClick={goApi} sx={{ m: 1 }}>
                <Icon icon='material-symbols:api' width={20} style={{ marginRight: '10px' }} />

                {t('DASHBOARD_ROUTES.API')}
              </MenuItem>
            )}
            <Divider />
          </>
        ) : (
          <>
            <MenuItem onClick={goProfileAdmin} sx={{ m: 1 }}>
              <Icon icon='f7:person-2-fill' width={20} style={{ marginRight: '10px' }} />

              {t('DASHBOARD_ROUTES.USER_MANAGEMENT')}
            </MenuItem>
            <MenuItem onClick={goPaymentAdmin} sx={{ m: 1 }}>
              <Icon
                icon='ic:twotone-payments'
                width={20}
                style={{ marginLeft: '1px', marginRight: '9px' }}
              />

              {t('DASHBOARD_ROUTES.PAYMENTS')}
            </MenuItem>
            <MenuItem onClick={goDevicesAdmin} sx={{ m: 1 }}>
              <Icon icon='tabler:device-laptop' width={20} style={{ marginRight: '10px' }} />

              {t('DASHBOARD_ROUTES.DEVICE_MANAGEMENT')}
            </MenuItem>
          </>
        )}
        <ChangeTheme />

        <Divider />
        <MenuItem onClick={logout} sx={{ m: 1 }}>
          <Icon icon='mdi:sign-out' width={20} style={{ marginRight: '10px' }} />
          {t('DASHBOARD_ROUTES.LOGOUT')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
