export default function EnumService() {
  function getCertificateEvidenceStatuses() {
    return [
      'ENUM.RECIEVED',
      'ENUM.TO_BE_PAID',
      'ENUM.UNPAID_CANCELED',
      'ENUM.CANCELED',
      'ENUM.APPROVAL',
      'ENUM.REJECTED',
      'ENUM.APPROVED',
      'ENUM.FINALIZATION',
      'ENUM.PROCESSING',
    ];
  }

  function getCertificateStatuses() {
    return ['ENUM.VALID', 'ENUM.REVOKED'];
  }

  function getCertificateActiveStatuses() {
    return { true: 'ENUM.ACTIVE', false: 'ENUM.BLOCKED' };
  }

  function getUserStatuses() {
    return ['ENUM.REGISTERED', 'ENUM.VERIFIED', 'ENUM.BLOCKED', 'ENUM.DELETED'];
  }

  function getStatusTypes() {
    return ['ENUM.NON_EID', 'ENUM.EID', 'ENUM.ADMIN'];
  }

  function getCompanyRole() {
    return [
      { label: 'ENUM.OWNER', id: 1 },
      { label: 'ENUM.MEMBER_READ_WRITE', id: 2 },
      { label: 'ENUM.MEMBER_READ', id: 3 },
    ];
  }

  return {
    getCertificateEvidenceStatuses,
    getCertificateStatuses,
    getCertificateActiveStatuses,
    getUserStatuses,
    getStatusTypes,
    getCompanyRole,
  };
}
