import { ReactNode, useMemo, useState } from 'react';

import { walktourCtx } from 'context/context';

interface WalktourProviderProps {
  children: ReactNode;
}

export default function WalktourProvider({ children }: Readonly<WalktourProviderProps>) {
  const [run, setRun] = useState(false);

  const value = useMemo(() => ({ run, setRun }), [run, setRun]);

  return <walktourCtx.Provider value={value}>{children}</walktourCtx.Provider>;
}

export const walktourPages =
  process.env.REACT_APP_HIDE_WALKTOUR === 'true'
    ? []
    : ['URL.HOME', 'URL.VALIDATE_DOCUMENT', 'URL.ELECTRONIC_MAILBOX_BOARDS'];
