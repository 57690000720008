export const OTP_SK = {
  OTP: {
    TYPES: {
      CHOOSE: 'Vyberte spôsob overenia',
      BACK_LINK: 'Späť na výber možnosti overenia',
      SMS: 'Overenie pomocou SMS',
      ALLOW_SMS: 'Aktivujte si spôsob overenia cez SMS.',
      SMS_SUBTEXT: 'Verifikačný kód vám bude zaslaný v SMS správe na vaše telefónne číslo',
      EMAIL: 'Overenie cez e-mail',
      EMAIL_SUBTEXT: 'Verifikačný kód vám pošleme e-mailom',
      ALLOW_EMAIL: 'Aktivujte si spôsob overenia cez e-mail',
      OCRA: 'Overenie pomocou OCRA tokenu',
      OCRA_SUBTEXT: 'Verifikačný kód bude vygenerovaný pomocou OCRA tokenu',
      ALLOW_OCRA: 'Aktivujte si spôsob overenia pomocou OCRA tokenu',
      VIRTUAL_TOKEN: 'Overenie pomocou NFQES Autenticator mobilnej aplikácie',
      VIRTUAL_TOKEN_SUBTEXT:
        'Verifikačný kód bude vygenerovaný v NFQES Authenticator mobilnej aplikácii',
      ALLOW_VIRTUAL_TOKEN: 'Aktivujte si spôsob overenia pomocou virtuálneho tokenu',
      OCRA_APP: 'Overenie pomocou NFQES Autenticator mobilnej aplikácie',
      OCRA_APP_SUBTEXT:
        'Verifikačný kód bude vygenerovaný v NFQES Authenticator mobilnej aplikácii',
      ALLOW_OCRA_APP: 'Aktivujte si spôsob overenia pomocou našej mobilnej authenticator aplikácie',
    },
    ACTIONS: {
      SMS: 'Poslali sme vám overovací kód prostredníctvom SMS. Prosím, potvrďte overenie zadaním overovacieho kódu.',
      EMAIL:
        'Poslali sme vám overovací kód prostredníctvom e-mailu. Prosím, potvrďte akciu zadaním overovacieho kódu.',
      OCRA: 'Vygenerovali sme pre vás výzvu. Výzvu zadajte do OCRA tokenu. Následne potvrďte overenie zadaním overovacieho kódu, ktorý vám vygeneroval OCRA token.',
      VIRTUAL_TOKEN:
        'Vygenerovali sme pre vás výzvu. Výzvu zadajte do mobilnej aplikácie (alebo nasnímajte QR kód). Následne potvrďte overenie zadaním overovacieho kódu, ktorý vám vygenerovala mobilná aplikácia.',
      OCRA_APP: 'Potvrďte overenie zadaním PUK kódu v mobilnej aplikácii.',
      OCRA_APP_ALERT: 'Pre túto akciu potrebujete aktivovať zariadenie na úroveň KEP(QES).',
    },
    CONFIRM: 'Potvrdiť',
    RESEND_CODE_PROMPT: 'Neprišiel vám kód?',
    RESEND_CODE: 'Odoslať znova',
    CONFIRM_RESEND: {
      TITLE: 'Odoslať overovací kód znova?',
      TEXT: 'Chcete znova odoslať overovací kód? Predchádzajúci kód bude zneplatnený! ',
      BUTTON: 'Odoslať kód',
    },
    CHALLENGE: 'Výzva: ',
    SUCCESS: 'Overenie bolo úspešné.',
    NO_2FA: 'Nemáte aktívnu žiadnu možnosť dvojfaktorového overenia.',
    GO_PROFILE: 'Ísť na profil',
    NEED_CALIBRATION: 'HW Token môže byť rozkalibrovaný. ',
    CALIBRATE: 'Kalibrovať HW token',
    NEW_METHOD_TEXT:
      "Poslali sme vám overovací kód prostredníctvom SMS. Pre aktivovanie <b>'{type}'</b>, zadajte overovací kód z SMS.",
    SUCCESS_NEW_METHOD: 'Metóda overenia aktivovaná',
    THREE_ATTEMPTS: 'Overenie nebolo úspešné! Zadali ste nesprávny kód príliš mnoho krát.',
    GET_CHALLENGE_ON_DEMAND: 'vygenerujte výzvu',
    CHALLENGE_ON_DEMAND: 'Neprišla vám notifikácia?',
    LOW_DEVICE_LEVEL: 'Potrebujete povýšiť úroveň zariadenia pre vykonanie tejto akcie.',
    NO_OCRA: 'Pre túto akciu potrebujete mať priradený ocra token na vašom účte',
    NO_DEVICE: 'Pre túto akciu potrebujete mať priradené zariadenie na vašom účte',
  },
};
