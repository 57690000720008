import { createContext, Dispatch, SetStateAction } from 'react';

import { servicesType } from './ServiceProvider';
import { showMenuType } from './ShowMenuProvider';
import { UserType } from './UserProvider';

export const services = createContext<servicesType>(undefined);

export const userCtx = createContext<UserType>(undefined);

export const loadingCtx = createContext<{
  loading: boolean;
  count: number;
  total: number;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCount: Dispatch<SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
}>(undefined);

export const showMenu = createContext<showMenuType>(undefined);

export const walktourCtx = createContext<{
  run: boolean;
  setRun: Dispatch<SetStateAction<boolean>>;
}>(undefined);
