import sendRequestFunctions from 'service/common/sendRequestFunctions';
import defaultService from 'service/nfqes/DefaultService';

import { lang } from 'translations/config';

import { handleErrors } from '../defaultServices/ErrorHandler';

export function notifyServiceFunction() {
  const {
    sendRequestNoBody,
    sendRequestNoBodyNoJSON,
    sendRequestWithBody,
    sendRequestWithBodyNoJSON,
  } = sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

  // ------------------------------------------------------------------------------------------------------------------

  function newNotify(
    key: string,
    language: lang,
    params: any,
    userId?: string,
    role?: 'user' | 'worker'
  ): Promise<any> {
    return sendRequestWithBodyNoJSON(
      `${role === 'worker' ? '/worker' : ''}/notifications/newNotification`,
      'POST',
      JSON.stringify({
        key,
        paramString: JSON.stringify(params),
        userId,
        language,
      })
    );
  }

  function newDeviceNotify(
    deviceName,
    role: 'user' | 'worker',
    language: lang,
    userId?: string
  ): Promise<any> {
    return newNotify('NEW_DEVICE', language, { deviceName }, userId, role);
  }

  function newUserNotify(
    id: string,
    email: string,
    language: lang,
    name: string,
    surname: string
  ): Promise<any> {
    return defaultService
      .customFetch(`${process.env.REACT_APP_NFQES_API_URL}/notifications/newUserNotification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: id, email, language, name, surname }),
      })
      .then((response) => handleErrors(response))
      .then((response) => response);
  }

  function readAllNotify(): Promise<any> {
    return sendRequestNoBodyNoJSON('/notifications/push/allRead', 'PUT');
  }

  function readNotify(id): Promise<any> {
    return sendRequestNoBodyNoJSON(`/notifications/push/oneRead?notificationId=${id}`, 'PUT');
  }

  function getAllNotify(page): Promise<any> {
    return sendRequestNoBody(
      `/notifications/push/user?page=${page}&size=5&sort=sentDate,DESC`,
      'GET'
    ).then((resp) => {
      const notificationDTOList = resp.notificationDTOList.map((notif) => {
         let params = {};

         if (notif.params) {
           const cleanedParams = notif.params
             .replace(/^{|}$/g, '')
             // eslint-disable-next-line no-useless-escape
             .replace(/\\\"/g, '"')
             .split(/,\s*(?![^{}]*\})/);

           cleanedParams.forEach(param => {
             // eslint-disable-next-line no-useless-escape
             const [key, value] = param.split(/:(.+)/).map(part => part.trim().replace(/^\"|\"$/g, ''));

             if (key && value !== undefined) {
               const finalValue = isNaN(value) ? value : Number(value);
               params[key] = finalValue;
             }
           });
         }

         return { ...notif, params };
       });

      return { ...resp, notificationDTOList };
    });
    // .then((response) => {
    //   response.notificationDTOList.map((notification) => {
    //     let n = notification;
    //     n.params = JSON.parse(notification.params);
    //     return n;
    //   });
    //   return response;
    // });
  }

  function getUnreadNotify(page): Promise<any> {
    return sendRequestNoBody(
      `/notifications/push/user?page=${page}&size=5&sort=sentDate,DESC&isRead=false`,
      'GET'
    )
    .then((resp) => {
      const notificationDTOList = resp.notificationDTOList.map((notif) => {
         let params = {};

         if (notif.params) {
           const cleanedParams = notif.params
             .replace(/^{|}$/g, '')
             // eslint-disable-next-line no-useless-escape
             .replace(/\\\"/g, '"')
             .split(/,\s*(?![^{}]*\})/);

           cleanedParams.forEach(param => {
             // eslint-disable-next-line no-useless-escape
             const [key, value] = param.split(/:(.+)/).map(part => part.trim().replace(/^\"|\"$/g, ''));

             if (key && value !== undefined) {
               const finalValue = isNaN(value) ? value : Number(value);
               params[key] = finalValue;
             }
           });
         }
        return { ...notif, params };
      });

      return { ...resp, notificationDTOList };
    });
    // .then((response) => {
    //   response.notificationDTOList.map((notification) => {
    //     let n = notification;
    //     n.params = JSON.parse(notification.params);
    //     return n;
    //   });
    //   return response;
    // });
  }

  function getSettings(): Promise<any> {
    return sendRequestNoBody('/notifications/options', 'GET');
  }

  function postSettings(body): Promise<any> {
    return sendRequestWithBody('/notifications/options', 'PUT', JSON.stringify(body));
  }

  return {
    newNotify,
    newDeviceNotify,
    newUserNotify,
    readAllNotify,
    readNotify,
    getAllNotify,
    getUnreadNotify,
    getSettings,
    postSettings,
  };
}

// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------

export function notifyWebServiceFunction() {
  // REMOVIE IF STILL WORK const notifyList = [];

  function send(id, message) {
    if (Notification.permission === 'granted') {
      // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API

      let n = new Notification('NFQES BrainIT', {
        body: message,
        tag: id, // sluzi na "nahradenie notifikacie, nieco ako jej ID"
        vibrate: [200, 100, 200], // 200ms vibruje, 100 nie a 200 zase vibruje
      } as any);
      n.addEventListener('click', (e) => onClickNotify(e, n));
      n.addEventListener('close', (e) => onCloseNotify(e, n));
      n.addEventListener('show', (e) => onShowNotify(e, n));
      // REMOVIE IF STILL WORK notifyList.push(n);
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(function () {});
    }
  }

  function onClickNotify(e, notify) {
    if (e === notify) {
      console.log('onClickNotify');
    }
  }

  function onShowNotify(e, notify) {
    if (e === notify) {
      console.log('onShowNotify');
    }
  }

  function onCloseNotify(e, notify) {
    if (e === notify) {
      console.log('onCloseNotify');
    }
  }

  return { send };
}
