import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SimplePage from 'containers/Page/SimplePage';

import { SlideButton } from 'components/Buttons';

import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';

export default function NotPrivilagesRegister() {
  const { t } = useTranslation();

  const navigation = useNavigate();

  const redirectProfile = () => {
    navigation(t('URL.MY_PROFILE'));
  };

  return (
    <SimplePage
      title={t('NOT_PRIVILAGE_REGISTER.MAIN')}
      component={
        <Box>
          <Icon icon='healthicons:crying' width={120} />
        </Box>
      }
    >
      <Typography
        paddingBottom={2}
        variant='h4'
        sx={{ maxWidth: '600px', margin: 'auto', color: '#76838d !important' }}
      >
        {t('NOT_PRIVILAGE_REGISTER.SECOND')}
      </Typography>
      <SlideButton onClick={redirectProfile} onMiddleClick={() => t('URL.MY_PROFILE')}>
        {t('NOT_PRIVILAGE_REGISTER.GO_TO_FULL_REGISTER')}
      </SlideButton>
    </SimplePage>
  );
}
