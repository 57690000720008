import PropTypes from 'prop-types';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { services, userCtx } from 'context/context';
import { formatDate } from 'service/defaultServices/TimeFormatterService';
import { limitsKeys, shownKeys } from 'service/nfqes/LimitsValue';
import { hexToRgb } from 'utils/HexToRGB';

import { SlideButton } from 'components/Buttons';
import { IconButton, IconButtonAnimate } from 'components/IconButton';
import { InfoBox } from 'components/InfoBox';
import { QuotaBox } from 'components/QuotaBox';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { Badge, Box, Grid, Typography } from '@mui/material';

import MenuPopover from './HeaderComponents/MenuPopover';
import LimitItem from './HeaderComponents/Subscription/LimitItem';

interface SubscriptionMenuProps {
  isMobile?: boolean;
}

type StrOrElOrNum = string | JSX.Element | number;

const allowedLevels = ['1', '2', '3', '4'] as ['1', '2', '3', '4'];
type allowedLevelType = (typeof allowedLevels)[number];

const StyledLineHorizontal: any = styled(Box)(({ theme }: any) => ({
  background: `linear-gradient(45deg, ${theme.palette.sidebar.primary}  180px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip1}  210px, rgba(0,0,0,0) 0),
  linear-gradient(45deg, ${theme.palette.sidebar.premiumStip2} 270px, rgba(0,0,0,0) 0),
  linear-gradient(45deg,${theme.palette.sidebar.premiumStip3} 360px, rgba(${hexToRgb(
    theme.palette.sidebar.premiumStip3
  )}, 0.1))`,
  height: '2px',
  width: '97%',
  marginBottom: '16px',
}));

const CustomIcon: any = styled(Icon)(() => ({
  '@media (max-width:900px)': {
    backgroundColor: 'transparent',
    color: '#fff',
  },
}));

export default function SubscriptionMenu({ isMobile = false }: Readonly<SubscriptionMenuProps>) {
  const { t } = useTranslation();
  const { smsService } = useContext(services);
  const { limits: userLimits, freeCerts, roleUserWorker: role, ...user } = useContext(userCtx);

  const navigate = useNavigate();

  const [remainingSms, setRemainingSms] = useState(0);

  const loadUserSMS = useCallback(async () => {
    smsService
      .getRemainingSmsCount()
      .then((resp) => {
        if (resp < 0 || resp > userLimits['TWO_FA_SMS_SENT_MONTH']) {
          setRemainingSms(resp);
          return;
        }

        if (resp <= userLimits['TWO_FA_SMS_SENT_MONTH']) {
          setRemainingSms(Number(userLimits['TWO_FA_SMS_SENT_MONTH']) - resp);
        }
      })
      .catch(() => setRemainingSms(0));
  }, [smsService, userLimits]);

  useEffect(() => {
    loadUserSMS();
  }, [loadUserSMS]);

  const correctCerts = freeCerts.map((item) => {
    if (item === 'AES') {
      return 'AdES';
    } else if (item === 'AES-S') {
      return 'AdES-S';
    }
    return item;
  });

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const daysDiffFromNow = (date) => {
    const now = new Date();
    const givenDate = new Date(date);
    const diffTime = givenDate.getTime() - now.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const yesIconSpan = (
    <Box
      component={'span'}
      sx={{
        color: 'text.success',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon icon='fa:check' />
    </Box>
  );

  const noIconSpan = (
    <Box component={'span'} sx={{ color: 'text.error', display: 'flex', alignItems: 'center' }}>
      <Icon icon='fa:times' />
    </Box>
  );
  const plainText = (text) => <span>{text}</span>;
  const monthText = (text) => (
    <span>
      {`${text} ${t('SUBSCRIBE.MONTHS')}`
        .replace('1 mesiacov', '1 mesiaca')
        .replace('1 months', '1 month')}
    </span>
  );
  const iconText = (text) => (text === '1' ? yesIconSpan : noIconSpan);
  const noIconOrPlainText = (text) => (text === '0' ? noIconSpan : text);
  const verifyDocumentSize = (text) => <span>{`${text} MB`}</span>;
  const archiveStorageNumberDocuments = (text) => (
    <span>{text === '0' ? noIconSpan : transform.mbText(text)}</span>
  );

  const transform: Record<
    'plainText' | 'mbText' | 'iconText' | 'monthText' | 'noIconOrPlainText',
    (
      _val: string | number,
      _level?: (typeof allowedLevels)[number]
    ) => string | number | JSX.Element
  > = {
    plainText: plainText,
    mbText: (text) => `${text} MB`,
    monthText: monthText,
    iconText: iconText,
    noIconOrPlainText: noIconOrPlainText,
  };

  const oneMonthLessDate = (resetDate) => {
    const nextResetDate = new Date(resetDate);
    return new Date(nextResetDate.setMonth(nextResetDate.getMonth() - 1));
  };

  const limitTransformations: Record<
    shownKeys,
    (_val: string | number, _level?: allowedLevelType) => StrOrElOrNum
  > = {
    DOCUMENTS_SIGN_MONTH: transform.plainText,
    SIGN_N_DOCUMENTS: transform.plainText,
    SIGN_DOCUMENT_SIZE: transform.mbText,
    ELECTRONIC_SIGN_N_DOCUMENTS: transform.plainText,
    ELECTRONIC_SIGN_DOCUMENT_SIZE: transform.mbText,
    DIGITAL_SIGN_N_DOCUMENTS: transform.plainText,
    DIGITAL_SIGN_DOCUMENT_SIZE: transform.mbText,
    VERIFY_N_DOCUMENTS: transform.plainText,
    VERIFY_DOCUMENT_SIZE: verifyDocumentSize,
    MESSAGE_SENT_MONTH: transform.plainText,
    MESSAGE_SIGN_N_DOCUMENTS: transform.plainText,
    MESSAGE_SIZE: transform.mbText,
    MESSAGE_DRAFT_MAX_NUMBER: transform.plainText,
    MESSAGE_HISTORY_MONTHS: transform.monthText,
    SIGN_HISTORY_MONTHS_SHOW: transform.monthText,
    SIGN_HISTORY_MONTHS_DOWNLOAD: transform.monthText,
    ARCHIVE_STORAGE_NUMBER_DOCUMENTS: archiveStorageNumberDocuments,
    HASH_ARCHIVE_API_NUMBER_DOCUMENTS: transform.noIconOrPlainText,
    DMS_USER_QUOTA: transform.mbText,
    COMPANY_NUMBER: transform.plainText,
    VISUAL_SIGNATURES_NUMBER_CUSTOM: transform.noIconOrPlainText,
    TIMESTAMPS_COUNT_START_YEARLY: transform.plainText,
    TIMESTAMPS_COUNT_PER_MONTH: transform.plainText,
    TWO_FA_SMS_SENT_MONTH: plainText,
    NFQES_ES_AVAILABLE_CERTIFICATE: transform.iconText,
    NFQES_ES_SEAL_AVAILABLE_CERTIFICATE: transform.iconText,
    NFQES_ADES_AVAILABLE_CERTIFICATE: transform.iconText,
    NFQES_ADES_SEAL_AVAILABLE_CERTIFICATE: transform.iconText,
  };

  const refreshLimitValues = useCallback(() => {
    user.refreshLimits();
    loadUserSMS();
  }, [user, loadUserSMS]);

  const renderSubscriptionInfo = () => {
    return (
      <>
        <GroupWrapper>
          <LimitItem
            title={t(`SUBSCRIBE.YOUR_SUBSCRIPTION`)}
            value={t(`SUBSCRIBE.PLAN_${user.subscription}`)}
            border={user.subscription > 1}
            sx={{ pt: 0 }}
          />
          {user.subscription > 1 && (
            <LimitItem
              title={t('SUBSCRIBE.TO')}
              value={
                user.subscriptionInfo?.automaticRenewal
                  ? `${formatDate(user.subscriptionInfo?.dateTo)} (${t(
                      'SUBSCRIBE.AUTO_RENEW_HOME'
                    )})`
                  : formatDate(user.subscriptionInfo?.dateTo)
              }
              border={false}
              sx={{ pt: 1 }}
            />
          )}
        </GroupWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12} position='relative'>
            <Typography
              fontSize={14}
              fontWeight={400}
              ml={0}
              mb={1}
              mt={0.5}
              dangerouslySetInnerHTML={{
                __html: t('SUBSCRIBE.USAGE.CURRENT_PERIOD')
                  .replace('{{date1}}', formatDate(oneMonthLessDate(user.nextSubscriptionReset)))
                  .replace('{{date2}}', formatDate(user.nextSubscriptionReset)),
              }}
            />
            <Box mr={1} position='absolute' top='-10px' right={0}>
              <IconButton
                icon='mdi:refresh'
                tooltip={t('SUBSCRIBE.BUTTON_REFRESH')}
                onClick={refreshLimitValues}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.SIGNED_FILES')}
              value={user.countOfSignDocuments}
              maxValue={userLimits['DOCUMENTS_SIGN_MONTH']}
              postFix={t('SUBSCRIBE.USAGE.DOCUMENTS')}
            />
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.MESSAGES_SENT')}
              value={user.messageSent}
              maxValue={userLimits['MESSAGE_SENT_MONTH']}
              postFix={t('SUBSCRIBE.USAGE.MESSAGES')}
            />
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.FREE_SPACE')}
              value={Number(user.usedDMSStorage) / 1024 / 1024}
              maxValue={userLimits['DMS_USER_QUOTA']}
              postFix={t('SUBSCRIBE.USAGE.MB')}
            />
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.SMS_COUNT')}
              value={remainingSms === -1 ? t('PROFILE.SMS.UNLIMITED') : remainingSms}
              maxValue={remainingSms === -1 ? undefined : userLimits['TWO_FA_SMS_SENT_MONTH']}
              postFix={remainingSms === -1 ? undefined : t('SUBSCRIBE.USAGE.AMOUNT')}
            />
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.TIMESTAMP_COUNT')}
              value={user.countOfTimestamp}
              maxValue={userLimits['TIMESTAMPS_COUNT_PER_MONTH']}
              postFix={t('SUBSCRIBE.USAGE.AMOUNT')}
            />
          </Grid>
          <Grid item xs={6}>
            <QuotaBox
              title={t('SUBSCRIBE.USAGE.NEXT_RESET_DAY')}
              value={31 - daysDiffFromNow(user.nextSubscriptionReset)}
              maxValue={31}
              postFix={t('SUBSCRIBE.USAGE.DAYS')}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderData = () => {
    const flattenedlimitKeys = limitsKeys.flat();

    const groups = [
      { title: t('SUBSCRIBE.GROUPS.LIMIT_SIGN'), data: flattenedlimitKeys.slice(0, 7) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_VERIFY'), data: flattenedlimitKeys.slice(7, 9) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_MAILBOX'), data: flattenedlimitKeys.slice(9, 13) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_ARCHIVE'), data: flattenedlimitKeys.slice(15, 18) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_COMPANIES'), data: flattenedlimitKeys.slice(18, 19) },
      {
        title: t('SUBSCRIBE.GROUPS.LIMIT_GRAPH_SIGNATURE'),
        data: flattenedlimitKeys.slice(19, 20),
      },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_TIMESTAMP'), data: flattenedlimitKeys.slice(20, 22) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_SMS'), data: flattenedlimitKeys.slice(22, 23) },
      { title: t('SUBSCRIBE.GROUPS.LIMIT_BONUS'), data: flattenedlimitKeys.slice(23) },
    ];

    return groups.map((_group, _index) => (
      <Fragment key={_group.title + '-' + _index}>
        <Typography key={_group.title} fontSize={14} fontWeight={500} ml={0} mb={0.5} mt={0.5}>
          {_group.title}:
        </Typography>
        <GroupWrapper>
          {_group.data.map((_key: string, _idx: number) => (
            <LimitItem
              key={`${_key}-${_idx}`}
              title={t(`SUBSCRIBE.BONUS.${_key}`)}
              value={
                limitTransformations[_key]
                  ? limitTransformations[_key](userLimits[_key])
                  : userLimits[_key] + '' + limitTransformations[_key]
              }
              border={_idx < _group.data.length - 1}
              sx={{ pt: _idx > 0 ? 1 : 0 }}
            />
          ))}
        </GroupWrapper>
      </Fragment>
    ));
  };

  if (role !== 'user') {
    return null;
  }

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40, mr: { md: 0, sm: 0, xs: 0 } }}
      >
        <Badge
          badgeContent={freeCerts.length > 0 ? '1' : ''}
          sx={{
            '& .MuiBadge-badge': {
              height: freeCerts.length > 0 ? '20px' : '12px',
              minWidth: freeCerts.length > 0 ? '20px' : '12px',
              backgroundColor: freeCerts.length > 0 ? 'text.error' : 'text.success',
            },
          }}
        >
          <CustomIcon icon='icon-park-outline:setting-config' width={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow={isMobile ? 'none' : 'top-right'}
        sx={{
          mt: 1.5,
          pl: '5px',
          width: isMobile ? '300px' : '600px',
          height: '600px',
          pb: 2,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ maxHeight: '100%', overflowY: 'auto' }} pl={2}>
          <Typography fontSize={16} fontWeight={500} ml={0} mb={0.5} mt={0.5} mr={2}>
            {t('SUBSCRIBE.GROUPS.SUBSCRIPTION')}
          </Typography>
          <StyledLineHorizontal />
          {freeCerts.length > 0 && (
            <Box mr={2} mb={2}>
              <InfoBox type='warning' leftBorder allowClose={false}>
                <Typography fontSize={16}>
                  {t('certificate:CREATE_CERT.FREE_LONG')
                    .replace('{certs}', correctCerts.join(', '))
                    .replace(
                      'vzdialené certifikáty',
                      correctCerts.length === 1 ? 'vzdialený certifikát' : 'vzdialené certifikáty'
                    )}
                </Typography>
                <div>
                  <SlideButton
                    primary
                    small
                    mainAction
                    onClick={() => {
                      navigate(t('URL.GENERATE_REQUEST_WITHOUT_ID'));
                      handleClose();
                    }}
                    onMiddleClick={() => t('URL.GENERATE_REQUEST_WITHOUT_ID')}
                    margin='right'
                    style={{ marginBottom: '0px !important' }}
                  >
                    {t('certificate:CREATE_CERT.FREE_GET')}
                  </SlideButton>
                </div>
              </InfoBox>
            </Box>
          )}
          {renderSubscriptionInfo()}
          <Typography fontSize={16} fontWeight={500} ml={0} mb={0.5} mt={0.5} mr={2}>
            {t('SUBSCRIBE.GROUPS.ALL_LIMITS')}
          </Typography>
          <StyledLineHorizontal />
          {renderData()}
        </Box>
      </MenuPopover>
    </>
  );
}

const GroupWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 3px 2px, rgba(0, 0, 0, 0.02) 0px 1px 2px 2px',
        borderRadius: '6px',
      }}
      mb={2}
      p={1.5}
      mr={2}
    >
      {children}
    </Box>
  );
};

GroupWrapper.propTypes = {
  children: PropTypes.node,
};
