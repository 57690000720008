import { lazy } from 'react';

import Layout from 'containers/layout/Layout';
import RouterProviders from 'router/RouterProviders';

import { lazyRetry } from '../Router';

const PageFileGatewayCreate = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/file-gateway/create/PageFileGatewayCreate'),
    'PageFileGatewayCreate'
  )
);
const PageFileGatewaySign = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/file-gateway/sign/[id]/logged-user/[uid]/PageFileGatewaySign'),
    'PageFileGatewaySign'
  )
);
const PageFileGatewayDownload = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/file-gateway/download/[id]/PageFileGatewayDownload'),
    'PageFileGatewayDownload'
  )
);
const PageFileGatewayMinimalCreate = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/file-gateway-minimal/create/PageFileGatewayMinimalCreate'),
    'PageFileGatewayMinimalCreate'
  )
);
const PageFileGatewaySignUser = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/file-gateway/sign/[id]/user/[uid]/PageFileGatewaySignUser'),
    'PageFileGatewaySignUser'
  )
);
const PageFileGatewayMinimalDownload = lazy(() =>
  lazyRetry(
    () =>
      import('pages/(public)/file-gateway-minimal/download/[id]/PageFileGatewayMinimalDownload'),
    'PageFileGatewayMinimalDownload'
  )
);

const URL = {
  FILE_GATEWAY_SIGN: '/file-gateway/sign/:id/logged-user/:uid',
  FILE_GATEWAY_DOWNLOAD: '/file-gateway/download/:id',
  FILE_GATEWAY_CREATE: '/file-gateway/create',
  FILE_GATEWAY_MINIMAL_SIGN: '/file-gateway/sign/:id/user/:uid',
  FILE_GATEWAY_MINIMAL_DOWNLOAD: '/file-gateway-minimal/download/:id',
  FILE_GATEWAY_MINIMAL_CREATE: '/file-gateway-minimal/create',
};

export const routesFileGateway: Array<any> = [
  {
    path: URL.FILE_GATEWAY_CREATE,
    element: <PageFileGatewayCreate />,
  },
  {
    path: URL.FILE_GATEWAY_SIGN,
    element: <PageFileGatewaySign />,
  },
  {
    path: URL.FILE_GATEWAY_DOWNLOAD,
    element: <PageFileGatewayDownload />,
  },
];

export const routesFileGatewayNoLayout: Array<any> = [
  {
    path: '/',
    element: (
      <RouterProviders>
        {/* <LayoutNoMenu /> */}
        <Layout hiddenMenu />
      </RouterProviders>
    ),
    children: [
      {
        path: URL.FILE_GATEWAY_MINIMAL_CREATE,
        element: <PageFileGatewayMinimalCreate />,
      },
      {
        path: URL.FILE_GATEWAY_MINIMAL_SIGN,
        element: <PageFileGatewaySignUser />,
      },
      {
        path: URL.FILE_GATEWAY_MINIMAL_DOWNLOAD,
        element: <PageFileGatewayMinimalDownload />,
      },
    ],
  },
];
