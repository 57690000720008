import i18next from 'i18next';

import sendRequestFunctions from 'service/common/sendRequestFunctions';

export default function smsServiceFunction() {
  const { sendRequestWithBodyNoJSON, sendRequestNoBody, sendRequestWithBody } =
    sendRequestFunctions(process.env.REACT_APP_ORCHESTRATOR_API_URL);

  function verifySMSToken(operation, token, operationName?) {
    return sendRequestWithBodyNoJSON(
      '/verifySmsToken',
      'POST',
      JSON.stringify({ operation, operationName, token })
    );
  }

  function send2fa(operation, operationName?, method?, level?): Promise<string> {
    return sendRequestWithBody(
      '/send2fa',
      'POST',
      JSON.stringify({
        operation,
        operationName: operationName,
        languageCode: i18next.language,
        skipSend: process.env.REACT_APP_HIDE_DEV_THINGS === 'false',
        authenticationMethod: method,
        level: level,
      })
    );
  }

  function send2faToken(operation, method?, operationName?): Promise<string> {
    return sendRequestWithBody(
      '/send2fa',
      'POST',
      JSON.stringify({
        operation: operation,
        operationName: operationName,
        authenticationMethod: method,
        languageCode: i18next.language,
        skipSend: process.env.REACT_APP_HIDE_DEV_THINGS === 'false',
      })
    );
  }

  function addUser2fa(
    operation: number,
    operationName?: string,
    method?: number,
    token?: string
  ): Promise<any> {
    return sendRequestWithBodyNoJSON(
      '/addUser2fa',
      'POST',
      JSON.stringify({
        operation: operation,
        operationName: operationName,
        languageCode: i18next.language,
        skipSend: process.env.REACT_APP_HIDE_DEV_THINGS === 'false',
        authenticationMethod: method,
        token: token,
      })
    );
  }

  function verify2fa(operation, token, operationName?) {
    return sendRequestWithBodyNoJSON(
      '/verify2fa',
      'POST',
      JSON.stringify({ operation, operationName, token, authenticationMethod: 0 })
    );
  }

  function getRemainingSmsCountById(userId) {
    return sendRequestNoBody(`/admin/remainingSms/${userId}`, 'GET');
  }

  function setNewRestrictions(userId, smsRestriction, limit) {
    return sendRequestWithBodyNoJSON(
      '/admin/smsRestriction',
      'POST',
      JSON.stringify({ userId, limit, smsRestriction })
    );
  }

  function getUserMethods() {
    return sendRequestNoBody('/user2fa', 'GET');
  }

  function getMinimalOperationLevel(operationId: number) {
    return sendRequestNoBody(`/operation/${operationId}`, 'GET');
  }

  function getRemainingSmsCount() {
    return sendRequestNoBody('/remainingSms', 'GET');
  }

  return {
    //  sendSMSVerification,
    verifySMSToken,
    send2faToken,
    getRemainingSmsCount,
    getRemainingSmsCountById,
    setNewRestrictions,
    send2fa,
    verify2fa,
    addUser2fa,
    getUserMethods,
    getMinimalOperationLevel,
  };
}
