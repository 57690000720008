import { useEffect } from 'react';
import { /*Cookies,*/ useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

const win: any = window;
win.dataLayer = win.dataLayer || [];

/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gtag(..._par) {
  (window as any).dataLayer.push(arguments);
}

function getConsentStatus(name) {
  if (!name) return 'denied';
  else if (name === 'allow') return 'granted';
  else if (name === 'deny') return 'denied';
  else if (name === 'denied') return 'denied';
  else return name;
}

export default function GoogleAnalytics(props: any) {
  const [cookies, setCookie] = useCookies();
  const statistics = getConsentStatus(cookies?.cmplz_cookies_statistics); // Štatistické
  const marketing = getConsentStatus(cookies?.cmplz_cookies_marketing); // Marketing
  const functional = getConsentStatus(cookies?.cmplz_cookies_functional); // Necessary

  useEffect(() => {
    initializeTagManager();
    gtag('consent', 'default', {
      ad_storage: marketing, // Marketing
      ad_user_data: marketing, // Marketing
      ad_personalization: marketing, // Marketing
      analytics_storage: statistics, // Štatistické
      //
      functionality_storage: functional, // Necessary
      personalization_storage: functional, // Necessary
      security_storage: functional, // Necessary
      //
    });
    setCookie('cmplz_cookies_statistics', statistics);
    setCookie('cmplz_cookies_marketing', marketing);
    setCookie('cmplz_cookies_functional', functional);
  }, [statistics, marketing, functional, cookies.cookies, setCookie]);

  return <>{props.children}</>;
}

export function GTMEvent(event, eventName, params) {
  // const cookies = new Cookies();
  // if (
  //   cookies.get('cookies') == 'true' || cookies.get
  //   localStorage.getItem('cookies') === 'true' ||
  //   eventName === 'ga4.neprijate cookies' ||
  //   eventName === 'ga4.prijate cookies'
  // ) {
  gtag(event, eventName, params);
  // }
}

// gtm.js
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CODE,
};

export const initializeTagManager = () => {
  TagManager.initialize(tagManagerArgs);
  (window as any).dataLayer.push({ event: 'pageview' });
};
