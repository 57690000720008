import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, FormHelperText } from '@mui/material';

import { InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

export interface TextInputProps extends InputProps<string> {
  maxRows?: number;
  rows?: number;
  limit?: number;
  noAutocomplete?: boolean;
  hint?: ReactNode;
}

export default function TextInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  // specifics
  rows,
  maxRows,
  limit,
  noAutocomplete,
  hint,
}: TextInputProps) {
  const { t } = useTranslation();

  const [characterCount, setCharacterCount] = useState(0);
  useEffect(() => {
    setCharacterCount(value?.length);
  }, [value?.length]);

  return (
    <Box position={'relative'}>
      <TextFieldSx
        size={size || 'medium'}
        id={name}
        data-testid={name}
        label={label}
        placeholder={placeholder}
        variant='outlined'
        type='text'
        value={value}
        error={error?.length > 0}
        helperText={t(error)}
        onChange={onChange}
        fullWidth
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        minRows={rows}
        maxRows={maxRows}
        multiline={rows ? true : false}
        autoComplete={noAutocomplete ? 'off' : undefined}
      />
      {limit !== undefined && (
        <FormHelperText
          sx={{
            marginTop: error?.length > 0 ? '-21px' : '4px',
            textAlign: 'end',
            paddingRight: '4px',
            marginBottom: error?.length > 0 ? '-4px' : '-25px',
          }}
          error={characterCount > limit}
        >
          {characterCount} / {limit}
        </FormHelperText>
      )}
      {hint && (
        <Box position='absolute' top='18px' right='12px'>
          {hint}
        </Box>
      )}
    </Box>
  );
}
