import { lazy, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Layout from 'containers/layout/Layout';
import DashboardRoutes, { DBRoute } from 'router/DashboardRoutes';

import { userCtx } from 'context/context';
import useSetLanguage from 'hooks/useSetLanguage';
import redrawService from 'service/common/RedrawService';

import { languages } from 'translations/config';

import GuardHandler from './guards/GuardHandler';
// import { VerifyPhone } from './guards/phoneVerify/PhoneVerify';
import RouterProviders from './RouterProviders';
import { routesFileGateway, routesFileGatewayNoLayout } from './routes/FileGateway';

const PageSignDocumentType = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/sign-document/[type]/PageSignDocumentType'),
    'PageSignDocumentType'
  )
);
const PageValidation = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/(public)/online-validation-of-qualified-electronic-signature-KEP/PageValidation'
      ),
    'PageValidation'
  )
);

const PageTrustedList = lazy(() =>
  lazyRetry(() => import('pages/(common)/trusted-list/PageTrustedList'), 'PageTrustedList')
);
// const PageVersion = lazy(() =>
//   lazyRetry(() => import('pages/(common)/version/PageVersion'), 'PageVersion')
// );
const PageTrustedListDetail = lazy(() =>
  lazyRetry(
    () => import('pages/(common)/trusted-list/[id]/PageTrustedListDetail'),
    'PageTrustedListDetail'
  )
);
const PageSignIn = lazy(() =>
  lazyRetry(() => import('pages/(public)/signin/PageSignIn'), 'PageSignIn')
);

const NotFound = lazy(() => lazyRetry(() => import('modules/no-access/NotFound'), 'NotFound'));

export default function Router() {
  const [, setRedraw] = useState('0');
  redrawService.setChangeMethod(setRedraw);

  const { t, i18n } = useTranslation();
  const user = useContext(userCtx);

  useSetLanguage();

  const lang = languages.map((lng) => i18n.getFixedT(lng));

  const prepareRoute = (route: DBRoute): any[] =>
    route.submenu
      ? [
          // flatMap - hodi prvky z vnutorneho pola do vonkajsieho -> tj. z [1, 2, [3, 4, 5]] spraví [1, 2, 3, 4, 5]
          ...(route.submenu?.flatMap((subroute) => prepareRoute(subroute)) || []),
          ...lang.map((lng) => ({
            path: lng(route.path),
            element: <GuardHandler route={route}>{route.component}</GuardHandler>,
          })),
        ]
      : [
          ...lang.map((lng) => ({
            path: lng(route.path),
            element: <GuardHandler route={route}>{route.component}</GuardHandler>,
          })),
        ];

  const flatRoutes = DashboardRoutes.flatMap((route) => prepareRoute(route));

  // PEVNE STRANKY
  flatRoutes.push({
    path: '/',
    element:
      user.role === 'admin' ? (
        <Navigate to={t('URL.ADMIN_DASHBOARD')} />
      ) : (
        <Navigate to={t('URL.HOME')} />
      ),
    replace: true,
  });
  flatRoutes.push({
    path: '/nfqes/home',
    element: <Navigate to={t('URL.HOME')} />,
    replace: true,
  });
  flatRoutes.push({
    path: '/signin',
    element: <PageSignIn />,
  });
  flatRoutes.push({
    path: '*',
    element: (
      <RouterProviders>
        <NotFound />
      </RouterProviders>
    ),
  });
  // flatRoutes.push({
  //   path: t('URL.PHONE_VERIFICATION'),
  //   element: (
  //     <RouterProviders>
  //       <VerifyPhone />
  //     </RouterProviders>
  //   ),
  // });

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <RouterProviders>
          <Layout />
        </RouterProviders>
      ),
      children: [...flatRoutes, ...routesFileGateway],
    },
    {
      path: '/SignModule/:type',
      element: (
        <RouterProviders>
          <PageSignDocumentType />
        </RouterProviders>
      ),
    },
    {
      path: '/VerifyModule',
      element: (
        <RouterProviders>
          <PageValidation />
        </RouterProviders>
      ),
    },
    {
      path: '/TrustedList',
      element: (
        <RouterProviders>
          <PageTrustedList />
        </RouterProviders>
      ),
    },
    {
      path: '/TrustedListDetail/:id',
      element: (
        <RouterProviders>
          <PageTrustedListDetail />
        </RouterProviders>
      ),
    },
    //  {
    //    path: '/version',
    //    element: (
    //        <PageVersion />
    //    ),
    //  },
    ...routesFileGatewayNoLayout,
  ]);

  return <RouterProvider router={router} />;
}

// Fix na chunk load error
const lazyRetry: any = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: Error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export { lazyRetry };
