import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { RQformats, RQmodules } from 'service/defaultServices/ReactQuillConfig';
import { sanitize } from 'utils/Format';

import { Box, FormHelperText, styled } from '@mui/material';

import { InputProps } from '../hooks/useInput';

import css from './Inputs.module.css';

const QuillBox = styled(Box)(({ theme }: any) => ({
  color: theme.palette.text.primary,
  '& .ql-toolbar': {
    borderColor: theme.palette.text.inputBorder,
    borderRadius: '10px 10px 0px 0px',
  },
  '& .ql-container': {
    borderColor: theme.palette.text.inputBorder,
    borderRadius: '0px 0px 10px 10px',
  },
  '& .ql-picker-options': {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  '& .ql-snow, .ql-picker': {
    color: `${theme.palette.text.primary} !important`,
  },
  '& .ql-fill': {
    fill: `${theme.palette.text.primary} !important`,
  },
  '& .ql-stroke': {
    stroke: `${theme.palette.text.primary} !important`,
  },
  '& .ql-editor.ql-blank:before': {
    color: `${theme.palette.text.primary} !important`,
  },
  '& .ql-transparent': {
    opacity: 1,
  },

  '.ql-editor': { minHeight: '100px' },
}));

interface RichTextInputProps extends InputProps<string> {
  limit?: number;
}

export default function RichTextInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  limit,
}: // specifics
// ...
RichTextInputProps) {
  const { t } = useTranslation();

  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = (newVal: string) => {
    if (value !== newVal) {
      onChange?.({ target: { value: newVal } });
    }
  };

  useEffect(() => {
    setCharacterCount(value?.length);
  }, [value?.length]);

  return (
    <QuillBox className={css.richTextFix}>
      <ReactQuill
        theme='snow'
        id={name}
        data-testid={name}
        placeholder={placeholder || label}
        // defaultValue={value}
        onChange={handleChange}
        modules={RQmodules}
        formats={RQformats}
        value={sanitize(value)}
        //value={value}
        onBlur={onBlur}
        // required={required}
        // disabled={disabled}
        // sx={{ "& fieldset": { borderColor: "primary.main" } }} // je v QuillBox
      />

      <FormHelperText id={`${name}-helper`} error={error?.length > 0} sx={{ paddingLeft: '12px' }}>
        {t(error)}
      </FormHelperText>
      {limit !== undefined && (
        <FormHelperText
          sx={{
            marginTop: error?.length > 0 ? '-21px' : '4px',
            textAlign: 'end',
            paddingRight: '4px',
            marginBottom: error?.length > 0 ? '-4px' : '-25px',
          }}
          error={characterCount > limit}
        >
          {characterCount} / {limit}
        </FormHelperText>
      )}
    </QuillBox>
  );
}
