import { useCallback, useState } from 'react';

import SecureRandom from 'service/defaultServices/SecureRandom';

import { Box } from '@mui/material';

import Notify, { NotifyType } from './Notify';
import { SetNotifyFunction } from './NotifyService';

import css from './Notify.module.css';

export default function NotifyBox() {
  const [notifications, setNotifications] = useState<Array<NotifyType>>([]);

  const addNotify = useCallback((notify: NotifyType) => {
    if (!notify.unique) notify.unique = new Date().getTime() + '_' + SecureRandom.random();
    setNotifications((old) => [...old, notify]);
  }, []);
  SetNotifyFunction(addNotify);

  const handleRemove = useCallback((notify: NotifyType) => {
    setNotifications((oldNotifications: Array<NotifyType>) => {
      const idx = oldNotifications.indexOf(notify);
      const newNotifications = [...oldNotifications];
      newNotifications.splice(idx, 1);
      return newNotifications;
    });
  }, []);

  const notificationsEle = notifications.map((notify) => (
    <Notify key={notify.unique} data={notify} onRemove={handleRemove} />
  ));

  return <Box className={css.notifyBox}>{notificationsEle}</Box>;
}
