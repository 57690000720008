import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import css from './Spinner.module.css';

interface Props {
  paddingTop?: string;
  hideText?: boolean;
}

export default function Spinner(props: Props) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: props.paddingTop,
      }}
    >
      <div className={css.loaderOffset}></div>
      {props.hideText ||
        (!props.hideText && (
          <Typography variant={'h3'} color='text.primary'>
            {t('HOME.LOADING')}...
          </Typography>
        ))}
      <div className={css.loaderOffset}></div>
      <span className={css.loader}></span>
    </div>
  );
}
