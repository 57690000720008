export function handleErrors(response: any): Promise<any> {
  if (response.ok) return response;
  else if (response.status === '500') throw new Error('500');
  else if (response.status === '304') throw new Error('304');
  else
    return response
      .text()
      .catch(() => {
        throw new Error(response.status);
      })
      .then((text) => {
        throw new Error(text);
      });
}
