import { authorizationService } from 'index';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GraviteeAccount } from 'pages/user-profile/[id]/_services/GraviteeDTO';
import { getUserGraviteeAccountsAll } from 'pages/user-profile/[id]/_services/GraviteeService';

import { services, userCtx as user } from 'context/context';
import { OcraValue, role, SubscriptionValue, UserValue } from 'service/iam/UserValue';
import { LimitsValue } from 'service/nfqes/LimitsValue';

import { NotificationManager } from 'components/NotifyBox';

import { lang } from 'translations/config';

export type FreeCerts = 'AES-S' | 'ES-S' | 'ES' | 'AES';

export interface UserType {
  data: UserValue;
  subscription: 0 | 1 | 2 | 3 | 4 | 5;
  limits: LimitsValue;
  nextSubscriptionReset: Date;
  load: () => void;
  loadUser: (_waitForLOad?: boolean) => void;
  loading: boolean;
  role: role;
  realRole: role;
  changeRole: (_role: role) => void;
  roleUserWorker: 'user' | 'worker';
  setSubscription: (_subscr: SubscriptionValue & { restrictions?: LimitsValue }) => void;
  removeOcraToken: () => void;
  isFullAccount: boolean;
  freeCerts: FreeCerts[];
  getFreeCerts: () => void;
  countOfSignDocuments: number;
  countOfTimestamp: number;
  messageSent: number;
  subscriptionInfo: any;
  userApis: any;
  allowNegativeTimestamp: boolean;
  changeTokenInfo: (_newTokenInfo: OcraValue) => void;
  usedDMSStorage: number;
  availableCertTypes: Record<'URL.MY_CERTIFICATES_DOWNLOADABLE', boolean>;
  refreshLimits: () => void;
}

const emptyUserValue: UserValue = {
  userId: '',
  person: {
    firstName: '',
    lastName: '',
    bornDate: '',
  },
  email: '',
  address: {
    street: '',
    buildingNumber: '',
    propertyNumber: '',
    postalCode: '',
    city: '',
    state: '703',
    stateNote: 'SK',
  },
  contacts: [
    {
      id: 0,
      type: 2,
      value: '',
    },
  ],
  phoneNumberConfirmed: true,
  status: 0,
  statusType: 0,
  ocraTokenSn: null,
  language: 'sk' as lang,
  roles: [],
  isFullAccount: false,
};

interface UserProviderProps {
  children: ReactNode;
}

export function UserProvider({ children }: Readonly<UserProviderProps>) {
  const { iamService, nfqesService, orchestratorService } = useContext(services);

  const [data, setData] = useState<UserValue>();
  const [subscription, setSubscription] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
  const [nextSubscriptionReset, setNextSubscriptionReset] = useState<Date>(new Date('2020-01-01'));
  const [countOfSignDocuments, setCountOfSignDocuments] = useState<number>(0);
  const [countOfTimestamp, setCountOfTimestamp] = useState<any>(0);
  const [messageSent, setMessageSent] = useState<any>(0);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>({});
  const [limits, setLimits] = useState<LimitsValue>({
    ELECTRONIC_SIGN_N_DOCUMENTS: 0,
    ELECTRONIC_SIGN_DOCUMENT_SIZE: 0,
    SIGN_N_DOCUMENTS: 0,
    SIGN_DOCUMENT_SIZE: 0,
    DIGITAL_SIGN_N_DOCUMENTS: 0,
    DIGITAL_SIGN_DOCUMENT_SIZE: 0,
    VERIFY_N_DOCUMENTS: 0,
    VERIFY_DOCUMENT_SIZE: 0,

    MESSAGE_SIGN_N_DOCUMENTS: 0,
    MESSAGE_SIZE: 0,
    MESSAGE_SENT_MONTH: 0,
    MESSAGE_HISTORY_MONTHS: 0,

    SIGN_HISTORY_MONTHS_SHOW: 0,
    SIGN_HISTORY_MONTHS_DOWNLOAD: 0,

    COMPANY_NUMBER: 0,
    COMPANY_NUMBER_EMPLOYEES: 0,

    VISUAL_SIGNATURES_NUMBER_GENERATED_US: 0,
    VISUAL_SIGNATURES_NUMBER_GENERATED: 0,
    VISUAL_SIGNATURES_NUMBER_CUSTOM: 0,

    TIMESTAMPS_COUNT_PER_MONTH: 0,
    TIMESTAMPS_COUNT_START_YEARLY: 0,
    TIMESTAMPS_COUNT_START_MONTHLY: 0,
    API_TOTAL_SIZE: 0,
    API_INTRODUCTION: 'X',

    TWO_FA_SMS_SENT_MONTH: 0,

    HASH_ARCHIVE_API_INTRODUCTION: 'X',
    ARCHIVE_STORAGE_NUMBER_DOCUMENTS: 0,
    HASH_ARCHIVE_API_NUMBER_DOCUMENTS: 0,
    DMS_USER_QUOTA: 0,

    NFQES_ES_AVAILABLE_CERTIFICATE: 0,
    NFQES_ES_SEAL_AVAILABLE_CERTIFICATE: 0,
    NFQES_ADES_AVAILABLE_CERTIFICATE: 0,
    NFQES_ADES_SEAL_AVAILABLE_CERTIFICATE: 0,

    EXTERNAL_CERTIFICATE_SUPPORT: 'X',
    NFQES_ES_SUPPORT: 'X',
    NFQES_ADES_SUPPORT: 'X',
    NFQES_QES_SUPPORT: 'X',
    NFQES_ES_SEAL_SUPPORT: 'X',
    NFQES_ADES_SEAL_SUPPORT: 'X',
    NFQES_QES_SEAL_SUPPORT: 'X',
    NFQES_QES_MQC_MANDATE_SUPPORT: 'X',
    NFQES_TLS_SUPPORT: 'X',
    TWO_FA_APP_SUPPORT: 'X',
    TWO_FA_APP_QUALIFIED_SUPPORT: 'X',
    DOCUMENTS_SIGN_MONTH: 0,
  });
  const [realRole, setRealRole] = useState<role>('unlogged');
  const [role, setRole] = useState<role>('user');

  const roleUserWorker = role === 'user' || role === 'unlogged' ? 'user' : 'worker';

  const [dataLoaded, setDataLoaded] = useState(false);
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const loading = !subscriptionLoaded || !dataLoaded;

  const [isFullAccount, setIsFullAccount] = useState(false);
  const [freeCerts, setFreeCerts] = useState<FreeCerts[]>([]);
  const [userApis, setUserApis] = useState<GraviteeAccount[]>([]);
  const [allowNegativeTimestamp, setAllowNegativeTimestamp] = useState<boolean>(false);

  const [usedDMSStorage, setUsedDMSStorage] = useState(0);
  const [availableCertTypes, setAvailableCertTypes] = useState({
    'url.MY_CERTIFICATES_ONLINE': false,
    'URL.MY_CERTIFICATES_DOWNLOADABLE': false,
    'URL.MY_CERTIFICATES_CARD': false,
    'URL.MY_CERTIFICATES_TLS': false,
    'URL.MY_CERTIFICATES_REQUESTS': false,
  });

  const roleNumbers = useMemo(
    () => ({
      user: 0,
      accountable: 1,
      worker: 1,
      manager: 2,
      admin: 3,
      oauth_admin: 0,
    }),
    []
  );
  const roleReplacements = useMemo(
    () => ({
      user: 'user',
      accountable: 'worker',
      manager: 'manager',
      admin: 'admin',
      oauth_admin: 'user',
    }),
    []
  );

  const changeRole = useCallback((newRole: role) => {
    if (newRole !== 'unlogged') {
      localStorage.setItem('role', newRole);
    }
    setRole(newRole);
  }, []);

  const loadUser = useCallback(async () => {
    return iamService
      .getUserData()
      .then(async (resp) => {
        try {
          resp.ocraTokenInfo = await orchestratorService.getOcra();
          resp.ocraTokenSn = resp.ocraTokenInfo.serialNumber;
        } catch {
          resp.ocraTokenSn = undefined;
        }
        /** DO NOT FILL emty contact
          let temp = resp.contacts.find((contact) => contact.type === 2)?.value;
          if (temp === undefined) {
            resp.contacts.push({ id: 0, type: 2, value: '' });
          }
        */
        setData(resp);
        const loadedRole = resp.roles.sort((r1, r2) =>
          roleNumbers[r1] < roleNumbers[r2] ? -1 : 1
        )[resp.roles.length - 1];
        setRealRole(roleReplacements[loadedRole]);

        const storedRole = localStorage.getItem('role') as role;
        if (storedRole && roleNumbers[storedRole] <= roleNumbers[loadedRole]) setRole(storedRole);
        else changeRole(roleReplacements[loadedRole]);
        setDataLoaded(true);
      })
      .catch(() => {
        NotificationManager.error('Subscription loading error');
        setData(emptyUserValue);
        setDataLoaded(true);
        authorizationService.autoLogout();
      });
  }, [iamService, roleNumbers, roleReplacements, orchestratorService, changeRole]);

  const setSubscriptionLevel = useCallback((subscr: SubscriptionValue) => {
    if (subscr) {
      setSubscription(subscr.level);
      setLimits(subscr.restrictions);
    } else setSubscription(0);
  }, []);

  const loadSubscription = useCallback(async () => {
    return nfqesService
      .getMySubscription()
      .then((resp) => {
        setSubscriptionInfo(resp);
        setSubscriptionLevel(resp);
        setSubscriptionLoaded(true);
      })
      .catch(() => {
        NotificationManager.error('Subscription loading error');
        setSubscriptionLoaded(true);
      });
  }, [nfqesService, setSubscriptionLevel]);

  const loadNextSubscriptionReset = useCallback(
    async () =>
      nfqesService
        .getUserInfo(data?.userId)
        .then((resp) => {
          setCountOfSignDocuments(resp.countOfSignDocuments);
          setMessageSent(resp.messageSent);
          setCountOfTimestamp(resp.countOfTimestamp);
          setNextSubscriptionReset(new Date(resp.nextReset));
          setAllowNegativeTimestamp(resp.allowNegativeTimestamp);
          setUsedDMSStorage(resp.dmsFilesSizeSum);
        })
        .catch(() => {}),
    [data?.userId, nfqesService]
  );

  const searchString = useMemo(
    () => ({
      ONLINE:
        '&evidenceType=2&showProlongations=true&hideServerCert=true&isOnQscdCard=false&downloadable=false&canSign=true',
      DOWNLOADABLE:
        '&evidenceType=2&showProlongations=true&hideServerCert=true&isOnQscdCard=false&downloadable=true&canSign=true',
      TLS: '&evidenceType=2&showProlongations=true&hideServerCert=false&isOnQscdCard=false&certType=2',
      REQUESTS: '&evidenceType=1&showProlongations=true',
    }),
    []
  );

  const loadAvailableCertTypes = useCallback(
    (type: 'ONLINE' | 'DOWNLOADABLE' | 'TLS' | 'REQUESTS') =>
      nfqesService
        .getCertificatesAndRequests('page=0&size=1', searchString[type], 'user')
        .then((resp) =>
          setAvailableCertTypes((prev) => ({
            ...prev,
            [`URL.MY_CERTIFICATES_${type}`]: resp.totalElements > 0,
          }))
        )
        .catch(() => {}),
    [nfqesService, searchString]
  );

  const checkIfUserHasQscdCards = useCallback(() => {
    if (data?.userId)
      nfqesService
        .getAllCards('page=0&size=1', `&userId=${data.userId}&canSign=true`, 'user')
        .then((resp) =>
          setAvailableCertTypes((prev) => ({
            ...prev,
            'URL.MY_CERTIFICATES_CARD': resp.totalElements > 0,
          }))
        );
  }, [nfqesService, data?.userId]);

  const load = useCallback(async () => {
    setDataLoaded(false);
    setSubscriptionLoaded(false);

    if (authorizationService.isLoggedIn())
      await Promise.all([
        loadSubscription(),
        loadUser(),
        loadAvailableCertTypes('ONLINE'),
        loadAvailableCertTypes('DOWNLOADABLE'),
        checkIfUserHasQscdCards(),
        loadAvailableCertTypes('TLS'),
        loadAvailableCertTypes('REQUESTS'),
        loadNextSubscriptionReset(),
      ]);
    else {
      changeRole('unlogged');
      setDataLoaded(true);
      setSubscription(0);
      nfqesService
        .getSubscriptionLimits(0)
        .then((resp) => {
          setLimits((old) => ({ ...old, ...resp[0].restrictions }));
        })
        .catch(() => {});
    }
  }, [
    loadSubscription,
    loadUser,
    loadNextSubscriptionReset,
    loadAvailableCertTypes,
    checkIfUserHasQscdCards,
    nfqesService,
    changeRole,
  ]);

  const refreshLimits = useCallback(async () => {
    if (authorizationService.isLoggedIn()) {
      await Promise.all([
        loadSubscription(),
        loadUser(),
        loadAvailableCertTypes('ONLINE'),
        loadAvailableCertTypes('DOWNLOADABLE'),
        checkIfUserHasQscdCards(),
        loadAvailableCertTypes('TLS'),
        loadAvailableCertTypes('REQUESTS'),
        loadNextSubscriptionReset(),
      ]);
    }
  }, [
    loadSubscription,
    loadUser,
    loadNextSubscriptionReset,
    loadAvailableCertTypes,
    checkIfUserHasQscdCards,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => setIsFullAccount(data?.isFullAccount), [data]);

  const removeOcraToken = useCallback(() => {
    setData((old) => ({ ...old, ocraTokenSn: undefined }));
  }, []);

  const getFreeCerts = useCallback(async () => {
    await nfqesService
      .getFreeCerts('user')
      .then((resp) => setFreeCerts(Object.keys(resp).filter((item) => resp[item]) as FreeCerts[]))
      .catch(() => {});
  }, [nfqesService]);

  const isLoggedIn = authorizationService.isLoggedIn();
  useEffect(() => {
    if (isLoggedIn) getFreeCerts();
  }, [getFreeCerts, isLoggedIn]);

  useEffect(() => {
    // handle gravitee apis for user
    if (data?.userId) getUserGraviteeAccountsAll(data.userId).then((resp) => setUserApis(resp));
  }, [data?.userId]);

  const changeTokenInfo = useCallback((newTokenInfo: OcraValue) => {
    setData((old) => ({ ...old, ocraTokenInfo: newTokenInfo }));
  }, []);

  const value: UserType = useMemo(
    () => ({
      data,
      subscription,
      limits,
      nextSubscriptionReset,
      load,
      loadUser,
      loading,
      role,
      realRole,
      changeRole,
      roleUserWorker,
      setSubscription: setSubscriptionLevel,
      removeOcraToken,
      isFullAccount: isFullAccount || role !== 'user',
      freeCerts,
      getFreeCerts,
      countOfSignDocuments,
      countOfTimestamp,
      messageSent,
      subscriptionInfo,
      userApis,
      allowNegativeTimestamp,
      changeTokenInfo,
      usedDMSStorage,
      availableCertTypes,
      refreshLimits,
    }),
    [
      data,
      subscription,
      limits,
      nextSubscriptionReset,
      load,
      loadUser,
      loading,
      role,
      realRole,
      changeRole,
      roleUserWorker,
      setSubscriptionLevel,
      removeOcraToken,
      isFullAccount,
      freeCerts,
      getFreeCerts,
      countOfSignDocuments,
      countOfTimestamp,
      messageSent,
      subscriptionInfo,
      userApis,
      allowNegativeTimestamp,
      changeTokenInfo,
      usedDMSStorage,
      availableCertTypes,
      refreshLimits,
    ]
  );

  return (
    <user.Provider value={value}>
      {(dataLoaded || window.location.pathname.includes('signin')) && children}
    </user.Provider>
  );
}

export function useUser() {
  return useContext(user);
}
