import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, MenuItem } from '@mui/material';

import { InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

import css from './Inputs.module.css';

export interface SelectInputProps extends InputProps<string | number> {
  options: Array<{ label: ReactNode; value: any; disabled?: boolean }> | Array<string>;
}

export default function SelectInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  // specifics
  options,
}: Readonly<SelectInputProps>) {
  const { t } = useTranslation();

  return (
    <Box>
      <TextFieldSx
        size={size || 'medium'}
        id={name}
        label={label}
        placeholder={placeholder}
        variant='outlined'
        select
        value={value}
        error={error?.length > 0}
        helperText={t(error)}
        onChange={onChange}
        fullWidth
        onBlur={onBlur}
        required={required}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem className={css.baseInput} value='' disabled>
            {placeholder}
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem
            key={option.value || option}
            className={css.baseInput}
            value={option.value === undefined ? option : option.value}
            disabled={option.disabled}
          >
            {option.label || option}
          </MenuItem>
        ))}
      </TextFieldSx>
    </Box>
  );
}
