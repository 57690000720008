import { lazy } from 'react';

import PageLogin from 'pages/(common)/login-from-nfqes/PageLogin';
import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageHome = lazy(() => lazyRetry(() => import('pages/(public)/home/PageHome'), 'PageHome'));
const PageRegistration = lazy(() =>
  lazyRetry(() => import('pages/registration/PageRegistration'), 'PageRegistration')
);

const PagePrefilledRegistration = lazy(() =>
  lazyRetry(
    () => import('pages/registration/[id]/PagePrefilledRegistration'),
    'PagePrefilledRegistration'
  )
);
const PageSuccess = lazy(() =>
  lazyRetry(() => import('pages/(common)/success/PageSuccess'), 'PagePasswordResetSuccess')
);
const PageSuccessRegistration = lazy(() =>
  lazyRetry(
    () => import('pages/registration/success/PageSuccessRegistration'),
    'PageSuccessRegistration'
  )
);
const PageSuccessPrefilledRegistration = lazy(() =>
  lazyRetry(
    () => import('pages/registration/success/[id]/PageSuccessPrefilledRegistration'),
    'PageSuccessPrefilledRegistration'
  )
);
const PageAutomaticLogout = lazy(() =>
  lazyRetry(
    () => import('pages/(common)/automatic-logout/PageAutomaticLogout'),
    'PageAutomaticLogout'
  )
);
const PageCookieSettings = lazy(() =>
  lazyRetry(() => import('pages/(common)/cookie-settings/PageCookieSettings'), 'PageCookieSettings')
);
const PageUserDetail = lazy(() =>
  lazyRetry(() => import('pages/user-profile/[id]/PageUserDetail'), 'PageUserDetail')
);
const PageNotificationSettings = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/settings/notifications/PageNotificationSettings'),
    'PageNotificationSettings'
  )
);

const routesAccount: Array<DBRoute> = [
  {
    path: 'URL.VERIFIED',
    component: <PageSuccess />,
    show: false,
    user: true,
    unlogged: true,
  },
  {
    path: 'URL.PASS_RESET_SUCCESS',
    component: <PageSuccess isPassword />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.REGISTER_WORKER',
    name: 'DASHBOARD_ROUTES.REGISTER',
    icon: <Icon icon='lets-icons:user-fill-add' width={30} height={30} />,
    component: <PageRegistration />,
    show: true,
    worker: true,
    manager: true,
    order: 12.1,
  },
  {
    path: 'URL.REGISTER_SUCCESS',
    component: <PageSuccessRegistration id={false} />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.REGISTER_SUCCESS_ID',
    component: <PageSuccessPrefilledRegistration id={true} />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.REGISTER',
    component: <PageRegistration />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.REGISTER_UUID',
    component: <PagePrefilledRegistration />,
    // isDevelop: true,
    show: false,
    user: false,
    unlogged: true,
    admin: false,
    manager: false,
    worker: false,
  },
  {
    path: 'URL.COOKIES',
    component: <PageCookieSettings />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.SIGNED_OUT',
    component: <PageAutomaticLogout />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: '/login-from-nfqes',
    component: <PageLogin />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: '/login',
    component: <PageHome />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: '/logout',
    component: <PageHome />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.PROFILE',
    component: <PageUserDetail />,
    show: false,
    user: false,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.NOTIFICATIONS_SETTINGS',
    component: <PageNotificationSettings />,
    show: false,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesAccount;
