import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatDate } from 'service/defaultServices/TimeFormatterService';

import { useInput } from 'components/Input';
import { NotificationManager } from 'components/NotifyBox';

import ConfirmDialog from './ConfirmDialog';
import InputDialog from './InputDialog';

export interface SmsDialogProps {
  showModal: boolean;
  setWait: (_val: boolean) => void;
  close: () => void;
  title: string;
  message: string | JSX.Element;
  getSmsCode: () => Promise<string>;
  sendSmsCode: (_code: string, _id?: string, _sessionId?: string) => Promise<any>;
  afterSuccessAction: () => void;
  afterFailAction?: (_err: Error) => void;
  user?: {
    nextSubscriptionReset: Date;
    subscription: number;
  };
  customLimitReachedContent?: JSX.Element;
  isPreffiledRegistration?: boolean;
  prefilledUid?: string;
}

export default function SmsDialog(props: SmsDialogProps) {
  const { t } = useTranslation(['translation', 'sign']);
  const [showLimitError, setShowLimitError] = useState(false); // Use state to manage showLimitError
  const [success, setSuccess] = useState(false); // State to handle success scenario
  const [sessionId, setSessionId] = useState<string>();
  const navigate = useNavigate();
  const inputProps = useInput<string>(
    '',
    'modal-input-sms',
    useCallback((val) => (val.length < 1 ? 'translation:DEFAULT.REQUIRED_FIELD' : ''), [])
  );

  const { showModal, getSmsCode: propsGetSmsCode, setWait } = props;
  const getSmsCode = useCallback(() => {
    setWait(true);
    propsGetSmsCode()
      .then((res) => {
        setWait(false);
        setSuccess(true); // Set success state to true
        if (props.isPreffiledRegistration) {
          setSessionId(res);
        }
      })
      .catch((err) => {
        setWait(false);
        try {
          const resp = JSON.parse(err.message);
          if (resp.message === 'SMS limit reached') {
            setShowLimitError(true);
          } else {
            setSuccess(true);
            const utcDate = new Date(resp.params[0] * 1000);
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

            const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone }));
            const offset = localDate.getTimezoneOffset();
            localDate.setMinutes(localDate.getMinutes() - offset);
            localDate.setMinutes(localDate.getMinutes() + 2);

            NotificationManager.error(
              t('HISTORY.SMS_NOT_SEND_TIME').replace(':time', localDate.toLocaleString())
            );
          }
        } catch {
          setSuccess(true);
          NotificationManager.error(t('HISTORY.SMS_NOT_SEND'));
        }
      });
  }, [setWait, propsGetSmsCode, props.isPreffiledRegistration, t]);

  useEffect(() => {
    if (showModal) getSmsCode();
  }, [showModal, getSmsCode]);

  const goToSubscription = () => navigate(t('URL.SUBSCRIBE_INFO'));

  const confirmHandler = () => {
    if (props.isPreffiledRegistration) {
      setWait(true);
      props
        .sendSmsCode(props.prefilledUid, inputProps.value, sessionId)
        .then(() => {
          props.afterSuccessAction();
        })
        .catch((err) => {
          if (!props.afterFailAction) NotificationManager.error(t('HISTORY.WRONG_TOKEN'));
          else props.afterFailAction(err);

          setShowLimitError(false);
          setSuccess(true);
        });
    } else {
      props
        .sendSmsCode(inputProps.value)
        .then(() => {
          props.close();
          props.afterSuccessAction();
        })
        .catch((err) => {
          if (!props.afterFailAction) NotificationManager.error(t('HISTORY.WRONG_TOKEN'));
          else props.afterFailAction(err);

          // po zadani zle sms kodu, neotvorilo znovu modal
          setShowLimitError(false);
          setSuccess(false);
          props.close();
        });
    }
  };

  return (
    <>
      {success && (
        <InputDialog
          showModal={props.showModal}
          close={(submit: boolean) => {
            if (props.isPreffiledRegistration) {
              setShowLimitError(false);
              setSuccess(false);
            } else {
              if (!submit) props.close();
              setShowLimitError(false);
              setSuccess(false);
            }
          }}
          title={props.title}
          message={props.message}
          button={{ label: t('DEFAULT.CONFIRM'), onClick: confirmHandler }}
          disableClose={props.isPreffiledRegistration ? true : false}
          secondaryButton={{
            label: t('PHONE_VERIFICATION.SEND_VERIFICATION_BTN'),
            onClick: getSmsCode,
          }}
          inputs={[
            {
              ...inputProps,
              label: t('PROFILE.VERIFY_PHONE_MODAL_TITLE'),
              required: true,
            },
          ]}
        />
      )}
      {showLimitError && (
        <>
          {props.customLimitReachedContent
            ? props.customLimitReachedContent
            : props.user && (
                <ConfirmDialog
                  showModal={props.showModal}
                  close={(submit: boolean) => {
                    if (props.isPreffiledRegistration) {
                      setShowLimitError(false);
                      setSuccess(false);
                    } else {
                      if (!submit) props.close();
                      setShowLimitError(false);
                      setSuccess(false);
                    }
                  }}
                  title={t('sign:SEND_SMS_MAX_MSG_MODAL.TITLE')}
                  // message={t("sign:SIGN_SMS_ERROR_TEXT")}
                  message={`${t('sign:SEND_SMS_MAX_MSG_MODAL.TEXT').replace(
                    '{date}',
                    formatDate(props.user.nextSubscriptionReset, true)
                  )}${
                    props.user.subscription < 4
                      ? t(`sign:SEND_SMS_MAX_MSG_MODAL.TEXT_LEVEL_${props.user.subscription}`)
                      : ''
                  }`}
                  button={
                    props.user.subscription < 4 && {
                      label: t('SUBSCRIBE.GET_SUBSCRIPTION'),
                      onClick: goToSubscription,
                    }
                  }
                />
              )}
        </>
      )}
    </>
  );
}
