import { authorizationService } from 'index';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageLogin() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorizationService.isLoggedIn()) {
      authorizationService.login();
    } else {
      navigate('/');
    }
  }, [navigate]);

  return null;
}
