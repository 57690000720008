import { Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from 'containers/SideBar/Sidebar';

import { showMenu } from 'context/context';

import { Spinner } from 'components/Spinner';

import AnnouncementShow from 'modules/announcements/_components/AnnouncementShow';

import { Box } from '@mui/material';

import CookieBanner from '../CookieBanner/CookieBanner';
import Footer from '../Footer/Footer';
import HeaderNavbar from '../Header/HeaderNavbar';

import css from './Layout.module.css';

interface IProps {
  hiddenMenu?: boolean;
}

export default function Layout(props: IProps) {
  const { isShown: sidebarIsShown } = useContext(showMenu);

  return (
    <Box className={css.relPos} sx={{ backgroundColor: 'background.paper' }}>
      <div className={css.container}>
        <div
          className={`${css.menu} ${props.hiddenMenu && css.hideMenuOnDesktop} ${
            sidebarIsShown && css.menuShow
          }`}
        >
          <Sidebar />
        </div>
        <div className={`${css.page} ${props.hiddenMenu && css.hideMenuOnDesktop}`}>
          <HeaderNavbar hiddenMenu={props.hiddenMenu} />
          <div className={css.pageContent}>
            <AnnouncementShow />
            <Suspense
              fallback={
                <div>
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
        <CookieBanner />
      </div>
      <div className={css.footer}>
        <Footer />
      </div>
      {/*<MobileAppHandler />*/}
    </Box>
  );
}
