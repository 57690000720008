import sendRequestFunctions from 'service/common/sendRequestFunctions';

import { handleErrors } from '../defaultServices/ErrorHandler';
import { PagedUserValue, PasswordChange, PhoneChange, StateValue, UserValue } from './UserValue';

const { sendRequestNoBody: requestIAMNoBody } = sendRequestFunctions(
  process.env.REACT_APP_IAM_API_URL
);

export function getUsersForFilter(page, perPage, search = '') {
  return requestIAMNoBody(
    `/api/user/users?page=${page}&size=${perPage}&sort=name&sortValue=asc&name=${search}`,
    'GET'
  );
}

export default function iamServiceFunction() {
  const {
    sendRequestNoBody,
    sendRequestNoBodyNoJSON,
    sendRequestWithBody,
    sendRequestWithBodyNoJSON,
    sendRequestNoBodyNoUser,
  } = sendRequestFunctions(process.env.REACT_APP_IAM_API_URL);

  const {
    sendRequestWithBodyNoJSON: APIsendRequestWithBodyNoJSON,
    sendRequestNoBodyNoJSON: APIsendRequestNoBodyNoJSON,
  } = sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

  // USER -----------------------------------------------------------------------------------------------------------------------

  function getUserData(): Promise<UserValue> {
    return sendRequestNoBody('/api/user/detail', 'GET').then((resp) => {
      if (!resp.bornDate) return resp;

      const dateOfBirth = resp.bornDate.split('T')[0];
      const [year, month, day] = dateOfBirth.split('-');
      return {
        ...resp,
        person: { ...resp.person, bornDate: `${day}.${month}.${year}` },
      };
    });
  }

  function createUser(userValue: UserValue): Promise<any> {
    return fetch(process.env.REACT_APP_IAM_API_URL + `/api/user`, {
      method: 'POST',
      //credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userValue),
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function createUserByAdmin(token, userValue: UserValue): Promise<any> {
    return sendRequestWithBody(
      `/api/User/admin/create?token=` + token,
      'POST',
      JSON.stringify(userValue)
    );
    // .then((response) => handleErrors(response))
    // .then((response) => response.json())
    // .then((json) => json);
  }

  function updateUser(
    userValue: UserValue,
    code: string,
    method: number,
    level: number | null
  ): Promise<void> {
    // POZOR!! VOLA SA REQUEST NA BE NIE NA OAUTH
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }

    return APIsendRequestWithBodyNoJSON(
      `/userInfo/updateUser?${query}`,
      'PUT',
      JSON.stringify({ ...userValue, code })
    );
  }

  function deleteUser(authType: 'SMS' | 'HW_TOKEN', token?: string): Promise<void> {
    // POZOR!! VOLA SA REQUEST NA BE NIE NA OAUTH
    return APIsendRequestNoBodyNoJSON(
      `/userInfo/deleteUser?authType=${authType}&code=${token}`,
      'DELETE'
    );
  }

  function adminDeleteUser(userId: string): Promise<void> {
    // POZOR!! VOLA SA REQUEST NA BE NIE NA OAUTH
    return APIsendRequestNoBodyNoJSON('/userInfo/deleteUser/' + userId, 'DELETE');
  }
  function adminDeactivateUser(
    userId: string,
    code: string /*, method: number, level: number | null*/
  ): Promise<string> {
    return sendRequestNoBodyNoJSON(`/api/User/deactivate/${userId}?token=${code}`, 'DELETE');
  }

  function changeLanguage(lang: string): Promise<void | Response> {
    return sendRequestNoBodyNoJSON(`/api/user/changeLanguage?langcode=${lang}`, 'POST');
  }

  function updateUserById(userValue: UserValue, id: string): Promise<void> {
    return sendRequestWithBodyNoJSON(`/api/user/${id}`, 'PUT', JSON.stringify(userValue));
  }

  function updatePassword(passwordChange: PasswordChange): Promise<void> {
    return sendRequestWithBodyNoJSON('/api/user/password', 'PUT', JSON.stringify(passwordChange));
  }

  // SMS verification and change phone number
  function changePhone(phoneChange: PhoneChange): Promise<void> {
    return sendRequestWithBodyNoJSON('/api/User/changePhone', 'POST', JSON.stringify(phoneChange));
  }

  function changePhoneAdmin(userId: string, phoneChange: PhoneChange): Promise<void> {
    return sendRequestWithBodyNoJSON(
      '/api/User/admin/changePhone/' + userId,
      'POST',
      JSON.stringify(phoneChange)
    );
  }

  function verifyPhone(verificationCode: string): Promise<void> {
    return sendRequestWithBodyNoJSON(
      '/api/User/verifyPhone',
      'POST',
      JSON.stringify(verificationCode)
    );
  }

  function resendPhoneVerification(): Promise<void> {
    return sendRequestNoBodyNoJSON('/api/User/resendPhoneVerification', 'POST');
  }

  function activatePhoneWithoutVerification(userId: string): Promise<void> {
    return sendRequestNoBodyNoJSON(`/api/User/activate/phone/${userId}`, 'POST');
  }

  function getStates(): Promise<StateValue[]> {
    return sendRequestNoBodyNoUser('/api/codetables/states', 'GET');
  }

  function getUsers(searchString: string): Promise<PagedUserValue> {
    return sendRequestNoBody(`/api/user/users?${searchString}`, 'GET').then((resp) => {
      if (!resp) return resp;

      return {
        ...resp,
        items: resp.items.map((item) => {
          if (!item.bornDate) return item;

          const dateOfBirth = item.bornDate.split('T')[0];
          const [year, month, day] = dateOfBirth.split('-');
          return {
            ...item,
            person: { ...item.person, bornDate: `${day}.${month}.${year}` },
          };
        }),
      };
    });
  }

  function getUserDetail(id: string): Promise<UserValue> {
    return sendRequestNoBody(`/api/user/detail/${id}`, 'GET').then((resp) => {
      if (!resp || !resp.bornDate) return resp;

      const dateOfBirth = resp.bornDate.split('T')[0];
      const [year, month, day] = dateOfBirth.split('-');

      return {
        ...resp,
        person: { ...resp.person, bornDate: `${day}.${month}.${year}` },
      };
    });
  }

  function activateUser(id: string): Promise<void> {
    return sendRequestWithBodyNoJSON(`/api/user/activate/${id}`, 'POST', '');
  }

  function blockUser(id: string): Promise<void> {
    return sendRequestWithBodyNoJSON(`/api/user/block/${id}`, 'POST', '');
  }

  // ROLES -----------------------------------------------------------------------------------------------------------------------------------------------------

  function getRoles(): Promise<any> {
    return sendRequestNoBody('/api/codetables/roles', 'GET');
  }

  function changeRoles(roles): Promise<any> {
    return sendRequestWithBodyNoJSON('/api/user/changeRoles', 'POST', JSON.stringify(roles));
  }

  // EMAIL -----------------------------------------------------------------------------------------------------------------------------------------------------

  function sendEmail(body: any): Promise<any> {
    return sendRequestWithBodyNoJSON('/api/Email', 'POST', JSON.stringify(body));
  }

  return {
    getUserData,
    createUser,
    createUserByAdmin,
    updateUser,
    changeLanguage,
    updateUserById,
    updatePassword,
    changePhone,
    changePhoneAdmin,
    verifyPhone,
    resendPhoneVerification,
    activatePhoneWithoutVerification,
    getStates,
    getUsers,
    getUserDetail,
    activateUser,
    blockUser,
    // createOCRA,
    // updateOCRA,
    //getAllOCRA,
    // getMyOCRA,
    // deleteOCRA,
    // assignOCRA,
    // revokeOCRA,
    // revokeMyOCRA,
    // reserveOCRA,
    // unreserveOCRA,
    // getPukCode,
    // doOcraDiagnosis,
    getRoles,
    changeRoles,
    deleteUser,
    adminDeleteUser,
    adminDeactivateUser,
    sendEmail,
  };
}
