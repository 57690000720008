import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box } from '@mui/material';

import { InputChangeMethod, InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

interface TextInputProps
  extends InputProps<
    { label: any; value: string | ({ id: string } & Record<string, any>) } & Record<string, any>
  > {
  options: Array<{ label: ReactNode; value: any } & Record<string, any>>;
  onSearch?: (_e: InputChangeMethod<string>) => void;
  search?: string;
  creatable?: boolean;
}

export default function AutocompleteInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  // specifics
  options,
  onSearch,
  search,
  creatable,
}: TextInputProps) {
  const { t } = useTranslation();

  const handleChange = (e, newVal) => {
    onChange({ target: { value: newVal || '' } });
  };

  return (
    <Box>
      <Autocomplete
        size={size || 'medium'}
        id={name}
        data-testid={name}
        value={value}
        onChange={handleChange}
        fullWidth
        onBlur={onBlur}
        disabled={disabled}
        options={options}
        freeSolo={creatable}
        renderInput={(params: any) => (
          <TextFieldSx
            {...params}
            value={search}
            variant='outlined'
            error={error?.length > 0}
            helperText={t(error)}
            required={required}
            onChange={onSearch}
            label={label}
            placeholder={placeholder}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component='li'
            {...props}
            key={
              ['string', 'number'].includes(typeof option.value) ? option.value : option.value?.id
            }
          >
            {option.label}
          </Box>
        )}
      />
    </Box>
  );
}
