import { formatDistanceToNowStrict } from 'date-fns';
import { enUS, sk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import URLTransform from 'service/defaultServices/URLTransform';

import { NotificationManager } from 'components/NotifyBox';

import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { INotification } from './NotificationBox';

import css from './HeaderNotifications.module.css';

const ThemeIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  transition: '0.3s',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

interface IProps {
  notification: INotification;
  index: number;
  readNotify: (_idx: number) => void;
  closeNotificationBox?: () => void;
}

export default function Notification(props: IProps) {
  const { closeNotificationBox } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const date = props.notification.sentDate
    ? formatDistanceToNowStrict(new Date(props.notification.sentDate), {
        addSuffix: true,
        locale: i18n.language === 'sk' ? sk : enUS,
      })
    : t('NOTIFY.NO_DATE');

  const msgTypes = {
    WELCOME: { url: 'MY_PROFILE' },
    POLICY_CHANGE: {
      onClick: () => window.open(URLTransform('notification_policy'), '_blank'),
    },
    //
    MESSAGE_NEW: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_ALL_SIGNED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    }, // OLD
    MESSAGE_ALL_ACCEPTED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    }, // OLD
    MESSAGE_COMPLETED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_COMPLETED_ACCEPTED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_NEED_ACCEPTANCE: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_REFUSED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_FORWARDED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    },
    MESSAGE_QUALIFIED_NEW: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    }, // asi
    MESSAGE_DEADLINE_MISSED: {
      url: 'ELECTRONIC_MAILBOX_DETAIL',
      urlReplacement: 'messageId',
    }, // asi
    //
    CERT_CREATED: {
      url: 'MY_CERTIFICATE_DETAIL',
      urlReplacement: 'certificateId',
    },
    CERT_DENIED: {
      url: 'MY_CERTIFICATE_REQUEST_DETAIL',
      urlReplacement: 'certificateId',
    },
    CERT_RETURNED: {
      url: 'MY_CERTIFICATE_REQUEST_DETAIL',
      urlReplacement: 'certificateId',
    },
    CERT_EXPIRING: {
      url: 'MY_CERTIFICATE_DETAIL',
      urlReplacement: 'certificateId',
    },
    CERT_PROLONGED: {
      url: 'MY_CERTIFICATE_REQUEST_DETAIL',
      urlReplacement: 'certificateId',
    },
    CERT_REVOKED: {
      url: 'MY_CERTIFICATE_DETAIL',
      urlReplacement: 'certificateId',
    },
    //
    INVOICE_PROFORMA_SEND: { url: 'PAYMENT_DETAIL', urlReplacement: 'orderId' },
    INVOICE_SEND: { url: 'PAYMENT_DETAIL', urlReplacement: 'orderId' },
    INVOICE_FIRST_REMINDER: {
      url: 'PAYMENT_DETAIL',
      urlReplacement: 'orderId',
    },
    INVOICE_SECOND_REMINDER: {
      url: 'PAYMENT_DETAIL',
      urlReplacement: 'orderId',
    },
    INVOICE_UNPAID_CANCELED: {
      url: 'PAYMENT_DETAIL',
      urlReplacement: 'orderId',
    },
    //
    COMPANY_INVITATION_NEW: { url: 'COMPANIES_LIST' },
    COMPANY_INVITATION_REFUSED: { url: 'COMPANIES_LIST' },
    COMPANY_MEMBER_REMOVED: { url: 'COMPANIES_LIST' },
    COMPANY_ROLE_CHANGE: {
      url: 'COMPANIES_DETAIL',
      urlReplacement: 'companyId',
    },
    COMPANY_MEMBER_LEFT: {
      url: 'COMPANIES_DETAIL',
      urlReplacement: 'companyId',
    },
    COMPANY_APPROVED: { url: 'COMPANIES_DETAIL', urlReplacement: 'companyId' },
    COMPANY_REJECTED: { url: 'COMPANIES_DETAIL', urlReplacement: 'companyId' },
    COMPANY_RETURNED: { url: 'COMPANIES_DETAIL', urlReplacement: 'companyId' },
    COMPANY_BLOCKED: { url: 'COMPANIES_DETAIL', urlReplacement: 'companyId' },
    COMPANY_TERMINATED: {
      url: 'COMPANIES_DETAIL',
      urlReplacement: 'companyId',
    },
    //
    NEW_DEVICE: { url: 'DEVICE_MANAGEMENT' },
    CERT_HUB_NEW_CERT: { url: 'MY_CERTIFICATE_REQUEST_DETAIL', urlReplacement: 'certificateId' },
    CERT_HUB_ADDED_AS_MEMBER: { url: 'COMPANIES_DETAIL', urlReplacement: 'companyId' },
    // USER_DELETED: {},
  };

  const messageClickHandler = () => {
    if (!props.notification.isRead) props.readNotify(props.index);

    const msgTypeObj = msgTypes[props.notification.notificationKey];
    if (!msgTypeObj) return;

    if (msgTypeObj.url) {
      let url = t(`URL.${msgTypeObj.url}`);
      if (msgTypeObj.urlReplacement) {
        if (!props.notification.params) {
          NotificationManager.error(t('NOTIFY.ERROR'));
          return;
        }
        url = url.replace(':id', props.notification.params[msgTypeObj.urlReplacement]);
      }
      navigate(url);
      closeNotificationBox?.();
    } else if (msgTypeObj.onClick) {
      msgTypeObj.onClick();
    }
  };
  console.log(props.notification);
  return (
    <Box
      className={`${css.message} ${props.notification.isRead ? '' : css.unread} ${
        msgTypes[props.notification.notificationKey]?.url ? css.clickable : ''
      }`}
      sx={{ '&:hover': { backgroundColor: 'background.paper' } }}
    >
      <Box className={css.innerMessage}>
        <Box className={css.messageText} onClick={messageClickHandler}>
          <Box
            className={`${css.nmTitle} ${css[props.notification.notificationKey]}`}
            color={
              props.notification.notificationKey === 'MESSAGE_NEW' ||
              props.notification.notificationKey === 'MESSAGE_ALL_SIGNED'
                ? 'primary.main'
                : 'text.primary'
            }
          >
            {t(`NOTIFY_TYPE.${props.notification.notificationKey}`)}
          </Box>

          <Box
            className={css.nmDesc}
            color='text.secondary'
            sx={{ '& span': { color: 'primary.main' } }}
            dangerouslySetInnerHTML={{
              __html: t(`NOTIFY_TYPE.${props.notification.notificationKey}_TEXT`)
                .replace('{sender}', `<span>${props.notification.params?.sender}</span>`)
                .replace('{subject}', `<span>${props.notification.params?.messageSubject}</span>`)
                .replace('{deviceName}', `<span>${props.notification.params?.deviceName}</span>`)
                .replace('{companyName}', `<span>${props.notification.params?.companyName}</span>`)
                .replace('{memberName}', `<span>${props.notification.params?.memberName}</span>`)
                .replace('{customer}', `<span>${props.notification.params?.customer}</span>`)
                .replace('{hubCompany}', `<span>${props.notification.params?.hubCompany}</span>`)
                .replace(
                  '{limit_type}',
                  `<span>${t(`NOTIFY_LIMIT.${props.notification.params?.type?.toString()}`)}</span>`
                )
                .replace(
                  '{limit_remain}',
                  `<span>${props.notification.params?.limit?.toString()}</span>`
                ),
            }}
          />
          <Box
            className={css.nmDesc}
            color={
              props.notification.notificationKey === 'MESSAGE_NEW' ||
              props.notification.notificationKey === 'MESSAGE_ALL_SIGNED'
                ? 'primary.main'
                : 'text.primary'
            }
            dangerouslySetInnerHTML={{ __html: date }}
          />
        </Box>

        <Box className={css.messageReadButton}>
          {!props.notification.isRead && (
            <ThemeIcon
              icon='fa:circle'
              className={css.nmI}
              onClick={() => props.readNotify(props.index)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
