import sendRequestFunctions from 'service/common/sendRequestFunctions';

export default function twoFaOrchestratorServiceFunction() {
  const { sendRequestNoBody } = sendRequestFunctions(process.env.REACT_APP_ORCHESTRATOR_API_URL);

  // -------------------------------------------------------------------------------------------------------------------------

  function generateCode(id: string, userId): Promise<string> {
    return sendRequestNoBody(`/ocra/v3/bo/challenge/${id}/${userId}`, 'GET');
  }

  return {
    generateCode,
  };
}
