import { ElementType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SlideButton } from 'components/Buttons';
import { InputLabel, InputProps, TextInput, UseInputProps } from 'components/Input';
import { NotificationManager } from 'components/NotifyBox';

import { Box, Link } from '@mui/material';

import { MButtons } from './ModalButtonDTO';
import ModalDialog from './ModalDialog';

interface InputDialogProps {
  showModal: boolean;
  title: string;
  close: (_submit?: boolean) => void;
  message?: any;
  messagePd?: string;
  button: MButtons<Array<any>>; //MButtons<Array<UseInputProps<string> | any>>;
  secondaryButton?: MButtons<Array<any>>; //MButtons<Array<UseInputProps<string> | any>>;
  inputs: Array<
    UseInputProps<string> & {
      input?: ElementType<InputProps<any>>;
      label?: string;
      required?: boolean;
      extraInputProps?: Record<string, any>;
    }
  >;
  disableClose?: boolean;
  doNotReset?: boolean;
}

export default function InputDialog(props: Readonly<InputDialogProps>) {
  const { t } = useTranslation();
  const { showModal, inputs, doNotReset } = props;

  const [onlyOnce, setOnlyOnce] = useState(false);

  useEffect(() => {
    setOnlyOnce(false);
  }, [showModal]);

  useEffect(() => {
    if (!onlyOnce) {
      if (doNotReset) {
        return;
      }
      if (showModal && inputs) {
        inputs.forEach((input) => {
          if (input && input.reset) input.reset();
        });
      }
      setOnlyOnce(true);
    }
  }, [showModal, onlyOnce, inputs, doNotReset]);

  const submit = () => {
    let valid = true;
    const reverseInputs = [...props.inputs].reverse();
    reverseInputs.forEach((input) => {
      if (input && input.hasError?.()) {
        valid = false;
      }
    });

    if (valid) {
      if (props.button.onClick) props.button.onClick(inputs);
      if (props.disableClose) {
        //
      } else {
        props.close(true);
      }
    } else NotificationManager.warning(t('MODAL.FILL_ALL_INPUTS'));
  };

  return (
    <ModalDialog close={() => props.close(false)} showModal={props.showModal} title={props.title}>
      <Box textAlign='center' sx={{ padding: props.messagePd || '16px' }}>
        {props.message && typeof props.message !== 'string' && <Box>{props.message}</Box>}
        {props.message && typeof props.message === 'string' && (
          <Box dangerouslySetInnerHTML={{ __html: props.message }} />
        )}
      </Box>
      <Box pt={3} sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
        {props.inputs.map((input) => {
          if (input) {
            const { input: InputComponent, extraInputProps, ...inputProps } = input;
            return (
              <Box key={input.name} pb={2} textAlign={'center'}>
                {input.label && (
                  <InputLabel id={input.name} required={input.required}>
                    {input.label}
                  </InputLabel>
                )}
                {!InputComponent && <TextInput {...inputProps} label={undefined} />}
                {InputComponent && (
                  <InputComponent {...inputProps} {...extraInputProps} label={undefined} />
                )}
              </Box>
            );
          } else {
            return null;
          }
        })}
      </Box>
      <Box pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <SlideButton
          id={props.button.id}
          style={{
            padding: '12px 26px',
          }}
          key={props.button.label}
          onClick={submit}
        >
          {t('OTP.CONFIRM')}
        </SlideButton>
      </Box>

      {props.secondaryButton && (
        <Box p={1} pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ marginRight: '4px' }}>{t('OTP.RESEND_CODE_PROMPT')}</p>
          <Link
            component='button'
            variant='body2'
            onClick={() => {
              props.secondaryButton.onClick(inputs);
            }}
          >
            {t('OTP.RESEND_CODE')}
          </Link>
        </Box>
      )}
    </ModalDialog>
  );
}
