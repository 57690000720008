import { t } from 'i18next';
import { ReactNode } from 'react';

import { NotifyType } from './Notify';

const DEFAULT_TIME = 6000;

interface NotifyConfig {
  time?: number;
  link?: string;
  linkTarget?: '_blank';
  onClick?: () => void;
}

let addFunction: any = undefined;

function AddNotify(notify: NotifyType) {
  addFunction?.(notify);
}

function SetNotifyFunction(func: (_notify: NotifyType) => void) {
  addFunction = func;
}

const NotificationManager = {
  success: (text: ReactNode | any, config?: NotifyConfig) => {
    AddNotify({
      type: 'success',
      text: t(text),
      time: config?.time || DEFAULT_TIME,
      link: config?.link,
      linkTarget: config?.linkTarget,
      onClick: config?.onClick,
    });
  },
  error: (text: ReactNode | any, config?: NotifyConfig) => {
    AddNotify({
      type: 'error',
      text: t(text),
      time: config?.time || DEFAULT_TIME,
      link: config?.link,
      linkTarget: config?.linkTarget,
      onClick: config?.onClick,
    });
  },
  warning: (text: ReactNode | any, config?: NotifyConfig) => {
    AddNotify({
      type: 'warning',
      text: t(text),
      time: config?.time || 6000,
      link: config?.link,
      linkTarget: config?.linkTarget,
      onClick: config?.onClick,
    });
  },
  info: (text: ReactNode | any, config?: NotifyConfig) => {
    AddNotify({
      type: 'info',
      text: t(text),
      time: config?.time || DEFAULT_TIME,
      link: config?.link,
      linkTarget: config?.linkTarget,
      onClick: config?.onClick,
    });
  },
};

export { AddNotify, SetNotifyFunction };

export default NotificationManager;
