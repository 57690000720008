import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';

/*
    Tento komponent sluzi ak nahrada za MUI Tooltip

    Pridava funkciu, ze ak je tooltip pouzity v "overflow" (napr. button tooltip v scrollovacom okne) tak tooltip nepretrca mimo toho okna

    Pouzitie je uplne rovnake ako pri klasickom MUI tooltipe
*/

export default function Tooltip({ PopperProps, ...props }: TooltipProps) {
  return (
    <MUITooltip
      {...props}
      PopperProps={{
        ...PopperProps,
        sx: {
          ...PopperProps?.sx,
          '&[data-popper-reference-hidden]': {
            ...PopperProps?.sx?.['&[data-popper-reference-hidden]'],
            visibility: 'hidden',
            pointerEvents: 'none',
          },
        },
      }}
    />
  );
}
