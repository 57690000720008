export const URL_SK = {
  URL: {
    HOME: '/domov',
    ADMIN_DASHBOARD: '/admin-dashboard',
    VERIFIED: '/ucet-overeny',
    PASS_RESET_SUCCESS: '/reset-hesla-uspech',
    SIGN_DOCUMENT_REDIRECT: '/podpisat-dokument/presmerovanie',
    SIGN_DOCUMENT: '/podpisat-dokument',
    SIGN_DOCUMENT_WITH_TYPE: '/podpisat-dokument/:type',
    VALIDATE_DOCUMENT: '/online-validacia-kvalifikovaneho-elektronickeho-podpisu-KEP',
    ELECTRONIC_MAILBOX_DETAIL: '/elektronicka-schranka/sprava/:id',
    ELECTRONIC_MAILBOX_PUBLIC_DETAIL:
      'elektronicka-schranka/sprava/:messageUuid/user/:recipientUuid',
    ELECTRONIC_MAILBOX_PUBLIC_DOWNLOAD:
      'elektronicka-schranka/sprava/stiahnut/:messageUuid/user/:recipientUuid',
    ELECTRONIC_MAILBOX_NEW: '/elektronicka-schranka/nova-sprava',
    ELECTRONIC_MAILBOX_NEW_TYPE: '/elektronicka-schranka/nova-sprava/:type',
    ELECTRONIC_MAILBOX_PUBLIC_NEW: '/elektronicka-schranka/nova-verejna-sprava',
    ELECTRONIC_MAILBOX_PUBLIC_NEW_TYPE: '/elektronicka-schranka/nova-verejna-sprava/:type',
    ELECTRONIC_MAILBOX_RECEIVED: '/elektronicka-schranka/prijate-spravy',
    ELECTRONIC_MAILBOX_SENT: '/elektronicka-schranka/odoslane-spravy',
    ELECTRONIC_MAILBOX_CONCEPT: '/elektronicka-schranka/rozpracovane-spravy',
    ELECTRONIC_MAILBOX_REMOVED: '/elektronicka-schranka/kos',
    ELECTRONIC_MAILBOX: '/elektronicka-schranka',
    ELECTRONIC_MAILBOX_BOARDS: '/elektronicka-schranka/nastenka',
    ELECTRONIC_MAILBOX_CONTACTS: '/elektronicka-schranka/kontakty',
    CERTIFICATES_MENU: '/eidas',
    MY_CERTIFICATES_ONLINE: '/eidas/moje-certifikaty/online-certifikaty',
    MY_CERTIFICATES_DOWNLOADABLE: '/eidas/moje-certifikaty/stiahnutelne-certifikaty',
    MY_CERTIFICATES_CARD: '/eidas/moje-certifikaty/QSCD-karty',
    MY_CERTIFICATES_CARD_DETAIL: '/eidas/moje-certifikaty/QSCD-karty/:id',
    MY_CERTIFICATES_TLS: '/eidas/moje-certifikaty/TLS-certifikaty',
    MY_CERTIFICATES_REQUESTS: '/eidas/moje-certifikaty/ziadosti-o-certifikat',
    GENERATE_REQUEST_WITHOUT_ID: '/eidas/vytvorit-certifikat',
    GENERATE_REQUEST_COMPANY_USER: '/eidas/vytvorit-certifikat/spolocnost/:idComp',
    GENERATE_REQUEST_WITHOUT_ID_CARD: '/eidas/vytvorit-certifikat/karta/:cardId',
    GENERATE_REQUEST_WITHOUT_ID_CARD_NEW: '/eidas/vytvorit-certifikat/karta',
    MY_CERTIFICATE_DETAIL: '/eidas/moje-certifikaty/:id',
    MY_CERTIFICATE_REQUEST_DETAIL: '/eidas/moje-certifikaty/ziadosti-o-certifikat/:id',
    CERTIFICATES_ONLINE: '/eidas/zoznam-certifikatov/online-certifikaty',
    CERTIFICATES_DOWNLOADABLE: '/eidas/zoznam-certifikatov/stiahnutelne-certifikaty',
    CERTIFICATES_CARD: '/eidas/zoznam-certifikatov/QSCD-karty',
    CERTIFICATES_TLS: '/eidas/zoznam-certifikatov/TLS-certifikaty',
    CERTIFICATES_CARD_DETAIL: '/eidas/zoznam-certifikatov/QSCD-karty/:id',
    CERTIFICATE_REQUESTS: '/eidas/zoznam-certifikatov/ziadosti-o-certifikat',
    GENERATE_REQUEST: '/eidas/vytvorit-certifikat/:id',
    GENERATE_REQUEST_COMPANY_ADMIN: '/eidas/vytvorit-certifikat/:id/spolocnost/:idComp',
    GENERATE_REQUEST_COMPANY_FOR_USER:
      '/eidas/vytvorit-certifikat/spolocnost/:idComp/member/:member/oddelenie/:ou',
    GENERATE_REQUEST_COMPANY_FOR_ADMIN:
      '/eidas/vytvorit-certifikat/uzivatel/:id/spolocnost/:idComp/member/:member/oddelenie/:ou',
    GENERATE_REQUEST_CARD: '/eidas/vytvorit-certifikat/:id/karta/:cardId',
    GENERATE_REQUEST_CARD_NEW: '/eidas/vytvorit-certifikat/:id/karta',
    CERTIFICATE_DETAIL: '/eidas/zoznam-certifikatov/:id',
    CERTIFICATE_REQUEST_DETAIL: '/eidas/zoznam-certifikatov/ziadosti-o-certifikat/:id',
    USER_MANAGEMEN: '/sprava-uzivatelov',
    USER_MANAGEMENT: '/sprava-uzivatelov/vsetci',
    CERTIFICATE_HUB: '/certificate-hub',
    CERTIFICATE_HUB_LIST: '/certificate-hub/zoznam',
    CERTIFICATE_HUB_DETAIL: '/certificate-hub/detail/:id',
    CERTIFICATE_HUB_CERTIFICATE: '/certificate-hub/certifikaty',
    CERTIFICATE_HUB_COMPANIES: '/certificate-hub/spolocnost',
    CERTIFICATE_HUB_QSCD_CARD: '/certificate-hub/qscd-karty',
    CERTIFICATE_HUB_CREATE_USER: '/certificate-hub/vytvorit-pouzivatela/:id',
    CERTIFICATE_HUB_CREATE_COMPANY: '/certificate-hub/vytvorit-spolocnosti/:id',
    CERTIFICATE_HUB_CREATE_CERTIFICATE: '/certificate-hub/vytvorit-certifikatu/:id',
    CERTIFICATE_HUB_REVOKE_CERT: '/certificate-hub/zrusene-certifikaty',
    USER_MANAGEMENT_NEW: '/sprava-uzivatelov/novy-uzivatel',
    MANUALS_DETAIL: '/navody/:section/:manual',
    MANUALS_SECTION: '/navody/:section',
    MANUALS: '/navody',
    REGISTER: '/registracia',
    REGISTER_UUID: '/registracia/:id',
    REGISTER_WORKER: '/pracovnik-registracia',
    REGISTER_SUCCESS: '/registracia/uspesna',
    REGISTER_SUCCESS_ID: '/registracia/uspesna/:id',
    CONTACT: '/kontakt',
    TRUSTED_LIST: '/doveryhodny-zoznam',
    TRUSTED_LIST_DETAIL: '/doveryhodny-zoznam/:id',
    COOKIES: '/nastavenie-cookies',
    MY_PROFILE: '/moj-ucet/profil',
    PROFILE: '/profil-uzivatela/:id',
    MY_ACCOUNT: '/moj-ucet',
    COMPANIES_LIST: '/moje-spolocnosti',
    COMPANIES_LIST_ADMIN: '/admin/spolocnosti',
    COMPANIES_CREATE_WITHOUT_ID: '/moje-spolocnosti/vytvorenie-spolocnosti',
    COMPANIES_CREATE: '/moje-spolocnosti/vytvorenie-spolocnosti/:id',
    COMPANIES_DETAIL: '/moje-spolocnosti/detail-spolocnosti/:id',
    COMPANIES_DETAIL_ADMIN: '/moje-spolocnosti/admin/detail-spolocnosti/:id',
    COMPANIES_APPROVAL_DETAIL_ADMIN:
      '/moje-spolocnosti/admin/detail-spolocnosti/:companyId/schvalenie/:id',
    COMPANIES_UPDATE: '/moje-spolocnosti/uprava-spolocnosti/:id',
    COMPANIES_UPDATE_ADMIN: '/moje-spolocnosti/admin/uprava-spolocnosti/:id',
    HW_TOKEN_REQUEST: '/moj-ucet/hw-token',
    PAYMENTS: '/moj-ucet/platby',
    PAYMENTS_ADMIN: '/platby',
    PAYMENT_DETAIL: '/moj-ucet/platby/:id',
    PAYMENT_DETAIL_ADMIN: '/platby/:id',
    PAYMENT_SUCCESS: '/platba-uspesna/:id',
    PAYMENT_FAIL: '/platba-neuspesna/:id',
    DEVICE_MANAGEMENT: '/moj-ucet/sprava-zariadeni',
    DEVICE_MANAGEMENT_ADMIN: '/sprava-zariadeni',
    REGISTER_DEVICE: '/sprava-zariadeni/nove/:id',
    REGISTER_DEVICE_WITHOUT_ID: '/moj-ucet/sprava-zariadeni/nove',
    SUBSCRIBE_INFO: '/predplatne',
    SUBSCRIBE_INFO_ADMIN: '/admin/predplatne',
    SUBSCRIBE: '/predplatne/vytvorit/:id',
    SUBSCRIBE_UPDATE: '/predplatne/zmenit/:id/:subscriptionId',
    SUBSCRIBE_BUY: '/predplatne/kupit/:level',
    SIGNED_OUT: '/automaticke-odhlasenie',
    NOT_FOUND: '/stranka-nenajdena',
    OCRA_MANAGEMENT: '/sprava-ocra-tokenov',
    DOWNLOAD_WEB_SIGNER: '/stiahnut-nfqes-desktop-toolkit',
    NOTIFICATIONS_SETTINGS: '/nastavenie/notifikacie',
    TIMESTAMP_INFO: '/casove-peciatky',
    TIMESTAMP_BUY_ID: '/casove-peciatky/kupit/:id',
    TIMESTAMP_BUY: '/casove-peciatky/kupit',
    TIMESTAMP_API_ADMIN: '/timestamp-api-konta',
    HISTORY: '/historia-podpisovania',
    TB_TEST: '/moj-ucet/test-platba',
    PAYMENT_DETAIL_TEST: '/moj-ucet/test-platba/:id',
    ANNOUNCEMENT_MANAGEMENT: '/oznamy/spravovat',
    API: '/api-konta',
    ANNOUNCEMENT_DETAIL: '/oznamy/spravovat/:id',
    ANNOUNCEMENT_CREATE_WITHOUT_ID: '/oznamy/spravovat/novy',
    ANNOUNCEMENT_SEND_EMAIL: '/oznamy/spravovat/hromadny-email',
    GRAPHICAL_SIGN: '/graficky-podpis',
    GRAPHICAL_SIGN_CREATE_NEW: '/graficky-podpis/novy/:id',
    SUPPORT: '/podpora',
    NO_SUBSCRIPTION: '/nemate-pristup',
    QUALIFIED_ARCHIVE: '/archiv-dokumentov',
    QUALIFIED_ARCHIVE_LIST: '/archiv-dokumentov/archiv',
    QUALIFIED_ARCHIVE_NEW: '/archiv-dokumentov/archivovanie-dokumentu',
    QUALIFIED_ARCHIVE_VALIDATE_NEW: '/archiv-dokumentov/overenie-dokumentu',
    QUALIFIED_ARCHIVE_VALIDATE_HASH: '/archiv-dokumentov/overenie-dokumentu/:id',
    EL_STORAGE_IDSK: '/elektronicka-schranka-sk',
    EZZK_NEW_RECORD: '/ezzk/novy-zaznam',
    EZZK_NEW_RECORD_TYPE: '/ezzk/novy-zaznam/:type',
    PHONE_VERIFICATION: '/overenie-telefoneho-cisla',
  },
  DASHBOARD_ROUTES: {
    HOME_PAGE: 'Domovská obrazovka',
    ADMIN_DASHBOARD: 'Admin dashboard',
    VERIFIED: 'Účet overený',
    PASS_RESET_SUCCESS: 'Resetovanie hesla úspešné',
    SIGN_DOCUMENT: 'Podpísať dokument',
    VALIDATE_DOCUMENT: 'Overiť dokument',
    GRAPHICAL_SIGN: 'Grafický podpis',
    ELECTRONIC_MAILBOX: 'Elektronická schránka',
    ELECTRONIC_MAILBOX_RECEIVED: 'Inbox',
    ELECTRONIC_MAILBOX_SENT: 'Odoslané správy',
    ELECTRONIC_MAILBOX_CONCEPT: 'Rozprac. správy',
    ELECTRONIC_MAILBOX_REMOVED: 'Kôš',
    ELECTRONIC_MAILBOX_BOARDS: 'Nástenka',
    ELECTRONIC_MAILBOX_CONTACTS: 'Kontakty',
    CLOUD_STORAGE: 'Cloudové úložisko',
    CERTIFICATES_ONLINE: 'Vzdialené certifikáty',
    CERTIFICATES_DOWNLOADABLE: 'Stiahnuteľné certifikáty',
    CERTIFICATES_CARD: 'QSCD karty',
    CERTIFICATES_TLS: 'TLS certifikáty',
    MY_CERTIFICATES: 'Moje certifikáty',
    CERTIFICATES: 'Certifikáty',
    CERTIFICATE_REQUESTS: 'Žiadosti o certifikát',
    GENERATE_REQUEST: 'Vytvoriť certifikát',
    USER_MANAGEMEN: 'Správa používateľov',
    USER_MANAGEMENT: 'Správa používateľov',
    CERTIFICATE_HUB: 'Certificate hub',
    CERTIFICATE_HUB_LIST: 'Používatelia',
    CERTIFICATE_HUB_CERTIFICATE: 'Certifikáty',
    CERTIFICATE_HUB_REVOKE_CERT: 'Zrušené certifikáty',
    CERTIFICATE_HUB_QSCD_CARD: 'Qscd karty',
    CERTIFICATE_HUB_CREATE_USER: 'Vytvoriť používateľa',
    CERTIFICATE_HUB_CREATE_COMPANY: 'Vytvoriť spoločnost',
    CERTIFICATE_HUB_CREATE_CERTIFICATE: 'Vytvoriť certifikát',
    CERTIFICATE_HUB_COMPANIES: 'Spoločnosti',
    USER_MANAGEMENT_NEW: 'Nový používateľ',
    MANUALS: 'Návody',
    MY_ACCOUNT: 'Môj účet',
    CERTIFICATE_DETAIL: 'Detail certifikátu',
    CERTIFICATE_REQUEST_DETAIL: 'Detail žiadosti o certifikát',
    CERTIFICATE_CHANGE_STATE: 'Zmena stavu certifikátu',
    REGISTER: 'Registrovať',
    LOGIN: 'Prihlásiť',
    LOGOUT: 'Odhlásiť',
    MY_PROFILE: 'Moje údaje',
    GDPR: 'GDPR',
    GENERAL_CONDITIONS: 'Všeobecné podmienky',
    ELECTRONIC_SIGN_CARD: 'Elektronický občianský preukaz',
    POLITICS: 'Politiky a podmienky',
    PRICING: 'Cenník',
    CONTACT: 'Kontakt',
    TRUSTED_LIST: 'Dôveryhodný zoznam',
    TRUSTED_LIST_DETAIL: 'Dôveryhodný zoznam - detail',
    COOKIES: 'Cookies',
    CHANGE_LANGUAGE: 'Zmeniť jazyk',
    NFQES: 'NFQES',
    COMPANIES_LIST: 'Spoločnosti',
    MY_COMPANIES_LIST: 'Moje spoločnosti',
    COMPANIES_CREATE: 'Vytvorenie spoločnosti',
    COMPANIES_DETAIL: 'Detail spoločnosti',
    COMPANIES_UPDATE: 'Úprava spoločnosti',
    COMPANIES_APPROVAL_DETAIL: 'Detail schválenia spoločnosti',
    ELECTRONIC_MAILBOX_NEW: 'Nová správa',
    ELECTRONIC_MAILBOX_PUBLIC_NEW: 'Nová verejná správa',
    ELECTRONIC_MAILBOX_DETAIL: 'Detail správy',
    REGISTER_SUCCESS: 'Registrácia úspešná',
    SUBSCRIBE: 'Subscribe',
    SUBSCRIBE_INFO: 'Predplatné',
    SUBSCRIBE_MANAGEMENT: 'Žiadosti o predplatné',
    SIGNED_OUT: 'Odhlásiť',
    OCRA_MANAGEMENT: 'Správa NFQES HW tokenov',
    USER: 'Používateľ',
    WORKER: 'Pracovník',
    MANAGER: 'Manažér',
    ADMIN: 'Administrátor',
    BRAINIT: 'Brain:IT',
    LOGREG: 'Prihlásiť/Registrovať',
    DOWNLOAD_WEB_SIGNER: 'Stiahnuť NFQES Desktop toolkit',
    PAYMENTS: 'Platby',
    DEVICE_MANAGEMENT: 'Správa zariadení',
    DEVICE_MANAGEMENT_2: 'Moje zariadenia',
    REGISTER_DEVICE: 'Pridať nové zariadenie',
    TIMESTAMP_INFO: 'Časové pečiatky',
    TIMESTAMP_API_ADMIN: 'Timestamp API kontá',
    API: 'Moje API kontá',
    HISTORY: 'História podpisovania',
    ANNOUNCEMENT_MANAGEMENT: 'Správa oznamov',
    ANNOUNCEMENT_DETAIL: 'Detail Oznamu',
    ANNOUNCEMENT_CREATE: 'Vytvorenie Oznamu',
    ANNOUNCEMENT_SEND_EMAIL: 'Hromadný email',
    HW_TOKEN_REQUEST: 'Žiadosť o HW token',
    GRAPHICAL_SIGN_TYPE: 'Typ grafického podpisu',
    GRAPHICAL_SIGN_CREATE_NEW: 'Vytvorenie grafického podpisu',
    QUALIFIED_ARCHIVE: 'Kvalifikovaný archív',
    QUALIFIED_ARCHIVE_LIST: 'Archív dokumentov',
    QUALIFIED_ARCHIVE_NEW: 'Archivovanie dokumentu',
    QUALIFIED_ARCHIVE_HASH: 'Archivovanie hash-u',
    QUALIFIED_ARCHIVE_DOCUMENT: 'Archivovanie dokumentu',
    QUALIFIED_ARCHIVE_VALIDATE_NEW: 'Overenie súboru',
    QUALIFIED_ARCHIVE_VALIDATE_HASH: 'Overenie hashu',
    QUALIFIED_ARCHIVE_VALIDATE_DOCUMENT: 'Overenie dokumentu',
    EZZK: 'Zaručené konverzie',
  },
};
