// @mui
import { styled } from '@mui/material/styles';
import { ListItemButton, ListItemIcon } from '@mui/material';

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub }) => ({
  cursor: 'pointer',
  color: '#fff !important',
  fontSize: '12px',
  textTransform: 'uppercase',
  fontFamily: "'Roboto', 'Helvetica Neue', Arial, sans-serif",
  margin: '8px',
  padding: '6px 12px !important',
  backgroundColor: 'rgba(255, 255, 255, 0)',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff;',
  },
  ...(activeRoot && {
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
      color: '#fff;',
    },
  }),
  ...(activeSub && {
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
      color: '#fff;',
    },
  }),
  height: 31.5,
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: '18px !important',
  height: '18px !important',
  display: 'flex !important',
  color: '#fff',
  alignItems: 'center !important',
  justifyContent: 'start !important',
  fontSize: '16px',
  minWidth: 0,
  marginTop: '-3px',
  marginRight: '10px',
  '& svg ': {
    width: '18px !important',
    height: '18px !important',
  },
});
