import { InputLabel } from 'components/Labels';

import { Box, Slider } from '@mui/material';

interface InputProps {
  label?: string;
  required?: boolean;
  handleChange: (_value: any) => void;
  handleBlur?: (_value: any) => void;
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  value?: number;
}

export function SliderInput(props: InputProps) {
  return (
    <Box>
      {props.label?.length > 0 && (
        <InputLabel size={2} required={props.required}>
          {props.label}
        </InputLabel>
      )}
      <Slider
        size='small'
        defaultValue={props.defaultValue}
        value={props.value}
        aria-label='Small'
        valueLabelDisplay='auto'
        min={props.minValue}
        max={props.maxValue}
        onChange={props.handleChange}
        onBlur={props.handleBlur || (() => {})}
      />
    </Box>
  );
}
