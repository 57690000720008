import { Fragment, useContext } from 'react';

import { userCtx } from 'context/context';
import useBreakpoint from 'hooks/useBreakpoint';

import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

interface IProps {
  hiddenMenu?: boolean;
}

function Header({ hiddenMenu }: IProps) {
  const { subscription } = useContext(userCtx);
  const isPhone = useBreakpoint(900);

  return (
    <Fragment>
      {/*///////////////MOBIL/////////////////*/}
      {isPhone && (
        <div>
          <MobileNavbar subscription={subscription} />
        </div>
      )}
      {/*///////////////PC/////////////////*/}
      {!isPhone && (
        <div>
          <DesktopNavbar subscription={subscription} hiddenMenu={hiddenMenu} />
        </div>
      )}
    </Fragment>
  );
}

export default Header;
