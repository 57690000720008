import { ReactNode, useMemo, useState } from 'react';

import { loadingCtx as load } from 'context/context';

import { LoadingOverlay } from 'components/Overlay';

interface LoadingProviderProps {
  children: ReactNode;
}

export function LoadingProvider({ children }: Readonly<LoadingProviderProps>) {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number>();
  const [total, setTotal] = useState<number>();
  // Použitie
  // pridaj const { setLoading } = useContext(loadingCtx);
  // setLoading(true); keď pre zobrazenie loadera
  // setLoading(false); pre skrytie

  const value = useMemo(
    () => ({ loading, count, total, setLoading, setCount, setTotal }),
    [loading, count, total, setLoading, setCount, setTotal]
  );

  return (
    <load.Provider value={value}>
      <LoadingOverlay
        loading={loading}
        setCount={setCount}
        count={count}
        setTotal={setTotal}
        total={total}
      >
        {children}
      </LoadingOverlay>
    </load.Provider>
  );
}
