import { useTranslation } from 'react-i18next';

import { NotificationManager } from 'components/NotifyBox';

import IconButton from './IconButton';

interface CopyButtonProps {
  /**
   * Text to copy in clipboard
   */
  text: string;
  /**
   * Farba tlacidla
   */
  color?:
    | 'info'
    | 'error'
    | 'success'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary';
  /**
   * Velkost tlacidla
   */
  width?: number;
  /**
   * Velkost tlacidla
   */
  height?: number;
}

/**
 * Komponent je wrapper na IconButton, ktory poskytuje jednotnu ikonu a funkciu pre kopirovanie do schranky
 * @param param0
 * @returns
 */
export default function CopyButton({ text, color, width, height }: Readonly<CopyButtonProps>) {
  const { t } = useTranslation();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    NotificationManager.success('DEFAULT.COPIED_TO_CLIPBOARD');
  };

  return (
    <IconButton
      icon='gravity-ui:copy'
      width={width}
      height={height}
      color={color}
      tooltip={t('DEFAULT.COPY_TO_CLIPBOARD')}
      onClick={handleCopyClick}
    />
  );
}
