import { handleErrors } from '../defaultServices/ErrorHandler';

export default function manualsServiceFunction() {
  function getManuals() {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/get-manuals')
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function getManual(route) {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/get-manuals?url=' + route)
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function updateManual(route, data) {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/update-manuals?url=' + route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function deleteManual(route) {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/delete-manuals?url=' + route, {
      method: 'DELETE',
      /*headers: {
				'Content-Type': 'application/json; charset=UTF-8'
			},
			body: JSON.stringify(data)*/
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function addManual(route, data) {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/add-manuals?url=' + route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function addFolder(route, data) {
    return fetch(process.env.REACT_APP_NFQES_MANUALS_API_URL + '/add-manuals?url=' + route, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  return {
    getManuals,
    getManual,
    updateManual,
    deleteManual,
    addManual,
    addFolder,
  };
}
