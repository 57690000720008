export const EZZK_SK = {
  EZZK: {
    NEW_RECORD_PAGE_TITLE: 'Zaručené konverzie',
    VERSION: 'Verzia',
    DATE: 'Účinná od',
    CHOOSE_THIS_OPTION: 'Zvoliť konverziu',
    UPLOAD_FILES_BUTTON: 'Zvoliť súbory',
    EMPTY: 'Nevyplnené',
    ASSISTANT: 'Asistent',
    ELECTRONIC_DOCUMENTS: 'Elektronické dokumenty',
    ELECTRONIC_DOCUMENT: 'Elektronický dokument',
    PAPER_DOCUMENTS: 'Papierové dokumenty',
    PAPER_DOCUMENT: 'Papierový dokument',
    AUTHORIZATION_MARKS: 'Autorizačné prostriedky',
    AUTHORIZATION_MARK: 'Autorizačný prostriedok',
    CONVERSION_INFO: 'Údaje o začučenej konverzii',
    HELP_BUTTON: 'Pomoc',
    SHOW_PREVIEW: 'Zobraziť náhľad',
    REMOVE_DOCUMENT: 'Odstrániť dokument',
    REMOVE_DOCUMENT_TEXT:
      'Naozaj chete odstrániť tento dokument? Túto akciu pravdepodobne nebude možné vrátiť.',
    EDIT_DOCUMENT: 'Upraviť dokument',
    EDIT_DOCUMENT_BUTTON: 'Upraviť dokument',
    ADD_DOCUMENT: 'Pridať dokument',
    FILE_NAME: 'Názov nahraného súboru',
    DOCUMENT_NAME: 'Názov dokumentu',
    DOCUMENT_NAME_HELP: `Do poľa sa udáva názov pôvodného elektronického dokumentu podľa nasledujúcich pravidiel:
    <br/>
    - v prípade že sa jedná o zaručenú konverziu jediného elektronického dokumentu, ktorý nie je v listinnej podobe explicitne označený, uvádza sa ako názov hodnota "Dokument"
    <br/>
    - v prípade viacerých pôvodných elektronických dokumentov sa uvádza názov dokumentu tak, ako je použitý pri označení dokumentu v elektronickej podobe (názov pôvodného súboru) za účelom jeho identifikácie pri popise väzby autorizácie k autorizovaným dokumentom
    <br/>
    - v prípade elektronického dokumentu vo formáte PDF obsahujúceho viacnásobné podpisy vo formáte PAdES, ktoré sa viažu na rôzne časti dokumentu vzniká názov odvodením z názvu pôvodného súboru a prípony poradia (napr. zmluva-1, zmluva-2, atď.)`,
    DOCUMENT_TYPE: 'Typ dokumentu',
    DOCUMENT_TYPE_HELP: `Do poľa sa uvádza formát dokumentu tak ako je uvedený v prostriedku autorizácie. Ak dokument (alebo jeho časť) nie je autorizovaný, uvedie sa formát dokumentu, tak ako je v dokumente uvedený, napr. jeho príponou:
    <br/>
    - "PDF" - elektronický dokument vo formáte Portable Document
    <br/>
    - "TXT" - elektronický dokument vo formáte Plain Text Format (.txt),
    <br/>
    - "PNG" - elektronický dokument vo formáte Portable Network Graphics (.png)"
    <br/>
    - "XMLDataContainer" - elektronický dokument vo formáte (XML) v štruktúre "kontajnera XML údajov" .
    <br/>
    - hodnoty pre ostatné formáty sa tvoria z obvyklej prípony použitého formátu alebo z obvyklej skratky názvu použitého formátu tak, aby bola identifikácia jednoznačná a nekonfliktná s inými formátmi v súlade s §57d ods. 1 výnosu Ministerstva financií Slovenskej republiky č. 55/2014 Z. z. štandardoch pre informačné systémy verejnej správy.`,
    HASH_TYPE: 'Funkcia el. odtlačku',
    HASH_TYPE_HELP:
      'Do poľa sa zadáva meno kryptografickej funkcie, ktorou bol odtlačok novovzniknutého elektronického dokumentu vypočítaný. Identifikácia použitej kryptografickej funkcie pre výpočet elektronického odtlačku sa vykoná v súlade s §11 ods. 1 písm. m) zákona č. 272/2016 Z. z. o dôveryhodných službách pre elektronické transakcie na vnútornom trhu a o zmene a doplnení niektorých zákonov (zákon o dôveryhodných službách) (napr. "sha-256").',
    HASH_VALUE: 'Hodnota el. odtlačku',
    HASH_VALUE_HELP:
      'Do poľa sa zadáva hodnota elektronického odtlačku (hash) novovzniknutého elektronického dokumentu, ktorá je zapísana v kódovaní BASE64.',
    UPLOAD_FILES_TEXT: 'Nahrajte súbor pre tento dokument.',
    UPLOAD_ERROR_TEXT: 'Niektoré súbory nie sú podporované a preto boli odstránené.<br/>:files',
    EDIT_ELECTRONIC_DOCUMENT: 'Upraviť elektronický dokument',
    ADD_AUTHORIZATION: 'Pridať autorizačný prostriedok',
    EDIT_AUTHORIZATION_MARK: 'Upraviť autorizačný prostriedok',
    EDIT_AUTHORIZATION_MARK_BUTTON: 'Upraviť autorizačný prostriedok',
    NO_DOCUMENTS: 'Nie sú vytvorené žiadne dokumenty',
    AUTHORIZATION_DOCUMENTS: 'Autorizované dokumenty',
    AUTHORIZATION_DOCUMENTS_HELP:
      "Do poľa sa uvedie názov elektronického dokumentu, ktorý je k danej autorizácií viazaný. Názov sa uvádza podľa poľa 'Názov dokumentu' v sekcii pôvodných dokumentov.",
    AUTHORIZATION_TYPE: 'Typ autorizácie',
    AUTHORIZATION_TYPE_HELP: 'Do poľa sa uvedie typ použitej autorizácie.',
    AUTHORIZATION_TYPE_NOTE: 'Typ autorizácie - iný',
    AUTHORIZATION_TYPE_NOTE_HELP: 'Do poľa sa zadáva iný uznaný spôsob autorizácie v budúcnosti.',
    AUTHORIZATION_STATUS: 'Stav autorizácie',
    AUTHORIZATION_STATUS_HELP: 'Do poľa sa uvedie výsledok overenia platnosti autorizácie.',
    AUTHORIZATION_DATE: 'Čas autorizácie',
    AUTHORIZATION_DATE_HELP:
      'Do poľa sa uvedie čas vykonania autorizácie pôvodného elektronického dokumentu, ak je ho z prostriedku autorizácie možné zisiť (t.j. keď je v ňom čas autorizácie uvedený), čas sa uvádza vo formáte podľa ISO8601 s časovou zónou UTC+01:00 CET a +02:00 pre CEST.',
    AUTHORIZATION_VERIFY_DATE: 'Čas overenia autorizácie',
    AUTHORIZATION_VERIFY_DATE_HELP:
      'Do poľa sa uvedie čas kedy bolo overenie platnosti autorizácie pri vykonávaní zaručenej konverzie realizované, čas sa uvádza vo formáte podľa ISO8601 s časovou zónou UTC+01:00 CET a +02:00 pre CEST.',
    AUTHORIZATION_PLACE: 'Miesto autorizácie',
    AUTHORIZATION_PLACE_HELP:
      "Miesto vykonania autorizácie pôvodného elektronického dokumentu, ak je ho z prostriedku autorizácie možné zistiť (t.j. keď je v ňom uvedené). Inak sa zadáva reťazec 'Neuvedené'.",
    AUTHORIZATION_OTHER: 'Ďalšie údaje o autorizácií',
    AUTHORIZATION_OTHER_HELP: `Do poľa sa uvádzajú ďalšie údaje o autorizácii, ktoré sú pre daný typ autorizácie dôležité a nemožno ich uviesť v žiadnej z vyššie uvedených charakteristík autorizácie. V prípade použitia elektronického podpisu alebo elektronickej pečate ako prostriedku autorizácie sa uvádza informácia, či použitý certifikát bol kvalifikovaný - v nasledujúcom formáte: „certifikát – kvalifikovaný“ alebo „certifikát – nekvalifikovaný. Súčasne do tohto poľa je uložená informácia o tom, či privátny kľúč podpisovateľa je uložený na bezpečnom zariadení – QSCD v nasledujúcom formáte: „certifikát na QSCD“ alebo „certifikát nie je na QSCD“. Ak je certifikát vydaný ako kvalifikovaný certifikát, je to možné identifikovať podľa nasledujúcich znakov:
      <br/><br/>
      - certifikát musí obsahovať položku QcCompliance OID (0.4.0.1862.1.1),
      <br/><br/>
      - certifikát vydaný podľa legislatívy SR musí obsahovať rozšírenie certifikátu certificatePolicies OID (2.5.29.32), ktoré musí minimálne obsahovať OID certifikačnej politiky NBÚ OID (1.3.158.36061701.0.0.0.1.2.2)
      <br/><br/>
      - vydavateľ certifikátu je uvedený v dôveryhodnom zozname podľa vykonávacieho rozhodnutia Komisie č. 2015/1505`,
    AUTHORIZATION_PERSON_IDENTITY: 'Identifikátor osoby',
    AUTHORIZATION_PERSON_IDENTITY_HELP: `Do poľa sa uvedie identifikátor fyzickej alebo právnickej osoby, ktorá vykonala autorizáciu. Identifikátor sa uvádza v nasledujúcej štruktúre:
      <br/><br/>
      <b>Právnická osoba</b>
      <br/>
      - Názov organizácie v atribúte certifikátu "organisationName"
      <br/>
      - Identifikačný údaj organizácie v atribúte certifikátu ako doplňujúci údaj v atribúte certifikátu "serialNumber" alebo "organizationIdentifier" v nasledujúcej štruktúre:
      <br/>
      - typ identifikátora pozostávajúci z 3 úvodných znakov určujúcich typ odkazu na identitu:
        <br/>
        &emsp;- VAT - pre identifikáciu na základe daňového identifikačného čísla
        <br/>
        &emsp;- NTR - pre identifikáciu na základe identifikačného čísla organizácie. Nasledujúce dva znaky obsahujú kód krajiny podľa ISO 3166 (pre Slovensko "SK"), ktorá údaje uvedené v druhej časti vydala a poskytuje ich na základe legislatívnych požiadaviek definovaných vo svojej krajine
        <br/>
        &emsp;- SZ - pre identifikáciu na základe súboru znakov pridelených podľa § 27 ods. 4 zákona
        <br/>
        &emsp;- nasledované 2 znakmi obsahujúcimi kód krajiny podľa ISO 3166 (pre Slovensko "SK"), ktorá údaje uvedené v druhej časti vydala a poskytuje ich na základe legislatívnych požiadaviek definovaných vo svojej krajine,
        <br/>
        &emsp;- oddelené znakom pomlčka "-" a nasledované znakom samotným identifikátorom, ktorého typ určujú prvé tri úvodné znaky. Príklad "SZ:SK-123123".
      <br/><br/>
      <b>Fyzická osoba</b>
      <br/>
      - krstné meno podpisovateľa  v atribúte certifikátu "givenName"
      <br/>
      - priezvisko podpisovateľa v atribúte certifikátu "surname"
      <br/>
      - identifikátor podpisovateľa v atribúte certifikátu "serialNumber" v nasledujúcej štruktúre:
      <br/>
      - typ identifikátora pozostávajúci z 3 úvodných znakov určujúcich typ odkazu na identitu:
        <br/>
        &emsp;- PNO - pre identifikáciu na základe osobného čísla, čo je rodné číslo u občanov Slovenskej republiky alebo u cudzincov, ktorí majú pridelené rodné číslo podľa zákona č. 301/1995 Z. z. o rodnom čísle
        <br/>
        &emsp;- IDC - pre identifikáciu na základe čísla identifikačnej karty
        <br/>
        &emsp;- PAS - pre identifikáciu na základe čísla pasu
        <br/>
        &emsp;- nasledované 2 znakmi obsahujúcimi kód krajiny podľa ISO 3166 (pre Slovensko  "SK"), ktorá údaje uvedené v druhej časti vydala a poskytuje ich na základe legislatívnych požiadaviek definovaných vo svojej krajine,
        <br/>
        &emsp;- oddelené znakom pomlčka "-" a nasledované samotným identifikátorom, ktorého typ určujú prvé tri úvodné znaky. Príklad "IDCSK-123123"
      <br/>
      - V prípade použitia pseudonymu musí byť jasne uvedená skutočnosť, že sa jedná o pseudonym použitý pre identifikáciu osoby. V prípade použitia pseudonymu sa z certifikátu získajú nasledujúce údaje:
        <br/>
        &emsp;- z atribútu certifikátu "pseudonym" - uvedený text pseudonymu.
        <br/>
        &emsp;- z atribútu certifikátu "commonName" -v položke commonName musí byť minimálne uvedený text "PSEUDONYM").`,
    AUTHORIZATION_PERSON_REPRESENTED: 'Zastupovaná osoba',
    AUTHORIZATION_PERSON_REPRESENTED_HELP:
      "Do poľa sa zadávajú identifikačné údaje fyzickej alebo právnickej osoby, v mene ktorej autorizujúca osoba konala. Identifikátor sa uvádza identický ako v prípade identifikátora autorizujúcej osoby, pričom v každej položke mandata v certifikáte je pred údajmi uvedený veľkými písmenami text 'MANDAT' oddelený medzerou. V prípade že certifikát neobsahuje žiadne oprávnenie, zadáva sa text 'Žiadna'.",
    AUTHORIZATION_MANDAT: 'Mandát',
    AUTHORIZATION_MANDAT_HELP: `Do poľa sa uvádza označenie oprávnenia uvedené ako posledné číslo OID certifikačnej politiky v rozšírení certifikátu (napr. 1.3.158.36061701.1.1.xyz).  Identifikátor "xyz" predstavuje číslo oprávnenia v zozname oprávnení, nie označenie (text) oprávnenia. Súčasne sa pripojí aj text oprávnenia uvedený v atribúte certifikátu "userNotice-explicitText", ktorý sa MÔŽE nachádzať v tomto atribúte.
      <br/><br/>
      V prípade že certifikát neobsahuje žiadne oprávnenie, zadáva sa text "Žiaden".`,
    REMOVE_AUTHORIZATION_MARK: 'Odstrániť autorizačný prostriedok',
    REMOVE_AUTHORIZATION_MARK_TEXT: 'Naozaj chcete odstrániť tento autorizačný prostriedok?',
    TIMESTAMPS: 'Časové pečiatky',
    EDIT_TIMESTAMP: 'Upraviť časovú pečiatku',
    EDIT_TIMESTAMP_BUTTON: 'Upraviť časovú pečiatku',
    REMOVE_TIMESTAMP: 'Odstrániť časovú pečiatku',
    REMOVE_TIMESTAMP_TEXT: 'Naozaj chcete odstrániť túto časovú pečiatku?',
    ADD_TIMESTAMP: 'Pridať časovú pečiatku',
    TIMESTAMP_PUBLISHER: 'Vydavateľ časovej pečiatky',
    TIMESTAMP_PUBLISHER_HELP:
      'Do poľa sa zadáva identifikácia poskytovateľa služby časovej pečiatky tak, ako je uvedený v atribúte Common-name v certifikáte vydavateľa časovej pečiatky.',
    TIMESTAMP_TYPE: 'Typ časovej pečiatky',
    TIMESTAMP_TYPE_HELP: 'Do poľa sa uvádza typ časovej pečiatky - Kvalifikovaná, Nekvalifikovaná.',
    TIMESTAMP_STATUS: 'Stav časovej pečiatky',
    TIMESTAMP_STATUS_HELP: 'Do poľa sa uvádza výsledok overenia platnosti časovej pečiatky.',
    TIMESTAMP_CREATE_DATE: 'Čas vystavenia časovej pečiatky',
    TIMESTAMP_CREATE_DATE_HELP:
      'Do poľa sa uvádza čas vydania časovej pečiatky, uvedený v časovej pečiatke. Čas sa uvádza vo formáte podľa ISO8601 s časovou zónou UTC+01:00 CET a +02:00 pre CEST.',
    TIMESTAMP_VERIFY_DATE: 'Čas overenia časovej pečiatky',
    TIMESTAMP_VERIFY_DATE_HELP:
      'Do poľa sa uvádza čas kedy bolo overenie platnosti časovej pečiatky pri zaručenej konverzii realizované. Čas sa uvádza vo formáte podľa ISO8601 s časovou zónou UTC+01:00 CET a +02:00 pre CEST.',
    EDIT_PAPER_FORMAT: 'Upraviť formát papiera',
    EDIT_PAPER_FORMAT_BUTTON: 'Upraviť formát papiera',
    REMOVE_PAPER_FORMAT: 'Odstrániť formát papiera',
    REMOVE_PAPER_FORMAT_TEXT: 'Naozaj chcete odstrániť tento formát papiera?',
    ADD_PAPER_FORMAT: 'Pridať formát papiera',
    EDIT_PAPER_DOCUMENT: 'Upraviť dokument',
    EDIT_DOCUMENT_CONFIRM_BUTTON: 'Upraviť dokument',
    NUMBER_OF_LISTS: 'Počet listov',
    NUMBER_OF_LISTS_HELP:
      'Do poľa sa udáva celkový počet listov listinného dokumentu, ktorý vznikol transformáciou pôvodných elektronických dokumentov do listinnej podoby. V prípade že sa súčasne konvertuje viacero elektronických dokumentov autorizovaných spoločne, zvádza sa sumárny počet listov, vrátane listov vložených na označenie začiatku a konca pôvodných elektronických dokumentov. Do počtu listov sa vznikajúca listinná forma osvedčovacej doložky nezapočítava.',
    NUMBER_OF_NON_EMPTY_PAGES: 'Počet neprázdnych strán',
    NUMBER_OF_NON_EMPTY_PAGES_HELP: 'Do poľa sa uvedie počet listov dokumentu s daným formátom.',
    PAPER_FORMATS_TITLE: 'Formáty papiera',
    PAPER_FORMAT_TYPE: 'Formát papiera',
    PAPER_FORMAT_TYPE_HELP:
      'Do poľa sa uvedie použitý formát papiera novovzniknutého listinného dokumentu.',
    PAPER_FORMAT_NOTE: 'Formát papiera - iný',
    PAPER_FORMAT_NOTE_HELP:
      'Do poľa sa uvedie použitý iný formát papiera novovzniknutého listinného dokumentu.',
    PAPER_FORMAT_VALUE: 'Počet listov',
    PAPER_FORMAT_VALUE_HELP: 'Do poľa sa uvedie počet listov dokumentu s daným formátom.',
    PAPER_FORMAT_IS_EMPTY:
      'Niektorý z formátov papiera nie je vyplený a preto nie je možné pridať ďalší formát papiera.',
    EDIT_CONVERSION_INFO: 'Upraviť informácie o konverzií',
    EDIT_CONVERSION_INFO_BUTTON: 'Upraviť',
    COVERSION_INFO: 'Údaje o zaručenej konverzii',
    CONVERSION_ECV: 'Evidenčné číslo záznamu o zar. konverzií',
    CONVERSION_ECV_HELP:
      "Do poľa sa zadáva evidenčné číslo záznamu o zaručenej konverzii v tvare URI https://data.gov.sk/id/egov/conversion-record/ID, kde sa ako 'ID' uvádza jedinečné evidenčné číslo záznamu.",
    CONVERSION_DATE: 'Dátum a čas vykonania zar. konverzie',
    CONVERSION_DATE_HELP:
      'Do poľa sa zadáva čas a dátum vykonania zaručenej konverzie vo formáte podľa ISO8601 s časovou zónou UTC+01:00 pre CET a +02:00 pre CEST.',
    CONVERSION_ICO: 'IČO',
    CONVERSION_ICO_HELP:
      'Do poľa sa zadáva identifikátor osoby alebo jej organizačnej zložky, ktorá zaručenú konverziu vykonala v prípade, že taký má pridelený. Uvádza sa v tvare URI https://data.gov.sk/id/legal-subject/ICO, pričom sa na konci ako ICO uvádza identifikátor osoby.',
    CONVERSION_PO_NAME: 'Názov právnickej osoby',
    CONVERSION_PO_NAME_HELP:
      'Do poľa sa zadáva názov osoby, ktorá je podľa zákona oprávnenou na vykonanie zaručenej konverzie, vrátane organizačnej zložky, ak zaručenú konverziu vykonáva organizačná zložka.',
    CONVERSION_FORENAME: 'Meno',
    CONVERSION_FORENAME_HELP:
      'Do poľa sa zadáva meno fyzickej osoby, ktorá zaručenú konverziu vykonala.',
    CONVERSION_SURNAME: 'Priezvisko',
    CONVERSION_SURNAME_HELP:
      'Do poľa sa zadáva priezvisko fyzickej osoby, ktorá zaručenú konverziu vykonala.',
    CONVERSION_WORK_POSITION: 'Funkcia alebo pracovné zaradenie',
    CONVERSION_WORK_POSITION_HELP:
      'Do poľa sa zadáva funkcia a pracovné zaradenie fyzickej osoby, ktorá zaručenú konverziu vykonala, ak takéto má určené (napr. zamestnanec obecného úradu, zamestnanec okresného súdu, advokát, notár, notársky koncipient a pod.).',
    CHOOSE_COMPANY_INPUT: 'Vyber si spoločnosť',
    CHOOSE_COMPANY_INPUT_HELP:
      'Táto spoločnosť bude vystupovať ako právnicka osoba v zaručenej konverzií.',
    CONVERSION_GET_ECV: 'Načítať ev. číslo',
    PAPER_DOCUMENT_NAME: 'Názov dokumentu',
    PAPER_DOCUMENT_NAME_HELP:
      'Do poľa sa udáva názov listinného dokumentu, tak aby bolo možné pôvodný listinný dokument vo vytvorenom elektronickom dokumente identifikovať.',
    SECURITY_MARKS_TITLE: 'Bezpečnostné prvky',
    EDIT_SECURITY_MARK: 'Upraviť bezpečnostný prvok',
    EDIT_SECURITY_MARK_CONFIRM_BUTTON: 'Upraviť bezpečnostný prvok',
    REMOVE_SECURITY_MARK: 'Odstrániť bezpečnostný prvok',
    REMOVE_SECURITY_MARK_TEXT: 'Naozaj chcete odstrániť tento bezpečnostný prvok?',
    ADD_SECURITY_MARK: 'Pridať bezpečnostný prvok',
    SECURITY_MARK_TYPE: 'Slovný opis',
    SECURITY_MARK_TYPE_HELP:
      'Do poľa sa uvádza slovný popis bezpečnostného prvku, napr. vlastnoručný podpis osoby, úradne osvedčený podpis osoby a pod.',
    SECURITY_MARK_TYPE_NOTE: 'Slovný opis - iný',
    SECURITY_MARK_TYPE_NOTE_HELP:
      'Do poľa sa uvádza slovný popis bezpečnostného prvku, napr. vlastnoručný podpis osoby, úradne osvedčený podpis osoby a pod.',
    SECURITY_MARK_PAGE: 'Výskyt na strane',
    SECURITY_MARK_PAGE_HELP:
      'Do poľa sa zadáva číslo strany v pôvodnom dokumente, na ktorom sa nachádza popisovaný bezpečnostný prvok. Počítajú sa neprázdne strany. Označenie strany v pôvodnom dokumente ak sa odližuje od reálneho poradia strany, sa neberie do úvahy.',
    SECURITY_MARK_LIST: 'Výskyt na liste',
    SECURITY_MARK_LIST_HELP:
      'Do poľa sa zadáva číslo listu v pôvodnom dokumente, na ktorom sa nachádza popisovaný bezpečnostný prvok.',
    SECURITY_MARK_PLACEMENT: 'Miesto umiestnenia na strane dokumentu',
    SECURITY_MARK_PLACEMENT_HELP:
      'Do poľa sa zapisuje popis miesta na strane, kde je bezpečnostný prvok umiestnený (napr. vpravo hore).',
    SECURITY_MARK_PAGE_IN_ELECTRONIC: 'Výskyt na strane v novom el. dokumente',
    SECURITY_MARK_PAGE_IN_ELECTRONIC_HELP:
      'Do poľa sa zadáva číslo strany v konvertovanom (novom) dokumente, na ktorom sa nachádza popisovaný bezpečnostný prvok. Nový dokument vzniká ako postupnosť strán obsahujúca konvertovaný obraz všetkých pôvodných dokumentov v poradí, v akom boli ku konverzii predložené.',
    DISABLE_EZZK_TITLE: 'Vypnutie zaručených konverzií',
    DISABLE_EZZK_BODY:
      'Táto funkcia je primárne určená pre právnické osoby vykonávajúce zaručenú konverziu.<br/><br/>Chcete pokračovať alebo vypnúť túto funkcionalitu?',
    DISABLE: 'Vypnúť',
    CONTINUE: 'Pokračovať',
    LOGIN: 'Prihlasovacie meno',
    PASSWORD: 'Prihlasovacie heslo',
    SAVE_ACCOUNT: 'Uložiť účet',
    WHY_WE_NEED_LOGIN:
      'Prihlasovacie údaje do portálu zaručených konverzií potrebujeme pre autorizáciu operácií vykonávaných na serveri zaručených konverzií.<br/><br/>Bez týchto údajov nemôžete pokračovať vo vytváraní zaručený konverzií.',
    VALIDATE_DOCUMENT: 'Overiť dokument',
    TYPES: {
      PAPER_TO_ELECTRONIC: 'Listinná podoba do elektronickej',
      PAPER_TO_ELECTRONIC_DESCRIPTION: 'Konverzia listinnej podoby do elektronickej podoby.',
      ELECTRONIC_TO_PAPER: 'Elektronická podoba do listinnej',
      ELECTRONIC_TO_PAPER_DESCRIPTION: 'Konverzia elektronickej podoby do listinnej podoby.',
      ELECTRONIC_TO_ELECTRONIC: 'Elektronická podoba do elektronickej',
      ELECTRONIC_TO_ELECTRONIC_DESCRIPTION:
        'Konverzia elektronickej podoby do elektronickej podoby.',
    },
    PAPER_FORMATS: {
      A1: 'Formát A1',
      A2: 'Formát A2',
      A3: 'Formát A3',
      A4: 'Formát A4',
      A5: 'Formát A5',
      A6: 'Formát A6',
      A7: 'Formát A7',
      B3: 'Formát B3',
      B4: 'Formát B4',
      B5: 'Formát B5',
      B6: 'Formát B6',
      B7: 'Formát B7',
      C3: 'Formát C3',
      C4: 'Formát C4',
      C5: 'Formát C5',
      C6: 'Formát C6',
      C7: 'Formát C7',
      OTHER: 'Iný formát',
    },
    SECURITY_MARKS: {
      ROUND_STAMP: 'Okrúhla pečiatka',
      ROUND_STAMP_WITH_STATE_EMBLEM: 'Okrúhla pečiatka so štátnym znakom',
      EMBOSSED_STAMP: 'Reliéfna pečiatka',
      WATERMARK: 'Vodotlač',
      SIGNATURE: 'Vlastnoručný podpis',
      OFFICIALLY_CERTIFIED_SIGNATURE: 'Úradne osvedčený podpis',
      PAPER_CLIP: 'Spinka',
      ADHESIVE_LABEL: 'Lepiaci štítok',
      THREAD_METAL: 'Nit - kovový',
      TRICOLOR_STRING: 'Trikolóra šnúrka',
      PERMANENT_CONNECTION_OF_THE_DOCUMENT: 'Trvalé spojenie dokumentu - iné',
      STAMP: 'Pečiatka',
      ANOTHER_MANUAL_INPUT: 'Iný manuálny vstup',
    },
    MARK_PLACEMENTS: {
      BOTTOM: 'Dole',
      TOP: 'Hore',
      BOTTOM_BORDER: 'Dolný okraj',
      TOP_BORDER: 'Horný okraj',
      LEFT_BORDER: 'Ľavý okraj',
      RIGHT_BORDER: 'Pravý okraj',
      MIDDLE: 'Uprostred',
      LEFT: 'Vľavo',
      LEFT_BOTTOM: 'Vľavo dole',
      LEFT_TOP: 'Vľavo hore',
      RIGHT: 'Vpravo',
      RIGHT_BOTTOM: 'Vpravo dole',
      RIGHT_TOP: 'Vpravo hore',
    },
    FILE_TYPES: {
      PDF: 'PDF',
      PDF_A1: 'PDF/A-1',
      PDF_A2: 'PDF/A-2',
      PNG: 'PNG',
      TXT: 'TXT',
      XMLDATACONTAINER: 'XMLDataContainer',
      OTHER: 'Iný',
    },
    AUTHORIZATION_TYPES: {
      NO_AUTHORIZATION_NO_TIMESTAMP: 'Bez autorizácie a časovej pečiatky',
      QUALIFIED_ELECTRONIC_SIGNATURE: 'Kvalifikovaný elektronický podpis',
      ADVANCED_ELECTRONIC_SIGNATURE: 'Zdokonalený elektronický podpis',
      ELECTRONIC_SIGNATURE: 'Elektronický podpis',
      QUALIFIED_ELECTRONIC_SEAL: 'Kvalifikovaná elektronická pečať',
      ADVANCED_ELECTRONIC_SEAL: 'Zdokonalená elektronická pečať',
      ELECTRONIC_SEAL: 'Elektronická pečať',
      OTHER: 'Iný',
    },
    AUTHORIZATION_STATUSES: {
      VALID: 'Platný',
      INVALID: 'Neplatný',
      UNKNOWN: 'Nie je možné zistiť',
    },
    TIMESTAMP_TYPES: {
      QUALIFIED: 'Kvalifikovaná',
      UNQUALIFIED: 'Nekvalikovaná',
    },
    TIMESTAMP_STATUSES: {
      VALID: 'Platný',
      INVALID: 'Neplatný',
      UNKNOWN: 'Nie je možné zistiť',
    },
    ERRORS: {
      REQUIRED: 'Toto pole je povinné.',
      DOCUMENT_NAME_REQUIRED: 'Toto pole je povinné.',
      IS_NOT_NUMBER: 'Musíte zadať celočíselnú hodnotu väčšiu ako 0.',
      ABOVE_LIST_NUMBER: 'Hodnota presahuje uvedený počet listov.',
      PAPER_FORMAT_ALREADY_USED: 'Tento formát papiera sa už nachádza na zozname.',
      NUMBER_MUST_BE_POSITIVE: 'Počet musí byť kladné číslo.',
      NUMBER_IS_OUT_OF_LIMIT:
        'Počet je mimo limitu. Súčet všetkých listov nesmie prekročiť celkový počet.',
      WRONG_NAME: 'Názov dokumentu musí byť vyplnený.',
      WRONG_NUMBER_OF_PAGES: 'Počet listov a počet neprázdnych strán nesmie byť 0.',
      WRONG_EMPTY_PAGES: 'Počet neprázdnych strán nesmie byť vyšší ako počet listov.',
      WRONG_PAPER_FORMAT_COUNT: 'Dokument musí obsahovať aspoň 1 formát papiera.',
      WRONG_PAPER_FORMAT: 'Súčet všetkých formátov papiera musí byť rovnaký ako počet listov.',
      WRONG_PAPER_FORMAT_VALUE: 'Formát papiera nesmie mať počet listov 0.',
      WRONG_SECURITY_MARKS_COUNT: 'Dokument musí obsahovať aspoň 1 bezpečnostný prvok.',
      WRONG_SECURITY_MARKS_LIST: 'Niektoré bezpečnostné prvky presahujú počet listov.',
      WRONG_SECURITY_MARKS_PAGE: 'Niektoré bezpečnostné prvky presahujú počet strán.',
      WRONG_COMPANY: 'Musíte zvoliť spoločnosť / právnicku osobu.',
      WRONG_ECV: 'Počkajte, kým sa načíta evidenčné číslo záznamu.',
      CONVERSION_ECV_NOT_LOADED: 'Evidenčné číslo sa nepodarilo načítať.',
    },
    CHECK: {
      CHECK_VALIDITY: 'Skontrolovať vstupy',
      CHECK_ERRORS_IN_FORM: 'Kontrola chýb',
      NEED_AT_LEAST_ONE_PAPER_DOCUMENT: 'Vytvorte aspoň <b>1 dokument</b>.',
      SOME_DOCUMENTS_MISSING_NAME: 'Niektoré dokumenty <b>nemajú</b> vyplnený <b>názov</b>.',
      SOME_DOCUMENTS_MISSING_NUMBER_OF_LISTS:
        'Niektoré dokumenty <b>nemajú</b> vyplnený <b>počet listov</b>.',
      SOME_DOCUMENTS_MISSING_NUMBER_OF_NON_EMPTY_PAGES:
        'Niektoré dokumenty <b>nemajú</b> vyplenný <b>počet neprázdnych strán</b>.',
      SOME_DOCUMENTS_MISSING_PAPER_FORMATS:
        'Niektoré dokumenty <b>nemajú</b> vyplnené <b>formáty papiera</b>.',
      SOME_DOCUMENTS_MISSING_HASH_TYPE:
        'Niektoré dokumenty <b>nemajú</b> vyplnenú <b>funkciu el. odtlačku</b>.',
      SOME_DOCUMENTS_MISSING_HASH:
        'Niektoré dokumenty <b>nemajú</b> vyplnenú <b>hodnotu el. odtlačku</b>.',
      SOME_PAPER_FORMATS_MISSING_TYPE:
        'Niektoré formáty papiera <b>nemajú</b> vyplnený <b>formát papiera</b>.',
      SOME_PAPER_FORMATS_MISSING_NOTE:
        'Niektoré formáty papiera <b>nemajú</b> vyplnený <b>iný formát papiera</b>.',
      SOME_PAPER_FORMATS_MISSING_VALUE:
        'Niektoré formáty papiera <b>nemajú</b> vyplnený <b>počet listov</b>.',
      SOME_DOCUMENT_PAPER_FORMATS_EXCEED_NUMBER_OF_LISTS:
        'Niektoré dokumenty majú <b>presahujúci počet listov<b> vo formátoch papiera.',
      SOME_DOCUMENTS_MISSING_SECURITY_MARKS:
        'Niektore dokumenty <b>nemajú</b> vyplnené <b>bezpečnostné prvky</b>.',
      SOME_SECURITY_MARKS_MISSING_TYPE:
        'Niektoré bezpečnostné prvky <b>nemajú</b> vyplnený <b>slovný opis</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_LIST:
        'Niektoré bezpečnostné prvky <b>nemajú</b> vyplnený <b>výskyt na liste</b>.',
      SOME_SECURITY_MARKS_NUMBER_OF_LIST_OUT_OF_RANGE:
        'Niektoré bezpečnostné prvky majú <b>presahujúci výskyt na liste</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_PAGE:
        'Niektoré bezpečnostné prvky <b>nemajú</b> vyplnený <b>výskyt na strane</b>.',
      SOME_SECURITY_MARKS_NUMBER_OF_PAGE_OUT_OF_RANGE:
        'Niektoré bezpečnostné prvky majú <b>presahujúci výskyt na strane</b>.',
      SOME_SECURITY_MARKS_MISSING_PLACEMENT:
        'Niektoré bezpečnostné prvky <b>nemajú</b> vyplnené <b>miesto umiestnenia na strane</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_PAGE_IN_ELECTRONIC:
        'Niektoré bezpečnostné prvky <b>nemajú</b> vyplnený <b>výskyt na strane v novom el. dokumente</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_DOCUMENTS:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnené <b>autorizované dokumenty</b>.',
      SOME_AUTHORIZATION_MARKS_DOCUMENTS_NOT_EXISTS:
        'Niektoré autorizačné prostriedky majú neexistujúce <b>autorizované dokumenty</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_TYPE:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnený <b>typ autorizácie</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_TYPE_OTHER:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnený <b>typ autorizácie iný</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_STATUS:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnený <b>stav autorizácie</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_VERIFY_DATE:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnený <b>čas overenia autorizácie</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_IDENTITY:
        'Niektoré autorizačné prostriedky <b>nemajú</b> vyplnený <b>identifikátor osoby</b>.',
      SOME_TIMESTAMPS_MISSING_PUBLISHER:
        'Niektoré časové pečiatky <b>nemajú</b> vyplneného <b>vydavateľa časovej pečiatky</b>.',
      SOME_TIMESTAMPS_MISSING_TYPE:
        'Niektoré časové pečiatky <b>nemajú</b> vyplnený <b>typ časovej pečiatky</b>.',
      SOME_TIMESTAMPS_MISSING_STATUS:
        'Niektoré časové pečiatky <b>nemajú</b> vyplnený <b>stav časovej pečiatky</b>.',
      SOME_TIMESTAMPS_MISSING_DATE:
        'Niektoré časové pečiatky <b>nemajú</b> vyplnený <b>čas vystavenia časovej pečiatky</b>.',
      SOME_TIMESTAMPS_MISSING_VERIFY_DATE:
        'Niektoré časové pečiatky <b>nemajú</b> vyplnený <b>čas overenia časovej pečiatky</b>.',
      NEW_DOCUMENT_MISSING_UPLOADED_FILE: 'V novom dokumente <b>nie je nahraný súbor</b>.',
      NEW_DOCUMENT_MISSING_NAME: 'V novom dokumente <b>nie je vyplnený názov dokumentu</b>.',
      NEW_DOCUMENT_MISSING_TYPE: 'V novom dokumente <b>nie je vyplnený typ dokumentu</b>.',
      NEW_DOCUMENT_MISSING_HASH_TYPE:
        'V novom dokumente <b>nie je vyplnená funkcia el. odtlačku</b>.',
      NEW_DOCUMENT_MISSING_HASH: 'V novom dokumente <b>nie je vyplnená hodnota el. odlačku</b>.',
      NEW_DOCUMENT_MISSING_NUMBER_OF_LISTS:
        'V novom dokumente <b>nie je vyplnený počet listov</b>.',
      NEW_DOCUMENT_MISSING_NUMBER_OF_NON_EMPTY_PAGES:
        'V novom dokumente <b>nie je vyplnený počet neprázdnych strán</b>.',
      NEW_DOCUMENT_MISSING_PAPER_FORMATS:
        'V novom dokumente <b>nie sú vyplnené formáty papiera</b>.',
      NEW_DOCUMENT_PAPER_FORMATS_EXCEED_NUMBER_OF_LISTS:
        'V novom dokumente <b>je presahujúci počet listov vo formátoch papiera</b>.',
      CONVERSION_MISSING_ECV:
        'V údajoch o zar. konverii <b>nie je vyplnené evidenčné číslo záznamu o zar. konverzii</b>.',
      CONVERSION_MISSING_DATE:
        'V údajoch o zar. konverii <b>nie je vyplnený dátum a čas vykonania zar. konverzie</b>.',
      CONVERSION_MISSING_ICO: 'V údajoch o zar. konverii <b>nie je vyplnené IČO</b>.',
      CONVERSION_MISSING_NAME:
        'V údajoch o zar. konverii <b>nie je vyplnený názov právnickej osoby</b>.',
    },
  },
};
