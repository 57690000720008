import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageGraphicSignature = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/graphic-signature/PageGraphicSignature'),
    'PageGraphicSignature'
  )
);
const PageGraphicSignatureNew = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/graphic-signature/new/[id]/[name]/PageGraphicSignatureNew'),
    'PageGraphicSignatureNew'
  )
);

const routesGraphicalSignature: Array<DBRoute> = [
  {
    path: 'URL.GRAPHICAL_SIGN',
    name: 'DASHBOARD_ROUTES.GRAPHICAL_SIGN',
    icon: <Icon icon='mdi:sign' width={30} height={30} />,
    component: <PageGraphicSignature />,
    show: true,
    user: true,
    order: 6,
  },
  {
    path: 'URL.GRAPHICAL_SIGN_CREATE_NEW',
    component: <PageGraphicSignatureNew />,
    show: false,
    user: true,
  },
];

export default routesGraphicalSignature;
