import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { InputProps } from '../hooks/useInput';
import { TextFieldSx } from './TextFieldSx';

interface TextInputProps extends InputProps<number | string> {
  maxValue?: number; // nedovoli zadat cislo vacsie
  minValue?: number; // nedovoli zadat cislo mensie (POZOR! nemusi dovolit zadat ani prve cislo ak pouzijes vacsie ako 0)
  // onlyPositive?: boolean; // pouzi "minValue={0}"
  step?: number; // o kolko sa bude zvysovat cislo pomocou tlacidiel
  decimal?: number; // pocet desatinnych miest
}

export default function NumberInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
  // specifics
  maxValue,
  minValue,
  step,
  decimal,
}: TextInputProps) {
  const { t } = useTranslation();

  const checkDecimal = (newValueStr) => {
    if (decimal !== undefined) {
      const [, decStr] = newValueStr.split('.');
      if (!decStr || decStr?.length <= decimal) return true;
      return false;
    }
    return true;
  };

  const checkMin = (newValue) => {
    if (minValue === undefined || newValue >= minValue) return true;
    return false;
  };

  const checkMax = (newValue) => {
    if (maxValue === undefined || newValue <= maxValue) return true;
    return false;
  };

  const handleChange = (e) => {
    const newValue = Number(e.target.value);
    if (checkMin(newValue) && checkMax(newValue) && checkDecimal(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <Box>
      <TextFieldSx
        size={size || 'medium'}
        id={name}
        data-testid={name}
        label={label}
        placeholder={placeholder}
        variant='outlined'
        type='number'
        value={value}
        error={error?.length > 0}
        helperText={t(error)}
        onChange={handleChange}
        fullWidth
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        inputProps={{ step }}
      />
    </Box>
  );
}
