import { BroadcastChannel } from 'broadcast-channel';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { userCtx } from 'context/context';

import { authorizationService } from '../index';

const AutoLogoutContext = createContext<number>(0);
const BROADCAST_CHANNEL_NAME = 'auto-logout-timer-channel';

export default function AutoLogoutProvider({ children }: any) {
  const idleTimeout = 900; // 900 seconds

  const [logoutTime, setLogoutTime] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number>(idleTimeout);
  const { data } = useContext(userCtx);
  const isLoggedIn = Boolean(data);

  const handleLogout = useCallback(() => {
    authorizationService.autoLogout();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;

    // Calculate the logout time
    const newLogoutTime = Date.now() + idleTimeout * 1000;

    // Set the logout time
    setLogoutTime(newLogoutTime);

    // Notify other tabs of the new logout time
    const channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    channel.postMessage({ type: 'UPDATE_LOGOUT_TIME', logoutTime: newLogoutTime });

    // Cleanup
    return () => {
      channel.close();
    };
  }, [isLoggedIn, idleTimeout]);

  useEffect(() => {
    // Handle messages from other tabs
    const channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

    const handleMessage = (event: any) => {
      if (event.type === 'UPDATE_LOGOUT_TIME') {
        setLogoutTime(event.logoutTime);
      }
    };

    channel.addEventListener('message', handleMessage);

    // Cleanup
    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, []);

  useEffect(() => {
    if (!isLoggedIn || logoutTime === null) return;

    // Calculate time remaining
    const updateRemainingTime = () => {
      const remaining = Math.max(Math.floor((logoutTime - Date.now()) / 1000), 0);
      setTimeRemaining(remaining);
      if (remaining <= 1) {
        handleLogout();
      }
    };

    // Set initial time remaining
    updateRemainingTime();

    // Update time remaining every second
    const intervalId = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(intervalId);
  }, [isLoggedIn, logoutTime, handleLogout]);

  useEffect(() => {
    if (!isLoggedIn) return;

    let lastActivityTime = Date.now();

    const handleUserActivity = () => {
      lastActivityTime = Date.now();
    };

    const activityCheckInterval = setInterval(() => {
      if (Date.now() - lastActivityTime <= 5000) {
        const newLogoutTime = Date.now() + idleTimeout * 1000;
        setLogoutTime(newLogoutTime);
        const channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        channel.postMessage({ type: 'UPDATE_LOGOUT_TIME', logoutTime: newLogoutTime });
      }
    }, 5000);

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('touchmove', handleUserActivity);

    return () => {
      clearInterval(activityCheckInterval);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('touchmove', handleUserActivity);
    };
  }, [isLoggedIn, idleTimeout]);

  return <AutoLogoutContext.Provider value={timeRemaining}>{children}</AutoLogoutContext.Provider>;
}

export function useAutoLogoutTimer(): number {
  const timeToLogout = useContext(AutoLogoutContext);
  return Number(timeToLogout);
}
