import sendRequestFunctions from 'service/common/sendRequestFunctions';

import { PagedHistoryValue } from './HistoryValue';

export default function historyServiceFunction() {
  const { sendRequestNoBody, sendRequestWithBodyNoJSON, sendRequestNoBodyNoJSON } =
    sendRequestFunctions(process.env.REACT_APP_NFQES_API_URL);

  function isHistoryActive() {
    return sendRequestNoBody(`/signingHistory/active/`, 'GET');
  }

  function getHistory(page, pageSize, orderBy, order, certType): Promise<PagedHistoryValue> {
    const searchString = `&sort=${orderBy},${order}${certType > -1 ? '&certificateType=' + certType : ''}`;
    return sendRequestNoBody(`/signingHistory?page=${page}&size=${pageSize}${searchString}`, 'GET');
  }

  function turnOffHistory(
    verifyToken: string,
    method: number,
    level: number | null
  ): Promise<void> {
    const data = verifyToken + '';
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestWithBodyNoJSON(`/signingHistory/cancel?${query}`, 'PUT', data);
  }

  function turnOnHistory(): Promise<string> {
    return sendRequestNoBodyNoJSON('/signingHistory/start', 'PUT');
  }

  function deleteHistoryEntity(id, code, method: number, level: number | null): Promise<string> {
    let query = `method=${method}`;
    if (level) {
      query += `&level=${level}`;
    }
    return sendRequestNoBodyNoJSON(`/signingHistory/${id}?code=${code}&${query}`, 'DELETE');
  }

  function downloadFile(id): Promise<any> {
    return sendRequestNoBodyNoJSON(`/signingHistory/download/${id}`, 'GET');
  }

  return {
    isHistoryActive,
    getHistory,
    turnOffHistory,
    turnOnHistory,
    deleteHistoryEntity,
    downloadFile,
  };
}
