import { lazy, ReactNode } from 'react';

import { Icon } from '@iconify/react';

import { lazyRetry } from './Router';
import routesAccount from './routes/Account';
import routesAdmin from './routes/Admin';
import routesCertificates from './routes/Certificates';
import routesCompanies from './routes/Companies';
import routesElMailbox from './routes/ElMailbox';
import { routesEzzk } from './routes/Ezzk';
import routesGraphicalSignature from './routes/GraphicalSignature';
import routesHashArchive from './routes/HashArchive';
import routesManuals from './routes/Manuals';
import routesMyAccount from './routes/MyAccount';
import routesNoCategory from './routes/NoCategory';
import routesSignAndVerify from './routes/SignAndVerify';
import routesSubscribe from './routes/Subscribe';
import routesTimestamp from './routes/Timestamp';

const PageSupport = lazy(() =>
  lazyRetry(() => import('pages/(public)/support/PageSupport'), 'PageSupport')
);
const PageHome = lazy(() => lazyRetry(() => import('pages/(public)/home/PageHome'), 'PageHome'));
const PageAdminDashboard = lazy(() =>
  lazyRetry(() => import('pages/admin/dashboard/PageDashboard'), 'PageAdminDashboard')
);
const PageNoAccess = lazy(() =>
  lazyRetry(() => import('pages/(common)/no-access/PageNoAccess'), 'PageNoAccess')
);
const PageTestKomponentov = lazy(() =>
  lazyRetry(
    () => import('pages/_dummy/test-komponentov/PageTestKomponentov'),
    'PageTestKomponentov'
  )
);

const PageHistory = lazy(() =>
  lazyRetry(() => import('pages/(public)/signing-history/PageHistory'), 'PageHistory')
);
const PageElectronicMailboxIdsk = lazy(() =>
  lazyRetry(
    () => import('pages/_dummy/electronic-mailbox-sk/PageElectronicMailboxIdsk'),
    'PageElectronicMailboxIdsk'
  )
);

interface MenuDataValue {
  icon?: string | ReactNode;
  show: true;
  name: string;
}

interface RouteDataValue {
  path: string;
  component: (() => JSX.Element) | ((_props: any) => JSX.Element) | Element | any;
  isDevelop?: boolean; // if true, only show in dev and local enviroments
  user?: boolean; // if true, route is available for a signed in user
  unlogged?: boolean; // if true, route is available for an unsigned user
  admin?: boolean; // if true, route is available for admin
  manager?: boolean; // if true, route is available for manager
  worker?: boolean; // if true, route is available for worker
  submenu?: DBRoute[]; // the submenu of a route, opens on click on the route
  onlyFinishedRegister?: boolean; // if true, route is available only for full registered user
  //needVerifiedPhone?: boolean; // if true, route will be wrapped in phoneGuard
  minLvlSubscription?: 0 | 1 | 2 | 3 | 4 | 5;
  order?: number;
}

type DBRoute = (RouteDataValue & { show: false }) | (RouteDataValue & MenuDataValue);

export type { DBRoute };

const DashboardRoutes: DBRoute[] = [
  {
    path: 'URL.HOME',
    name: 'DASHBOARD_ROUTES.HOME_PAGE',
    icon: <Icon icon='ion:home' width={30} height={30} />,
    component: <PageHome />,
    show: true,
    user: true,
    unlogged: true,
    order: 0,
  },
  {
    path: 'URL.ADMIN_DASHBOARD',
    name: 'DASHBOARD_ROUTES.ADMIN_DASHBOARD',
    icon: <Icon icon='oi:dashboard' width={30} height={30} />,
    component: <PageAdminDashboard />,
    show: true,
    user: false,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 0,
  },
  ...routesSignAndVerify,
  ...routesElMailbox,
  ...routesHashArchive,
  ...routesCertificates,
  ...routesMyAccount,
  ...routesGraphicalSignature,
  ...routesAdmin,
  ...routesSubscribe,
  ...routesTimestamp,
  ...routesCompanies,
  {
    path: 'URL.HISTORY',
    name: 'DASHBOARD_ROUTES.HISTORY',
    icon: <Icon icon='ic:outline-history' width={30} height={30} />,
    component: <PageHistory />,
    show: true,
    user: true,
    unlogged: false,
    admin: false,
    manager: false,
    worker: false,
    order: 11,
  },
  ...routesManuals,
  ...routesAccount,
  ...routesNoCategory,
  // ...routesFileGateway, je priamo v Router.tsx
  ...routesEzzk,
  {
    path: '/_dummy/test-komponentov',
    name: 'Test komponentov',
    icon: <Icon icon='fa6-brands:dev' width={30} height={30} />,
    component: <PageTestKomponentov />,
    isDevelop: true,
    show: true,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
  },
  {
    path: 'URL.EL_STORAGE_IDSK',
    name: 'EL_STORAGE_IDSK.PAGE_TITLE',
    icon: <Icon icon='fa6-brands:dev' width={30} height={30} />,
    component: <PageElectronicMailboxIdsk />,
    isDevelop: true,
    show: true,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
  },
  {
    path: 'URL.SUPPORT',
    name: 'SUPPORT.PAGE_TITLE',
    icon: <Icon icon='mdi:help' width={30} height={30} />,
    component: <PageSupport />,
    show: true,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    order: 15,
  },
  {
    path: 'URL.NO_SUBSCRIPTION',
    component: <PageNoAccess />,
    show: false,
    user: true,
    unlogged: false,
    admin: false,
    manager: false,
    worker: false,
  },
];

export default DashboardRoutes;
