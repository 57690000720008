import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { sanitize } from 'utils/Format';
import { hexToRgb } from 'utils/HexToRGB';

import { Tooltip } from 'components/Tooltip';

import { Icon } from '@iconify/react';
import { Collapse, IconButton, styled } from '@mui/material';

import css from './InfoBox.module.css';

export const ALLOWED_COOKIE_NAMES = [
  'freeCert',
  'certHub',
  'elMailbox',
  'company',
  'visualSign',
  'validation',
  'signature_ES',
  'signature_DS',
  'signature_QSCD',
  'history',
  'timestamps',
] as const;

type CookieName = (typeof ALLOWED_COOKIE_NAMES)[number];

interface InfoBoxProps {
  /**
   * Ak je zadane cookieName, zobrazi sa tlacidlo X a uklada sa do cookies
   */
  cookieName?: CookieName;
  /**
   * Ak je zadane onClose, zobrazuje sa tlacidlo X a vola sa po kliknuti na toto tlacidlo
   * Ak chýba cookieName, po refreshnutí stránky sa opäť zobrazuje InfoBox
   * @returns
   */
  onClose?: () => void;
  /**
   * Ak je true, zobrazuje sa tlacidlo X aj bez cookieName alebo onClose
   * Ak chýba cookieName, po refreshnutí stránky sa opäť zobrazuje InfoBox
   */
  allowClose?: boolean;
  /**
   * Ak je true, zobrazi sa fancy border na labej strane
   */
  leftBorder?: boolean;

  text?: string;
  textElement?: JSX.Element;
  title?: string;
  type: 'error' | 'info' | 'success' | 'warning';
  align?: 'center' | 'left' | 'right' | 'justify';
  margin?: number;
  marginBottom?: number;
  marginTop?: number;
  children?: any;
  noBreak?: boolean;
}

export default function InfoBox({
  cookieName,
  onClose,
  allowClose,
  leftBorder,
  ...props
}: InfoBoxProps) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies();
  const [closed, setClosed] = useState(false);
  const cookieFullName = `infoBox_${cookieName}`;

  const onClickClose = (e) => {
    e.stopPropagation();
    const cookieValue = cookies.cookies || localStorage.getItem('cookies');
    setClosed(true);
    if (cookieValue) {
      setCookie(cookieFullName, 'true');
    }
    if (typeof onClose !== 'boolean') {
      onClose?.();
    }
  };

  const isVisible = cookieName ? cookies[cookieFullName] !== 'true' : !closed;

  return (
    <Collapse in={isVisible}>
      <OuterBoxSx
        className={`nexu-${props.type} ${css.infobox}`}
        type={props.type}
        leftborder={leftBorder ? 'true' : 'false'}
        style={{
          textAlign: props.align,
          marginTop: (props.marginTop || props.margin) + 'px',
          marginBottom: (props.marginBottom || props.margin) + 'px',
        }}
      >
        {props.title && (
          <div
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '16px',
            }}
            dangerouslySetInnerHTML={{ __html: sanitize(props.title) }}
          ></div>
        )}
        {(cookieName || onClose || allowClose) && (
          <Tooltip
            title={<span style={{ fontSize: '12px' }}>{t('PROMO.DONT_SHOW')}</span>}
            placement='left'
          >
            <StyledIconButtonSx type={props.type} onClick={onClickClose}>
              <Icon icon='iconamoon:close-bold' width='22' />
            </StyledIconButtonSx>
          </Tooltip>
        )}

        {props.noBreak ? (
          <span
            dangerouslySetInnerHTML={{ __html: sanitize(props.text) }}
            style={{ width: leftBorder ? '92%' : '100%' }}
          ></span>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: sanitize(props.text) }}
            style={{ width: leftBorder ? '92%' : '100%' }}
          ></div>
        )}
        {props.textElement ?? <span>{props.textElement}</span>}

        <div style={{ width: leftBorder ? '92%' : '100%' }}>{props.children}</div>
      </OuterBoxSx>
    </Collapse>
  );
}

const OuterBoxSx: any = styled('div')(({ theme, leftborder, type }: any) => ({
  color: theme.palette[type].contrastText,
  backgroundColor: `rgba(${hexToRgb(theme.palette[type].default)}, 0.5)`,
  borderLeft:
    leftborder === 'true'
      ? `8px solid rgba(${hexToRgb(theme.palette[type].contrastText)}, 0.9)`
      : `8px solid transparent`,
}));

const StyledIconButtonSx: any = styled(IconButton)(({ theme, type }: any) => ({
  color: theme.palette[type].contrastText + '!important',
  position: 'absolute',
  right: '18px',
  marginTop: '-10px',
  '@media (min-width: 900px)': {
    right: '26px',
  },
}));
