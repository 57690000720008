import { MuiOtpInput } from 'mui-one-time-password-input';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SlideButton } from 'components/Buttons';
import { InfoBox } from 'components/InfoBox';
import { QRCodeCmp } from 'components/QRCode';

import { Box, CircularProgress, Link, Paper, useTheme } from '@mui/material';

import ModalManager from '../../modal-service/ModalManager';
import { OTPUserType } from '../OTPDialog';
import { getType } from './GridItem';

interface IProps {
  infoText?: string;
  type: string;
  challenge: string | null;
  step: number;
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  error: boolean;
  sendSMS: any;
  getChalengeOnDemand: () => void;
  submitOtp: () => void;
  user: OTPUserType;
}

export default function ResponseToChallenge(props: IProps) {
  const { t } = useTranslation(['translation', 'otp']);

  const theme = useTheme();
  const mode = localStorage.getItem('mode');

  const {
    infoText,
    type,
    challenge,
    step,
    otp,
    setOtp,
    error,
    sendSMS,
    getChalengeOnDemand,
    submitOtp,
    user,
  } = props;

  const handleChangeOtp = async (newValue) => {
    setOtp(newValue);
    if (/^\d{4}$/.test(newValue)) {
      setOtp(newValue);
    }
  };

  const handleClickResend = async () => {
    const response = await ModalManager.confirm(
      'OTP.CONFIRM_RESEND.TITLE',
      'OTP.CONFIRM_RESEND.TEXT',
      'OTP.CONFIRM_RESEND.BUTTON'
    );
    if (response) {
      sendSMS(type);
    }
  };

  return (
    <>
      {infoText && (
        <Box mb={1}>
          <InfoBox type='warning' text={infoText} />
        </Box>
      )}
      <Box p={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            style={{
              maxWidth: '480px',
              width: '100%',
              fontSize: '22px',
              paddingBottom: '4px',
            }}
          >
            <b>{t(`OTP.TYPES.${getType(type, challenge)}`)}</b>
          </Box>
          <Box
            style={{ maxWidth: '580px', width: '100%', fontSize: '16px' }}
            dangerouslySetInnerHTML={{
              __html:
                step === 1
                  ? t(`OTP.ACTIONS.${getType(type, challenge)}`)
                  : t(`OTP.NEW_METHOD_TEXT`).replace(
                      '{type}',
                      t(`OTP.TYPES.${getType(type, challenge)}`)
                    ),
            }}
          />
        </Box>
      </Box>
      {challenge && (
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            maxWidth: '480px',
            margin: 'auto',
          }}
          variant='outlined'
        >
          <Box p={3} pt={4}>
            {Number(type) === 5 && <QRCodeCmp value={challenge} />}
            <div
              className='text-center'
              style={{ fontSize: '24px', paddingTop: Number(type) === 5 ? '24px' : '0' }}
            >
              {t('OTP.CHALLENGE')} <br /> <b>{challenge}</b>
            </div>
          </Box>
        </Paper>
      )}
      <Box pt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        <MuiOtpInput
          value={otp}
          onChange={handleChangeOtp}
          length={6}
          TextFieldsProps={{
            type: 'number',
            inputProps: {
              inputMode: 'numeric',
              pattern: 'd*',
            },
            size: 'medium',
          }}
          sx={{
            maxWidth: '480px',
            gap: { xs: 1, sm: 1.7 },
            '& input': {
              height: '34px',
              padding: '14.5px 12px',
              background:
                mode === 'light'
                  ? error
                    ? '#FEF0F0'
                    : theme.palette.grey[50]
                  : error
                    ? '#bc3f3f3d'
                    : theme.palette.grey[800],
              borderRadius: '4px',
              fontSize: { xs: '1rem', sm: '1.3rem' },
              fontWeight: '500',
              color: error ? theme.palette.error.main : '',
              border: error
                ? `1px solid ${theme.palette.error.main} !important`
                : `1px solid ${theme.palette.grey[400]} !important`,
            },
            '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
              border: `1px solid ${theme.palette.primary.main} !important`,
            },
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
      </Box>
      {error && (
        <Box
          pt={1}
          sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.error.main }}
        >
          <div style={{ maxWidth: '480px', width: '100%' }}>
            <b>{t('HISTORY.WRONG_TOKEN')}</b>
          </div>
        </Box>
      )}

      {type + '' === '5' && !challenge && (
        <Box pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ marginRight: '4px' }}>{t('OTP.CHALLENGE_ON_DEMAND')}</p>
          <Link component='button' variant='body2' onClick={() => getChalengeOnDemand()}>
            {t('OTP.GET_CHALLENGE_ON_DEMAND')}
          </Link>
        </Box>
      )}
      {type + '' === '2' && error && (
        <Box pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ marginRight: '4px' }}>{t('OTP.NEED_CALIBRATION')}</p>

          <Link
            component='button'
            variant='body2'
            onClick={() => {
              user?.role === 'user'
                ? window.open(t('URL.DEVICE_MANAGEMENT'))
                : window.open(t('URL.DEVICE_MANAGEMENT_ADMIN'));
            }}
          >
            {t('OTP.CALIBRATE')}
          </Link>
        </Box>
      )}
      <Box pt={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        {otp.length <= 5 ? (
          <SlideButton
            id={'confirm'}
            primary
            large
            key={'confirm'}
            onClick={() => submitOtp()}
            style={{
              padding: '12px 26px',
            }}
            disabled={otp.length <= 5}
          >
            {t('OTP.CONFIRM')}
          </SlideButton>
        ) : (
          <Box mb={2}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box p={1} pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ marginRight: '4px' }}>{t('OTP.RESEND_CODE_PROMPT')}</p>

        <Link component='button' variant='body2' onClick={() => handleClickResend()}>
          {t('OTP.RESEND_CODE')}
        </Link>
      </Box>
    </>
  );
}
