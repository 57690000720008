// GuardHandler.tsx
import { FC, ReactElement, useContext } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { userCtx } from 'context/context';

import NotPrivilagesRegister from 'modules/no-access/NotPrivilagesRegister';
import NotPrivileges from 'modules/no-access/NotPrivileges';

import SubscriptionGuard from './SubscriptionGuard';

interface GuardHandlerProps {
  route: DBRoute;
  children: ReactElement;
}

const GuardHandler: FC<GuardHandlerProps> = ({ route, children }) => {
  const user = useContext(userCtx);

  const isCorrect = (route: DBRoute): boolean => {
    if (route.isDevelop && process.env.REACT_APP_HIDE_DEV_THINGS !== 'false') {
      return false;
    } else return !!route[user.role];
  };

  const isCorrectFullRegister = (route: DBRoute): boolean => {
    return !route.onlyFinishedRegister || (route.onlyFinishedRegister && user.isFullAccount);
  };

  // create new guards if needed
  if (!isCorrect(route)) return <NotPrivileges />;
  if (!isCorrectFullRegister(route)) return <NotPrivilagesRegister />;
  // -------------------------------------------------------------------
  let WrappedComponent = children;

  const conditions = [
    {
      condition: route.minLvlSubscription,
      component: (
        <SubscriptionGuard minLvlSubscription={route.minLvlSubscription}>
          {WrappedComponent}
        </SubscriptionGuard>
      ),
    },
  ];

  conditions.forEach(({ condition, component }) => {
    if (condition) {
      WrappedComponent = component;
    }
  });

  return WrappedComponent;
};

export default GuardHandler;
