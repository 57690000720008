import sendRequestFunctions from 'service/common/sendRequestFunctions';
import { SearchParamFormat } from 'utils/Format';

import { DeviceList, Pairing, QrCode } from './2FAvalues';

export default function twoFaServiceFunction() {
  const { sendRequestNoBody, sendRequestNoBodyNoJSON, sendRequestWithBodyNoJSON } =
    sendRequestFunctions(process.env.REACT_APP_2FA_API_URL);

  // -------------------------------------------------------------------------------------------------------------------------

  function getQrCode(role: 'user' | 'worker', userId?: string): Promise<QrCode> {
    let adminInPath = '/bo/pairings';
    if (role === 'worker') adminInPath = `/admin/bo/pairings/${userId}`;
    return sendRequestNoBody(`/v3${adminInPath}`, 'POST');
  }

  function checkPairing(requestId: string): Promise<Pairing> {
    return sendRequestNoBody(`/v3/bo/pairings/${requestId}`, 'GET');
  }

  function sendName(
    role: 'user' | 'worker',
    requestId,
    name: string,
    userId?: string
  ): Promise<any> {
    const strippedName = name.replace(/\s+/g, ' ');

    return sendRequestNoBodyNoJSON(
      `/v3${
        role === 'worker'
          ? `/admin/bo/pairings/${requestId}/${userId}`
          : `/bo/pairings/${requestId}`
      }/setname?name=${strippedName}`,
      'POST'
    );
  }

  function sendPuk(
    role: 'user' | 'worker',
    requestId: string,
    puk: string,
    userId?: string
  ): Promise<any> {
    return sendRequestWithBodyNoJSON(
      `/v3${
        role === 'worker'
          ? `/admin/bo/pairings/${requestId}/${userId}`
          : `/bo/pairings/${requestId}`
      }/puk`,
      'PUT',
      JSON.stringify({ puk: puk })
    );
  }

  function generatePuk(
    role: 'user' | 'worker',
    requestId: string,
    userId?: string
  ): Promise<{ puk: string }> {
    return sendRequestNoBody(
      `/v3${
        role === 'worker'
          ? `/admin/bo/pairings/${requestId}/${userId}`
          : `/bo/pairings/${requestId}`
      }/generatepuk`,
      'POST'
    );
  }

  function getDevices(
    role: 'user' | 'worker',
    page: number,
    size = 5000,
    status = undefined,
    userId = undefined,
    deviceName = undefined,
    orderBy = undefined,
    order = undefined
  ): Promise<DeviceList> {
    return sendRequestNoBody(
      SearchParamFormat(`/v3${role === 'worker' ? '/admin' : ''}/bo/pairings`, {
        page,
        size,
        status,
        userId,
        deviceName,
        sort: orderBy && order ? `${orderBy}:${order}` : undefined,
      }),
      'GET'
    );
  }

  function blockDevice(role: 'user' | 'worker', requestId: string, userId?: string): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/v3${
        role === 'worker'
          ? `/admin/bo/pairings/${requestId}/${userId}`
          : `/bo/pairings/${requestId}`
      }`,
      'DELETE'
    );
  }

  function activateDeviceWorker(
    id: string,
    userId: string,
    code: string,
    activationState: number,
    challenge: string
  ): Promise<any> {
    return sendRequestWithBodyNoJSON(
      `/v3/manager/bo/pairings/${id}/activate/${userId}/${activationState}`,
      'POST',
      JSON.stringify({ response: code, challenge, time: new Date().toISOString() })
    );
  }

  function activateDeviceAdmin(id: string, userId: string, activationState: number): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/v3/admin/bo/pairings/${id}/activate/${userId}/${activationState}`,
      'POST'
    );
  }

  function changeDevicePUKAdmin(
    role: 'user' | 'worker',
    userId: string,
    id: string,
    puk: string
  ): Promise<any> {
    return sendRequestWithBodyNoJSON(
      `/v3${
        role === 'worker'
          ? `/admin/bo/pairings/${id}/${userId}/changepuk`
          : `/bo/pairings/${id}/changepuk`
      }`,
      'POST',
      JSON.stringify({
        puk,
      })
    );
  }

  function verifyCode(responseCode: string, id: string): Promise<any> {
    return sendRequestNoBodyNoJSON(`/v3/bo/pairings/${responseCode}/activate/${id}`, 'POST');
  }

  return {
    getQrCode,
    checkPairing,
    sendName,
    sendPuk,
    generatePuk,
    getDevices,
    blockDevice,
    activateDeviceWorker,
    activateDeviceAdmin,
    changeDevicePUKAdmin,
    verifyCode,
  };
}
