import sendRequestFunctions from 'service/common/sendRequestFunctions';
import { SearchParamFormat } from 'utils/Format';

import { OcraValue, UpdateOcra } from '../iam/UserValue';

export default function orchestratorServiceFunction() {
  const { sendRequestNoBody, sendRequestNoBodyNoJSON, sendRequestWithBody } = sendRequestFunctions(
    process.env.REACT_APP_ORCHESTRATOR_API_URL
  );

  function createOCRA(token: OcraValue): Promise<any> {
    return sendRequestWithBody('/ocra', 'POST', JSON.stringify(token));
  }

  function updateOCRA(token: UpdateOcra): Promise<any> {
    return sendRequestWithBody('/ocra', 'PUT', JSON.stringify(token));
  }

  function updateOCRAAdmin(token: UpdateOcra): Promise<any> {
    return sendRequestWithBody('/ocra/admin', 'PUT', JSON.stringify(token));
  }

  function getAllOCRA(searchString: string): Promise<any> {
    //{ data: OcraValue[]; total: number }
    return sendRequestNoBody(`/ocra/admin/tokens?${searchString}`, 'GET');
  }

  function getMyOCRA(searchString: string): Promise<any> {
    //{ data: OcraValue[]; total: number }
    return sendRequestNoBody(`/ocra/reservedByMe?${searchString}`, 'GET');
  }

  function deleteOCRA(serialNumber: string): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/ocra?serialNumber=${serialNumber.replace('#', '%23')}`,
      'DELETE'
    );
  }

  function assignOCRA(serialNumber: string, userId: string | number): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/ocra/assign?userId=${userId}&serialNumber=${serialNumber.replace('#', '%23')}`,
      'POST'
    );
  }

  function revokeOCRA(serialNumber: string): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/ocra/revoke?serialNumber=${serialNumber.replace('#', '%23')}`,
      'POST'
    );
  }

  function revokeMyOCRA(): Promise<any> {
    return sendRequestNoBodyNoJSON(`/ocra/revoke/my`, 'POST');
  }

  function reserveOCRA(serialNumber: string, userId: string | number): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/ocra/reservation/add?userId=${userId}&serialNumber=${serialNumber.replace('#', '%23')}`,
      'POST'
    );
  }

  function unreserveOCRA(serialNumber: string): Promise<any> {
    return sendRequestNoBodyNoJSON(
      `/ocra/reservation/remove?serialNumber=${serialNumber.replace('#', '%23')}`,
      'POST'
    );
  }

  function getPukCode(serialNumber: string): Promise<any> {
    return sendRequestNoBody(`/ocra/puk/${serialNumber}`, 'GET');
  }

  function doOcraDiagnosisAdmin(serialNumber: string, code: string): Promise<any> {
    const question = '0000000';
    const requestData = {
      question: question,
      code: code,
    };
    return sendRequestWithBody(
      `/ocra/admin/diagnostics/${serialNumber}`,
      'POST',
      JSON.stringify(requestData)
    );
  }

  function doOcraDiagnosisUser(serialNumber: string, code: string): Promise<any> {
    const question = '0000000';
    const requestData = {
      question: question,
      code: code,
    };
    return sendRequestWithBody(
      `/ocra/diagnostics/${serialNumber}`,
      'POST',
      JSON.stringify(requestData)
    );
  }

  function getUsersOcraOrders(
    page: number,
    size: number,
    status?: number[],
    userId?: string
  ): Promise<any> {
    const statusString = status
      ? `${status.length > 0 ? '&statusId=' : ''}${status.join('&statusId=')}`
      : '';
    return sendRequestNoBody(
      `/ocra/admin/tokens?page=${page}&size=${size}${
        userId ? `&userId=${userId}` : ''
      }${statusString}`,
      'GET'
    );
  }

  function getMyOcraOrders(page: number, size: number, availableOnly?: boolean): Promise<any> {
    const url = SearchParamFormat(`/ocra/ReservedByMe`, {
      page,
      size,
      availableOnly,
    });
    return sendRequestNoBody(url, 'GET');
  }

  function getOcra(): Promise<any> {
    return sendRequestNoBody(`/ocra`, 'GET');
  }

  return {
    createOCRA,
    updateOCRA,
    updateOCRAAdmin,
    getAllOCRA,
    getMyOCRA,
    deleteOCRA,
    assignOCRA,
    revokeOCRA,
    revokeMyOCRA,
    reserveOCRA,
    unreserveOCRA,
    getPukCode,
    doOcraDiagnosisAdmin,
    doOcraDiagnosisUser,
    getUsersOcraOrders,
    getMyOcraOrders,
    getOcra,
  };
}
