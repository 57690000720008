export const EZZK_EN = {
  EZZK: {
    NEW_RECORD_PAGE_TITLE: 'Guaranteed Conversions',
    VERSION: 'Version',
    DATE: 'Effective from',
    CHOOSE_THIS_OPTION: 'Choose Conversion',
    UPLOAD_FILES_BUTTON: 'Choose Files',
    EMPTY: 'Empty',
    ASSISTANT: 'Assistant',
    ELECTRONIC_DOCUMENTS: 'Electronic Documents',
    ELECTRONIC_DOCUMENT: 'Electronic Document',
    PAPER_DOCUMENTS: 'Paper Documents',
    PAPER_DOCUMENT: 'Paper Document',
    AUTHORIZATION_MARKS: 'Authorization Means',
    AUTHORIZATION_MARK: 'Authorization Means',
    CONVERSION_INFO: 'Guaranteed Conversion Data',
    HELP_BUTTON: 'Help',
    SHOW_PREVIEW: 'Show Preview',
    REMOVE_DOCUMENT: 'Remove Document',
    REMOVE_DOCUMENT_TEXT:
      'Do you really want to remove this document? This action may not be reversible.',
    EDIT_DOCUMENT: 'Edit Document',
    EDIT_DOCUMENT_BUTTON: 'Edit Document',
    ADD_DOCUMENT: 'Add Document',
    FILE_NAME: 'Uploaded File Name',
    DOCUMENT_NAME: 'Document Name',
    DOCUMENT_NAME_HELP: `The field specifies the name of the original electronic document according to the following rules:
    <br/>
    - in the case of guaranteed conversion of a single electronic document that is not explicitly marked in paper form, the value "Document" is entered as the name
    <br/>
    - in the case of multiple original electronic documents, the name of the document is specified as used to label the document in electronic form (the name of the original file) for the purpose of identifying it in the description of the authorization link to authorized documents
    <br/>
    - in the case of an electronic document in PDF format containing multiple signatures in PAdES format, which refer to different parts of the document, the name is derived from the name of the original file and the order suffix (e.g., contract-1, contract-2, etc.)`,
    DOCUMENT_TYPE: 'Document Type',
    DOCUMENT_TYPE_HELP: `The field specifies the format of the document as stated in the authorization means. If the document (or its part) is not authorized, the format of the document is specified as stated in the document, e.g., by its extension:
    <br/>
    - "PDF" - electronic document in Portable Document format
    <br/>
    - "TXT" - electronic document in Plain Text Format (.txt),
    <br/>
    - "PNG" - electronic document in Portable Network Graphics (.png)"
    <br/>
    - "XMLDataContainer" - electronic document in (XML) format in the structure of "XML data container".
    <br/>
    - values for other formats are formed from the usual extension of the used format or from the usual abbreviation of the name of the used format to ensure clear and non-conflicting identification with other formats in accordance with §57d par. 1 of the decree of the Ministry of Finance of the Slovak Republic no. 55/2014 Coll. on standards for public administration information systems.`,
    HASH_TYPE: 'Hash Function',
    HASH_TYPE_HELP:
      'The field specifies the name of the cryptographic function used to calculate the hash of the newly created electronic document. Identification of the cryptographic function used for calculating the electronic hash is performed in accordance with §11 par. 1 letter m) of Act no. 272/2016 Coll. on trusted services for electronic transactions in the internal market and on the amendment and supplementation of certain laws (Act on Trusted Services) (e.g., "sha-256").',
    HASH_VALUE: 'Hash Value',
    HASH_VALUE_HELP:
      'The field specifies the value of the electronic hash (hash) of the newly created electronic document, encoded in BASE64.',
    UPLOAD_FILES_TEXT: 'Upload a file for this document.',
    UPLOAD_ERROR_TEXT: 'Some files are not supported and have therefore been removed.<br/>:files',
    EDIT_ELECTRONIC_DOCUMENT: 'Edit Electronic Document',
    ADD_AUTHORIZATION: 'Add Authorization Means',
    EDIT_AUTHORIZATION_MARK: 'Edit Authorization Means',
    EDIT_AUTHORIZATION_MARK_BUTTON: 'Edit Authorization Means',
    NO_DOCUMENTS: 'No documents have been created',
    AUTHORIZATION_DOCUMENTS: 'Authorized Documents',
    AUTHORIZATION_DOCUMENTS_HELP:
      "The field specifies the name of the electronic document associated with the given authorization. The name is stated according to the 'Document Name' field in the original documents section.",
    AUTHORIZATION_TYPE: 'Authorization Type',
    AUTHORIZATION_TYPE_HELP: 'The field specifies the type of authorization used.',
    AUTHORIZATION_TYPE_NOTE: 'Other Authorization Type',
    AUTHORIZATION_TYPE_NOTE_HELP:
      'The field specifies another recognized method of authorization in the future.',
    AUTHORIZATION_STATUS: 'Authorization Status',
    AUTHORIZATION_STATUS_HELP:
      'The field specifies the result of the authorization validity verification.',
    AUTHORIZATION_DATE: 'Authorization Time',
    AUTHORIZATION_DATE_HELP:
      'The field specifies the time of authorization of the original electronic document, if it can be determined from the authorization means (i.e., when the authorization time is stated), the time is stated in ISO8601 format with time zone UTC+01:00 CET and +02:00 for CEST.',
    AUTHORIZATION_VERIFY_DATE: 'Authorization Verification Time',
    AUTHORIZATION_VERIFY_DATE_HELP:
      'The field specifies the time when the validity of the authorization was verified during the guaranteed conversion, the time is stated in ISO8601 format with time zone UTC+01:00 CET and +02:00 for CEST.',
    AUTHORIZATION_PLACE: 'Authorization Place',
    AUTHORIZATION_PLACE_HELP:
      "The place of authorization of the original electronic document, if it can be determined from the authorization means (i.e., when stated). Otherwise, the string 'Not Specified' is entered.",
    AUTHORIZATION_OTHER: 'Other Authorization Information',
    AUTHORIZATION_OTHER_HELP: `The field specifies other information about the authorization that is important for the given type of authorization and cannot be stated in any of the above-mentioned authorization characteristics. In the case of using an electronic signature or electronic seal as an authorization means, information on whether the used certificate was qualified is stated in the following format: "certificate - qualified" or "certificate - unqualified". At the same time, this field contains information on whether the private key of the signer is stored on a secure device - QSCD in the following format: "certificate on QSCD" or "certificate not on QSCD". If the certificate is issued as a qualified certificate, it can be identified by the following features:
<br/><br/>
- the certificate must contain the QcCompliance OID item (0.4.0.1862.1.1),
<br/><br/>
- the certificate issued according to the SR legislation must contain the certificatePolicies OID extension (2.5.29.32), which must at least contain the OID of the certification policy of the NBÚ OID (1.3.158.36061701.0.0.0.1.2.2)
<br/><br/>
- the certificate issuer is listed in the trusted list according to Commission Implementing Decision no. 2015/1505`,
    AUTHORIZATION_PERSON_IDENTITY: 'Person Identifier',
    AUTHORIZATION_PERSON_IDENTITY_HELP: `The field specifies the identifier of the natural or legal person who performed the authorization. The identifier is stated in the following structure:
<br/><br/>
<b>Legal Person</b>
<br/>
- Organization name in the certificate attribute "organisationName"
<br/>
- Identification data of the organization in the certificate attribute as supplementary data in the certificate attribute "serialNumber" or "organizationIdentifier" in the following structure:
<br/>
- identifier type consisting of the first 3 characters determining the type of identity reference:
<br/>
&emsp;- VAT - for identification based on tax identification number
<br/>
&emsp;- NTR - for identification based on the organization's identification number. The following two characters contain the country code according to ISO 3166 (for Slovakia "SK"), which issued and provides the data stated in the second part based on legislative requirements defined in its country
<br/>
&emsp;- SZ - for identification based on a set of characters assigned according to § 27 par. 4 of the Act
<br/>
&emsp;- followed by 2 characters containing the country code according to ISO 3166 (for Slovakia "SK"), which issued and provides the data stated in the second part based on legislative requirements defined in its country,
<br/>
&emsp;- separated by a dash "-" and followed by the identifier itself, the type of which is determined by the first three characters. Example "SZ:SK-123123".
<br/><br/>
<b>Natural Person</b>
<br/>
- first name of the signer in the certificate attribute "givenName"
<br/>
- surname of the signer in the certificate attribute "surname"
<br/>
- identifier of the signer in the certificate attribute "serialNumber" in the following structure:
<br/>
- identifier type consisting of the first 3 characters determining the type of identity reference:
<br/>
&emsp;- PNO - for identification based on personal number, which is the birth number for citizens of the Slovak Republic or for foreigners who have been assigned a birth number according to Act no. 301/1995 Coll. on the birth number
<br/>
&emsp;- IDC - for identification based on the identification card number
<br/>
&emsp;- PAS - for identification based on passport number
<br/>
&emsp;- followed by 2 characters containing the country code according to ISO 3166 (for Slovakia "SK"), which issued and provides the data stated in the second part based on legislative requirements defined in its country,
<br/>
&emsp;- separated by a dash "-" and followed by the identifier itself, the type of which is determined by the first three characters. Example "IDCSK-123123"
<br/>
- If a pseudonym is used, it must be clearly stated that it is a pseudonym used to identify the person. If a pseudonym is used, the following data is obtained from the certificate:
<br/>
&emsp;- from the certificate attribute "pseudonym" - the pseudonym text.
<br/>
&emsp;- from the certificate attribute "commonName" - the commonName item must at least contain the text "PSEUDONYM").`,
    AUTHORIZATION_PERSON_REPRESENTED: 'Represented Person',
    AUTHORIZATION_PERSON_REPRESENTED_HELP:
      "The field specifies the identification data of the natural or legal person on whose behalf the authorizing person acted. The identifier is stated identically as in the case of the identifier of the authorizing person, with each mandate item in the certificate being preceded by the text 'MANDATE' in capital letters separated by a space. If the certificate does not contain any authorization, the text 'None' is entered.",
    AUTHORIZATION_MANDAT: 'Mandate',
    AUTHORIZATION_MANDAT_HELP: `This field specifies the authorization identifier as the last number of the OID (Object Identifier) of the certificate policy extension (e.g., 1.3.158.36061701.1.1.xyz). The identifier "xyz" represents the authorization number in the list of authorizations, not the designation (text) of the authorization. Additionally, the text of the authorization specified in the "userNotice-explicitText" attribute of the certificate MAY be included.
      <br/><br/>
      If the certificate does not contain any authorization, enter the text "Žiaden" (None).`,
    REMOVE_AUTHORIZATION_MARK: 'Remove Authorization Mark',
    REMOVE_AUTHORIZATION_MARK_TEXT: 'Do you really want to remove this authorization mark?',
    TIMESTAMPS: 'Timestamps',
    EDIT_TIMESTAMP: 'Edit Timestamp',
    EDIT_TIMESTAMP_BUTTON: 'Edit Timestamp',
    REMOVE_TIMESTAMP: 'Remove Timestamp',
    REMOVE_TIMESTAMP_TEXT: 'Do you really want to remove this timestamp?',
    ADD_TIMESTAMP: 'Add Timestamp',
    TIMESTAMP_PUBLISHER: 'Timestamp Publisher',
    TIMESTAMP_PUBLISHER_HELP:
      'Enter the identification of the timestamping service provider as specified in the Common Name attribute of the timestamp issuer certificate.',
    TIMESTAMP_TYPE: 'Timestamp Type',
    TIMESTAMP_TYPE_HELP: 'Specify the type of timestamp - Qualified or Non-qualified.',
    TIMESTAMP_STATUS: 'Timestamp Status',
    TIMESTAMP_STATUS_HELP: 'Specify the result of the validity verification of the timestamp.',
    TIMESTAMP_CREATE_DATE: 'Timestamp Creation Date',
    TIMESTAMP_CREATE_DATE_HELP:
      'Specify the date and time when the timestamp was created, as indicated in the timestamp. The time is provided in ISO8601 format with the time zone UTC+01:00 for CET and +02:00 for CEST.',
    TIMESTAMP_VERIFY_DATE: 'Timestamp Verification Date',
    TIMESTAMP_VERIFY_DATE_HELP:
      'Specify the date and time when the validity verification of the timestamp was performed during the guaranteed conversion. The time is provided in ISO8601 format with the time zone UTC+01:00 for CET and +02:00 for CEST.',
    EDIT_PAPER_FORMAT: 'Edit Paper Format',
    EDIT_PAPER_FORMAT_BUTTON: 'Edit Paper Format',
    REMOVE_PAPER_FORMAT: 'Remove Paper Format',
    REMOVE_PAPER_FORMAT_TEXT: 'Do you really want to remove this paper format?',
    ADD_PAPER_FORMAT: 'Add Paper Format',
    EDIT_PAPER_DOCUMENT: 'Edit Document',
    EDIT_DOCUMENT_CONFIRM_BUTTON: 'Edit Document',
    NUMBER_OF_LISTS: 'Number of Lists',
    NUMBER_OF_LISTS_HELP:
      'Specify the total number of pages of the paper document created by transforming original electronic documents into paper form. If multiple electronically signed documents are converted simultaneously, the cumulative number of pages is provided, including pages inserted to mark the beginning and end of the original electronic documents. The number of pages does not include the created paper document of the authentication certificate.',
    NUMBER_OF_NON_EMPTY_PAGES: 'Number of Non-empty Pages',
    NUMBER_OF_NON_EMPTY_PAGES_HELP:
      'Specify the number of pages of the document with the specified format.',
    PAPER_FORMATS_TITLE: 'Paper Formats',
    PAPER_FORMAT_TYPE: 'Paper Format',
    PAPER_FORMAT_TYPE_HELP: 'Specify the paper format used for the newly created paper document.',
    PAPER_FORMAT_NOTE: 'Paper Format - Other',
    PAPER_FORMAT_NOTE_HELP:
      'Specify the other paper format used for the newly created paper document.',
    PAPER_FORMAT_VALUE: 'Number of Pages',
    PAPER_FORMAT_VALUE_HELP:
      'Specify the number of pages of the document with the specified format.',
    PAPER_FORMAT_IS_EMPTY:
      'One of the paper formats is not filled in, so it is not possible to add another paper format.',
    EDIT_CONVERSION_INFO: 'Edit Conversion Information',
    EDIT_CONVERSION_INFO_BUTTON: 'Edit',
    COVERSION_INFO: 'Guaranteed Conversion Data',
    CONVERSION_ECV: 'Record Identification Number of the Guaranteed Conversion',
    CONVERSION_ECV_HELP:
      "Specify the record identification number of the guaranteed conversion in the format URI https://data.gov.sk/id/egov/conversion-record/ID, where 'ID' is the unique record identification number.",
    CONVERSION_DATE: 'Date and Time of Guaranteed Conversion',
    CONVERSION_DATE_HELP:
      'Specify the date and time of the guaranteed conversion in ISO8601 format with the time zone UTC+01:00 for CET and +02:00 for CEST.',
    CONVERSION_ICO: 'ICO (Identification Number)',
    CONVERSION_ICO_HELP:
      'Specify the identifier of the person or organizational unit that performed the guaranteed conversion, if such identifier is assigned. It is entered in the form of URI https://data.gov.sk/id/legal-subject/ICO, where the ICO identifier is specified at the end.',
    CONVERSION_PO_NAME: 'Name of the Legal Entity',
    CONVERSION_PO_NAME_HELP:
      'Specify the name of the entity authorized by law to perform the guaranteed conversion, including the organizational unit if the conversion is performed by an organizational unit.',
    CONVERSION_FORENAME: 'First Name',
    CONVERSION_FORENAME_HELP:
      'Specify the first name of the individual who performed the guaranteed conversion.',
    CONVERSION_SURNAME: 'Last Name',
    CONVERSION_SURNAME_HELP:
      'Specify the last name of the individual who performed the guaranteed conversion.',
    CONVERSION_WORK_POSITION: 'Position or Job Title',
    CONVERSION_WORK_POSITION_HELP:
      'Specify the position or job title of the individual who performed the guaranteed conversion, if applicable (e.g., municipal office employee, district court employee, lawyer, notary, notary intern, etc.).',
    CHOOSE_COMPANY_INPUT: 'Choose Company',
    CHOOSE_COMPANY_INPUT_HELP:
      'This company will act as a legal entity in the guaranteed conversion.',
    CONVERSION_GET_ECV: 'Load record number',
    PAPER_DOCUMENT_NAME: 'Document Name',
    PAPER_DOCUMENT_NAME_HELP:
      'Specify the name of the paper document to identify the original paper document in the created electronic document.',
    SECURITY_MARKS_TITLE: 'Security Features',
    EDIT_SECURITY_MARK: 'Edit Security Feature',
    EDIT_SECURITY_MARK_CONFIRM_BUTTON: 'Edit Security Feature',
    REMOVE_SECURITY_MARK: 'Remove Security Feature',
    REMOVE_SECURITY_MARK_TEXT: 'Do you really want to remove this security feature?',
    ADD_SECURITY_MARK: 'Add Security Feature',
    SECURITY_MARK_TYPE: 'Textual Description',
    SECURITY_MARK_TYPE_HELP:
      'Specify the textual description of the security feature, e.g., handwritten signature of person, officially certified signature of person, etc.',
    SECURITY_MARK_TYPE_NOTE: 'Textual Description - Other',
    SECURITY_MARK_TYPE_NOTE_HELP:
      'Specify the other textual description of the security feature, e.g., handwritten signature of person, officially certified signature of person, etc.',
    SECURITY_MARK_PAGE: 'Occurrence on Page',
    SECURITY_MARK_PAGE_HELP:
      'Specify the page number in the original document where the described security feature is located. Non-empty pages are counted. The page numbering in the original document, if different from the actual page order, is not considered.',
    SECURITY_MARK_LIST: 'Occurrence on Sheet',
    SECURITY_MARK_LIST_HELP:
      'Specify the sheet number in the original document where the described security feature is located.',
    SECURITY_MARK_PLACEMENT: 'Placement Location on Document Page',
    SECURITY_MARK_PLACEMENT_HELP:
      'Enter the description of the location on the page where the security feature is placed (e.g., top right).',
    SECURITY_MARK_PAGE_IN_ELECTRONIC: 'Occurrence on Page in New Electronic Document',
    SECURITY_MARK_PAGE_IN_ELECTRONIC_HELP:
      'Specify the page number in the converted (new) document where the described security feature is located. The new document is created as a sequence of pages containing the converted image of all original documents in the order in which they were submitted for conversion.',
    DISABLE_EZZK_TITLE: 'Turn off Guaranteed Conversions',
    DISABLE_EZZK_BODY:
      'This function is primarily intended for legal entities performing guaranteed conversion.<br/><br/>Do you want to continue or disable this functionality?',
    DISABLE: 'Turn off',
    CONTINUE: 'Continue',
    LOGIN: 'Login',
    PASSWORD: 'Password',
    SAVE_ACCOUNT: 'Save account',
    WHY_WE_NEED_LOGIN:
      'We need login details to the Guaranteed Conversions portal to authorize operations performed on the Guaranteed Conversions server.<br/><br/>Without these details, you cannot continue creating Guaranteed Conversions.',
    VALIDATE_DOCUMENT: 'Validate document',
    TYPES: {
      PAPER_TO_ELECTRONIC: 'Paper to Electronic',
      PAPER_TO_ELECTRONIC_DESCRIPTION: 'Conversion from paper format to electronic format.',
      ELECTRONIC_TO_PAPER: 'Electronic to Paper',
      ELECTRONIC_TO_PAPER_DESCRIPTION: 'Conversion from electronic format to paper format.',
      ELECTRONIC_TO_ELECTRONIC: 'Electronic to Electronic',
      ELECTRONIC_TO_ELECTRONIC_DESCRIPTION:
        'Conversion from one electronic format to another electronic format.',
    },
    PAPER_FORMATS: {
      A1: 'Format A1',
      A2: 'Format A2',
      A3: 'Format A3',
      A4: 'Format A4',
      A5: 'Format A5',
      A6: 'Format A6',
      A7: 'Format A7',
      B3: 'Format B3',
      B4: 'Format B4',
      B5: 'Format B5',
      B6: 'Format B6',
      B7: 'Format B7',
      C3: 'Format C3',
      C4: 'Format C4',
      C5: 'Format C5',
      C6: 'Format C6',
      C7: 'Format C7',
      OTHER: 'Other format',
    },
    SECURITY_MARKS: {
      ROUND_STAMP: 'Round stamp',
      ROUND_STAMP_WITH_STATE_EMBLEM: 'Round stamp with state emblem',
      EMBOSSED_STAMP: 'Embossed stamp',
      WATERMARK: 'Watermark',
      SIGNATURE: 'Handwritten signature',
      OFFICIALLY_CERTIFIED_SIGNATURE: 'Officially certified signature',
      PAPER_CLIP: 'Paper clip',
      ADHESIVE_LABEL: 'Adhesive label',
      THREAD_METAL: 'Thread - metal',
      TRICOLOR_STRING: 'Tricolor string',
      PERMANENT_CONNECTION_OF_THE_DOCUMENT: 'Permanent connection of the document - other',
      STAMP: 'Stamp',
      ANOTHER_MANUAL_INPUT: 'Other manual input',
    },
    MARK_PLACEMENTS: {
      BOTTOM: 'Bottom',
      TOP: 'Top',
      BOTTOM_BORDER: 'Bottom border',
      TOP_BORDER: 'Top border',
      LEFT_BORDER: 'Left border',
      RIGHT_BORDER: 'Right border',
      MIDDLE: 'Middle',
      LEFT: 'Left',
      LEFT_BOTTOM: 'Bottom left',
      LEFT_TOP: 'Top left',
      RIGHT: 'Right',
      RIGHT_BOTTOM: 'Bottom right',
      RIGHT_TOP: 'Top right',
    },
    FILE_TYPES: {
      PDF: 'PDF',
      PDF_A1: 'PDF/A-1',
      PDF_A2: 'PDF/A-2',
      PNG: 'PNG',
      TXT: 'TXT',
      XMLDATACONTAINER: 'XMLDataContainer',
      OTHER: 'Other',
    },
    AUTHORIZATION_TYPES: {
      NO_AUTHORIZATION_NO_TIMESTAMP: 'No authorization and timestamp',
      QUALIFIED_ELECTRONIC_SIGNATURE: 'Qualified electronic signature',
      ADVANCED_ELECTRONIC_SIGNATURE: 'Advanced electronic signature',
      ELECTRONIC_SIGNATURE: 'Electronic signature',
      QUALIFIED_ELECTRONIC_SEAL: 'Qualified electronic seal',
      ADVANCED_ELECTRONIC_SEAL: 'Advanced electronic seal',
      ELECTRONIC_SEAL: 'Electronic seal',
      OTHER: 'Other',
    },
    AUTHORIZATION_STATUSES: {
      VALID: 'Valid',
      INVALID: 'Invalid',
      UNKNOWN: 'Unknown',
    },
    TIMESTAMP_TYPES: {
      QUALIFIED: 'Qualified',
      UNQUALIFIED: 'Unqualified',
    },
    TIMESTAMP_STATUSES: {
      VALID: 'Valid',
      INVALID: 'Invalid',
      UNKNOWN: 'Unknown',
    },
    ERRORS: {
      REQUIRED: 'This field is required.',
      DOCUMENT_NAME_REQUIRED: 'This field is required.',
      IS_NOT_NUMBER: 'You must enter a positive integer value greater than 0.',
      ABOVE_LIST_NUMBER: 'The value exceeds the specified number of sheets.',
      PAPER_FORMAT_ALREADY_USED: 'This paper format is already in the list.',
      NUMBER_MUST_BE_POSITIVE: 'The number must be positive.',
      NUMBER_IS_OUT_OF_LIMIT:
        'The number is out of limit. The total sum of sheets must not exceed the overall number.',
      WRONG_NAME: 'Document name must be filled in.',
      WRONG_NUMBER_OF_PAGES: 'Number of sheets and number of non-empty pages must not be 0.',
      WRONG_EMPTY_PAGES: 'Number of non-empty pages must not exceed the number of sheets.',
      WRONG_PAPER_FORMAT_COUNT: 'Document must contain at least 1 paper format.',
      WRONG_PAPER_FORMAT:
        'The total number of paper formats must be equal to the number of sheets.',
      WRONG_PAPER_FORMAT_VALUE: 'Paper format must not have 0 sheets.',
      WRONG_SECURITY_MARKS_COUNT: 'Document must contain at least 1 security mark.',
      WRONG_SECURITY_MARKS_LIST: 'Some security marks exceed the number of sheets.',
      WRONG_SECURITY_MARKS_PAGE: 'Some security marks exceed the number of pages.',
      WRONG_COMPANY: 'You must select a company / legal entity.',
      WRONG_ECV: 'Please wait until the record number is loaded.',
      CONVERSION_ECV_NOT_LOADED: 'Failed to load the record number.',
    },
    CHECK: {
      CHECK_VALIDITY: 'Check Inputs',
      CHECK_ERRORS_IN_FORM: 'Check Form Errors',
      NEED_AT_LEAST_ONE_PAPER_DOCUMENT: 'Create at least <b>1 document</b>.',
      SOME_DOCUMENTS_MISSING_NAME: 'Some documents <b>are missing</b> a <b>name</b>.',
      SOME_DOCUMENTS_MISSING_NUMBER_OF_LISTS:
        'Some documents <b>are missing</b> the <b>number of sheets</b>.',
      SOME_DOCUMENTS_MISSING_NUMBER_OF_NON_EMPTY_PAGES:
        'Some documents <b>are missing</b> the <b>number of non-empty pages</b>.',
      SOME_DOCUMENTS_MISSING_PAPER_FORMATS:
        'Some documents <b>are missing</b> <b>paper formats</b>.',
      SOME_DOCUMENTS_MISSING_HASH_TYPE:
        'Some documents <b>are missing</b> the <b>electronic hash function</b>.',
      SOME_DOCUMENTS_MISSING_HASH:
        'Some documents <b>are missing</b> the <b>electronic hash value</b>.',
      SOME_PAPER_FORMATS_MISSING_TYPE:
        'Some paper formats <b>are missing</b> the <b>paper format</b>.',
      SOME_PAPER_FORMATS_MISSING_NOTE:
        'Some paper formats <b>are missing</b> the <b>other paper format</b>.',
      SOME_PAPER_FORMATS_MISSING_VALUE:
        'Some paper formats <b>are missing</b> the <b>number of sheets</b>.',
      SOME_DOCUMENT_PAPER_FORMATS_EXCEED_NUMBER_OF_LISTS:
        'Some documents have <b>excessive number of sheets</b> in paper formats.',
      SOME_DOCUMENTS_MISSING_SECURITY_MARKS:
        'Some documents <b>are missing</b> <b>security marks</b>.',
      SOME_SECURITY_MARKS_MISSING_TYPE:
        'Some security marks <b>are missing</b> the <b>description</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_LIST:
        'Some security marks <b>are missing</b> the <b>occurrence on sheet</b>.',
      SOME_SECURITY_MARKS_NUMBER_OF_LIST_OUT_OF_RANGE:
        'Some security marks have <b>excessive occurrence on sheet</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_PAGE:
        'Some security marks <b>are missing</b> the <b>occurrence on page</b>.',
      SOME_SECURITY_MARKS_NUMBER_OF_PAGE_OUT_OF_RANGE:
        'Some security marks have <b>excessive occurrence on page</b>.',
      SOME_SECURITY_MARKS_MISSING_PLACEMENT:
        'Some security marks <b>are missing</b> the <b>placement location on page</b>.',
      SOME_SECURITY_MARKS_MISSING_NUMBER_OF_PAGE_IN_ELECTRONIC:
        'Some security marks <b>are missing</b> the <b>occurrence on page in new electronic document</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_DOCUMENTS:
        'Some authorization means <b>are missing</b> <b>authorized documents</b>.',
      SOME_AUTHORIZATION_MARKS_DOCUMENTS_NOT_EXISTS:
        'Some authorization means have non-existent <b>authorized documents</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_TYPE:
        'Some authorization means <b>are missing</b> the <b>authorization type</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_TYPE_OTHER:
        'Some authorization means <b>are missing</b> the <b>other authorization type</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_STATUS:
        'Some authorization means <b>are missing</b> the <b>authorization status</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_VERIFY_DATE:
        'Some authorization means <b>are missing</b> the <b>authorization verification time</b>.',
      SOME_AUTHORIZATION_MARKS_MISSING_IDENTITY:
        'Some authorization means <b>are missing</b> the <b>person identifier</b>.',
      SOME_TIMESTAMPS_MISSING_PUBLISHER:
        'Some timestamps <b>are missing</b> the <b>timestamp issuer</b>.',
      SOME_TIMESTAMPS_MISSING_TYPE: 'Some timestamps <b>are missing</b> the <b>timestamp type</b>.',
      SOME_TIMESTAMPS_MISSING_STATUS:
        'Some timestamps <b>are missing</b> the <b>timestamp status</b>.',
      SOME_TIMESTAMPS_MISSING_DATE:
        'Some timestamps <b>are missing</b> the <b>timestamp issuance time</b>.',
      SOME_TIMESTAMPS_MISSING_VERIFY_DATE:
        'Some timestamps <b>are missing</b> the <b>timestamp verification time</b>.',
      NEW_DOCUMENT_MISSING_UPLOADED_FILE: 'In the new document, <b>no file is uploaded</b>.',
      NEW_DOCUMENT_MISSING_NAME: 'In the new document, <b>the document name is not filled in</b>.',
      NEW_DOCUMENT_MISSING_TYPE: 'In the new document, <b>the document type is not filled in</b>.',
      NEW_DOCUMENT_MISSING_HASH_TYPE:
        'In the new document, <b>the electronic hash function is not filled in</b>.',
      NEW_DOCUMENT_MISSING_HASH:
        'In the new document, <b>the electronic hash value is not filled in</b>.',
      NEW_DOCUMENT_MISSING_NUMBER_OF_LISTS:
        'In the new document, <b>the number of sheets is not filled in</b>.',
      NEW_DOCUMENT_MISSING_NUMBER_OF_NON_EMPTY_PAGES:
        'In the new document, <b>the number of non-empty pages is not filled in</b>.',
      NEW_DOCUMENT_MISSING_PAPER_FORMATS:
        'In the new document, <b>the paper formats are not filled in</b>.',
      NEW_DOCUMENT_PAPER_FORMATS_EXCEED_NUMBER_OF_LISTS:
        'In the new document, <b>the number of sheets in paper formats exceeds the limit</b>.',
      CONVERSION_MISSING_ECV:
        'In the conversion data, <b>the record conversion evidence number is not filled in</b>.',
      CONVERSION_MISSING_DATE:
        'In the conversion data, <b>the conversion execution date and time are not filled in</b>.',
      CONVERSION_MISSING_ICO:
        'In the conversion data, <b>the ICO (Identification Number) is not filled in</b>.',
      CONVERSION_MISSING_NAME: 'In the conversion data, <b>the company name is not filled in</b>.',
    },
  },
};
