import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageSignDocument = lazy(() =>
  lazyRetry(() => import('pages/(public)/sign-document/PageSignDocument'), 'PageSignDocument')
);
const PageSignDocumentType = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/sign-document/[type]/PageSignDocumentType'),
    'PageSignDocumentType'
  )
);
const PageValidation = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/(public)/online-validation-of-qualified-electronic-signature-KEP/PageValidation'
      ),
    'PageValidation'
  )
);
const PageDownloadNFQESWebSigner = lazy(() =>
  lazyRetry(
    () => import('pages/(common)/download-NFQES-Web-Signer/PageDownloadNFQESWebSigner'),
    'PageDownloadNFQESWebSigner'
  )
);

const routesSignAndVerify: Array<DBRoute> = [
  {
    path: 'URL.SIGN_DOCUMENT',
    name: 'DASHBOARD_ROUTES.SIGN_DOCUMENT',
    icon: <Icon icon='mdi:file-sign' width={30} height={30} />,
    component: <PageSignDocument />,
    show: true,
    user: true,
    unlogged: true,
    order: 1,
  },
  {
    path: 'URL.SIGN_DOCUMENT_WITH_TYPE',
    component: <PageSignDocumentType />,
    show: false,
    user: true,
    unlogged: true,
  },
  {
    path: 'URL.VALIDATE_DOCUMENT',
    name: 'DASHBOARD_ROUTES.VALIDATE_DOCUMENT',
    icon: <Icon icon='carbon:certificate-check' width={36} height={36} />,
    component: <PageValidation />,
    show: true,
    user: true,
    unlogged: true,
    order: 4,
  },
  {
    path: 'URL.DOWNLOAD_WEB_SIGNER',
    component: <PageDownloadNFQESWebSigner />,
    show: false,
    user: true,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesSignAndVerify;
