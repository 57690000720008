export const FILE_GATEWAY_EN = {
  FILEGATEWAY: {
    TITLE: 'Sign document',
    TITLE_CREATE: 'Upload documents',
    WELCOME: 'Welcome to NFQES',
    WELCOME_MAIN:
      'NFQES is a service of Brain:IT, which is a certification authority, for providing and managing electronic signatures and ensuring legal certainty in electronic signing.',
    WELCOME_SUB:
      'This service guides you through the simple process of electronically signing documents of ',
    WELCOME_SUB_CREATE:
      'On this page, you upload files that your recipients can sign. We recommend checking the settings before saving.',
    CREATE_DATE: 'Creation date',
    DEADLINE: 'Message deadline',
    STATUS: 'Message status',
    NUMBER_OF_FILES: 'Number of files',
    SENDER: 'Sender',
    RECIPIENT: 'Recipient',
    ALREADY_SIGNED:
      'You have already <b>signed</b> this message and the files in it, so there is no need to sign them again. <br/><br/><b>Follow the instructions you received from the sender of this message.</b>',
    FILES: 'Files in message',
    GRAPHICAL_SIGNATURE: 'Graphical signature',
    SAVE_GRAPHICAL_SIGNATURE: 'Save graph. signature',
    REMOVE_GRAPHICAL_SIGNATURE: 'Draw new signature',
    MIN_SIGN_LEVEL: 'Minimum sign level',
    DIGITAL: 'Digital signature',
    ES: 'Electronic signature',
    AdES: 'Advanced electronic signature',
    QES: 'Qualified electronic signature',
    ALLOWED_TYPES: 'Allowed sign methods',
    ALLOW_DIGI: 'Digital signature',
    ALLOW_HAND: 'Hand signature',
    ALLOW_ONLINE_CERT: 'Online certificate',
    ALLOW_CARD: 'ID card or QSCD card',
    AUTO_PREVIEW_FILES: 'Show file details upon load',
    IS_PUBLIC: 'Create public message (original file gateway)',
    SWAP_TO_GENERATE: 'I want a generated signature',
    SWAP_TO_DRAW: 'I want a hand drawn signature',
    SETTINGS: 'Settings',
    RECIPIENTS: 'Recipients',
    RECIPIENTS_EMPTY: 'List of recipients is empty.',
    RECIPIENT_NAME: 'Recipient name',
    RECIPIENT_MAIL: 'Recipient mail address',
    ADD_RECIPIENT: 'Add',
    REMOVE: 'Remove',
    URL_TO_SIGN: 'URL address for',
    URL_TO_DOWNLOAD: 'Download URL address',
    SAVE_MESSAGE: 'Save and send message',
    ERROR_SENDING: 'Error when saving!',
    SHORT_UUID: 'Fill in this field.',
    SENDER_UUID: 'Identification data of the sender',
    SIGNED: 'Signed',
    NO_DOWNLOAD:
      'The files have <b>not yet been signed</b> by all recipients, so they cannot be downloaded.<br/><br/><b>After signing, you will find the download button in this window.</b>',
    DOWNLOAD:
      'The files <b>have been signed</b> by all recipients.<br/><br/><b>Click this message to start the download.</b>',
    LOAD_MESSAGE: 'Load message',
    NO_GRAPHIC_SIGNATURE:
      'To sign a document, you must <b>draw</b> or <b>generate</b> a graphic signature.<br/>You can use the <b>field below</b> to draw or generate a graphic signature.<br/> <br/>The resulting graphic signature will be displayed in the <b>introductory part of the page</b>.',
    CONTINUE_TO_SIGN_BUTTON:
      'To complete the signing, continue to the <b>bottom of the page</b> where you will find the button for signing.',
    DRAW_SIGNATURE: {
      PLACEHOLDER: 'Draw graphical signature here',
    },
    SIGN: 'Sign documents',
    SIGN_AND_PRESS_SIGN_BUTTON:
      'Please draw your signature in the field below and press the SIGN button.',
    EXPIRED: 'This message is past deadline and cannot be signed anymore.',
    ERROR: {
      GET_FILE: 'Failed to load file',
    },
    ARCHIVED_UNTIL: 'Archive until',
    WILL_BE_ARCHIVED: 'Archived until',
  },
  DEFAULT: {
    YES: 'Yes',
    NO: 'No',
  },
};
