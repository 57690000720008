import { QRCodeSVG } from 'qrcode.react';

import { Grid, useTheme } from '@mui/material';

interface QRCodeProps {
  value: string;
}

export default function QRCodeCmp(props: QRCodeProps) {
  const theme = useTheme();

  return (
    <Grid sx={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
      <QRCodeSVG
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={props.value}
        fgColor={theme.palette.text.primary}
        bgColor={theme.palette.background.paper}
      />
    </Grid>
  );
}
