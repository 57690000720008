import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import QueryLanguageSwitcher from 'containers/layout/QueryLanguageSwitcher';

import { ModalProvider } from 'components/Modal';
import { NotifyBox } from 'components/NotifyBox';
import { WalktourProvider } from 'components/Walktour';

import ErrorBoundary from 'modules/error-boundary/ErrorBoundary';

import { useTheme } from '@mui/material';
import {
  ModalProvider as ModalProviderSigner,
  NotifyBox as NotifyBoxSigner,
  SignatureProviders,
} from '@nfqes/enterprise-web-signature';

interface RouterProvidersProps {
  children: ReactNode;
}

export default function RouterProviders({ children }: Readonly<RouterProvidersProps>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const translation = useTranslation(['translation', 'sign', 'certificate', 'otp']);

  return (
    <ErrorBoundary>
      <WalktourProvider>
        <QueryLanguageSwitcher>
          <SignatureProviders theme={theme} navigate={navigate} translation={translation}>
            <NotifyBoxSigner />
            <NotifyBox />
            <ModalProvider>
              <ModalProviderSigner>{children}</ModalProviderSigner>
            </ModalProvider>
          </SignatureProviders>
        </QueryLanguageSwitcher>
      </WalktourProvider>
    </ErrorBoundary>
  );
}
