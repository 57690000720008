import DOMPurify from 'dompurify';

// TOTO TU MUSI BYT, pretoze dompurify meni poradie atributov (konkretne ich otaca) a preto to v useEffectoch toci dokola
DOMPurify.addHook('uponSanitizeElement', (currentNode /*, _hookEvent, _config*/) => {
  const atrs: any = currentNode.attributes ? Array.from(currentNode.attributes).reverse() : [];
  const atrsNamed = atrs.sort((a: Attr, b: Attr) => a.localName.localeCompare(b.localName) * -1);
  const fullAtrs = atrsNamed.map((atr: Attr) => [atr.localName, atr.value]);
  fullAtrs.forEach((atr) => {
    currentNode.removeAttribute(atr[0]);
  });
  fullAtrs.forEach((atr) => {
    currentNode.setAttribute(atr[0], atr[1]);
  });
  return currentNode;
});

export function ReverseFormat(str: string) {
  return [...str].reverse().join('');
}

export function AddressFormat({ street, building, property, postal, city }: AddressProps) {
  // na komplet v celom projekte je vymenen building a property
  // problem je ze aj BE v certifikatoch doplna taku adresu
  const buildingText = building ? `/${building}` : '';
  return `${street} ${property}${buildingText}, ${PSCFormat(postal)} ${city}`;
}

export function PSCFormat(number: string) {
  /* Most of the regular expression engines use backtracking to try all possible execution paths of the regular expression when evaluating an input, 
  in some cases it can cause performance issues, called catastrophic backtracking situations. In the worst case, the complexity of the regular 
  expression is exponential in the size of the input, this means that a small carefully-crafted input (like 20 chars) can trigger catastrophic 
  backtracking and cause a denial of service of the application. Super-linear regex complexity can lead to the same impact too with, in this case, 
  a large carefully-crafted input (thousands chars). */
  //OSTRANIT KED SA OVERI FUNKCNOSŤ NIŽŠIE return ReverseFormat(ReverseFormat(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
  return number.replace(/\d{3}(?=.)/g, '$& ');
}

export function PhoneNumberFormat(number: string) {
  /* Most of the regular expression engines use backtracking to try all possible execution paths of the regular expression when evaluating an input, 
  in some cases it can cause performance issues, called catastrophic backtracking situations. In the worst case, the complexity of the regular 
  expression is exponential in the size of the input, this means that a small carefully-crafted input (like 20 chars) can trigger catastrophic 
  backtracking and cause a denial of service of the application. Super-linear regex complexity can lead to the same impact too with, in this case, 
  a large carefully-crafted input (thousands chars). */
  //OSTRANIT KED SA OVERI FUNKCNOSŤ NIŽŠIE return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return number.replace(/\d{3}(?=.)/g, '$& ');
}

export function NumberFormat(number: string | number) {
  /* Most of the regular expression engines use backtracking to try all possible execution paths of the regular expression when evaluating an input, 
  in some cases it can cause performance issues, called catastrophic backtracking situations. In the worst case, the complexity of the regular 
  expression is exponential in the size of the input, this means that a small carefully-crafted input (like 20 chars) can trigger catastrophic 
  backtracking and cause a denial of service of the application. Super-linear regex complexity can lead to the same impact too with, in this case, 
  a large carefully-crafted input (thousands chars). */
  //   OSTRANIT KED SA OVERI FUNKCNOSŤ NIŽŠIE if (number === undefined || number === null) return 'NaN';
  //   return number
  //     .toString()
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  //     .replace('.', ',');
  if (number === undefined || number === null) return 'NaN';
  number = number.toString();
  const splitNumber = number.split('.');
  splitNumber[0] = ReverseFormat(ReverseFormat(splitNumber[0]).replace(/\d{3}(?=.)/g, '$& '));
  if (splitNumber[1]) {
    splitNumber[1] = ReverseFormat(ReverseFormat(splitNumber[1]).replace(/\d{3}(?=.)/g, '$& '));
    return `${splitNumber[0]},${splitNumber[1]}`;
  }
  return splitNumber[0];
}

export function IBANFormat(number: string) {
  /* Most of the regular expression engines use backtracking to try all possible execution paths of the regular expression when evaluating an input, 
  in some cases it can cause performance issues, called catastrophic backtracking situations. In the worst case, the complexity of the regular 
  expression is exponential in the size of the input, this means that a small carefully-crafted input (like 20 chars) can trigger catastrophic 
  backtracking and cause a denial of service of the application. Super-linear regex complexity can lead to the same impact too with, in this case, 
  a large carefully-crafted input (thousands chars). */
  //   OSTRANIT KED SA OVERI FUNKCNOSŤ NIŽŠIE return number.replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
  return number.replace(/\d{4}(?=.)/g, '$& ');
}

export function SearchParamFormat(url, searchParams: object) {
  let outUrl = url;
  Object.keys(searchParams).forEach((key) =>
    searchParams[key] === undefined ? delete searchParams[key] : {}
  );
  const query = new URLSearchParams(searchParams as any).toString();
  if (query.length > 0) outUrl += `?${query}`;
  return outUrl;
}

export function sanitize(text: string) {
  // "target" z <a> je povoleny kvoli rich text inputu
  return DOMPurify.sanitize(text || '', { ADD_ATTR: ['target'] });
}

interface AddressProps {
  street: string;
  property: string;
  building?: string;
  postal: string;
  city: string;
}
