import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageMyCompanies = lazy(() =>
  lazyRetry(() => import('pages/(public)/my-companies/PageMyCompanies'), 'PageMyCompanies')
);
const PageCreateCompany = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-companies/company-create/[id]/PageCreateCompany'),
    'PageCreateCompany'
  )
);
const PageCompanyDetail = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-companies/company-detail/[id]/PageCompanyDetail'),
    'PageCompanyDetail'
  )
);
const PageAdminCompanyDetail = lazy(() =>
  lazyRetry(
    () => import('pages/admin/companies/company-detail/[id]/PageAdminCompanyDetail'),
    'PageAdminCompanyDetail'
  )
);
const PageCompanyUpdate = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/my-companies/company-update/[id]/PageCompanyUpdate'),
    'PageCompanyUpdate'
  )
);
const PageAdminCompanyHistory = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/admin/companies/company-detail/[companyId]/approval/[id]/PageAdminCompanyHistory'
      ),
    'PageAdminCompanyHistory'
  )
);
const PageAdminCompanies = lazy(() =>
  lazyRetry(() => import('pages/admin/companies/PageAdminCompanies'), 'PageAdminCompanies')
);

const routesCompanies: Array<DBRoute> = [
  {
    path: 'URL.COMPANIES_LIST',
    name: 'DASHBOARD_ROUTES.MY_COMPANIES_LIST',
    icon: <Icon icon='mdi:company' width={30} height={30} />,
    component: <PageMyCompanies />,
    show: true,
    user: true,
    unlogged: false,
    manager: false,
    worker: false,
    admin: false,
    order: 9,
  },
  {
    path: 'URL.COMPANIES_CREATE_WITHOUT_ID',
    component: <PageCreateCompany />,
    show: false,
    user: true,
    unlogged: true,
  },
  {
    path: 'URL.COMPANIES_UPDATE',
    component: <PageCompanyUpdate />,
    show: false,
    user: true,
    unlogged: true,
  },
  {
    path: 'URL.COMPANIES_DETAIL',
    component: <PageCompanyDetail />,
    show: false,
    user: true,
    unlogged: true,
  },
  {
    path: 'URL.COMPANIES_LIST_ADMIN',
    name: 'DASHBOARD_ROUTES.COMPANIES_LIST',
    icon: <Icon icon='mdi:company' width={30} height={30} />,
    component: <PageAdminCompanies />,
    show: true,
    user: false,
    admin: true,
    manager: true,
    worker: true,
    order: 3.7,
  },
  {
    path: 'URL.COMPANIES_CREATE',
    component: <PageCreateCompany />,
    show: false,
    user: false,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.COMPANIES_UPDATE_ADMIN',
    component: <PageCompanyUpdate />,
    show: false,
    user: false,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.COMPANIES_DETAIL_ADMIN',
    component: <PageAdminCompanyDetail />,
    show: false,
    user: false,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.COMPANIES_APPROVAL_DETAIL_ADMIN',
    component: <PageAdminCompanyHistory />,
    show: false,
    user: false,
    unlogged: true,
    admin: true,
    manager: true,
    worker: true,
  },
];

export default routesCompanies;
