import { ReactNode, useCallback, useMemo, useState } from 'react';

import { showMenu } from './context';

export type showMenuType = {
  isShown: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
};

interface ShowMenuProviderProps {
  children: ReactNode;
}

export default function ShowMenuProvider({ children }: Readonly<ShowMenuProviderProps>) {
  const [isShown, setIsShown] = useState(false);

  const show = useCallback(() => setIsShown(true), []);

  const hide = useCallback(() => setIsShown(false), []);

  const toggle = useCallback(() => setIsShown((prev) => !prev), []);

  const value = useMemo(
    () => ({
      isShown,
      show,
      hide,
      toggle,
    }),
    [isShown, show, hide, toggle]
  );

  return <showMenu.Provider value={value}>{children}</showMenu.Provider>;
}
