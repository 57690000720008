// MANUAL:
//
// showModal - je parameter, ci je okno otvorene v parent komponente alebo nie - ked sa showModal nastavi na true, zavola sa request na toekn z BE a az ked dorazi, okno sa realne otvori
// close - zavretie okna (setShowModal(false))
//
//TEXTY:
// title - title modalneho okna
// customText - iny text ako defaultny, ktory je pripodpisovani
// buttonText - text na buttone, defaultne je len fajocka bez textu
//
// GENEROVANIE KODU:
// generateCode: funkcia, ktorou sa ziska kod z BE - ! treba dat pozor, aby sa volanie neopakovalo. niekedy tuto funkciu treba dat v parentovi do useCallbacku
// generateCodeValue: akekolvek specialne hodnoty, ktore potrebuje funkcia generateCode. napriklad
//      - pri podpisovani je to hash suboru, ktory BE potrebuje na vygenerovanie kodu
//      - pri potvrdzovani kvoli revoke certu je to konstanta 'CERT_REVOKE' ktora BE povie, ze toto bude kod na revoke certu
// fixedChallenge: ak sa kod nema tahat z BE, ale ma byt vzdy rovnaky. zatial sa pouziva len v overovani zariadenia, kde je kod vzdy 1234567
//
// ODOSIELANIE KODU:
// verifyCode: funkcia, ktorou sa na BE posiela userova odpoved. z BE pride odpoved
// extraParams: podobne ako generateCodeValue, ale pre funkciu verifyCode
// afterSuccessAction: co sa ma stat po uspesnom overeni ocra tokenu (napriklad odoslat subory na podpis, poziadat o zneplatnenie certu)
//
// setWait - ak parent potrebuje informaciu o tom, ci sa deju nejake volania na BE. najcastejsie setLoading z loadingContextu
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OTPInput, useInput } from 'components/Input';
import { NotificationManager } from 'components/NotifyBox';
import { QRCodeCmp } from 'components/QRCode';

import { Box, Paper } from '@mui/material';

import InputDialog from './InputDialog';

export type ModalInfo = { attempt: number; guid: string; code: number };

export interface IProps {
  showModal: boolean;
  close: () => void;
  customText?: string;
  title?: string;
  buttonText?: string;

  generateCodeValue: string;
  fixedChallenge?: number;
  extraParams?: any;

  generateCode: (_val: any) => Promise<{ requestCode: string; guid: string }>;
  verifyCode: (
    _guid: string,
    _responseCode: string,
    _extraData?: any,
    _challenge?: any
  ) => Promise<{
    verify: boolean;
    numberOfFailedAttempts?: number;
    errorText?: string;
  }>;
  afterSuccessAction: (_guid: string) => void;
  setWait?: (_val: boolean) => void;
}

export default function ConfirmOcraTokenDialog(props: IProps) {
  const { t } = useTranslation('sign');
  const setWait = useState(() => (props.setWait ? props.setWait : () => {}))[0];

  const inputProps = useInput<string>(
    '',
    'modal-input-name',
    useCallback((val) => (val.length < 1 ? 'translation:DEFAULT.REQUIRED_FIELD' : ''), [])
  );

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfo>();

  const [extraVerifyData, setExtraVerifyData] = useState<any>();

  const close = () => {
    setWait(false);
    inputProps.setValue('');
    setShowModal(false);
    props.close();
  };

  const verifyCode = () => {
    props
      .verifyCode(modalInfo.guid, inputProps.value, extraVerifyData, modalInfo.code)
      .then((resp) => {
        if (resp.verify) props.afterSuccessAction(modalInfo.guid);
        else {
          if (
            (resp.numberOfFailedAttempts && resp.numberOfFailedAttempts < 3) ||
            modalInfo.attempt < 3
          ) {
            setTimeout(
              () => verifyCodeModalOpen(modalInfo.guid, modalInfo.code, modalInfo.attempt + 1),
              200
            );
          } else {
            NotificationManager.warning(t('sign:TOO_MANY_WRONG_CODES'));
          }
        }
      })
      .catch((e) => {
        if (JSON.parse(e.message).message?.includes('Token must be valid for'))
          NotificationManager.error(t('translation:DEFAULT.LOW_LEVEL'));
        else NotificationManager.error(t('sign:ERROR_CODE'));
      });
  };

  const verifyCodeModalOpen = (guid, code, attempt) => {
    setModalInfo({ guid: guid, code: code, attempt: attempt });
    setShowModal(true);
  };

  const { generateCode } = props;
  useEffect(() => {
    if (props.showModal) {
      setWait(true);
      setExtraVerifyData(props.extraParams);
      if (!props.fixedChallenge)
        generateCode(props.generateCodeValue)
          .then((resp) => {
            setWait(false);
            verifyCodeModalOpen(resp.guid, resp.requestCode, 0);
          })
          .catch(() => {
            setWait(false);
          });
      else verifyCodeModalOpen(props.generateCodeValue, props.fixedChallenge, 0);
    }
  }, [
    props.showModal,
    generateCode,
    props.extraParams,
    props.fixedChallenge,
    props.generateCodeValue,
    setWait,
  ]);

  useEffect(() => {
    const pressSubmitOnEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        document.getElementById('confirm_ocra_dialog_submit_button').click();
      }
    };

    if (showModal) document.addEventListener('keypress', pressSubmitOnEnter);

    return () => document.removeEventListener('keypress', pressSubmitOnEnter);
  }, [showModal]);

  return (
    <Fragment>
      {/*modalInfo && modalInfo.attempt*/}
      <InputDialog
        showModal={showModal}
        title={props.title ? props.title : ''}
        close={close}
        message={
          modalInfo ? (
            <>
              <Box
                p={1}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    style={{
                      maxWidth: '480px',
                      width: '100%',
                      fontSize: '22px',
                      paddingBottom: '4px',
                    }}
                  >
                    {modalInfo.attempt > 0 ? (
                      t('sign:VERIFY_CODE_WRONG')
                    ) : (
                      <div style={{ marginBottom: '8px' }}>
                        {props.customText ? props.customText : t('sign:VERIFY_CODE')}
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  maxWidth: '480px',
                  margin: 'auto',
                }}
                variant='outlined'
              >
                <Box p={3} pt={4}>
                  <QRCodeCmp value={modalInfo.code + ''} />
                  <div className='text-center' style={{ fontSize: '24px', paddingTop: '24px' }}>
                    {t('OTP.CHALLENGE')} <br /> <b>{modalInfo.code}</b>
                  </div>
                </Box>
              </Paper>
            </>
          ) : undefined
        }
        messagePd='16px 16px 0px 16px'
        button={{
          label: props.buttonText ? `${props.buttonText}` : '',
          icon: 'fa:check',
          onClick: verifyCode,
          id: 'confirm_ocra_dialog_submit_button',
        }}
        inputs={[
          {
            ...inputProps,
            // label: t('sign:CODE'),
            required: true,
            id: 'confirm_ocra_dialog_input',
            extraInputProps: { center: true, length: 6, maxWdith: '480px' },
            input: OTPInput,
          },
        ]}
      />
    </Fragment>
  );
}
