const SecureRandom = {
  random: () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / (2_147_483_647 * 2);
  },

  /**
   * UUID v4 - nie je dostatocne na generovanie obrovskeho mnozstva UUID, ale na nase ucely na generovanie KEY do poli bude stacit
   * @returns
   */
  uuid: () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    );
  },
};

export default SecureRandom;
