import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { userCtx } from 'context/context';

import { authorizationService } from '../index';

function useSetLanguage() {
  const { i18n } = useTranslation();

  const isLoggedIn = authorizationService.isLoggedIn();

  const cookies = useCookies()[0];

  const user = useContext(userCtx).data;

  const cookieValue = cookies.cookies || localStorage.getItem('cookies');

  useEffect(() => {
    if (!window.location.search?.includes('lang')) {
      if (isLoggedIn && user && user.language) {
        i18n.changeLanguage(user.language);
        (window as any).webkit?.messageHandlers?.changeLanguage.postMessage(user.language);
      } else if (!!sessionStorage.getItem('lang')) {
        i18n.changeLanguage(sessionStorage.getItem('lang'));
        (window as any).webkit?.messageHandlers?.changeLanguage.postMessage(
          sessionStorage.getItem('lang')
        );
      } else if (cookieValue === 'true' && !!cookies.lang) {
        i18n.changeLanguage(cookies.lang);
        (window as any).webkit?.messageHandlers?.changeLanguage.postMessage(cookies.lang);
      }
    }
  }, [cookies, i18n, isLoggedIn, user, cookieValue]);
}

export default useSetLanguage;
