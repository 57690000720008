import { authorizationService } from 'index';

const defaultService = {
  customFetch: async (url: string, other?: any): Promise<any> => {
    const fetchWithAuth = async (url: string, options: any) => {
      const response = await fetch(url, options);
      if (response.status === 401) {
        await authorizationService.autoLogout();
      }
      return response;
    };

    let response = await fetch(url, other);

    if (response.status === 401) {
      await authorizationService.signinSilent();

      if (other?.headers?.Authorization) {
        const user = await authorizationService.getUser();
        other.headers.Authorization = 'Bearer ' + user.access_token;
      }

      response = await fetchWithAuth(url, other);
    }

    return response;
  },
};

export default defaultService;
