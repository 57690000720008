import { CustomTheme } from 'theme/PaletteDTO';

import { Icon } from '@iconify/react';
import { Box, LinearProgress, Typography, useTheme } from '@mui/material';

interface QuotaBoxProps {
  title: string;
  value: string | number;
  maxValue?: number;
  icon?: string;
  postFix?: string;
}

export default function QuotaBox({
  title,
  value,
  maxValue,
  icon,
  postFix = '',
}: Readonly<QuotaBoxProps>) {
  const theme: CustomTheme = useTheme();

  const numValue = typeof value === 'number' ? value : parseFloat(value);

  const revertValue = numValue > maxValue ? numValue : maxValue - numValue;
  const maxValuePercentage =
    numValue > maxValue || typeof value === 'string' ? 100 : (revertValue / maxValue) * 100;

  const getColorBasedOnPercentage = (percentage: number) => {
    switch (true) {
      case percentage > 50:
        return 'text.success';
      case percentage > 10:
        return theme.palette?.quota?.warning;
      case percentage > 0:
        return 'text.error';
      default:
        return theme.palette?.divider;
    }
  };

  const isInteger = (num: string) => Number.isInteger(num);

  const formatValue = (num: any) =>
    isInteger(num) || typeof num === 'string' ? num : num.toFixed(1);

  const renderValue = () => {
    if (!maxValue) {
      return (
        <strong>
          {formatValue(value)} {postFix}
        </strong>
      );
    }
    return (
      <>
        <strong>{formatValue(revertValue)}</strong>
        <span style={{ fontSize: '12px' }}>{` / ${maxValue} ${postFix}`}</span>
      </>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 3px 2px, rgba(0, 0, 0, 0.02) 0px 1px 2px 2px',
        borderRadius: '6px',
      }}
      mb={2}
      p={2}
      mr={2}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon !== undefined && <Icon icon={icon} height={'24px'} style={{ marginRight: '6px' }} />}
        <Typography fontSize={12}>{title}</Typography>
      </Box>

      <Typography fontSize={16} mt={0.5} mb={0.5}>
        {renderValue()}
      </Typography>

      <LinearProgress
        sx={{
          height: '4px',
          borderRadius: '2px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: getColorBasedOnPercentage(maxValuePercentage),
          },
          backgroundColor: theme.palette.divider,
        }}
        variant='determinate'
        value={maxValuePercentage}
      />
    </Box>
  );
}
