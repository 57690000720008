import { ReactComponent as Forbidden } from 'assets/img/forbidden.svg';
import { authorizationService } from 'index';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SimplePage from 'containers/Page/SimplePage';

import { SlideButton } from 'components/Buttons';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const FForbidden = styled(Forbidden)(({ theme }: any) => ({
  height: 120,
  width: 120,
  '& .cls-1': {
    fill: theme.palette.text.primary + ' !important',
  },
  '& .cls-2': {
    fill: theme.palette.primary.main + ' !important',
  },
}));

export default function NotFound() {
  const { t } = useTranslation();
  const [, setCookies] = useCookies();

  const location = useLocation();
  const navigation = useNavigate();

  const redirectToContact = () => {
    navigation(t('URL.CONTACT'));
  };

  const redirectToHomepage = () => {
    navigation(t('URL.HOME'));
  };

  useEffect(() => {
    if (!authorizationService.isLoggedIn()) {
      setCookies('after-login-uri', location.pathname);
      authorizationService.setState(location.pathname);
      authorizationService.login();
    }
  }, [location, setCookies]);

  return (
    <SimplePage
      title={t('NOT_ACCESS.MAIN')}
      component={
        <Box>
          <FForbidden />
        </Box>
      }
    >
      <Typography
        paddingBottom={2}
        variant='h4'
        sx={{ maxWidth: '600px', margin: 'auto', color: '#76838d !important' }}
      >
        {t('NOT_ACCESS.SECOND')}
      </Typography>
      <SlideButton onClick={redirectToHomepage} onMiddleClick={() => t('URL.HOME')}>
        {t('NOT_ACCESS.BACK_TO_HOMEPAGE')}
      </SlideButton>
      <SlideButton onClick={redirectToContact} onMiddleClick={() => t('URL.CONTACT')}>
        {t('NOT_ACCESS.CONTACT_US')}
      </SlideButton>
    </SimplePage>
  );
}
