import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageTimestamps = lazy(() =>
  lazyRetry(() => import('pages/(public)/timestamps/PageTimestamps'), 'PageTimestamps')
);
const PageTimestampsBuy = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/timestamps/buy/[id]/PageTimestampsBuy'),
    'PageTimestampsBuy'
  )
);

const routesTimestamp: Array<DBRoute> = [
  {
    path: 'URL.TIMESTAMP_INFO',
    name: 'DASHBOARD_ROUTES.TIMESTAMP_INFO',
    icon: <Icon icon='ph:stamp-fill' width={30} height={30} />,
    component: <PageTimestamps />,
    show: true,
    user: true,
    admin: false,
    manager: false,
    order: 5,
  },
  {
    path: 'URL.TIMESTAMP_BUY_ID',
    component: <PageTimestampsBuy />,
    show: false,
    admin: true,
    manager: true,
    worker: true,
  },
  {
    path: 'URL.TIMESTAMP_BUY',
    component: <PageTimestampsBuy />,
    show: false,
    user: true,
    admin: false,
    manager: false,
    worker: false,
  },
];

export default routesTimestamp;
