function getFirstCharacter(name) {
  if (name && name !== '') {
    const words = name.split(' ');
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  }
  return '';
}
export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: '#379BDD',
  };
}
