export interface LimitsValue {
  ELECTRONIC_SIGN_N_DOCUMENTS?: number;
  ELECTRONIC_SIGN_DOCUMENT_SIZE?: number;
  SIGN_N_DOCUMENTS?: number;
  SIGN_DOCUMENT_SIZE?: number;
  DIGITAL_SIGN_N_DOCUMENTS?: number;
  DIGITAL_SIGN_DOCUMENT_SIZE?: number;
  VERIFY_N_DOCUMENTS?: number;
  VERIFY_DOCUMENT_SIZE?: number;

  MESSAGE_SIGN_N_DOCUMENTS?: number;
  MESSAGE_SIZE?: number;
  MESSAGE_SENT_MONTH?: number;
  MESSAGE_DRAFT_MAX_NUMBER?: number;
  MESSAGE_HISTORY_MONTHS?: number;

  SIGN_HISTORY_MONTHS_SHOW?: number;
  SIGN_HISTORY_MONTHS_DOWNLOAD?: number;

  COMPANY_NUMBER?: number;
  COMPANY_NUMBER_EMPLOYEES?: number;

  VISUAL_SIGNATURES_NUMBER_GENERATED_US?: number;
  VISUAL_SIGNATURES_NUMBER_GENERATED?: number;
  VISUAL_SIGNATURES_NUMBER_CUSTOM?: number;

  TIMESTAMPS_COUNT_PER_MONTH?: number;
  TIMESTAMPS_COUNT_START_YEARLY?: number;
  TIMESTAMPS_COUNT_START_MONTHLY?: number;
  API_TOTAL_SIZE?: number;
  API_INTRODUCTION?: 'yes' | 'X';

  TWO_FA_SMS_SENT_MONTH?: number;

  HASH_ARCHIVE_API_INTRODUCTION?: 'yes' | 'X';
  ARCHIVE_STORAGE_NUMBER_DOCUMENTS?: number;
  HASH_ARCHIVE_API_NUMBER_DOCUMENTS?: number;
  DMS_USER_QUOTA: number;

  NFQES_ES_AVAILABLE_CERTIFICATE?: number;
  NFQES_ES_SEAL_AVAILABLE_CERTIFICATE?: number;
  NFQES_ADES_AVAILABLE_CERTIFICATE?: number;
  NFQES_ADES_SEAL_AVAILABLE_CERTIFICATE?: number;

  EXTERNAL_CERTIFICATE_SUPPORT?: 'yes' | 'X';
  NFQES_ES_SUPPORT?: 'yes' | 'X';
  NFQES_ADES_SUPPORT?: 'yes' | 'X';
  NFQES_QES_SUPPORT?: 'yes' | 'X';
  NFQES_ES_SEAL_SUPPORT?: 'yes' | 'X';
  NFQES_ADES_SEAL_SUPPORT?: 'yes' | 'X';
  NFQES_QES_SEAL_SUPPORT?: 'yes' | 'X';
  NFQES_QES_MQC_MANDATE_SUPPORT?: 'yes' | 'X';
  NFQES_TLS_SUPPORT?: 'yes' | 'X';
  TWO_FA_APP_SUPPORT?: 'yes' | 'X';
  TWO_FA_APP_QUALIFIED_SUPPORT?: 'yes' | 'X';
  DOCUMENTS_SIGN_MONTH?: number;
}

export type LimitsKeyType = keyof LimitsValue;

export type shownKeys =
  | 'DOCUMENTS_SIGN_MONTH'
  | 'SIGN_N_DOCUMENTS'
  | 'SIGN_DOCUMENT_SIZE'
  | 'ELECTRONIC_SIGN_N_DOCUMENTS'
  | 'ELECTRONIC_SIGN_DOCUMENT_SIZE'
  | 'DIGITAL_SIGN_N_DOCUMENTS'
  | 'DIGITAL_SIGN_DOCUMENT_SIZE'
  | 'VERIFY_N_DOCUMENTS'
  | 'VERIFY_DOCUMENT_SIZE'
  | 'MESSAGE_SENT_MONTH'
  | 'MESSAGE_SIGN_N_DOCUMENTS'
  | 'MESSAGE_SIZE'
  | 'MESSAGE_DRAFT_MAX_NUMBER'
  | 'MESSAGE_HISTORY_MONTHS'
  | 'SIGN_HISTORY_MONTHS_SHOW'
  | 'SIGN_HISTORY_MONTHS_DOWNLOAD'
  | 'ARCHIVE_STORAGE_NUMBER_DOCUMENTS'
  | 'HASH_ARCHIVE_API_NUMBER_DOCUMENTS'
  | 'DMS_USER_QUOTA'
  | 'COMPANY_NUMBER'
  // | 'COMPANY_NUMBER_EMPLOYEES'
  | 'VISUAL_SIGNATURES_NUMBER_CUSTOM'
  | 'TIMESTAMPS_COUNT_START_YEARLY'
  // | 'TIMESTAMPS_COUNT_START_MONTHLY'
  | 'TIMESTAMPS_COUNT_PER_MONTH'
  // | 'API_TOTAL_SIZE'
  // | 'API_INTRODUCTION'
  | 'TWO_FA_SMS_SENT_MONTH'

  // | 'HASH_ARCHIVE_API_INTRODUCTION'
  // | 'NFQES_ES_SUPPORT'
  | 'NFQES_ES_AVAILABLE_CERTIFICATE'
  // | 'NFQES_ES_SEAL_SUPPORT'
  | 'NFQES_ES_SEAL_AVAILABLE_CERTIFICATE'
  // | 'NFQES_ADES_SUPPORT'
  | 'NFQES_ADES_AVAILABLE_CERTIFICATE'
  // | 'NFQES_ADES_SEAL_SUPPORT'
  | 'NFQES_ADES_SEAL_AVAILABLE_CERTIFICATE';
// | 'EXTERNAL_CERTIFICATE_SUPPORT'
// | 'NFQES_QES_SUPPORT'
// | 'NFQES_QES_SEAL_SUPPORT'
// | 'NFQES_QES_MQC_MANDATE_SUPPORT'
// | 'NFQES_TLS_SUPPORT'

// | 'TWO_FA_SMS_SENT_MONTH'
// | 'TWO_FA_APP_SUPPORT'
// | 'TWO_FA_APP_QUALIFIED_SUPPORT'

export const limitsKeys: shownKeys[][] = [
  ['DOCUMENTS_SIGN_MONTH'],
  ['SIGN_N_DOCUMENTS', 'SIGN_DOCUMENT_SIZE'],
  ['ELECTRONIC_SIGN_N_DOCUMENTS', 'ELECTRONIC_SIGN_DOCUMENT_SIZE'],
  ['DIGITAL_SIGN_N_DOCUMENTS', 'DIGITAL_SIGN_DOCUMENT_SIZE'],

  ['VERIFY_N_DOCUMENTS', 'VERIFY_DOCUMENT_SIZE'],

  ['MESSAGE_SENT_MONTH', 'MESSAGE_SIGN_N_DOCUMENTS', 'MESSAGE_SIZE'],
  ['MESSAGE_HISTORY_MONTHS'],
  // 'MESSAGE_DRAFT_MAX_NUMBER',

  ['SIGN_HISTORY_MONTHS_SHOW', 'SIGN_HISTORY_MONTHS_DOWNLOAD'],
  // 'HASH_ARCHIVE_API_INTRODUCTION',
  ['ARCHIVE_STORAGE_NUMBER_DOCUMENTS', 'HASH_ARCHIVE_API_NUMBER_DOCUMENTS'],
  ['DMS_USER_QUOTA'],

  ['COMPANY_NUMBER'],
  // 'COMPANY_NUMBER_EMPLOYEES',

  ['VISUAL_SIGNATURES_NUMBER_CUSTOM'],

  ['TIMESTAMPS_COUNT_START_YEARLY', 'TIMESTAMPS_COUNT_PER_MONTH'],
  //TIMESTAMPS_COUNT_START_MONTHLY,
  // 'API_TOTAL_SIZE',
  // 'API_INTRODUCTION',

  ['TWO_FA_SMS_SENT_MONTH'],

  [
    'NFQES_ES_AVAILABLE_CERTIFICATE',
    'NFQES_ES_SEAL_AVAILABLE_CERTIFICATE',
    'NFQES_ADES_AVAILABLE_CERTIFICATE',
    'NFQES_ADES_SEAL_AVAILABLE_CERTIFICATE',
  ],
  // 'EXTERNAL_CERTIFICATE_SUPPORT',
  // 'NFQES_ES_SUPPORT',
  // 'NFQES_ADES_SUPPORT',
  // 'NFQES_QES_SUPPORT',
  // 'NFQES_ES_SEAL_SUPPORT',
  // 'NFQES_ADES_SEAL_SUPPORT',
  // 'NFQES_QES_SEAL_SUPPORT',
  // 'NFQES_QES_MQC_MANDATE_SUPPORT',
  // 'NFQES_TLS_SUPPORT',

  // 'TWO_FA_APP_SUPPORT',
  // 'TWO_FA_APP_QUALIFIED_SUPPORT',
];
