import { MouseEventHandler } from 'react';

import { Tooltip } from 'components/Tooltip';

import { Icon } from '@iconify/react';
import { IconButton as MuiIconButton, styled, SxProps } from '@mui/material';

interface IProps {
  name?: string;
  tooltip?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  sx?: SxProps;

  icon: string;
  width?: number;
  height?: number;
  color?:
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary';
}

const ColorIcon = styled(Icon)(({ theme, color }: any) => ({
  color: color ? theme.palette[color].contrastText : undefined,
}));

export default function IconButton({
  icon,
  tooltip,
  onClick,
  onMouseDown,
  disabled,
  width = 24,
  height = 24,
  color,
  sx,
}: Readonly<IProps>) {
  const iconBtn = (
    <MuiIconButton onClick={onClick} onMouseDown={onMouseDown} sx={sx} disabled={disabled}>
      <ColorIcon icon={icon} width={width} height={height} color={color} />
    </MuiIconButton>
  );

  if (tooltip)
    return (
      <Tooltip title={tooltip} arrow>
        {iconBtn}
      </Tooltip>
    );

  return iconBtn;
}
