import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CustomTheme } from 'theme/PaletteDTO';

import { Box, styled } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { InputProps } from '../hooks/useInput';

export interface DateTimeInputProps extends InputProps<Date | string> {}

export default function DateTimeInput({
  // all inputs
  name,
  label,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  size,
}: // specifics
// ...
DateTimeInputProps) {
  const { t } = useTranslation();

  const handleChange = (newValue) => {
    if (newValue) {
      const newDate = new Date(newValue.$d);
      onChange?.({ target: { value: newDate } });
    } else {
      onChange?.({ target: { value: '' } });
    }
  };

  return (
    <Box>
      <DateTimePickerSx
        label={label}
        value={dayjs(value)}
        onChange={handleChange}
        slotProps={{
          textField: {
            size: size || 'medium',
            id: name,
            placeholder: placeholder || label,
            variant: 'outlined',
            helperText: t(error),
            fullWidth: true,
            // ...inpProps,
            error: error?.length > 0,
            onBlur: onBlur,
            required: required,
            disabled: disabled,
          },
        }}
      />
    </Box>
  );
}

const DateTimePickerSx = styled(DateTimePicker)(({ theme }: CustomTheme) => ({
  '& .MuiFormLabel-root': {
    top: '8px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '& div button': { color: 'text.primary' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder },
    '& svg': { color: 'text.primary' },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': { borderColor: theme.palette.text.inputBorder, opacity: 0.6 },
  },
}));
