import { useTranslation } from 'react-i18next';

import { Box, useTheme } from '@mui/material';

import { ShowIcon } from './GridItem';

export default function Success() {
  const { t } = useTranslation(['translation', 'otp']);
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          pt={3}
          style={{ maxWidth: '480px', width: '100%', fontSize: '22px', paddingBottom: '4px' }}
        >
          <b>{t(`OTP.SUCCESS`)}</b>
        </Box>
      </Box>
      <Box
        pt={6}
        pb={6}
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            maxHeight: '80px',
            fill: theme.palette.success.main,
          },
        }}
      >
        {ShowIcon('success')}
      </Box>
    </>
  );
}
