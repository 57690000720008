import logo from 'assets/img/NFQES_horizontalne.svg';
import { authorizationService } from 'index';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import DashboardRoutes from 'router/DashboardRoutes';

import { loadingCtx, services, showMenu, userCtx } from 'context/context';

import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

import NavSectionVertical from './vertical';

import css from './Sidebar.module.css';

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { loading } = useContext(loadingCtx);
  const user = useContext(userCtx);
  const { hide: hideSidebar } = useContext(showMenu);
  const menuRef = useRef(null);

  const [msgSummary, setMsgSummary] = useState<any>({
    drafts: 0,
    toBeApproved: 0,
    toBeSigned: 0,
  });

  const { messageService } = useContext(services);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) hideSidebar();
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, hideSidebar]);

  useEffect(() => {
    messageService
      .getMessagesSummary()
      .then((resp) => {
        setMsgSummary(resp);
      })
      .catch(() => {});
  }, [location, messageService]);

  const hideMenu = () => {
    hideSidebar();
  };

  const isCorrect = (route): boolean => {
    if (
      route.minLvlSubscription &&
      user.role === 'user' &&
      user.subscription < route.minLvlSubscription
    )
      return false;

    if (route.path === 'URL.SIGN_DOCUMENT_REDIRECT' && !user.data && isMobile) {
      return false;
    }

    if (route.path === 'URL.API') {
      const services = ['file-gateway', 'signer', 'hash-archive', 'archive', 'timestampApi'];
      const isActiveServices = user.userApis.some((api) => services.includes(api.apiName));
      if (user.role === 'user' && user.subscription === 5 && isActiveServices) return true;
      return false;
    }

    //dočasné kým sa nevytvoria EN materialy pre manualy
    if (route.path === 'URL.MANUALS' && i18n.language === 'en') {
      return false;
    }
    if (route.show) {
      if (route.isDevelop && process.env.REACT_APP_HIDE_DEV_THINGS !== 'false') return false;
      else if (user.availableCertTypes[route.path] === false) return false;
      else return !!route[user.role];
    }

    return false;
  };

  const sidebarShields = {
    'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_RECEIVED':
      msgSummary?.toBeSigned + msgSummary?.toBeApproved > 0
        ? msgSummary?.toBeSigned + msgSummary?.toBeApproved
        : undefined,
    'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX_CONCEPT':
      msgSummary?.drafts > 0 ? msgSummary?.drafts : undefined,
    'DASHBOARD_ROUTES.ELECTRONIC_MAILBOX':
      msgSummary?.drafts + msgSummary?.toBeSigned + msgSummary?.toBeApproved > 0
        ? msgSummary?.drafts + msgSummary?.toBeSigned + msgSummary?.toBeApproved
        : undefined,
  };

  const goToLogin = () => authorizationService.login();
  const goToLogout = () => authorizationService.logout().then(() => {});

  const onClickActions = {
    'DASHBOARD_ROUTES.MY_CERTIFICATE': () => {
      sessionStorage.removeItem('certFilterOnline');
      sessionStorage.removeItem('certFilterDownloadable');
      sessionStorage.removeItem('certFilterCard');
      sessionStorage.removeItem('certFilterTls');
    },
    'DASHBOARD_ROUTES.CERTIFICATE_REQUESTS': () => sessionStorage.removeItem('certRequestFilter'),
    'DASHBOARD_ROUTES.LOGOUT': goToLogout,
    'DASHBOARD_ROUTES.LOGIN': goToLogin,
  };

  const drawNavLink = (route, level) => (
    <NavLink
      key={route.name}
      to={t(route.path).replace(':id', '')}
      className={({ isActive }) => (isActive ? css.active : undefined)}
      onClick={(e) => {
        e.stopPropagation();
        hideSidebar();
        if (onClickActions[route.name]) onClickActions[route.name]();
      }}
    >
      <div className={`${css.navLink} ${css['level_' + level]}`}>
        {level === 0 ? (
          <>
            {typeof route.icon === 'string' ? (
              <Icon icon={route.icon} className='navbar-icon' />
            ) : (
              <route.icon className='navbar-icon' />
            )}
          </>
        ) : (
          <Icon
            icon='fa:circle'
            className='navbar-icon'
            style={{ display: 'inline-block', fontSize: '7px', marginRight: '2px' }}
          />
        )}
        {sidebarShields[route.name] && (
          <span className={css.redDotNumber}>{sidebarShields[route.name]}</span>
        )}
        <p>{t(route.name)}</p>
      </div>
    </NavLink>
  );

  const routes = DashboardRoutes.slice();

  routes.sort((a, b) => {
    const attributeA = a.order ?? 60;
    const attributeB = b.order ?? 60;

    if (attributeA !== attributeB) {
      return attributeA - attributeB;
    } else {
      const nameA = a.show || '';
      const nameB = b.show || '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  });

  const secondRoutes = (
    <Fragment>
      <br />
      <br />
      {authorizationService.isLoggedIn()
        ? [
            {
              name: 'DASHBOARD_ROUTES.MY_PROFILE',
              path: 'URL.MY_PROFILE',
              icon: 'fa:user',
            },
            {
              name: 'DASHBOARD_ROUTES.LOGOUT',
              path: '/logout',
              icon: 'fa:sign-out',
            },
          ].map((route) => drawNavLink(route, 0))
        : [
            {
              name: 'DASHBOARD_ROUTES.LOGIN',
              path: '/login',
              icon: 'fa:sign-in',
            },
            {
              name: 'DASHBOARD_ROUTES.REGISTER',
              path: 'URL.REGISTER',
              icon: 'fa:user',
            },
          ].map((route) => drawNavLink(route, 0))}
    </Fragment>
  );

  return (
    <Box
      id='nfqes-sidebar'
      className={css.sidebar}
      ref={menuRef}
      sx={{ backgroundColor: 'sidebar.primary' }}
    >
      <div className={css.logo}>
        <a
          target={authorizationService.isLoggedIn() ? '_self' : '_blank'}
          rel='noreferrer'
          href={
            authorizationService.isLoggedIn()
              ? user.role === 'user'
                ? t('URL.HOME')
                : t('URL.ADMIN_DASHBOARD')
              : process.env.REACT_APP_NFQES_REDIRECT_URL
          }
          onClick={() => {
            hideMenu();
          }}
          className='clickable simple-text logo-mini'
        >
          <div className='logo-img'>
            <img src={logo} alt='logo_image' />
          </div>
        </a>
      </div>
      <div className={css.menuItems}>
        <Box pb={8}>
          <NavSectionVertical
            userRole={user.role}
            navConfig={routes}
            isCorrect={isCorrect}
            sidebarShields={sidebarShields}
            closeMenu={hideMenu}
          />

          {isMobile && secondRoutes}
        </Box>
      </div>

      {loading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            opacity: '0',
          }}
        ></div>
      )}
    </Box>
  );
}
