import { gtag, GTMEvent } from 'providers/GoogleAnalytics/GoogleAnalytics';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { userCtx } from 'context/context';

import { SlideButton } from 'components/Buttons';

import { Box, Checkbox, Collapse, FormControlLabel, FormGroup } from '@mui/material';
import { styled } from '@mui/system';

import css from './CookieBanner.module.css';

const Banner = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: `${theme.palette.tertiary.main} !important`,
}));

const BannerBackground = styled(Box)(() => ({
  background: '#205985',
}));

function GDPRBanner() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies();
  const user = useContext(userCtx);
  const [showOptions, setShowOptions] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [statistic, setStatistic] = useState(false);

  const [bannerClass, setBannerClass] = useState(css.banner);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieValue = cookies.cookies || localStorage.getItem('cookies');

    let disabledCookies: any = false;
    if (user?.data) disabledCookies = cookieValue === 'true';
    else disabledCookies = cookieValue !== undefined && cookieValue !== null;

    if (disabledCookies) setBannerClass(css.none);
    else {
      setShowBanner(true);
      setBannerClass(css.banner);
    }
  }, [cookies.cookies, user]);

  const changeCookies = (val: string) => {
    const isValueTrue = val === 'true';
    const isValueChange = val === 'change';

    setBannerClass(`${css.banner} ${css.hide}`);

    setTimeout(() => {
      const data = {
        ad_storage: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
        ad_user_data: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
        ad_personalization: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
        analytics_storage: isValueTrue || statistic ? 'granted' : 'denied', // Štatistické

        functionality_storage: 'granted', // Necessary
        personalization_storage: 'granted', // Necessary
        // personalization_storage: statistic || isValueTrue ? 'granted' : 'denied', // Štatistické
        security_storage: 'granted', // Necessary
      };
      gtag('consent', 'update', data);
      if (isValueChange) {
        if (marketing) setCookie('cmplz_cookies_marketing', 'granted');
        if (statistic) setCookie('cmplz_cookies_statistics', 'granted');
        setCookie('cmplz_cookies_functional', 'granted');
        setCookie('cookies', 'true');
      }
      if (isValueTrue) {
        setCookie('cmplz_cookies_marketing', 'granted');
        setCookie('cmplz_cookies_functional', 'granted');
        setCookie('cmplz_cookies_statistics', 'granted');
        setCookie('cookies', val);
      }
      localStorage.setItem('cookies', val);
      setBannerClass(css.none);
    }, 480);
  };

  const options = [
    {
      label: 'NECESSARY',
      handleFunc: () => null,
      disabled: false,
      defaultChecked: true,
    },
    {
      label: 'STATISTIC',
      handleFunc: (e: ChangeEvent<HTMLInputElement>) => setStatistic(e.target.checked),
      disabled: false,
      defaultChecked: statistic,
    },
    {
      label: 'MARKETING',
      handleFunc: (e: ChangeEvent<HTMLInputElement>) => setMarketing(e.target.checked),
      disabled: false,
      defaultChecked: marketing,
    },
  ];

  return (
    showBanner && (
      <>
        <Banner className={bannerClass}>
          <BannerBackground className={css.background} />
          <h3>{t('COOKIE.TITLE')}</h3>
          <p>{t('COOKIE.TEXT')}</p>
          {showOptions && (
            <Collapse in={showOptions} sx={{ mt: 1 }}>
              {options.map((option) => (
                <FormGroup key={option.label}>
                  <FormControlLabel
                    label={t(`COOKIE.${option.label}`)}
                    control={
                      <StyledCheckbox
                        disabled={option.disabled}
                        checked={option.defaultChecked}
                        size='small'
                        onChange={option.handleFunc}
                      />
                    }
                  />
                </FormGroup>
              ))}
            </Collapse>
          )}
          <div className={css.actions} style={{ marginTop: '20px' }}>
            {!user?.data && (
              <>
                <SlideButton
                  primary
                  style={{ backgroundColor: '#08162C !important' }}
                  margin='right'
                  onClick={() => {
                    if (showOptions) {
                      changeCookies('change');
                      GTMEvent('event', 'ga4.pirjate cookies', {
                        event_category: 'ga4.prijate cookies',
                        event_label: 'ga4.prijate cookie',
                        event_value: `Necessary ${marketing ? 'marketing' : ''}, ${
                          statistic ? 'statistic' : ''
                        }`,
                      });
                    } else {
                      setShowOptions(!showOptions);
                      setStatistic(true);
                    }
                  }}
                >
                  {!showOptions ? t('COOKIE.OPTIONS') : t('COOKIE.ACCEPT_OPTIONS')}
                </SlideButton>
                <SlideButton
                  primary
                  margin='right'
                  style={{ backgroundColor: '#08162C !important' }}
                  onClick={async () => {
                    setStatistic(false);
                    GTMEvent('event', 'ga4.neprijate cookies', {
                      event_category: 'ga4.neprijate cookies',
                      event_label: 'ga4.neprijate cookies',
                    });
                    changeCookies('false');
                  }}
                >
                  {t('COOKIE.DENY')}
                </SlideButton>
              </>
            )}
            <SlideButton
              mainAction
              margin='right'
              onClick={() => {
                GTMEvent('event', 'ga4.pirjate cookies', {
                  event_category: 'ga4.prijate cookies',
                  event_label: 'ga4.prijate cookie',
                  event_value: 'all',
                });
                changeCookies('true');
              }}
            >
              {t('COOKIE.ALLOW')}
            </SlideButton>
          </div>
        </Banner>
      </>
    )
  );
}

export default GDPRBanner;
