import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { Icon } from '@iconify/react';
import { Box, ClickAwayListener, Grid, Paper } from '@mui/material';

import css from './Modal.module.css';

interface ModalDialogProps {
  close: any;
  outerClickClose?: boolean;
  showModal: boolean;
  children: string | JSX.Element | Array<string | JSX.Element>;
  title: string | JSX.Element;
  sx?: Object;
  uniqKey?: string;
}

export default function ModalDialog({ uniqKey, ...props}: Readonly<ModalDialogProps>) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    if (dialog === null || header === null) return;

    const centerDialog = () => {
      const dialogProps = dialog.getBoundingClientRect();
      let calcMarginTop = window.innerHeight / 4 - dialogProps.height / 4;
      if (calcMarginTop < 0) calcMarginTop = 0;
      let calcMarginLeft = window.innerWidth / 2 - dialogProps.width / 2;
      if (calcMarginLeft < 0) calcMarginLeft = 0;
      dialog.style.top = calcMarginTop + 'px';
      dialog.style.left = calcMarginLeft + 'px';
    };

    const resize = () => {
      centerDialog();
    };

    let isMoving = false;
    let lastPosY = 0;
    let lastPosX = 0;

    const startMove = (e) => {
      isMoving = true;
      lastPosX = e.clientX || e.changedTouches?.[0]?.clientX;
      lastPosY = e.clientY || e.changedTouches?.[0]?.clientY;
    };

    const endMove = () => {
      isMoving = false;
    };

    const move = (e) => {
      if (isMoving) {
        const actualPosX = e.clientX || e.changedTouches?.[0]?.clientX;
        const actualPosY = e.clientY || e.changedTouches?.[0]?.clientY;
        const changedX = actualPosX - lastPosX;
        const changedY = actualPosY - lastPosY;
        lastPosX = actualPosX;
        lastPosY = actualPosY;
        dialog.style.left = Number(dialog.style.left.replace('px', '')) + changedX + 'px';
        dialog.style.top = Number(dialog.style.top.replace('px', '')) + changedY + 'px';
      }
    };

    centerDialog();
    header.addEventListener('mousedown', startMove);
    header.addEventListener('mouseup', endMove);
    header.addEventListener('touchstart', startMove);
    header.addEventListener('touchend', endMove);
    window.addEventListener('mousemove', move);
    window.addEventListener('touchmove', move);
    window.addEventListener('resize', resize);
    return () => {
      header.removeEventListener('mousedown', startMove);
      header.removeEventListener('mouseup', endMove);
      header.removeEventListener('touchstart', startMove);
      header.removeEventListener('touchend', endMove);
      window.removeEventListener('mousemove', move);
      window.removeEventListener('touchmove', move);
      window.removeEventListener('resize', resize);
    };
  }, [dialog, header, uniqKey]);

  const customStyles = {
    content: {
      width: '100%',
      left: 0,
      top: 0,
      padding: 0,
      backgroundColor: 'rgb(8, 22, 43, 0.75)',
      border: 'none',
      overflow: 'hidden',
      inset: '0px',
      borderRadius: '0px',
      zIndex: 110,
    },
  };

  return (
    <Modal isOpen={props.showModal} style={customStyles} ariaHideApp={false}>
      <ClickAwayListener onClickAway={() => (props.outerClickClose ? props.close() : {})}>
        <Paper
          ref={(el) => setDialog(el)}
          id='custom-dialog'
          className={css.customDialog}
          sx={props.sx}
        >
          <Box className={css.texts}>
            <Box
              className={`${css.cdTitle} draggable`}
              ref={(el) => setHeader(el)}
              sx={{ borderColor: 'primary.main' }}
              /*draggable='true'
              onDragStart={(e) => onDragStart(e)}
              onDrag={(e) => onDrag(e)}*/
            >
              <Grid container>
                <Grid item xs={10}>
                  <Box className='dots'>
                    {typeof props.title === 'string' ? t(props.title) : props.title}
                  </Box>
                </Grid>
                <Grid item xs={2} textAlign='right'>
                  <Icon
                    icon='fa:times'
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.close()}
                  />
                </Grid>
              </Grid>
            </Box>
            {/*telo*/}
            <Box className={css.content}>{props.children}</Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Modal>
  );
}
