import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTheme } from 'theme/PaletteDTO';

import { SlideButton } from 'components/Buttons';

import { Box, styled, useTheme } from '@mui/material';

import NFQESLogo from '../../assets/NFQESLogo';
import ModalDialog from '../ModalDialog';
import { ModalContentProps, SetModalManagerInternalFunctions } from './ModalManager';

/*

    KOMPONENT sluzi ako zaklad pre ModalManager sluzbu.
    Mal by byt pouzity v index.tsx a zaobalovat dalsie sluzby do seba (pozor na pozitie Theme!).
    Aktualne komponent nevytvara ziaden context, ale neskor bude mozno treba vyuzit context napr. ci je modalne okno zobrazene a podobne.
*/

interface ModalProviderProps {
  children: ReactNode;
}

export default function ModalProvider({ children }: Readonly<ModalProviderProps>) {
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<any>(undefined);
  // POZOR! isCloseable urcuje, ci sa da modalne okno zatvorit kliknutim mimo okna!
  // Nezabranuje vsak zatvoreniu okna cez krizik!
  const [purpose, setPurpose] = useState<'info' | 'success' | 'warning' | 'error'>(undefined);
  const [closeable, setCloseable] = useState(true);
  const [resolveFn, setResolveFn] = useState<{ resolve: (_val: any) => void }>(undefined);
  const [maxWidth, setMaxWidth] = useState<string>(undefined);

  useEffect(() => {
    SetModalManagerInternalFunctions({
      setTitle,
      setShow,
      setContent,
      setCloseable,
      setResolveFn,
      setMaxWidth,
      setPurpose,
    });
  }, []);

  const handleClose = () => {
    resolveFn?.resolve?.(false);
    //setShow(false); // robi modal manager
  };

  return (
    <Box>
      <ModalDialog
        title={<ModalTitleComponent purpose={purpose} title={title} />}
        showModal={show}
        close={handleClose}
        outerClickClose={closeable}
        sx={{ width: maxWidth, maxWidth: 'min(800px, 100%)' }}
        uniqKey={Array.isArray(content) && content?.length > 0 && content?.[content.length - 1]?.key}
      >
        {content}
      </ModalDialog>
      {children}
    </Box>
  );
}

interface ConfirmModalProps {
  text: string;
  confirm?: string;
}

export function ConfirmModal({ resolve, text, confirm }: ModalContentProps<ConfirmModalProps>) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box py={2} dangerouslySetInnerHTML={{ __html: t(text || 'DEFAULT.ARE_YOU_SURE') }} />
      <Box textAlign='right'>
        <SlideButton margin='none' onClick={() => resolve(true)}>
          {t(confirm || 'DEFAULT.CONFIRM')}
        </SlideButton>
      </Box>
    </Box>
  );
}

function ModalTitleComponent({ title, purpose }: any) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (purpose) {
    console.log(theme.palette[purpose]);
    return (
      <Box px='4px'>
        <NFQESLogoSx color={purpose} />
        {typeof title === 'string' ? t(title) : title}
      </Box>
    );
  }

  return typeof title === 'string' ? t(title) : title;
}

const NFQESLogoSx = styled(NFQESLogo)(({ color, theme }: CustomTheme) => ({
  position: 'absolute',
  top: '-18px',
  left: '-14px',
  width: '28px',
  height: '28px',
  padding: '6px',
  border: '4px solid',
  borderRadius: '50%',
  color: '#FFF',
  background: theme.palette[color].main,
  borderColor: theme.palette.background.paper,
}));
