import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageDocumentArchive = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/document-archive/PageDocumentArchive'),
    'PageDocumentArchive'
  )
);
const PageDocumentArchiveArchive = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/document-archive/archive/PageDocumentArchiveArchive'),
    'PageDocumentArchiveArchive'
  )
);
const PageDocumentArchiveArchiving = lazy(() =>
  lazyRetry(
    () => import('pages/(public)/document-archive/document-archiving/PageDocumentArchiveArchiving'),
    'PageDocumentArchiveArchiving'
  )
);
const PageDocumentArchiveValidation = lazy(() =>
  lazyRetry(
    () =>
      import('pages/(public)/document-archive/document-validation/PageDocumentArchiveValidation'),
    'PageDocumentArchiveValidation'
  )
);
const PageDocumentArchiveValidationDetail = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/(public)/document-archive/document-validation/[id]/PageDocumentArchiveValidationDetail'
      ),
    'PageDocumentArchiveValidationDetail'
  )
);

const routesHashArchive: Array<DBRoute> = [
  {
    path: 'URL.QUALIFIED_ARCHIVE',
    name: 'DASHBOARD_ROUTES.QUALIFIED_ARCHIVE',
    icon: <Icon icon='solar:cloud-storage-bold' width={30} height={30} />,
    component: <PageDocumentArchive />,
    show: true,
    user: true,
    unlogged: false,
    admin: true,
    manager: true,
    worker: true,
    minLvlSubscription: 2,
    order: 3,
    submenu: [
      {
        path: 'URL.QUALIFIED_ARCHIVE_NEW',
        name: 'DASHBOARD_ROUTES.QUALIFIED_ARCHIVE_NEW',
        icon: 'fa:plus',
        component: <PageDocumentArchiveArchiving />,
        show: true,
        user: true,
        unlogged: false,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 2,
      },
      {
        path: 'URL.QUALIFIED_ARCHIVE_VALIDATE_NEW',
        name: 'DASHBOARD_ROUTES.QUALIFIED_ARCHIVE_VALIDATE_NEW',
        icon: 'fa:check-circle',
        component: <PageDocumentArchiveValidation />,
        show: true,
        user: true,
        unlogged: false,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 2,
      },
      {
        path: 'URL.QUALIFIED_ARCHIVE_LIST',
        name: 'DASHBOARD_ROUTES.QUALIFIED_ARCHIVE_LIST',
        icon: 'fa:list',
        component: <PageDocumentArchiveArchive />,
        show: true,
        user: true,
        unlogged: false,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 2,
      },
      {
        path: 'URL.QUALIFIED_ARCHIVE_VALIDATE_HASH',
        component: <PageDocumentArchiveValidationDetail />,
        show: false,
        user: true,
        unlogged: true,
        admin: true,
        manager: true,
        worker: true,
        minLvlSubscription: 2,
      },
    ],
  },
];

export default routesHashArchive;
