import { languages, resources } from 'translations/config';

const checkSubLang = (object: Object, prefix: string, output: any) => {
  Object.entries(object).forEach((entry: any[]) => {
    output[prefix + entry[0]] = entry[1] !== '';
    if (typeof entry[1] === 'object') {
      checkSubLang(entry[1], `${prefix}${entry[0]}.`, output);
    }
  });
};

const LangChecker = {
  test: () => {
    if (window?.location?.hostname?.includes?.('localhost')) {
      const output = {};
      languages.forEach((lang) => {
        output[lang] = {};
        checkSubLang(resources[lang], '', output[lang]);
      });
      const missing = [];
      languages.forEach((lang) => {
        Object.keys(output[lang]).forEach((key) => {
          if (!output[lang][key]) missing.push(`${lang}: ${key}`);
          languages.forEach((langToCheck) => {
            if (langToCheck !== lang) {
              if (!output[langToCheck][key]) {
                missing.push(`${langToCheck}: ${key}`);
              }
            }
          });
        });
      });
      if (missing.length > 0) {
        console.log('====== MISSING TRANSLATION ====== (localhost only)');
        console.log('');
        console.log('UPOZORNENIE! Tento nástroj neslúži na chýbajúce preklady v komponentoch!');
        console.log('Nástroj porovnáva a hľadá chýbajúce preklady v konfiguračných súboroch i18n!');
        console.log(
          'To znamená, že preklad musí existovať aspoň v 1 z konfiguračných súborov aby bol skontrolovaný.'
        );
        console.log('');
        console.log('Checked lang: ' + languages.join(','));
        console.log('');
        missing.forEach((mis) => {
          console.log(mis);
        });
        console.log('============== END ==============');
      }
    }
  },
};

export default LangChecker;
