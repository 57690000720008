import { authorizationService } from 'index';

import defaultService from 'service/nfqes/DefaultService';

import { handleErrors } from '../defaultServices/ErrorHandler';

type RequestMethod = 'POST' | 'PUT' | 'GET' | 'DELETE';

export default function sendRequestFunctions(url: string) {
  function sendRequestNoBody(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            ...customHeaders,
          },
        })
      )
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function sendRequestNoBodyPlainText(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            ...customHeaders,
          },
        })
      )
      .then((response) => handleErrors(response))
      .then((response) => response.text())
      .then((json) => json);
  }

  function sendRequestNoBodyNoUser(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return defaultService
      .customFetch(`${url}${request}`, {
        signal,
        method: method,
        headers: customHeaders,
        //credentials: 'same-origin',
      })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function sendRequestNoBodyNoUserBlob(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return defaultService
      .customFetch(`${url}${request}`, {
        signal,
        method: method,
        headers: customHeaders,
        //credentials: 'same-origin',
      })
      .then((response) => handleErrors(response))
      .then((response) => response.blob())
      .then((data) => data);
  }

  function sendRequestWithBodyNoUser(
    request: string,
    method: RequestMethod,
    body: string | FormData,
    bodyType: 'JSON' | 'FORM',
    signal?,
    customHeaders?
  ): Promise<any> {
    return defaultService
      .customFetch(`${url}${request}`, {
        signal,
        method: method,
        //credentials: 'same-origin',
        body: body,
        headers:
          bodyType === 'JSON'
            ? { 'Content-Type': 'application/json', ...customHeaders }
            : customHeaders,
      })
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function sendRequestWithBodyNoUserNoJSON(
    request: string,
    method: RequestMethod,
    body: string | FormData,
    bodyType: 'JSON' | 'FORM',
    signal?,
    customHeaders?
  ): Promise<any> {
    return defaultService
      .customFetch(`${url}${request}`, {
        signal,
        method: method,
        //credentials: 'same-origin',
        body: body,
        headers:
          bodyType === 'JSON'
            ? { 'Content-Type': 'application/json', ...customHeaders }
            : customHeaders,
      })
      .then((response) => handleErrors(response));
  }

  function sendRequestWithBody(
    request: string,
    method: RequestMethod,
    body: string | FormData,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            'Content-Type': 'application/json',
            ...customHeaders,
          },
          body: body,
        })
      )
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  function sendRequestNoBodyNoJSON(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            ...customHeaders,
          },
        })
      )
      .then((response) => handleErrors(response));
  }

  function sendRequestNoBodyNoUserNoJSON(
    request: string,
    method: RequestMethod,
    signal?,
    customHeaders?
  ): Promise<any> {
    return defaultService
      .customFetch(`${url}${request}`, {
        signal,
        method: method,
        //credentials: 'same-origin',

        headers: {
          ...customHeaders,
        },
      })
      .then((response) => handleErrors(response));
  }

  function sendRequestWithBodyNoJSON(
    request: string,
    method: RequestMethod,
    body: string | FormData,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            'Content-Type': 'application/json',
            ...customHeaders,
          },
          body: body,
        })
      )
      .then((response) => handleErrors(response));
  }

  function sendRequestWithFormDataBody(
    request: string,
    method: RequestMethod,
    body: string | FormData,
    signal?,
    customHeaders?
  ): Promise<any> {
    return authorizationService
      .getUser()
      .then((user) =>
        defaultService.customFetch(`${url}${request}`, {
          signal,
          method: method,
          //credentials: 'same-origin',

          headers: {
            Authorization: 'Bearer ' + user.access_token,
            ...customHeaders,
          },
          body: body,
        })
      )
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((json) => json);
  }

  return {
    sendRequestNoBody,
    sendRequestNoBodyNoUser,
    sendRequestNoBodyNoUserBlob,
    sendRequestWithBodyNoUser,
    sendRequestWithBodyNoUserNoJSON,
    sendRequestNoBodyNoJSON,
    sendRequestNoBodyNoUserNoJSON,
    sendRequestWithBody,
    sendRequestWithBodyNoJSON,
    sendRequestWithFormDataBody,
    sendRequestNoBodyPlainText,
  };
}
