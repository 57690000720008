import { ReactComponent as SuccessSVG } from 'assets/img/cert/icon-active.svg';
import { ReactComponent as ErrorSVG } from 'assets/img/cert/icon-inactive.svg';
import { ReactComponent as AuthSVG } from 'assets/img/twofa/auth.svg';
import { ReactComponent as EmailSVG } from 'assets/img/twofa/email.svg';
import { ReactComponent as OcraSVG } from 'assets/img/twofa/ocra.svg';
import { ReactComponent as SmsSVG } from 'assets/img/twofa/sms.svg';
import { ReactComponent as VirtualSVG } from 'assets/img/twofa/virtual.svg';
import PropTypes from 'prop-types';

import { Box, Paper, styled, Typography, useTheme } from '@mui/material';

interface IconWrapperProps {
  theme: any;
  isDisabled: boolean;
}

const IconWrapper = styled('div')<IconWrapperProps>(({ theme, isDisabled }: IconWrapperProps) => ({
  width: '100%',
  maxWidth: '120px',
  maxHeight: '100px',
  display: 'flex',
  justifyContent: 'center',

  '& svg': {
    width: '100%',
    maxWidth: '100px',
    maxHeight: '100px',
    fill: isDisabled
      ? `${theme.palette.common.white} !important`
      : `${theme.palette.grey[100]} !important`,
  },

  // Media queries for maxWidth
  [theme.breakpoints.up('xs')]: {
    maxWidth: '60px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '80px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '120px',
  },
}));

const getType = (type, challenge) => {
  switch (type) {
    case 0:
      return 'SMS';
    case 1:
      return 'EMAIL';
    case 2:
      return 'OCRA';
    case 3:
      return 'VIRTUAL_TOKEN';
    case 5:
      if (challenge) {
        return 'VIRTUAL_TOKEN';
      }
      return 'OCRA_APP';
  }
};
const ShowIcon = (icon) => {
  switch (icon) {
    case 0:
      return <SmsSVG />;
    case 1:
      return <EmailSVG />;
    case 2:
      return <OcraSVG />;
    case 3:
      return <VirtualSVG />;
    case 5:
      return <AuthSVG />;
    case 'success':
      return <SuccessSVG />;
    case 'error':
      return <ErrorSVG />;
    default:
      break;
  }
};

const GridItem = ({ icon, name, subtext, onClick, disabled = false, minLevel = false }) => {
  const theme = useTheme();
  const mode = localStorage.getItem('mode');

  const bgColor = () => {
    if (!disabled) {
      return mode === 'light' ? theme.palette.info.contrastText : theme.palette.primary.dark;
    } else {
      return theme.palette.text.disabled;
    }
  };
  return (
    <Paper
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        borderColor: disabled
          ? ''
          : mode === 'light'
            ? theme.palette.info.contrastText
            : theme.palette.primary.dark,
      }}
      variant='outlined'
      onClick={onClick}
    >
      <IconWrapper
        sx={{
          backgroundColor: bgColor,
          borderRadius: '10px 0 0 10px',
        }}
        theme={theme}
        isDisabled={disabled ? true : false}
      >
        {ShowIcon(icon)}
      </IconWrapper>
      <Typography ml={2} sx={{ textDecoration: disabled && !minLevel ? 'underline' : 'none' }}>
        <b>{name}</b>
        <Box component='span' sx={{ display: { xs: 'none', sm: 'inherit' } }}>
          {subtext}
        </Box>
      </Typography>
    </Paper>
  );
};

GridItem.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string,
  subtext: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  minLevel: PropTypes.bool,
};

export { getType, ShowIcon, GridItem };
