import { handleErrors } from '../defaultServices/ErrorHandler';

export default function nexUServiceFunction() {
  function checkNexU() {
    return fetch('https://localhost:9895/nexu-info')
      .then((response) => handleErrors(response))
      .then((response) => response.json());
  }

  function getCertificate() {
    return fetch('http://localhost:9795/rest/certificates', { method: 'POST' })
      .then((response) => handleErrors(response))
      .then((response) => response.json());
  }

  function signFile(key, token, file) {
    const data: any = {
      tokendId: token,
      digestAlgorithm: 'SHA256',
      keyId: key,
      toBeSigned: file,
    };
    return fetch('http://localhost:9795/rest/sign', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json; charset=UTF-8',
        Referer: undefined,
        'Sec-Fetch-Site': 'cross-site',
      },
      body: JSON.stringify(data),
    })
      .then((response) => handleErrors(response))
      .then((response) => response.json());
  }

  return {
    checkNexU,
    getCertificate,
    signFile,
  };
}
