import sendRequestFunctions from 'service/common/sendRequestFunctions';

export default function fileGatewayServiceFunction() {
  const { sendRequestNoBodyNoUserBlob, sendRequestNoBodyNoUser } = sendRequestFunctions(
    process.env.REACT_APP_NFQES_API_URL
  );

  function getMessage(msgId: string, uid: string) {
    return sendRequestNoBodyNoUser(`/file-gateway/public/${msgId}?userUuid=${uid}`, 'GET');
  }

  function getMessageContainers(msgId: string, uid: string) {
    return sendRequestNoBodyNoUser(
      `/file-gateway/public/${msgId}/file-containers?userUuid=${uid}`,
      'GET'
    );
  }

  function getFile(id: string, fileId: string, uid: string) {
    return sendRequestNoBodyNoUserBlob(
      `/file-gateway/public/${id}/file/${fileId}/download?userUuid=${uid}`,
      'GET'
    );
  }

  function getMessageConfig(msgId: string) {
    return sendRequestNoBodyNoUser(`/file-gateway/public/messageConfig/${msgId}`, 'GET');
  }

  return { getMessage, getMessageContainers, getFile, getMessageConfig };
}
