import i18n from 'translations/config';

export function formatBornDate(date: string | Date): string {
  const dateObj = new Date(date);
  if (date === undefined || isNaN(dateObj.getTime())) return '';
  let day = String(dateObj.getDate());
  if (day.length < 2) day = `0${day}`;
  let month = String(dateObj.getMonth() + 1);
  if (month.length < 2) month = `0${month}`;
  let year = String(dateObj.getFullYear());
  if (year.length < 4) day = `0${year}`;
  return `${day}.${month}.${year}`;
}

export function formatDate(date: string | Date, showTime = false): string {
  const dateObj = new Date(date);
  if (date === undefined || isNaN(dateObj.getTime())) return '';
  const lang = i18n.language;
  if (showTime) return dateObj.toLocaleString(lang === 'sk' ? 'sk-SK' : 'en-US');
  else return dateObj.toLocaleDateString(lang === 'sk' ? 'sk-SK' : 'en-US');
}

export function excelFormatDateTime(date: string): string {
  if (!date) return '';
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  }).format(new Date(date));
}

export function formatDateTime(date: string | Date): string {
  const dateObj = new Date(date);
  if (!date) return '';
  const lang = i18n.language;
  return new Intl.DateTimeFormat(lang === 'sk' ? 'sk-SK' : 'en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  }).format(dateObj);
}

export function excelFormatDateOnly(date: string | Date): string {
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
}
