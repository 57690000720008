import { authorizationService } from 'index';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Page from 'containers/Page/Page';

import { services } from 'context/context';

import { InfoBox } from 'components/InfoBox';
import { ModalManager, TwoFaDialog, TwoFaDialogProps } from 'components/Modal';

import i18n from 'translations/config';

export default function PageElectronicMailboxPublicMessageDownload() {
  const { t } = useTranslation();
  const params = useParams();
  const { messageUuid, recipientUuid } = params;

  const location = useLocation();
  const navigate = useNavigate();
  const { messageService } = useContext(services);

  const [linkValidity, setLinkValidity] = useState<'valid' | 'expired' | 'used' | 'error'>();

  const checkLinkValidity = useCallback(
    () =>
      messageService
        .checkDownloadLinkValidity(messageUuid, recipientUuid)
        // then by mal dostat ine hodnoty, toto je tweaknute, aby to zozralo nespravny response z BE, kde je chyba
        .then((resp) => {
          if (resp) setLinkValidity('valid');
        })
        .catch(() => setLinkValidity('error')),
    [messageService, messageUuid, recipientUuid]
  );

  const { changeLanguage } = i18n;
  useEffect(() => {
    // prihlaseneho usera presmeruje na spravu v schranke
    if (authorizationService.isLoggedIn()) {
      messageService
        .getMessageIdPublic(messageUuid, recipientUuid)
        .then((id) => navigate(t('URL.ELECTRONIC_MAILBOX_DETAIL').replace(':id', id)))
        .catch(() => {});
    }
    //neprihlasenemu nastavi jazyk, skontroluje ci je linka este platna, ak ano zavola dvojfaktor
    else {
      // nastavenie jazyka
      const language = location.pathname.includes('sprava') ? 'sk' : 'en';
      sessionStorage.setItem('lang', language);
      changeLanguage(language);

      // kontrola platnosti linky, ak nie, zobrazi sa mu InfoBox, preco nie je platna. ak je platna
      checkLinkValidity();
    }
  }, [messageService, messageUuid, recipientUuid, checkLinkValidity]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint je zablokovany kvoli t, changeLanguage, navigate a location - sposobovali nekonecny rerender, aj po jednom

  const downloadAfterSuccess = useCallback(
    (token: string, _method: number, _level: number, sessionId: string) =>
      messageService
        .getSignedDocumentsPublicMessage(messageUuid, recipientUuid, token, sessionId)
        .then(async (resp) => {
          const fileName = resp.headers.get('Content-Disposition').split(`filename=`)[1];
          const blobPart = await resp.blob();
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([blobPart]));
          link.download = fileName;
          link.click();

          checkLinkValidity();
          ModalManager.close('download_signed_documents_public_msg');

          return { status: 200 };
        })
        .catch(() => {}),
    [messageService, messageUuid, recipientUuid, checkLinkValidity]
  );

  const downloadPublicMessageService = useMemo(
    () => ({
      send2fa: (_operation: number, _operationName?: string, _method?: number, _level?: number) =>
        messageService.send2faToDownloadSignedDocumentsPublicMessage(messageUuid, recipientUuid),
      getUserMethods: () =>
        new Promise((resolve) => {
          resolve({ items: [{ method: 1, level: 2 }] });
        }) as Promise<{ items: { method: 0 | 1 | 2 | 3 | 4 | 5; level: number }[] }>,
    }),
    [messageService, messageUuid, recipientUuid]
  );

  useEffect(() => {
    if (linkValidity === 'valid') {
      ModalManager.open<TwoFaDialogProps>(
        '',
        TwoFaDialog,
        {
          allowedMethods: [1],
          operation: 1,
          operationName: 'email',
          afterSuccessAction: downloadAfterSuccess,
          smsService: downloadPublicMessageService,
          user: { nextSubscriptionReset: new Date(), subscription: 0, role: 'user' },
        },
        {},
        'download_signed_documents_public_msg'
      );
    }
  }, [
    linkValidity,
    messageService,
    messageUuid,
    recipientUuid,
    downloadAfterSuccess,
    downloadPublicMessageService,
  ]);

  return (
    <Page>
      {linkValidity && linkValidity !== 'valid' && (
        <InfoBox type={linkValidity === 'used' ? 'success' : 'error'}>
          {t(`EL_MAILBOX.PUBLIC_INFO.DOWNLOAD_${linkValidity.toUpperCase()}`)}
        </InfoBox>
      )}
    </Page>
  );
}
