import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { services } from 'context/context';

import { SlideButton } from 'components/Buttons';

import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';

import Notification from './Notification';

import css from './HeaderNotifications.module.css';

interface IProps {
  notifications: INotification[];
  setNotifications: (_val: any) => void;
  totalCount: number;
  unreadCount?: number;
  setUnreadCount?: (_val: any) => void;
  load: (_page: number, _onlyUnread: boolean) => void;
  loading: boolean;
  mobile: boolean;
  dashboard?: boolean;
  closeNotificationBox?: () => void;
}

export interface INotification {
  id: number;
  isRead: boolean;
  isSent?: boolean;
  notificationKey: string;
  notificationTypeDTO?: { name: string; description: string };
  params: {
    messageText?: string;
    sender?: string;
    messageSubject?: string;
    messageId?: string;
    deviceName?: string;
    companyName?: string;
    memberName?: string;
    customer?: string;
    hubCompany?: string;
    type?: string;
    limit?: string;
  };
  recipient?: string;
  sentDate?: Date;
}

export default function NotificationBox(props: IProps) {
  const { closeNotificationBox } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyService } = useContext(services);
  const { load, loading } = props;

  const [onlyUnread, setOnlyUnread]: [boolean, Function] = useState(false);
  const [page, setPage]: [number, Function] = useState(0);
  const maxPage = Math.floor((onlyUnread ? props.unreadCount : props.totalCount) / 5 - 1);

  const increasePage = useCallback(() => {
    if (props.loading) return;
    setPage((oldPage) => (oldPage >= Math.ceil(props.totalCount / 5) - 1 ? oldPage : oldPage + 1));
  }, [props.loading, props.totalCount]);

  useEffect(() => {
    const notiBox = document.getElementById(
      props.mobile
        ? 'notificationBoxMobile'
        : props.dashboard
          ? 'notificationDashBox'
          : 'notificationBox'
    );

    const handleScroll = () => {
      const missing = notiBox.scrollHeight - notiBox.offsetHeight - notiBox.scrollTop;
      if (missing < 50 && !loading && page < maxPage) {
        increasePage();
      }
    };

    notiBox?.addEventListener('scroll', handleScroll);

    return () => {
      notiBox?.removeEventListener('scroll', handleScroll);
    };
  }, [
    increasePage,
    loading,
    page,
    props.totalCount,
    props.mobile,
    props.dashboard,
    onlyUnread,
    props.unreadCount,
    maxPage,
  ]);

  useEffect(() => load(page, onlyUnread), [page, onlyUnread, load]);

  const handleCheckboxChange = () => {
    setOnlyUnread((old) => !old);
    setPage(0);
  };

  const readAllClickHandle = () => {
    notifyService
      .readAllNotify()
      .then(() => {
        props.setNotifications((prev) =>
          prev.map((notification) => ({ ...notification, isRead: true }))
        );
        props.setUnreadCount(0);
      })
      .catch(() => {});
  };

  const readNotify = (index) => {
    notifyService.readNotify(props.notifications[index].id).then(() => {
      if (!onlyUnread)
        props.setNotifications((prev) => [
          ...prev.slice(0, index),
          { ...prev[index], isRead: true },
          ...prev.slice(index + 1),
        ]);
      else props.setNotifications((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);

      props.setUnreadCount((prev) => prev - 1);
    });
  };

  const handleSettingsClick = () => {
    navigate(t('URL.NOTIFICATIONS_SETTINGS'));
    closeNotificationBox?.();
  };

  const handleMiddleClick = () => t('URL.NOTIFICATIONS_SETTINGS');

  return (
    <Box className={css.outerBox}>
      <Box className={css.headerBox}>
        <SlideButton
          secondary
          small
          disabled={!onlyUnread}
          onClick={handleCheckboxChange}
          style={{
            padding: '6px 12px',

            marginRight: '5px',
          }}
        >
          {t('NOTIFICATIONS.ALL_NOTIF')}
        </SlideButton>
        <SlideButton
          secondary
          small
          disabled={onlyUnread}
          onClick={handleCheckboxChange}
          style={{
            padding: '6px 12px',
            zIndex: '11',
          }}
        >
          {t('NOTIFICATIONS.ONLY_UNREAD')} {/* zIndex 11 je kvoli trom bodkam vpravo */}
        </SlideButton>
        <Box className={css.settings}>
          <Box className={css.menu}>
            <Typography variant='h3' color={'text.primary'}>
              <Icon icon='fe:elipsis-v' />
            </Typography>
          </Box>
          <Box className={css.dropdown} bgcolor={'background.paper'}>
            <SlideButton
              small
              primary
              onClick={readAllClickHandle}
              icon='fa:check'
              style={{
                marginBottom: '0px !important',
                width: '250px !important',
              }}
              rightIcon
            >
              {t('NOTIFICATIONS.MARK_AS_READ')}
            </SlideButton>
            <hr />
            <SlideButton
              small
              primary
              onClick={handleSettingsClick}
              onMiddleClick={handleMiddleClick}
              style={{
                marginTop: '0px !important',
                width: '250px !important',
              }}
              icon='fa:cog'
              rightIcon
            >
              {t('NOTIFICATIONS.SETTINGS')}
            </SlideButton>
          </Box>
        </Box>
      </Box>
      <Box
        id={
          props.mobile
            ? 'notificationBoxMobile'
            : props.dashboard
              ? 'notificationDashBox'
              : 'notificationBox'
        }
        className={css.notifyMsgBox}
      >
        {props.notifications.map((item: INotification, index: number) => (
          <Notification
            notification={item}
            key={item.id}
            index={index}
            readNotify={readNotify}
            closeNotificationBox={closeNotificationBox}
          />
        ))}
        {props.notifications.length === 0 && (
          <Typography variant='body1' color={'text.primary'}>
            {t('NOTIFICATIONS.NO_NEW')}
          </Typography>
        )}
        {props.loading && (
          <Typography variant='body1' color={'text.primary'}>
            {t('EL_MAILBOX.LOADING')}
          </Typography>
        )}
        {props.notifications.length > 0 && page >= maxPage && (
          <Typography variant='body1' color={'text.primary'}>
            {t('NOTIFICATIONS.NO_MORE')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
