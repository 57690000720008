import { lazy } from 'react';

import { DBRoute } from 'router/DashboardRoutes';

import { Icon } from '@iconify/react';

import { lazyRetry } from '../Router';

const PageOnlineCertificates = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/my-certificates/online-certificates/PageOnlineCertificates'),
    'PageOnlineCertificates'
  )
);
const PageDownloadableCertificates = lazy(() =>
  lazyRetry(
    () =>
      import('pages/eidas/my-certificates/downloadable-certificates/PageDownloadableCertificates'),
    'PageDownloadableCertificates'
  )
);
const PageQSCDCards = lazy(() =>
  lazyRetry(() => import('pages/eidas/my-certificates/QSCD-cards/PageQSCDCards'), 'PageQSCDCards')
);
const PageQSCDCardDetail = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/my-certificates/QSCD-cards/[id]/PageQSCDCardDetail'),
    'PageQSCDCardDetail'
  )
);
const PageTLSCertificates = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/my-certificates/TLS-certificates/PageTLSCertificates'),
    'PageCertificateRequests'
  )
);
const PageCertificateRequests = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/my-certificates/certificate-requests/PageCertificateRequests'),
    'PageCertificateRequests'
  )
);
const PageCreateCertificate = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/create-certificate/PageCreateCertificate'),
    'PageCreateCertificate'
  )
);
const PageCreateCertificateCard = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/create-certificate/card/[cardId]/PageCreateCertificateCard'),
    'PageCreateCertificateCard'
  )
);
const PageCreateCertificateCompanyId = lazy(() =>
  lazyRetry(
    () =>
      import('pages/eidas/create-certificate/[id]/company/[idComp]/PageCreateCertificateCompanyId'),
    'PageCreateCertificateCompanyId'
  )
);
const PageCreateCertificateCompany = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/create-certificate/company/[idComp]/PageCreateCertificateCompany'),
    'PageCreateCertificateCompany'
  )
);
const PageCreateCertificateCompanyMemberDepartment = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/eidas/create-certificate/company/[idComp]/member/[member]/department/[ou]/PageCreateCertificateCompanyMemberDepartment'
      ),
    'PageCreateCertificateCompanyMemberDepartment'
  )
);
const PageCreateCertificateCardId = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/create-certificate/[id]/card/[cardId]/PageCreateCertificateCardId'),
    'PageCreateCertificateCardId'
  )
);
const PageCreateCertificateNoCardId = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/create-certificate/[id]/card/PageCreateCertificateNoCardId'),
    'PageCreateCertificateNoCardId'
  )
);
const PageCertificateDetail = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/my-certificates/[id]/PageCertificateDetail'),
    'PageCertificateListDetail'
  )
);
const PageCertificateRequestDetail = lazy(() =>
  lazyRetry(
    () =>
      import('pages/eidas/my-certificates/certificate-requests/[id]/PageCertificateRequestDetail'),
    'PageCertificateListRequestDetail'
  )
);
const PageCertificateListDetail = lazy(() =>
  lazyRetry(
    () => import('pages/eidas/certificate-list/[id]/PageCertificateListDetail'),
    'PageCertificateListDetail'
  )
);
const PageCertificateListRequestDetail = lazy(() =>
  lazyRetry(
    () =>
      import(
        'pages/eidas/certificate-list/certificate-request/[id]/PageCertificateListRequestDetail'
      ),
    'PageCertificateListRequestDetail'
  )
);

const routesCertificates: Array<DBRoute> = [
  {
    path: 'URL.CERTIFICATES_MENU',
    name: 'DASHBOARD_ROUTES.MY_CERTIFICATES',
    icon: <Icon icon='ph:certificate-duotone' width={30} height={30} />,
    component: <PageOnlineCertificates />,
    show: true,
    user: true,
    order: 8,
    submenu: [
      {
        path: 'URL.MY_CERTIFICATES_ONLINE',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_ONLINE',
        icon: 'tabler:file-certificate',
        component: <PageOnlineCertificates />,
        show: true,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATES_DOWNLOADABLE',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_DOWNLOADABLE',
        icon: 'tabler:file-certificate',
        component: <PageDownloadableCertificates />,
        show: true,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATES_CARD',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_CARD',
        icon: 'tabler:certificate',
        component: <PageQSCDCards />,
        show: true,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATES_TLS',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_TLS',
        icon: 'tabler:certificate',
        component: <PageTLSCertificates />,
        show: true,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATES_CARD_DETAIL',
        component: <PageQSCDCardDetail />,
        show: false,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATES_REQUESTS',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_REQUESTS',
        icon: 'fa:send',
        component: <PageCertificateRequests />,
        show: true,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_WITHOUT_ID',
        name: 'DASHBOARD_ROUTES.GENERATE_REQUEST',
        icon: 'fa:plus-circle',
        component: <PageCreateCertificate />,
        show: true,
        user: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_WITHOUT_ID_CARD',
        component: <PageCreateCertificateCard />,
        show: false,
        user: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_COMPANY_USER',
        component: <PageCreateCertificateCompany />,
        show: false,
        user: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_COMPANY_ADMIN',
        component: <PageCreateCertificateCompanyId />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_COMPANY_FOR_USER',
        component: <PageCreateCertificateCompanyMemberDepartment />,
        show: false,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_COMPANY_FOR_ADMIN',
        component: <PageCreateCertificateCompanyMemberDepartment />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_WITHOUT_ID_CARD_NEW',
        component: <PageCreateCertificateCompanyMemberDepartment />,
        show: false,
        user: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATE_DETAIL',
        component: <PageCertificateDetail />,
        show: false,
        user: true,
        //onlyFinishedRegister: true,
      },
      {
        path: 'URL.MY_CERTIFICATE_REQUEST_DETAIL',
        component: <PageCertificateRequestDetail />,
        show: false,
        user: true,
        //onlyFinishedRegister: true,
      },
    ],
  },
  {
    path: 'URL.CERTIFICATES_MENU',
    name: 'DASHBOARD_ROUTES.CERTIFICATES',
    icon: <Icon icon='ph:certificate-duotone' width={30} height={30} />,
    component: <PageOnlineCertificates />,
    show: true,
    user: false,
    admin: true,
    manager: true,
    worker: true,
    onlyFinishedRegister: true,
    order: 2.1,
    submenu: [
      {
        path: 'URL.CERTIFICATES_ONLINE',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_ONLINE',
        icon: 'tabler:file-certificate',
        component: <PageOnlineCertificates />,
        show: true,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATES_DOWNLOADABLE',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_DOWNLOADABLE',
        icon: 'tabler:file-certificate',
        component: <PageDownloadableCertificates />,
        show: true,
        user: true,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATES_CARD',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_CARD',
        icon: 'tabler:certificate',
        component: <PageQSCDCards />,
        show: true,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATES_TLS',
        name: 'DASHBOARD_ROUTES.CERTIFICATES_TLS',
        icon: 'tabler:certificate',
        component: <PageTLSCertificates />,
        show: true,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATES_CARD_DETAIL',
        component: <PageQSCDCardDetail />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATE_REQUESTS',
        name: 'DASHBOARD_ROUTES.CERTIFICATE_REQUESTS',
        icon: 'fa:send',

        component: <PageCertificateRequests />,
        show: true,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST',
        component: <PageCreateCertificate />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_CARD',
        component: <PageCreateCertificateCardId />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.GENERATE_REQUEST_CARD_NEW',
        component: <PageCreateCertificateNoCardId />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATE_DETAIL',
        component: <PageCertificateListDetail />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
      {
        path: 'URL.CERTIFICATE_REQUEST_DETAIL',
        component: <PageCertificateListRequestDetail />,
        show: false,
        user: false,
        admin: true,
        manager: true,
        worker: true,
        onlyFinishedRegister: true,
      },
    ],
  },
];

export default routesCertificates;
