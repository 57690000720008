import { Typography } from '@mui/material';

import css from './Labels.module.css';

interface InputLabelProps {
  htmlFor?: string;
  className?: string;
  style?: any;
  size?: number;
  noUpperCase?: boolean;
  children?: any;
  required?: boolean;
}

export default function InputLabel({
  className = undefined,
  style = {},
  size = 2,
  noUpperCase = false,
  children = '',
  htmlFor,
  required,
}: Readonly<InputLabelProps>) {
  const cssSize = `size_${size}`;

  return (
    <label htmlFor={htmlFor}>
      <Typography
        variant='body1'
        fontWeight={'500'}
        className={`${css.inputLabel} ${className} ${css[cssSize]} ${
          noUpperCase ? '' : css.upperCase
        }`}
        style={style}
      >
        {children}
        {required && <span style={{ color: 'red' }}> *</span>}
      </Typography>
    </label>
  );
}
